import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import Search from "components/Search/Search";
import AlertBox from "components/AlertBox/AlertBox";
import { bookingStatus, findBookingStatusByValue } from "./bookingStatus";
import { fetchCities } from "services/RegionService";
import { formatDate, formatSingleTimeslot } from "utils/commons";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import moment from "moment";
import BroadcastTimer from "./BroadcastTimer";
import { trackShowOrdersClick,trackBookingsSearchKeyword, trackAcceptingOrderClick, trackDenyingOrderClick  } from "analytics";
import { getUserId } from 'services/UserService';


const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const BASE_URL_UPCOMING_BOOKINGS = "/homecare-waiting-broadcast/";

const FILTER_OPTIONS = [
  {
    key: "ALL",
    value: "ALL",
    text: "All",
  },
  {
    key: "NEW",
    value: "NEW",
    text: "New",
  },
  {
    key: "ACTIVE",
    value: "ACTIVE",
    text: "Active",
  },
  {
    key: "COMPLETED",
    value: "COMPLETED",
    text: "Completed",
  },
  {
    key: "CANCELLED",
    value: "CANCELLED",
    text: "Cancelled",
  },
];
class UpcomingOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookings: [],
      booking: {},

      cities: [],
      labs: [],
      errors: {},
      editables: {},
      filter: { status: "ALL" },
      loadError: null,
      searchTerm: null,
    };

    this.columns = [
      {
        name: "Actions",
        selector: "order",

        minWidth: "180px",
        cell: (row) => this.renderActions(row),
      },
      {
        name: "Order ID ",
        selector: "orderId",
        sortField: "orderId",
        sortable: true,
      },
      {
        name: "Product Name",
        selector: "packageName",
        wrap: true,
        sortable: true,
        format: (order) => order.packageName ?? "",
      },
      {
        name: "Appointment Date & Time",
        selector: "confirmed_date_time",
        wrap: true,
        sortable: true,

        format: (order) => this.renderAppointmentTime(order),
      },
      {
        name: "Location",
        selector: "address_line1",
        wrap: true,
        sortable: false,
        minWidth: "200px",
        format: (order) => this.getFullAddress(order),
      },

      {
        name: "Special Instructions",
        selector: "specialInstructions",
        wrap: true,
        sortable: true,
        minWidth:"200px",
        format: (order) => order.specialInstructions ? <p style={{fontWeight: "bold" }}>{order.specialInstructions}</p>: "",
      },
      {
        name: "Status",
        selector: "nurse_appointment_status",
        sortable: true,
        center: true,
        cell: (row) => this.renderBookingStatus(row),
      },
    ];
  }
    creatingCleverTapProfileUser=()=>{
    const userId= localStorage.getItem("userId");
    const email = localStorage.getItem("userEmail");
    return (window.clevertap.onUserLogin.push({
     "Site": {
       "Identity": userId,
       "Email": email,
       "MSG-email": false,
       "MSG-push": false,
       "MSG-sms": false,
       "MSG-whatsapp": false,
     },
     "cart_data": []
   }))
   }
  async componentDidMount() {
    await Promise.all([this.fetchUser(), this.fetchCities()]);

    const today = moment().format("YYYY-MM-DD");
    this.setState({ selectedStartDate: today, selectedEndDate: today });
    this.fetchBookings();
    this.creatingCleverTapProfileUser()
  }

  fetchUser = async () => {
    try {
      this.showLoading();
      const response = await API.get("/users/");
      const { data: users = [] } = response;
      console.log("fetchUser:: Response: ", { response });
      this.setState({ user: users && users.length ? users[0] : null });
    } catch (error) {
      console.log("fetchUser:: Error on fetching users!", error);
      this.showErrorNotification("Error on fetching user!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchCities = async () => {
    try {
      this.showLoading();
      const cities = (await fetchCities()) ?? [];
      this.setState({ cities });
    } catch (error) {
      this.showErrorNotification("Error on fetching cities!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchBookings = async () => {
    this.showLoading();
    const { user, selectedStartDate, selectedEndDate,   searchApplied,searchTerm } = this.state;
    console.log(
      "selected start and end dates are",
      selectedStartDate,
      selectedEndDate
    );
    if (!user || !user.homecare_service_id) {
      console.log("fetchBookings:: Invalid user!", { user });
      this.showErrorNotification("Invalid user!", { user });
      return;
    }
    const params = {};

    if (selectedStartDate)
      params.fromDate = moment(selectedStartDate).format("YYYY-MM-DD");
    if (selectedEndDate)
      params.toDate = moment(selectedEndDate).format("YYYY-MM-DD");

      if (searchApplied)
      params.searchValue = searchTerm ? searchTerm.trim() : searchTerm;

    try {
      const url = `${BASE_URL_UPCOMING_BOOKINGS}/${user.homecare_service_id}`;
      const { data: bookings = [] } = await PhoenixAPI.get(url, { params });

      this.setState({ bookings });
    } catch (error) {
      console.log("Error on fetching bookings!", error);
      this.showErrorNotification("Error on fetching bookings: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    this.setState({ searchApplied: true, searchTerm: queryString ?? "" });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      booking: {},
    });
  };

  renderAppointmentTime = (order) => {
    if (!order) {
      console.log("getAppointmentTime:: Invalid order!", { order });
      return "-";
    }
    const [appointmentDate, appointmentTime] =
      order && order.confirmedDateTime
        ? order.confirmedDateTime.split(" ")
        : [null, null];
    const formattedDate = appointmentDate ? formatDate(appointmentDate) : "";
    const formattedTimeslot = appointmentTime
      ? formatSingleTimeslot(appointmentTime)
      : "";
    return formattedDate || formattedTimeslot ? (
      <>
        <p className="mb-1">
          {formattedDate}
          <br />
          {formattedTimeslot}
        </p>
      </>
    ) : (
      ""
    );
  };

  getFullAddress = (order) => {
    if (!order) return "";

    const { addressLine1, addressLine2, buildingApt, city, country } = order;
    const location = [addressLine1, addressLine2, buildingApt, city, country]
      .filter((it) => it ?? false)
      // .join("\r\n");
      .join(", ");

    return location;
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  handeAccept = async (order) => {
    const loginUserId= getUserId();
    const payload = {
      broadcastBasketId: order.broadcastBasketId,
      homecareId: order.homecareId,
      orderId: order.orderId,
      isAccepted: true,
      isRejected: false,
      isWaiting: false,
      homecareLoginId:parseInt(loginUserId)
    };
    const {user}= this.state;
    const deviceId= sessionStorage.getItem("device_id") ??""
    const trackingData={
      "timeLeft":order?.timer ?? "00:00:00",
      "city":order.city,
      "country":order.country,
      "packageName":order.packageName,
       "homecareId":user?.homecare_service_id??"",
       "deviceId":deviceId,
       "homecare":user?.email??"",
       "homecareName":user?.username??"",

    }
    trackAcceptingOrderClick(trackingData)
    try {
      this.showLoading();
      const url = `broadcast-basket-homecare-response`;
      const repsponse = await PhoenixAPI.patch(url, payload);

      this.showSuccessNotification("Order Accepted Successfully.");
      this.fetchBookings();
    } catch (error) {
      console.log("Error in Accepting the order.", error.message);
      this.showErrorNotification(
        "Error in Accepting the order.",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleReject = async (order) => {
    const loginUserId= getUserId();
    const payload = {
      broadcastBasketId: order.broadcastBasketId,
      homecareId: order.homecareId,
      orderId: order.orderId,
      isAccepted: false,
      isRejected: true,
      isWaiting: false,
      homecareLoginId:parseInt(loginUserId)
    };
    const {user}= this.state;
    const deviceId= sessionStorage.getItem("device_id") ??""
    const trackingData={
      "timeLeft":order?.timer??"00:00:00",
      "city":order.city,
      "country":order.country,
      "packageName":order.packageName,
       "homecareId":user?.homecare_service_id ??"",
       "deviceId":deviceId,
       "homecare":user?.email??"",
       "homecareName":user?.username??"",
    }

    trackDenyingOrderClick(trackingData)
    try {
      this.showLoading();
      const url = `broadcast-basket-homecare-response`;
      const repsponse = await PhoenixAPI.patch(url, payload);

      this.showSuccessNotification("Order Rejected Successfully.");
      this.fetchBookings();
    } catch (error) {
      console.log("Error in Rejecting the order.", error.message);
      this.showErrorNotification(
        "Error in Rejecting the order.",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  renderActions = (order) => {
  
    return (
      <div>
        <div>Time Left:</div>
        {/* <div style={{ color: "#F77077" }}>{order.timer ?? ""}</div> */}
        <BroadcastTimer timer={order.timer ?? null }/>
        <div className="button-div">
        <div style={{ marginTop: "12px" }}>
          <button
            className="accept-btn"
            style={{ backgroundColor: "#CEE741", width:"110px", border:"none", padding:"5px", fontSize:"14px", borderRadius:"4px"}}
            onClick={() => this.handeAccept(order)}
          >
            Accept
          </button>
        </div>
        <div style={{ marginTop: "12px" }}>
          <button
            className="accept-btn"
            style={{ backgroundColor: "#F77077", color: "#FFFFFF", width:"110px", border:"none", padding:"5px", fontSize:"14px", borderRadius:"4px"}}
            onClick={() => this.handleReject(order)}
          >
            Deny
          </button>
        </div>
        </div>
       
      </div>
    );
  };

  renderBookingStatus = (booking) => {
    const status = "Upcoming";
    const color = "red";
    console.log("status: " + booking);

    return (
      <span
        className={`ui small ${color ?? "green"} label`}
        style={{ fontWeight: "normal" }}
      >
        {status ?? ""}
      </span>
    );
  };

  getSearchedBookings = (bookings) => {
    if (!bookings || !bookings.length) return [];

    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return bookings;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return bookings.filter((booking) => {
      const { orderId, packageName } = booking;
      return (
        (orderId && orderId.toString().includes(newValue)) ||
        (packageName && packageName.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  handleShowOrders = () => {
    this.fetchBookings();
  };

  handleDateSelect = ({ target: input }) => {
    if (input.name === "selectedStartDate" && this.state.selectedEndDate) {
      if (moment(input.value).isAfter(this.state.selectedEndDate)) {
        this.setState({
          selectedStartDate: input.value,
          selectedEndDate: input.value,
        });
      }
    }

    this.setState({ [input.name]: input.value });
  };
  handleSearchEnter=()=>{
    const { searchTerm, user } = this.state;
    const deviceId = sessionStorage.getItem("device_id") ?? "";
    const trackingData = {
      "searchKeyword": searchTerm,
      "homecareId": user?.homecare_service_id ??"",
      "deviceId": deviceId,
      "homecare": user?.email??"",
      "homecareName": user?.username??"",
      
    }
    trackBookingsSearchKeyword(trackingData)
    this.setState({ searchApplied: true }, () =>
    this.fetchBookings()
  );
  }

  render() {
    const {
      filter,
      bookings,
      searchTerm,
      searchApplied,
      loadError,

      selectedStartDate,
      selectedEndDate,
    } = this.state;

    const filteredBookings = this.getSearchedBookings(bookings);

    return (
      <div className="manage-package">
        <div className="row" style={{ gap: "20px", marginBottom: "30px" }}>
          <div className="" style={{ marginTop: "5px" }}>
            Start Date
          </div>
          <div className="">
            <div className="input-group user-input-container">
              <input
                value={selectedStartDate}
                onChange={this.handleDateSelect}
                // min={this.minStartDate}
                // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
                type="date"
                id="selectedStartDate"
                name="selectedStartDate"
                className="form-control py-3"
              />
            </div>
          </div>
          <div className="" style={{ marginTop: "5px" }}>
            End Date
          </div>
          <div className="">
            <input
              value={selectedEndDate}
              onChange={this.handleDateSelect}
              min={selectedStartDate ?? null}
              // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
              type="date"
              id="selectedEndDate"
              name="selectedEndDate"
              className="form-control py-3"
            />
          </div>
          <div className="">
            <button
              onClick={this.handleShowOrders}
              // disabled={!isEditable}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Show Orders
            </button>
          </div>
        </div>
        <div className="page-header">
          {/* <div className="col-3 ml-0 pl-0">
            <Dropdown
              value={filter.status ?? null}
              onChange={(event, value) => {
                this.setState({
                  filter: {
                    ...filter,
                    status: value.value,
                  },
                });
              }}
              id="filter-dropdown"
              name="filter-dropdown"
              search
              selection
              options={FILTER_OPTIONS}
              className="form-control"
            />
          </div> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchEnter={this.handleSearchEnter}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
            <button
              className="btn button-blue btn-md new-user"
              onClick={this.handleSearchEnter}
            >
              Search
            </button>
          </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={bookings ??[]}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + bookings.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingOrders);
