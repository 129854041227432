import React, { Component } from "react";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import PhoenixAPI from "utils/PhoenixAPI";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import ActionBar from "react-bootstrap/Dropdown";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SampleDetails from "pages/Packages/SampleDetails";
import PropTypes from "prop-types";

const STATUS_OPTIONS = [
    {
      key: "Active",
      value: true,
      text: "Active",
    },
    {
      key: "Inactive",
      value: false,
      text: "Inactive",
    },
  ];
  
  class PackageSampleDetails extends Component {
    constructor(props) {
      super(props);
      const {
               packageSamples,
              countryId,
              allSamples=[],
              packageSamplesUpdate=(f)=>f,
              isEditable,
              sampleOptions,
              type=""

      }= this.props || {};

      this.packageSamplesUpdate=packageSamplesUpdate;

      this.state = {
        packageSamples,
        packageSample:{},
        samples: allSamples ?? [],
        isPackageSampleViewModalVisible: false,
        errors: {},
        editables: {},
        loadError: "",
        sampleOptions,

    }
    this.columns = [
        {
          name: "Sample type",
          selector: "sample_detail_id",
          wrap: true,
          sortable: true,
          cell:(row)=>this.renderSampleType(this.props.type=="MiniPackage"?row.sampleDetailId:row.sample_detail_id)
        },
        {
            name:"Quantity",
            selector:"quantity",
            wrap: true,
            sortable: true,
            
         },
        {
            name:"Unit",
            selector:null,
            wrap: true,
            sortable: true,
            cell:(row)=>this.renderUnit(this.props.type=="MiniPackage"?row.sampleDetailId:row.sample_detail_id)
        },
        
        {
            name:"Actions",
            selector:null,

            allowOverflow: true,
            cell: (packageSample) => this.renderActionBar(packageSample),
            width: "100px",
            center: true,
        }
    ]
}


handleAddSample = () => {
    this.setState({ isNewSamplePackage: true }, () =>
      this.showPackageSampleViewModal()
    );
  };
  showPackageSampleViewModal = () => {
    const { isNewSamplePackage,packageSamples,sampleOptions,packageSample } = this.state;
     console.log("package sample",packageSample);
    const{type}=this.props;
    if (isNewSamplePackage) {
      const packageSample = {};
      if(packageSamples.length > 0){
      const packageSampleIds =type=="MiniPackage"?packageSamples.map(sample=>sample.sampleDetailId) :packageSamples.map(sample => sample.sample_detail_id);

    const filteredSampleOptions = this.props.sampleOptions.filter(option => !packageSampleIds.includes(option.key));

    this.setState({
      packageSample: packageSamples, // Assuming you want to store packageSamples in packageSample state
      sampleOptions: filteredSampleOptions,
      isPackageSampleViewModalVisible: true,
      });
      }

      else{
      this.setState({
          packageSample,
        isPackageSampleViewModalVisible: true,
      });
    } 
  }
    
    else {
      if(packageSamples.length > 0){
        const packageSampleIds =type=="MiniPackage"?packageSamples.filter(sample=>sample.sampleDetailId && sample.sampleDetailId!==packageSample.sampleDetailId).map(sample => sample.sampleDetailId) :packageSamples.filter(sample => sample.sample_detail_id && sample.sample_detail_id!==packageSample.sample_detail_id).map(sample => sample.sample_detail_id);
      console.log("package sample Ids",packageSampleIds)
      const filteredSampleOptions = this.props.sampleOptions.filter(option => !packageSampleIds.includes(option.key) );
      console.log("filtered options",filteredSampleOptions);
      this.setState({
        packageSample, // Assuming you want to store packageSamples in packageSample state
        sampleOptions: filteredSampleOptions,
        isPackageSampleViewModalVisible: true,
        });

        }

      else{
      this.setState({ packageSample,isPackageSampleViewModalVisible: true,});
      }
    }
  };

  
    resetForm = () => {
      this.setState({
        SampleDetails: {},
        errors: {},
        editables: {},
        packageSample:{},
        isNewSamplePackage: false ,
        
      });
    };
  

  hidePackageSampleViewModal = () => {
    this.setState({ isPackageSampleViewModalVisible: false });
    this.resetForm();
  };

  handleChange = ({ currentTarget: input }) => {
    const packageSample = { ...this.state.packageSample };
    packageSample[input.name] = input.value;
    this.setState({ packageSample });
  };

  renderSampleType = (sampleId)=>{
    const { sampleOptions = [] } = this.props || {};
    const sample = sampleOptions.find((it) => it.value === sampleId);

    console.log("sample options",sample);
    return sample? sample.text : "";
  }

  renderUnit = (sampleId)=>{
    console.log("samples",this.props.allSamples,sampleId);
    const sample = this.props.allSamples.find((it) => it.id == sampleId);
  
    if (sample) {
      return sample.units;
    }
    
  }

  renderActionBar = (packageSample) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleViewPackageSample(this.props.type=="MiniPackage"?packageSample.sampleDetailId:packageSample.sample_detail_id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.handleDeletePackageSample(this.props.type=="MiniPackage"?packageSample.sampleDetailId:packageSample.sample_detail_id)}>
        <DeleteIcon className="icon-small" style={{ fill: "red" }} />
        <span style={{ color: "red" }}>Remove</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );


  savePackageSample = async () => {
    const { packageSamples = [], packageSample } = this.state;
    const { allSamples = [] } = this.props || {};
    const ids = packageSamples.length > 0 ? packageSamples.map((it) => it.id) : null;
    packageSample.id = ids ? Math.max(...ids) + 1 : 1;
    packageSample.is_new = true;
    /*const filteredSamples = allSamples.filter(
      (it) => it.id === packageSample.sample_detail_id
    );
    packageSample.sample_detail_id =
      filteredSamples.length > 0 ? filteredSamples[0].id : "";*/

    this.setState({ packageSamples: [...this.state.packageSamples, packageSample] }, () => {
      this.onPackageSampleUpdatedLocally();
      this.showSuccessNotification(
        "Package Sample  Created Successfully!"
      );
      
    });
  };

  updatePackageSample = async (id) => {
    const { packageSamples = [], packageSample,previousSampleId} = this.state;
    const{type}=this.props;
  
    console.log("prev sample id",previousSampleId);
    let newSamples = [];
    if(packageSample.id){
    newSamples =packageSamples.map((it) => 
      (type === "MiniPackage" ? it.sampleDetailId : it.sample_detail_id) === id 
        ? packageSample
        : it);
    }
    else{
      newSamples = [
        ...packageSamples.filter((it) => 
          (type === "MiniPackage" ? it.sampleDetailId : it.sample_detail_id) !== previousSampleId
        ),
        packageSample
      ];
    }

    console.log("new samples",newSamples);

    this.setState({packageSamples: newSamples }, () => {
      this.showSuccessNotification(
        "Package Sample Updated Successfully!"
      );
      this.onPackageSampleUpdatedLocally();
    });
  };

  onPackageSampleUpdatedLocally = () => {
    // this.clearForm();
    console.log("update locally",this.state.packageSamples);
    this.packageSamplesUpdate(
      this.state.packageSamples.map((sample) => {
        const q = { ...sample };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  validate = () => {
    const { packageSample } = this.state;
    const errors = {};
    const {sample_detail_id, quantity } = packageSample || {};
    if(this.props.type=="MiniPackage"){
       if(this.state.packageSample.sampleDetailId==null)
        errors.sampleType = "Sample type cannot be blank";
    }
    else{
      if (sample_detail_id ==null)
      errors.sampleType = "Sample type cannot be blank";
    }
    if (quantity ==null) errors.quantity = "quantity cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSavePackageSample = async () => {
    const { packageSample, isNewSamplePackage } = this.state;
    const{type}=this.props;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const payload = { ...packageSample };
    try {
      this.showLoading();
      // const isValidId = suggestion.supplementId !== null && suggestion.supplementId !== undefined;
      const isValidId = isNewSamplePackage ?? false;

      const response = !isValidId
        ? await this.updatePackageSample(type=="MiniPackage"?payload.sampleDetailId:payload.sample_detail_id)
        : await this.savePackageSample();

      this.hidePackageSampleViewModal();
    } catch (error) {
      console.log("error occurred on saving suggestion", error.message);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleDeletePackageSample =  (sampleid) => {
    const { packageSamples } = this.state || {};
 const {type}=this.props;
 console.log("props are type is",type,packageSamples,sampleid);
    const updatedPackageSamples =type=="MiniPackage"?packageSamples.filter((it)=>it.sampleDetailId!=sampleid): packageSamples.filter(
      (item) => item.sample_detail_id != sampleid);
     console.log("package samples",updatedPackageSamples)
    this.setState({ packageSamples: updatedPackageSamples }, () =>
      this.onPackageSampleUpdatedLocally()
    );
  };

  

  handleViewPackageSample = (id) => {
   // this.clearForm();
   const {type}=this.props;
    const { packageSamples = [] } = this.state;
    const packageSample = type=="MiniPackage"?packageSamples.find((it)=>it.sampleDetailId===id):packageSamples.find((it) => it.sample_detail_id === id);
    if (!packageSample) return;

   console.log("package sample",id,packageSample)
    this.setState({ packageSample,previousSampleId:id }, () => this.showPackageSampleViewModal());
  };

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (!this.props.isEditable || this.state.isNewSamplePackage) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };


  

  

  

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  handleChange = ({ currentTarget: input }) => {
    const samplePackage = { ...this.state.samplePackage };
    samplePackage[input.name] = input.value;
    this.setState({ samplePackage });
  };


  render(){

    const { packageSamples, isEditable,packageSample ,sampleOptions,errors,editables,isNewSamplePackage} = this.state;

    const{type}=this.props;
    const detailsForm=( <div className="container mt-4">
        <div className="row">
           <div className="form-group col">
               <label htmlFor="Sample Type">Sample Type</label>
               <div className="input-group">
               <Dropdown
                value={type=="MiniPackage"?packageSample.sampleDetailId:packageSample.sample_detail_id}
                onChange={(event, value) => {
                  type=="MiniPackage"?this.setState({ packageSample:{...this.state.packageSample,sampleDetailId: value.value}}):this.setState({ packageSample:{...this.state.packageSample,sample_detail_id: value.value}}) ;
                }}
                id="sampledetailId"
                placeholder="sample type"
                search
                selection
                options={sampleOptions}
                disabled={!isNewSamplePackage && !editables.sampleType}
                className={
                  "form-control" + (errors.is_best_seller ? " has-error" : "")
                }
              />
               {this.renderPencilIcon("sampleType")}
                </div>
                 <span className="help-block">{errors.sampleType}</span>
                 </div>
                 <div className="form-group col">
                     <label htmlFor="units">Units</label>

                     <input
                         type="text"
                         className="form-control"
                         name="Units"
                         value={type=="MiniPackage"?(this.props.allSamples.find((it) => it.id === this.state.packageSample?.sampleDetailId))?.units:(this.props.allSamples.find((it) => it.id === this.state.packageSample?.sample_detail_id))?.units 
                          ?? ''}
                         readOnly={true}
                     />
                     </div>

                   </div>
                   <div className="row">
                    <div className="form-group col-6">

                        <label>Quantity {type=="MiniPackage"?"(in units)":""}</label>
                        <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            name="quantity"
                            value={packageSample.quantity??null}
                            onChange={(e)=>{this.setState({packageSample:{
                              ...this.state.packageSample,
                              quantity:e.target.value? Number(e.target.value):null
                            }})}}
                            disabled={!isNewSamplePackage && !editables.quantity}
                            onKeyPress={(event) => {
                              if(type=="MiniPackage"){
                              if (!/[0-9 || .]/.test(event.key)) event.preventDefault();
                              }
                              else{
                              if (!/[0-9]/.test(event.key)) event.preventDefault();
                              }


                            }}
                        />
                        {this.renderPencilIcon("quantity")}
                        
                    </div>
                    <span className="help-block">{errors.quantity}</span>
                </div>
                </div>
               </div>

    );

    return(
        <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col px-0">
        <Card>
           
            
                <DataTable
                    title="Sample Details"
                    columns={this.columns}
                    data={packageSamples}
                    defaultSortAsc={false}
                    sortIcon={<ArrowDownward></ArrowDownward>}
                    highlightOnHover
                // pagination
                responsive
                noDataComponent={
                    this.state.loadError ? (
                      <AlertBox
                        message={this.state.loadError}
                        error={true}
                      ></AlertBox>
                    ) : (
                      <AlertBox message="There's nothing here."></AlertBox>
                    )
                  }
                    
                />
                <button
                onClick={this.handleAddSample}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            
            
        </Card>
        </div>
            </div>
        <Modal
        size="lg"
        show={this.state.isPackageSampleViewModalVisible}
        onHide={this.hidePackageSampleViewModal}
        // className="user-data-modal"
      >
        <Modal.Header closeButton>
        <Modal.Title>
              {packageSample.id === null || packageSample.id === undefined
                ? "Add Sample"
                : "Edit Sample"}
            </Modal.Title>
          </Modal.Header>
        <Modal.Body>{detailsForm}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary rounded-pill px-4 py-2"
            onClick={this.hidePackageSampleViewModal}
          >
            Cancel
          </button>
          <button
            onClick={this.handleSavePackageSample}
            disabled={false}
            className="btn btn-lg btn-success rounded-pill px-4 py-2"
          >
            {packageSample.id ? "Save" : "Add"}
          </button>
        </Modal.Footer>
      </Modal>
      </div>
    )
  }

}

PackageSampleDetails.propTypes = {
  packageSamples: PropTypes.arrayOf(PropTypes.object).isRequired,
  packageSamplesUpdate: PropTypes.func.isRequired,
  allSamples:PropTypes.arrayOf(PropTypes.object).isRequired,
  sampleOptions:PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditable: PropTypes.bool.isRequired
};

PackageSampleDetails.defaultProps = {
  packageSamples : [],
  packageSamplesUpdate: (f) => f,
  allSamples:[],
  sampleOptions:[],
  isEditable: true
};

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PackageSampleDetails);


