import React, { useState, useEffect } from "react";
import "./heightDropdown.scss";

const HeightDropdown = ({type,heightWeight,onHeightWeightChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedHeight, setSelectedHeight] = useState(); // Track selected height
  const [savedHeight, setSavedHeight] = useState(null);
  const [nursePanelIsArabic,setNursePanelIsArabic] = useState("");

  useEffect(()=>{
    if(window.localStorage && localStorage.getItem("nursePanelIsArabic")){
      const isArabic = JSON.parse(localStorage.getItem("nursePanelIsArabic"));
      setNursePanelIsArabic(isArabic);
    }
  },[])

  const heightoptions = [
     // Placeholder option
    ...Array.from({ length: 101 }, (_, i) => ({
      key: i + 100,
      value: i + 100,
      text: (i + 100).toString(),
    })),
  ];



  const weightoptions=[
     // Placeholder option
    ...Array.from({ length: 190 }, (_, i) => ({
      key: i + 10,
      value: i + 10,
      text: (i + 10).toString(),
    })),

  ]

  // Toggle dropdown open or close
  const toggleDropdown = () => {
    console.log("open dropdown");
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    setSelectedHeight(heightWeight);
    setSavedHeight(heightWeight);
  }, [heightWeight]);

  const handleChange=(heightWeight)=>{
    setSelectedHeight(heightWeight);
    
  }
  
  const handleSave = () => {
    setSavedHeight(selectedHeight);
    onHeightWeightChange(selectedHeight);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element is inside the dropdown or button
     if(isOpen){

      if (isOpen && !event.target.closest(".heightdropdown-container")) {

        setIsOpen(false);
      }
    };
  };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
   
  }, [isOpen]);

  return (
    <div className="heightdropdown-container" key="height-container">
      <button className="heightdropdown-button" placeholder="Select Option" onClick={toggleDropdown}>

     <span> {savedHeight && savedHeight!=="null" ? `${parseInt(savedHeight)}` : nursePanelIsArabic? "انقر للاختيار" : "Click to select"} </span>
     <span className="cm-text"> {type=="height"?"  Cm":"  Kg"}</span>
      </button>

      {isOpen && (<>
        <div className="heightdropdown-menu-wrapper">
        <div className="close-modal" onClick={() => setIsOpen(false)}>
    <img
      src="https://d25uasl7utydze.cloudfront.net/assets/cross_icon_search.svg"
      alt="cross-icon"
      className="close-icon"
    />
  </div>
        <div className="heightdropdown-placeholder">
              {type === "height" ? nursePanelIsArabic? "حدد الطول" : "Select Height" : nursePanelIsArabic? "حدد الوزن" : "Select Weight"}
        </div>
        <div className="heightdropdown-menu" key="height-menu">
          {(type === "height" ? heightoptions : weightoptions).map((option) => (
            <div 
              key={option}
              className={`heightdropdown-item ${option.value === "" ? "heightdropdown-placeholder" : ""}`}
              onClick={() => {
                console.log("check option",option);
                if (option.value !== "") {
                    console.log(`Clicked: ${option.value}`);
                  handleChange(option.value); //
                  
                  // Close dropdown
                }
              }}
              style={{
                backgroundColor: selectedHeight === option.value ? "rgba(206, 231, 65, 0.23)" : "", // Apply highlight if selected
                pointerEvents: option.value === "" ? "none" : "auto", // Disable click for placeholder
                color: option.value === "" ? "#aaa" : selectedHeight === option.value ? "rgba(13, 59, 94, 1)" : "",
                fontSize: selectedHeight === option.value ? "24px" : "16px", // Font size for selected option
                fontWeight: selectedHeight === option.value ? "600" : "500", // Font weight for selected option
                zIndex: selectedHeight === option.value ? 999 : undefined,
                paddingLeft: selectedHeight === option.value ?type=="height"? "58px":"50px" : "",
                
              }}
            >
              {option.text} {selectedHeight === option.value && type === "height" ? (
                <span style={{ fontSize: "16px", marginLeft: "10px" }}>CM</span>
              ) : selectedHeight === option.value && type === "weight" ? (
                <span style={{ fontSize: "16px", marginLeft: "10px" }}>KG</span>
              ) : ""}
            </div>
          ))}
        </div>
        
      <button  className="heightdropdown-save-button"
        onClick={() => {
          // Call save functionality here
          console.log("Save button clicked");
          handleSave(); // Save the selected height or weight
          setIsOpen(false); // Close the dropdown after saving
        }}
      >
        {nursePanelIsArabic? "إحفظ" : "Save"}
      </button>
    
        </div>
        </>)}
      
      
       

     

    </div>
  );
};

export default HeightDropdown;