import React, { Component } from "react";
import { connect } from "react-redux";
import { ArrowDownward } from "@material-ui/icons";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import AlertBox from "components/AlertBox/AlertBox";
import SelectCity from "components/Common/SelectCity";
import SelectCountry from "components/Common/SelectCountry";
import Search from "components/Search/Search";
import PhoenixAPI from "utils/PhoenixAPI";
import { formatDate } from "utils/commons";
import { fetchCountries, fetchCities } from "services/RegionService";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { Link } from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import { Modal, Tabs, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import * as XLSX from 'xlsx';
const DEFAULT_ROWS_PER_PAGE = 100;

const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const SCHEDULE_STATUS_OPTIONS = [
  {
    key: "PENDING",
    value: "PENDING",
    text: "Pending",
  },
  {
    key: "LAB_DETAILS_SENT",
    value: "LAB_DETAILS_SENT",
    text: "Lab Details Sent",
  },
  {
    key: "LAB_CONFIRMED",
    value: "LAB_CONFIRMED",
    text: "Lab Confirmed",
  },
  {
    key: "RESCHEDULED",
    value: "RESCHEDULED",
    text: "Rescheduled",
  },
];

class OrdersCalendarView extends Component {
  constructor(props) {
    super(props);
    this.todaysDateString = moment().format("YYYY-MM-DD");
    this.minStartDate = moment().subtract(1, "M").format("YYYY-MM-DD");
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      orders: [],
      countries: [],
      cities: [],
      selectedCountry: null,
      selectedCity: null,
      selectedStartDate: null,
      selectedEndDate: null,
      searchTerm: null,
      searchApplied: false,
      columns:this.ordersColumns(),
      downloadOption:false,
      csvFormat: false,
      xlsFormat: false,
      rowsPerPage:DEFAULT_ROWS_PER_PAGE
    };

    // this.columns = [
    //   {
    //     name: "ID",
    //     selector: "orderId",
    //     sortable: true,
    //     maxWidth: "100px",
    //     format: (order) => {
    //       const { orderId = null, isPaymentPendingOrder = false, cartOrderId = null } = order || {};

    //       return (
    //         <>
    //           {/* {orderId && <div>

    //             {isPaymentPendingOrder ? { orderId } : <Link
    //               id={orderId}
    //               to={`/dashboard/orders/all/${cartOrderId}`}
    //               target="_blank"
    //             >
    //               {orderId}
    //             </Link>}
    //           </div>} */}{orderId}
    //         </>

    //       )
    //     }
    //   },
    //   // As per ticket "https://feelvaleo.atlassian.net/browse/SB-2988" we need to remove these.
    //   // {
    //   //   name: "Order UUID",
    //   //   selector: "order_uuid",
    //   //   sortable: true,
    //   //   wrap: true,
    //   // },
    //   // {
    //   //   name: "Purchased On",
    //   //   selector: "created_at",
    //   //   wrap: true,
    //   //   sortable: true,
    //   //   format: (order) =>
    //   //     order.created_at
    //   //       ? this.getFormattedPurchasedDate(order.created_at)
    //   //       : "-",
    //   // },
    //   {
    //     name: "Package Name",
    //     selector: "package_name",
    //     sortable: true,
    //     wrap: true,
    //   },
    //   {
    //     name: "Customer",
    //     selector: "first_name",
    //     sortField: "first_name",
    //     wrap: true,
    //     sortable: false,
    //     format: (order) => this.getFormattedUserName(order),
    //   },
    //   {
    //     name: "Payment Mode",
    //     selector: "payment_method",
    //     wrap: true,
    //     sortable: false,
    //     format: (order) => order.payment_method ?? "",
    //   },
    //   {
    //     name: "Add on Biomarkers",
    //     selector: "mini_package_name",
    //     wrap: true,
    //     sortable: true,
    //     width: "150px",
    //     format: (order) => order.mini_package_name?? "",
    //   },
    //   {
    //     name: "Lab 1",
    //     selector: "lab_1_name",
    //     wrap: true,
    //     sortable: true,
    //     format: (order) => order.lab_1_name ?? "",
    //   },
    //   {
    //     name: "Sample Details - Lab 1",
    //     selector: "sample_details_lab1",
    //     wrap: true,
    //     sortable: true,
    //     format: (order) => order.sample_details_lab1 ?? "",
    //   },
    //   {
    //     name: "Homecare",
    //     selector: "homecare_name",
    //     wrap: true,
    //     sortable: true,
    //     format: (order) => order.homecare_name ?? "",
    //   },
    //   {
    //     name: "Payment to be collected",
    //     selector: "payment_to_be_collected",
    //     wrap: true,
    //     sortable: true,
    //     minWidth: "150px",
    //     format: (order) => order.payment_to_be_collected ?? "",
    //   },
    //   {
    //     name: "Appointment Date & Time",
    //     selector: "booked_slot_date",
    //     wrap: true,
    //     sortable: true,
    //     minWidth: "180px",
    //     cell: (order) => this.getAppointmentDateAndTime(order),
    //   },
    //   // Commented because now we are showing these in a combined column.
    //   // {
    //   //   name: "Appointment Date",
    //   //   selector: "booked_slot_date",
    //   //   wrap: true,
    //   //   sortable: true,
    //   //   format: (order) =>
    //   //     order.booked_slot_date ? formatDate(order.booked_slot_date) : "-",
    //   // },
    //   // {
    //   //   name: "Appointment Time",
    //   //   selector: "booked_slot_time",
    //   //   wrap: true,
    //   //   sortable: true,
    //   //   format: (order) =>
    //   //     order.booked_slot_time ? order.booked_slot_time : "-",
    //   // },
    //   {
    //     name: "Confirmed Date & Time",
    //     selector: "confirmed_date",
    //     wrap: true,
    //     sortable: true,
    //     minWidth: "180px",
    //     cell: (order) => this.getConfirmedDateAndTime(order),
    //   },
    //   // Commented because now we are showing these in a combined column.
    //   // {
    //   //   name: "Confirmed Date",
    //   //   selector: "confirmed_date",
    //   //   wrap: true,
    //   //   sortable: true,
    //   //   format: (order) =>
    //   //     order.confirmed_date ? formatDate(order.confirmed_date) : "-",
    //   // },
    //   // {
    //   //   name: "Confirmed Time",
    //   //   selector: "confirmed_time",
    //   //   wrap: true,
    //   //   sortable: true,
    //   //   format: (order) => (order.confirmed_time ? order.confirmed_time : "-"),
    //   // },
    //   {
    //     name: "Schedule Status",
    //     selector: "schedule_status",
    //     wrap: true,
    //     sortable: true,
    //     minWidth: "180px",
    //     format: (order) => this.renderScheduleStatusDropdown(order),
    //   },
    //   {
    //     name: "Order Notes",
    //     selector: "order_notes",
    //     wrap: true,
    //     sortable: true,
    //     format: (order) => order.order_notes ?? "-",
    //   },
    //   {
    //     name: "Country",
    //     selector: "country_name",
    //     wrap: true,
    //     sortable: true,
    //   },
    //   {
    //     name: "City",
    //     selector: "city_name",
    //     wrap: true,
    //     sortable: true,
    //   },
    //   {
    //     name: "Special Instructions",
    //     selector: "special_instructions",
    //     wrap: true,
    //     sortable: true,
    //     format: (order) => order.special_instructions ?? "",
    //   },
    // ];
  }

  componentDidMount() {
    Promise.all([
      this.fetchAclUser(),
      this.fetchCountries(), 
      this.fetchCities(),
    ]);
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.ORDERS_CALENDAR_VIEW;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {
      console.log("Error on fetching countries", error);
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) ?? [];
      this.setState({ countries });
    } catch (error) {
      console.log("Error on fetching countries: ", error);
    }
  };

  fetchCities = async () => {
    try {
      const cities = (await fetchCities()) ?? [];
      this.setState({ cities });
    } catch (error) {
      console.log("Error on fetching cities: ", error);
    }
  };

  findCountry = (id) => {
    const { countries } = this.state;
    return countries && countries.find((it) => it.id === id);
  };

  findCity = (id) => {
    const { cities } = this.state;
    return cities && cities.find((it) => it.id === id);
  };

  resetForm = () => {
    this.setState({
      selectedCountry: null,
      selectedCity: null,
      selectedStartDate: null,
      selectedEndDate: null,
    });
  };

  ordersColumns=()=>{
   return  [

    {
      name: "ID",
      selector: "orderId",
      sortable: true,
      maxWidth: "100px",
      format: (order) => {
        const { orderId = null, isPaymentPendingOrder = false, cartOrderId = null } = order || {};
        if(orderId){
          return (
            <>
              
  
                {isPaymentPendingOrder ? order.orderId ?? ""  : <Link
                  id={orderId}
                  to={`/dashboard/orders/all/${cartOrderId}`}
                  target="_blank"
                >
                  {order.orderId ?? ""}
                </Link>}
             
            </>
  
          )
        }
        else{
          return <>""</>
        }
       
      }
    },


    // As per ticket "https://feelvaleo.atlassian.net/browse/SB-2988" we need to remove these.
    // {
    //   name: "Order UUID",
    //   selector: "order_uuid",
    //   sortable: true,
    //   wrap: true,
    // },
    // {
    //   name: "Purchased On",
    //   selector: "created_at",
    //   wrap: true,
    //   sortable: true,
    //   format: (order) =>
    //     order.created_at
    //       ? this.getFormattedPurchasedDate(order.created_at)
    //       : "-",
    // },
    {
      name: "Package Name",
      selector: "package_name",
      sortable: true,
      wrap: true,
    },
    {
      name: "Customer",
      selector: "first_name",
      sortField: "first_name",
      wrap: true,
      sortable: false,
      format: (order) => this.getFormattedUserName(order),
    },
    {
     name:"Address",
     selector: "userAddress",
     sortable: true,
     width:"200px",
     wrap: true,
    },
    {
      name:"Pin Location",
      selector: "userPinLocation",
      sortable: true,
      width:"200px",
      wrap: true,
     },
     {
      name:"Phone Number",
      selector: "client_phone_number",
      sortable: true,
      width:"200px",
      wrap: true,
     },
     {
      name:"Client Notes",
      selector: "client_note",
      sortable: true,
      width:"200px",
      wrap: true,
      cell: (order)=>order.client_note? <div>
              <div  className="orderNotes" style={{ fontWeight: "bold" }}>{order.client_note}</div></div>:"",
     },
     {
      name: "Appointment Date & Time",
      selector: "booked_slot_date",
      wrap: true,
      sortable: true,
      minWidth: "180px",
      cell: (order) => this.getAppointmentDateAndTime(order),
    },
    {
      name: "Confirmed Date & Time",
      selector: "confirmed_date",
      wrap: true,
      sortable: true,
      minWidth: "180px",
      cell: (order) => this.getConfirmedDateAndTime(order),
    },
    // {
    //   name:"Delivery Time",
    //   selector:"orderItemType",
    //   wrap: true,
    //   width:"200px",
    //   cell:(order)=>order.orderItemType=="Mini_Package"?this.getFormattedMiniPackageDelivery(order.miniPackageDeliveryTime):this.getFormattedCombinePackage(order.package_name,order.deliveryTime,order.miniPackageDeliveryTime),
    // },
    {
      name: "Add on Biomarkers",
      selector: "mini_package_name",
      wrap: true,
      sortable: true,
      width: "150px",
      format: (order) => order.mini_package_name?? "",
    },
     {
       name: "Order Status",
       selector: "order_status",
       wrap: true,
       sortable: true,
       width: "150px",
       format: (order) => order.order_status ?? "",
     },
    {
      name: "Lab 1",
      selector: "lab_1_name",
      wrap: true,
      sortable: true,
      format: (order) => order.lab_1_name ?? "",
    },
    {
      name: "Sample Details - Lab 1",
      selector: "sample_details_lab1",
      wrap: true,
      sortable: true,
      format: (order) => order.sample_details_lab1 ?? "",
    },
   
    {
      name: "Homecare",
      selector: "homecare_name",
      wrap: true,
      sortable: true,
      format: (order) => order.homecare_name ?? "",
    },
    {
     name:"Homecare Acceptance Status",
     selector:"homecare_name",
     wrap:true,
    //  sortable:true,
    width:"150px",
     cell:(order)=>this.renderNurseTimeStatus(order)
    },
    {
      name: "Payment Mode",
      selector: "payment_method",
      wrap: true,
      sortable: false,
      format: (order) => order.payment_method ?? "",
    },
    {
      name: "Payment to be collected",
      selector: "payment_to_be_collected",
      wrap: true,
      sortable: true,
      minWidth: "150px",
      format: (order) => order.payment_to_be_collected ?? "",
    },
    { 
      name:"Payment Collected by Nurse",
      selector:"homecarePaymentStatus",
      wrap:true,
      sortable:true,
      cell:(order)=>this.renderPaymentCollectedNurse(order)
    },
    {
      name: "Previous Homecare, Nurse, Lab",
      selector: "",
      // sortable: true,
      sortField: "",
      wrap: true,
      center: true,
      minWidth: "220px",
      cell:(row)=>this.renderPreviousHomecare(row)
    },
    {
      name:"Payment Notes",
      selector:"paymentNotes",
      wrap:true,
      format:(row) =>
          row.paymentNotes ? (
            <div>
              <div className="orderNotes">{row.paymentNotes}</div>

              <EditIcon
                className="edit-icon"
                onClick={() => this.showNoteAssignModal(row)}
              ></EditIcon>
            </div>
          ) : (
            <button
              className="btn button button-green btn-sm"
              onClick={() => this.showNoteAssignModal(row)}
            >
              Add Notes
            </button>
          )
      
    },
    
    // Commented because now we are showing these in a combined column.
    // {
    //   name: "Appointment Date",
    //   selector: "booked_slot_date",
    //   wrap: true,
    //   sortable: true,
    //   format: (order) =>
    //     order.booked_slot_date ? formatDate(order.booked_slot_date) : "-",
    // },
    // {
    //   name: "Appointment Time",
    //   selector: "booked_slot_time",
    //   wrap: true,
    //   sortable: true,
    //   format: (order) =>
    //     order.booked_slot_time ? order.booked_slot_time : "-",
    // },
   
    // Commented because now we are showing these in a combined column.
    // {
    //   name: "Confirmed Date",
    //   selector: "confirmed_date",
    //   wrap: true,
    //   sortable: true,
    //   format: (order) =>
    //     order.confirmed_date ? formatDate(order.confirmed_date) : "-",
    // },
    // {
    //   name: "Confirmed Time",
    //   selector: "confirmed_time",
    //   wrap: true,
    //   sortable: true,
    //   format: (order) => (order.confirmed_time ? order.confirmed_time : "-"),
    // },
    {
      name: "Schedule Status",
      selector: "schedule_status",
      wrap: true,
      sortable: true,
      minWidth: "180px",
      format: (order) => this.renderScheduleStatusDropdown(order),
    },
    {
      name: "Country",
      selector: "country_name",
      wrap: true,
      sortable: true,
    },
    {
      name: "City",
      selector: "city_name",
      wrap: true,
      sortable: true,
    },
    {
      name: "Order Notes",
      selector: "order_notes",
      wrap: true,
      sortable: true,
      format: (order) => order.order_notes ?? "-",
    },
    {
      name: "Special Instructions",
      selector: "special_instructions",
      wrap: true,
      sortable: true,
      format: (order) => order.special_instructions ?? "",
    },
    
  ];
  }
  renderOrderId=(order)=>{
    const {orderId=null, isPaymentPendingOrder=false,cartOrderId=null}= order || {};
      console.log("order ID:",order)
     return (
      <>
      {orderId? <div>

       {isPaymentPendingOrder?{orderId}:<Link
          //  id={orderId}
           to={`/dashboard/orders/all/${cartOrderId}`}
           target="_blank"
         >
           {orderId}
         </Link>}
 </div>:""}
      </>
     
     )
  }
   
  showNoteAssignModal(row) {
    const { orderId, paymentNotes} = row || {};

    this.setState({
      childOrderId: orderId,
      paymentNotes: paymentNotes,
      showNoteAssignModal: true,
      
      
    });
  }

  hideNoteAssignModal = () => {
    this.setState({ showNoteAssignModal: false });
  };


  getAppointmentDateAndTime = (order) => {
    if (!order) return "";

    const { booked_slot_date, booked_slot_time } = order;
    const bookingDate = booked_slot_date ? formatDate(booked_slot_date) : "-";
    const bookingTime = booked_slot_time ? booked_slot_time : "-";
    return <div>
      <span>{bookingDate}</span><br/>
      <span>{bookingTime}</span>
    </div>
  }

  getAppointmentDateAndTimeForMapping=(order)=>{
    const { booked_slot_date, booked_slot_time } = order;
    const bookingDate = booked_slot_date ? formatDate(booked_slot_date) : "-";
    const bookingTime = booked_slot_time ? booked_slot_time : "-";
    return bookingDate+" "+bookingTime
  }

  getConfirmedDateAndTime = (order) => {
    if (!order) return "";

    const { confirmed_date, confirmed_time } = order;
    const confirmedDate = confirmed_date ? formatDate(confirmed_date) : "-";
    const confirmedTime = confirmed_time ? confirmed_time : "-";
    return <div>
      <span>{confirmedDate}</span><br/>
      <span>{confirmedTime}</span>
    </div>
  }

  getConfirmedDateAndTimeForMapping=(order)=>{
    if (!order) return "";

    const { confirmed_date, confirmed_time } = order;
    const confirmedDate = confirmed_date ? formatDate(confirmed_date) : "-";
    const confirmedTime = confirmed_time ? confirmed_time : "-";
    return confirmedDate+" "+confirmedTime
  }


  getFormattedUserName = (order) => {
    if (!order) return "";
    const { username, full_name, first_name, last_name, email } = order;
    const name =
      username || full_name || `${first_name ?? ""}  ${last_name ?? ""}`;
    return `${name} (${email ?? ""})`;
  };

  getFormattedMiniPackageDelivery=(deliveryTimeDetails)=>{
   const modifieddeliveryTime= deliveryTimeDetails && deliveryTimeDetails.replace(/:-/g, ': ');
   const dataArray = modifieddeliveryTime && modifieddeliveryTime.split(',').map(item => item.trim());
  
 
   return  <div>
   {dataArray &&  dataArray.map((item, index) => (
     <div key={index}>{item}</div>
   ))}
 </div>
  }
  getFormattedCombinePackage=(packagename,bloodPackageDeliveryTime,miniPackageTime)=>{
    if(miniPackageTime==null){
      return bloodPackageDeliveryTime;
    }
    else{
      const modifieddeliveryTime= miniPackageTime && miniPackageTime.replace(/:-/g, ': ');
      const dataArray =modifieddeliveryTime && modifieddeliveryTime.split(',').map(item => item.trim());
      dataArray && dataArray.unshift(`${packagename} : ${bloodPackageDeliveryTime}`);
     
      return<>
    <div>{dataArray && dataArray.map((item, index) => (
     <div key={index}>{item}</div>
   ))} </div>
      </>
    }

  }
  getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  renderNurseTimeStatus = (order) => {
    const { isStart = false, isEnd = false, nurseOnTimeResponse = null, nurseRunningLateReason = null,
      sampleDroppedAtLab = null,
      nurseAssigned = null,
      homecareAccepted = null,
      homecareWaiting = null
    } = order || {};
    if (sampleDroppedAtLab) {
      return sampleDroppedAtLab
    }
   

    else if (nurseOnTimeResponse) {
      if (nurseRunningLateReason && nurseRunningLateReason) {
        const reason = `${nurseOnTimeResponse} 
                       ${nurseRunningLateReason}`
        return reason
      }
      else {
        return nurseOnTimeResponse
      }

    }
  
    // else if(nurseRunningLateReason){
    //   return nurseRunningLateReason
    // }
    else if (isStart && isEnd) {
      return "Nurse has collected the sample"
    }
    else if (isStart) {
      return "Nurse has started"
    }
    else if (isEnd) {
      return "Nurse has collected the sample"
    }
    else if (nurseAssigned) {
      return nurseAssigned
    }
    else if (homecareAccepted) {
      return homecareAccepted
    }
    else if (homecareWaiting) {
      return homecareWaiting
    }
    else {
      return ""
    }
  }

  handleDateSelect = ({ target: input }) => {
    if (input.name === "selectedStartDate" && this.state.selectedEndDate) {
      if (moment(input.value).isAfter(this.state.selectedEndDate)) {
        this.setState({
          selectedStartDate: input.value,
          selectedEndDate: input.value,
        });
      }
    }
    this.setState({ [input.name]: input.value });
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ?? "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleScheduleStatusChange = async (order, selectedStatus) => {
    if (!order || !order.order_id || !selectedStatus) {
      console.log("handleScheduleStatusChange:: Invalid arguments", {
        order,
        selectedStatus,
      });
      return;
    }

    try {
      this.showLoading();
      const url = `/update-schedule-status/${order.order_id}`;
      const response = await PhoenixAPI.patch(url, {
        scheduleStatus: selectedStatus,
      });
      if (response && response.status === 200) {
        this.showSuccessNotification("Status updated successfully.");
        order.scheduleStatus = selectedStatus;
      } else {
        console.log(
          "handleScheduleStatusChange:: Something went wrong!",
          response
        );
      }
    } catch (error) {
      console.log(
        "handleScheduleStatusChange:: Error on updating shcedule status",
        error
      );
      this.showErrorNotification(
        "Error on updating shcedule status!",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleShowOrders = () => {
    this.fetchOrders();
  };

  fetchOrders = async () => {
    const {
      selectedCountry,
      selectedCity,
      selectedStartDate,
      selectedEndDate,
    } = this.state;
    // https://phoenix.bevaleo.dev/order-details-slot-wise?countryId=1&cityId=1&fromDate=2022/10/02&toDate=2022/12/14
    try {
      this.showLoading();
      const params = {};
      if (selectedCountry) params.countryId = selectedCountry.value;
      if (selectedCity) params.cityId = selectedCity.value;
      if (selectedStartDate)
        params.fromDate = moment(selectedStartDate).format("YYYY/MM/DD");
      if (selectedEndDate)
        params.toDate = moment(selectedEndDate).format("YYYY/MM/DD");

      const response = await PhoenixAPI.get("/order-details-slot-wise", {
        params,
      });
      const { data: orders } = response;
      if (response.status === 200) {
        const message =
          orders && orders.length
            ? `Found ${orders.length} orders.`
            : "No orders found!";
        this.showSuccessNotification(message);
      }

      if (orders)
        orders.forEach((order) => {
          const country = this.findCountry(order.country_id);
          order.country_name = country?.country_name ?? "";

          const city = this.findCity(order.city_id);
          order.city_name = city?.city_name ?? "";
        });

        orders.sort((a, b) => {
          const dateA = new Date(`${a.confirmedDate}T${a.confirmedTime}`);
          const dateB = new Date(`${b.confirmedDate}T${b.confirmedTime}`);
          return dateA - dateB;
        }); 

      this.setState({ orders: orders ?? [] });
    } catch (error) {
      this.showErrorNotification("Error on fetching orders: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  getFilteredOrders = () => {
    const { orders } = this.state;
    return this.getSearchedOrders(orders);
  };

  getSearchedOrders = (orders) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return orders;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return orders.filter((order) => {
      const { first_name, last_name, email, package_name, orderId } = order;
      return (
        (first_name && first_name.toLowerCase().indexOf(newValue) !== -1) ||
        (last_name && last_name.toLowerCase().indexOf(newValue) !== -1) ||
        (email && email.toLowerCase().indexOf(newValue) !== -1) ||
        (package_name && package_name.toLowerCase().indexOf(newValue) !== -1) ||
        (orderId && orderId.toString() === newValue) 
      );
    });
  };
  isTableRowColorSchemeApplicable=(order)=>{
    const{isPaymentPendingOrder}=order;
    if(isPaymentPendingOrder)
    return isPaymentPendingOrder;
    else{
      if(order.bundle &&  (order.orderStatus=="ASSIGNED" || order.orderStatus=="CREATED")){
       
        return true;

      }
      
    }
  }

  getTableRowColorScheme = (row) => {
    
    

    //console.log("count is",row.id)
    let color = "#FFCCCB";
    const rowId = row.id.split('-')[1]; 
    const orderId = parseInt(rowId); 
    
    const finalOrders=this.state?.orders?.filter((it)=>it.isPaymentPendingOrder || it.parentItemId!=null)
   const order= this.state.orders?.find((it,index) => index === orderId);
   console.log("orderId",orderId,order);
   
      if (order) {
        if (order.bundle ) {

          color = "#ADD8E6"; // Color for rows with a parent item
        } 
         if (order.isPaymentPendingOrder) {
          color = "#FFCCCB"; // Color for payment-pending orders
        }
      }

      return {
        backgroundColor: color,
        "&:hover": {
          backgroundColor: color,
        },
      };
    
  }
   renderPaymentCollectedNurse=(order)=>{
    if((order.paymentMethod=="COD"  || order.paymentMethod=="COD_MAMOPAY") && order.homecarePaymentStatus==null)

    return(<>
    Not collected
    </>)
    else if((order.paymentMethod=="COD"  || order.paymentMethod=="COD_MAMOPAY") && order.homecarePaymentStatus!==null)
    return(<>{order.homecarePaymentStatus}</>)
    else{
     return(<>Not required</>) 
    }
   }
   renderPaymentCollectedNurseForMapping=(order)=>{
    if(order.paymentMethod=="COD" && order.homecarePaymentStatus==null)

    return "Not collected"
    
    else if(order.paymentMethod=="COD" && order.homecarePaymentStatus!==null){
      return order.homecarePaymentStatus
    }
   
    else{
     return "Not required" 
    }
   }
   handleChange = (event) => {
    const target = event.target;
    this.setState({ [target.name]: target.value });
   
  };

  renderPreviousHomecare=(order)=>{
    const { previousHomecareName=null,previousLabName=null,previousNurseName=null} = order || {}
    let combinedArray=[];
    if(previousHomecareName){
      combinedArray.push(previousHomecareName)
    }
    if(previousNurseName){
      combinedArray.push(previousNurseName)
    }
    if(previousLabName){
      combinedArray.push(previousLabName)
    }
   
    return combinedArray?.join(",")  ?? "" 
  }
 

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  addNotes = async () => {
       const paymentnotes=this.state.paymentNotes;
       const orderid=this.state.childOrderId;

       this.hideNoteAssignModal();
       try {
        this.showLoading();
        const response=await  PhoenixAPI.patch(`payment-notes/${orderid}?paymentNotes=${paymentnotes}`);
        this.fetchOrders();
        this.showSuccessNotification("Order is Updated Successfully");
       }
       catch(error){
         this.showErrorNotification(error.message);
       }

  }

  renderScheduleStatusDropdown = (order) => {
    const titleOption = (
      <option key={-1} value={-1} hidden={true}>
        Select...
      </option>
    );
    return (
      <select
        value={order.schedule_status}
        onChange={(e) => this.handleScheduleStatusChange(order, e.target.value)}
        name="select_schedule_status"
        className="form-control"
      >
        {!order.schedule_status && titleOption}
        {SCHEDULE_STATUS_OPTIONS.map((option) => (
          <option key={option.key} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    );
  };

  

 jsonToCsv = (json) => {
    const csvRows = [];
    // Get the headers
    const headers = Object.keys(json[0]);
    csvRows.push(headers.join(','));
  
    // Map over each row
    json.forEach(row => {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });
  
    // Join rows with newline character
    return csvRows.join('\n');
  };

  mappingOrdersForDownload = (orders) => {
    const mappedOrders = orders.map((order) => {
      const item = {
        "Order Id": order.orderId,
        "Package Name": order.package_name,
        "Customer": this.getFormattedUserName(order),
        "Address": order.userAddress,
        "Pin Location": order.userPinLocation,
        "Appointment Date & Time": this.getAppointmentDateAndTimeForMapping(order),
        "Confirmed Date & Time": this.getConfirmedDateAndTimeForMapping(order),
        "Add on Biomarkers": order.miniPackageName,
        "Order Status": order.order_status,
        "Lab 1": order.lab_1_name,
        "Sample Details - Lab 1": order.sample_details_lab1,
        "Homecare": order.homecare_name,
        "Homecare Acceptance Status": this.renderNurseTimeStatus(order),
        "Payment Mode": order.payment_method,
        "Payment to be collected": order.payment_to_be_collected,
        "Payment Collected by Nurse": this.renderPaymentCollectedNurseForMapping(order),
        "Payment Notes": order.payment_notes,
        "Schedule Status": order.schedule_status,
        "Country": order.country_name,
        "City": order.city_name,
        "Order Notes": order.order_notes,
        "Special Instructions": order.special_instructions,

      }

      return item;
    })
    return mappedOrders
  }

  downloadCsv = async () => {
    this.setState({
      downloadOption: true, csvFormat: true,
      xlsFormat: false,
    })
  }

  hideDownloadOptionModal = () => {
    this.setState({
      downloadOption: false, csvFormat: false,
      xlsFormat: false,
    })
  }

  downloadXls = async () => {
    this.setState({
      downloadOption: true, csvFormat: false,
      xlsFormat: true
    })
  }


  downloadAllRecords = () => {
    const { orders, csvFormat, xlsFormat } = this.state || {}
    const mappedOrders = this.mappingOrdersForDownload(orders);
    if (csvFormat) {
      if (mappedOrders.length > 0) {
        const csvContent = this.jsonToCsv(mappedOrders);
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      } else {
        return;
      }
    }
    else if (xlsFormat) {
      if (mappedOrders.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      }
      else {
        return;
      }
    }
  }

  downloadSelectedRows = () => {
    const orders=this.getFilteredOrders() ?? [];
    const { csvFormat, xlsFormat } = this.state || {}
    const mappedOrders = this.mappingOrdersForDownload(orders);
    if (csvFormat) {
      if (mappedOrders.length > 0) {
        const csvContent = this.jsonToCsv(mappedOrders);
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      } else {
        return;
      }
    }
    else if (xlsFormat) {
      if (mappedOrders.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      }
      else {
        return;
      }
    }
  }

  handlePageChange = (page) => {
    const {rowsPerPage, orders}= this.state;
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedData = orders.slice(startIndex, endIndex);
     this.setState({orders:displayedData})
  };

  handleRowsPerPageChange = (rowsPerPage, page) => {
    const {orders}= this.state;
    console.log("OrdersDashboard:: handleRowsPerPageChange:", {
      rowsPerPage,
      page,
    });
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedData = orders.slice(startIndex, endIndex);
    this.setState({rowsPerPage,orders:displayedData})
  };

  render() {
    let {
      selectedCountry,
      selectedCity,
      selectedStartDate,
      selectedEndDate,
      loadError,
      searchApplied,
      searchTerm,
    } = this.state;
    const filteredOrders = this.getFilteredOrders() ?? [];

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    const customStyles = {
      rows: {
        style: {
          minHeight: "150px",
        },
      },
    };

    return (
      <>
        <div className="row">
          <div className="form-group col-4">
            <label>Country</label>
            <SelectCountry
              selectedCountry={selectedCountry}
              onSelect={(value) =>
                this.setState({ selectedCountry: value, selectedCity: null })
              }
            />
          </div>
          <div className="form-group col-4">
            <label>City</label>
            <SelectCity
              countryId={selectedCountry?.value ?? null}
              selectedCity={selectedCity}
              onSelect={(value) => this.setState({ selectedCity: value })}
            />
          </div>
          <div style={{display:"flex", paddingTop:"10px",justifyContent:"space-evenly"}}>
          <div className="form-group col-4">
            <button
              onClick={(e) => this.downloadCsv()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#2ebc98" , color:"white"}}
            >
              Download CSV
            </button>
          </div>
          <div className="form-group col-4">
            <button
              onClick={(e) => this.downloadXls()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#2ebc98", color:"white" }}
            >
              Download Excel
            </button>
          </div>
          </div>
         
        </div>
        <div className="row">
          <div className="form-group col-4">
            <label htmlFor="selectedStartDate">Start Date</label>
            <div className="input-group user-input-container">
              <input
                value={selectedStartDate}
                onChange={this.handleDateSelect}
                // min={this.minStartDate}
                // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
                type="date"
                id="selectedStartDate"
                name="selectedStartDate"
                className="form-control py-3"
              />
            </div>
          </div>
          <div className="form-group col-4">
            <label htmlFor="selectedEndDate">End Date</label>
            <input
              value={selectedEndDate}
              onChange={this.handleDateSelect}
              min={selectedStartDate ?? null}
              // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
              type="date"
              id="selectedEndDate"
              name="selectedEndDate"
              className="form-control py-3"
            />
          </div>
        </div>
        <div className="row mb-1">
          <div className="col">
            <button
              onClick={(e) => this.handleShowOrders()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#CEE741" }}
            >
              Show Orders
            </button>
          </div>
          <div className="ml-auto mr-3">
            <Search
              searchExpanded={true}
              searchTerm={searchTerm}
              handleSearch={this.handleSearch}
              clearSearch={this.handleSearchClear}
              handleSearchExit={this.handleSearchExit}
              ref={(input) => {
                this.search = input;
              }}
            ></Search>
          </div>
        </div>
        <Card body>
       
          <DataTable
            data={filteredOrders ?? []}
            columns={this.state.columns}
            // defaultSortField="order_id"
            // defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredOrders.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            conditionalRowStyles={[
              {
                 when: this.isTableRowColorSchemeApplicable,
                 style: this.getTableRowColorScheme,
               },
             ]}

            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
            
          />
          
        </Card>
        <Modal
          show={this.state.showNoteAssignModal}
          onHide={this.hideNoteAssignModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Add Note *</label>

                <textarea
                  type="text"
                  name="paymentNotes"
                  className={"form-control "}
                  placeholder="Add note"
                  onChange={this.handleChange}
                  value={this.state.paymentNotes}
                  maxLength={200}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={this.state.paymentNotes ? false : true}
              variant="primary"
              onClick={this.addNotes}
            >
              Add Note
            </button>
            <Button variant="secondary" onClick={this.hideNoteAssignModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="sm"
          show={this.state.downloadOption}
          onHide={this.hideDownloadOptionModal}
          
        >
          <Modal.Header closeButton>
            <Modal.Title>Please Select an option from below</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div style={{display:"flex", flexDirection:"column", gap:"25px",margin:"auto", alignItems:"center"}}>
          <div>
          <button
              onClick={(e) => this.downloadAllRecords()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789" , color:"white"}}
            >
              Download all records
            </button>
          </div>
          <div>
          <button
              onClick={(e) => this.downloadSelectedRows()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789", color:"white" }}
            >
              Download selected records
            </button>
          </div>
          </div>
          </Modal.Body>
        </Modal>
      </>
    );

  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersCalendarView);
