import Home from "pages/Home";
import Clients from "pages/Users/Clients";
import NutritionistDetails from "pages/Users/Details";
import CRM from "pages/Users/CRM";
import Lab from "pages/Users/Lab";
import AuthorsAndEligibleAuthors from "pages/Users/AuthorsAndEligibleAuthors";
import InterestedPeople from "pages/Users/InterestedPeople";
import FAQ from "pages/FAQ";
import Chat from "pages/Chat";
import SupplementOrdersDashboard from "pages/Orders/SupplementOrdersDashboard";
import GeneralSurvey from "pages/HealthProfile/GeneralSurvey";
import GeneralSurveyResults from "pages/HealthProfile/GeneralSurveyResults";
import CustomSurvey from "pages/HealthProfile/CustomSurvey";
import CustomSurveyResults from "pages/HealthProfile/CustomSurveyResults";
import CustomQuestions from "pages/HealthProfile/CustomQuestions";
import Categories from "pages/HealthProfile/Categories";
import MedicalSurveyResults from "pages/MedicalProfile/MedicalSurveyResults";
import MedicalSurvey from "pages/MedicalProfile/MedicalSurvey";
import LifestyleSurvey from "pages/LifestyleProfile/LifestyleSurvey";
import LifestyleSurveyResults from "pages/LifestyleProfile/LifestyleSurveyResults";
import SurveyOptions from "pages/MedicalProfile/SurveyOptions";
import ManagePackages from "pages/Packages/ManagePackages";

import ManageTests from "pages/Packages/ManageTests";
import ManageTestCategories from "pages/Packages/ManageTestCategories";
import ManageBioMarkers from "pages/Packages/ManageBioMarkers";
import FoodIntolerance from "pages/Packages/FoodIntolerance";
import PackageSubscriptions from "pages/Subscriptions/PackageSubscriptions";
import NutritionistSubscriptions from "pages/Subscriptions/NutritionistSubscriptions";
import Nutritionist from "pages/Users/Nutritionist";
import TimeSlots from "pages/Orders/TimeSlots";
import ActiveOrders from "pages/Orders/ActiveOrders";
import PastOrders from "pages/Orders/PastOrders";
import TestResults from "pages/Orders/TestResults";
import OrderReviews from "pages/Reviews/OrderReviews";
import RatingsPanel from "pages/RatingsPanel";
import NutritionistReviews from "pages/Reviews/NutritionistReviews";
import NutritionistRatings from "pages/Reviews/NutritionistRatings";
import HomeIcon from "@material-ui/icons/Home";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ShopIcon from "@material-ui/icons/Shop";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import HelpIcon from "@material-ui/icons/Help";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import Slider from "pages/LandingPage/Slider";
import HowItWorks from "pages/LandingPage/HowItWorks";
import WhatWeFocus from "pages/LandingPage/WhatWeFocus";
import CouponCodeGenerator from "pages/CouponCode/CouponCodeGenerator";
import ForcedUpdate from "pages/ForcedUpdate/ForcedUpdate";//forced update
import CouponCodes from "pages/CouponCode/CouponCodes";
import LandingPageIcon from "@material-ui/icons/Landscape";
import CouponCodeIcon from "@material-ui/icons/ConfirmationNumber";
import ContactUs from "pages/Users/ContactUs";
import Labslots from "pages/LabManagment/index";
import EditLabSlots from "pages/LabManagment/LabSlots";
import CoachSlots from "pages/CoachManagment/CoachSlots";
import CoachslotDetails from "pages/CoachManagment/CoachslotDetails";
import ProductCategory from "pages/PackageCreation/ProductCategory";
import Packages from "pages/CustomPackageCreation/Packages";
import RegionManagment from "pages/LabManagment/RegionManagment";
import AdminUser from "pages/Users/AdminUser";
import HealthCoachPackages from "pages/Packages/HealthCoachPackages";
import BloodBiomarkerPackages from "pages/Packages/BloodBiomarkerPackages";
import PackagesCMS from "pages/CMS/PackagesCMS";
import SeoLandingPage from "pages/CMS/SeoLandingPage"; 
import WebIcon from '@material-ui/icons/Web';
import WebsiteCategories from "pages/CMS/WebsiteCategories";
import StarsIcon from '@material-ui/icons/Stars';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LandingPage from "./pages/CMS/LandingPage"
import SettingsIcon from '@material-ui/icons/Settings';
import ReviewsIcon from "@material-ui/icons/RateReviewTwoTone";
import ForceUpdate from "pages/ForceUpdate/ForceUpdate";
import MobilePromotions from "pages/MobilePromotions/MobilePromotions"
import FAQDetails from "pages/FAQDetails"
import DashboardDetail from "pages/CoachManagment/DashboardDetail";
import RoomServiceIcon from '@material-ui/icons/RoomService';
import ServiceCategories from "pages/Services/ServiceCategories";
import ConditionCategories from "pages/Services/ConditionCategories";
import Questionnaires from "pages/Services/Questionnaires";
import Bundles from "pages/Services/Bundles";
import Medicines from "pages/Services/Medicines";
import Reviews from "pages/Reviews/Reviews";
import OtherItems from "pages/Services/OtherItems";
import SupplementCategories from "pages/Services/SupplementCategories";
import Labdashboard from "pages/LabManagment/LabDashboard";
import OrdersDashboard from "pages/Orders/OrdersDashboard";
import OrdersDashboardDetails from "pages/Orders/OrdersDashboardDetails";
import HomecareServices from "./pages/LabManagment/HomecareServices"
import OrderSurveyResults from "pages/OrderSurveyResults/OrderSurveyResults"
import SurveyResultDetails from "pages/OrderSurveyResults/SurveyResultDetails"
import ExchangeRates from "pages/ExchangeRates/ExchangeRates";
import MoneyIcon from '@material-ui/icons/Money';
import ReferralCode from "pages/ReferralCodes/ReferralCode";
import Banners from "pages/WebisteBanners/Banners";
import HowDoYouHear from "pages/HowDoYouHear/HowDoYouHear";
import ArticleCategories from "pages/HealthHub/ArticleCategories";
import Articles from "pages/HealthHub/Articles";
import MiniPackageCityUpdates from "pages/PackageCreation/MiniPackageCityUpdates";
import MiniPackages from "pages/PackageCreation/MiniPackages";
import OrdersCalendarView from "pages/Orders/OrdersCalendarView";
import ClientNotesTable from "pages/CoachManagment/ClientNotesTable";
import subscriptionOrders from "pages/Orders/SubscriptionOrders/subscriptionOrders";
import RolesAndAccessRights from "pages/ACL/RolesAndAccessRights";
import PageCategories from "pages/ACL/PageCategories";
import UserPagePermissions from "pages/ACL/UserPagePermissions";
import ProfessionalManagement from "pages/HomeCarePortal/ProfessionalManagement";
import Bookings from "pages/HomeCarePortal/Bookings";
import PaymentPendingOrders from "pages/Orders/PaymentPendingOrders/PaymentPendingOrders";
import DraftOrderDashboardDetails from "pages/Orders/PaymentPendingOrders/components/DraftOrderDashboardDetails";
import ValeoAmbassadors from "pages/Partners/ValeoAmbassadors";
import LongevityScore from "pages/LongevityScore/LongevityScore";
import ParameterDetails from "pages/LongevityScore/ParameterDetails";
import LongevityParameters from "pages/LongevityScore/LongevityParameters";
import CustomersLongevityScore from "pages/CoachManagment/CustomersLongevityScore";
import CustomersLongevityScoreDetail from "pages/CoachManagment/CustomersLongevityScoreDetail";
import SkincareSurvey from "pages/Orders/SkincareSurvey";
import ValeoPartners from "pages/Partners/ValeoPartners";
import DewaPartnerPackages from "pages/Partners/DewaPartnerPackages";
import CoachConsultationPackage from "pages/CoachConsultationPackage/CoachConsultationPackage";
import BundleMapping from "pages/BundleMapping/BundleMapping";
import NewOrdersRevamp from "pages/NewOrdersRevamp/NewOrdersRevamp"
import NewActiveOrders from "pages/Orders/NewActiveOrders";
import HomecarePortal from "pages/HomecareMwebPortal/index.jsx";
import NurseManagement from "pages/NurseManagement/NurseManagement";
import ListOfFood from "pages/FoodIntolerance/ListOfFood";
import FoodCategories from "pages/FoodIntolerance/FoodCategories";
import NurseNotifications from "pages/Notifications/Notifications"
import NursePanelNotifications from "pages/HomecareMwebPortal/NursePanelNotifications";

import ClientUploadResults from "pages/Orders/ClientUploadResults/ClientUploadResults";


import HomecareMobilePortal from "pages/HomecareMwebPanelNew/index.jsx";

import UpcomingOrders from "pages/HomeCarePortal/UpcomingOrders";

import HomeCareServicesIndex from "pages/LabManagment/HomeCareServicesIndex";

import SearchKeywords from "pages/SearchKeywords/SearchKeywords";

import Physiotherapist from "pages/PhysioManagement/Physiotherapist";


import NursePastOrders from "pages/HomecareMwebPortal/NursePastOrders/NursePastOrders";
import ClientLabDetails from "pages/HomecareMwebPortal/ClientLabDetails";
import UploadSampleDetails from "pages/HomecareMwebPortal/UploadSampleDetails";
import ReportIssue from "pages/HomecareMwebPortal/ReportIssue/ReportIssue";

import ValeoCorporates from "pages/Partners/ValeoCorporates";
import HomecareUsers from "pages/Users/HomecareUsers";

import ManageNonBloodTests from "pages/Packages/ManageNonBloodTests";

import LabCosts from "pages/LabCosts/LabCosts";
import LabSlotsPackages from "pages/LabManagment/LabSlotsPackages";
import CouponCodeIndex from "pages/CouponCode/CouponCodeIndex";
import HomecareCosts from "pages/HomecareCosts/HomecareCosts";
import SampleDetails from "pages/Packages/SampleDetails";
import CreateTicket from "pages/HomeCarePortal/CreateTicket";
import ViewMyTickets from "pages/HomeCarePortal/ViewMyTickets";





import FitnessPlan from "pages/FitnessPlan/FitnessPlan";




import CMSNavigationMenu from "pages/CMS/CMSNavigationMenu";


import ViewAllMyTickets from "pages/TicketingManagement/ViewAllMyTickets"
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import HomecareTicketManagement from "pages/HomeCarePortal/HomecareTicketManagement";
import HomecareTicketView from "pages/HomeCarePortal/HomecareTicketView";

import ReopenTicket from "pages/HomeCarePortal/ReopenTicket";


import AdvanceLandingPageHome from "pages/AdvanceLandingPages/AdvanceLandingPageHome";
import WidgetsList from "./pages/AdvanceLandingPages/WidgetsList";
import ClientPasswordReset from "pages/Orders/ClientPasswordReset";



import CustomTestAddonsIndex from "pages/CustomTestAddOns/CustomTestAddonsIndex";


import HomePageAppBanners from "pages/MobileHomePageBanners/HomePageAppBanners";
import HomePageBannersRankings from "pages/MobileHomePageBanners/HomePageBannersRankings";
import ChangeMemberDetails from "pages/HomecareMwebPortal/ChangeMemberDetails";


let routes = [
  {
    name: "Home",
    icon: HomeIcon,
    hasSubroute: false,
    path: "/dashboard/home",
    component: Home,
    display: true,
    role: ["admin", "nutritionist", "crm", "lab", "externalNutritionist"],
  },
  {
    name: "Access Control",
    icon: PeopleAltIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Roles & Access Rights",
        hasSubroute: false,
        path: "/dashboard/security/acl/roles-and-access-rights",
        component: RolesAndAccessRights,
        display: true,
        role: ["admin"]
      },
      {
        name: "Page & Page Categories",
        hasSubroute: false,
        path: "/dashboard/security/acl/page-and-page-categories",
        component: PageCategories,
        display: true,
        role: ["admin"]
      },
      {
        name: "User Page Permissions",
        hasSubroute: false,
        path: "/dashboard/security/acl/user-page-permissions",
        component: UserPagePermissions,
        display: true,
        role: ["admin"]
      }
    ],
  },
  {
    name: "Clients",
    icon: PeopleAltIcon,
    hasSubroute: false,
    path: "/dashboard/users/clients",
    component: Clients,
    display: true,
    role: ["nutritionist", "crm"],
  },
  {
    name: "User Management",
    icon: PeopleAltIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],

    subroutes: [
      {
        name: "Clients",
        hasSubroute: false,
        path: "/dashboard/users/clients",
        component: Clients,
        display: true,
        role: ["admin"],
      },
      {
        name: "Admin Users",
        hasSubroute: false,
        path: "/dashboard/users/adminusers",
        component: AdminUser,
        display: true,
        role: ["admin"],
      },
      {
        name: "Coach Details",
        hasSubroute: false,
        path: "/dashboard/users/nutritionists/details/:profileId",
        component: NutritionistDetails,
        display: false,
        role: ["admin"],
      },
      {
        name: "Coach Details",
        hasSubroute: false,
        path: "/dashboard/users/nutritionists/details",
        component: NutritionistDetails,
        display: false,
        role: ["nutritionist"],
      },
      // {
      //   name: "CRM Users",
      //   hasSubroute: false,
      //   path: "/dashboard/users/crm",
      //   component: CRM,
      //   display: true,
      //   role: ["admin"],
      // },
      {
        name: "Lab Users",
        hasSubroute: false,
        path: "/dashboard/users/lab",
        component: Lab,
        display: true,
        role: ["admin"],
      },
      {
        name: "Author Users",
        hasSubroute: false,
        path: "/dashboard/users/author",
        component: AuthorsAndEligibleAuthors,
        display: true,
        role: ["admin"],
      },
      {
        name: "Homecare Users",
        hasSubroute: false,
        path: "/dashboard/users/homecares",
        component: HomecareUsers,
        display: true,
        role: ["admin"],
      },
      {
        name: "Interested People",
        hasSubroute: false,
        path: "/dashboard/users/interested",
        component: InterestedPeople,
        display: true,
        role: ["admin"],
      },
      {
        name: "Contacted Us",
        hasSubroute: false,
        path: "/dashboard/users/contactus",
        component: ContactUs,
        display: true,
        role: ["admin"],
      },
    ],
  },
  {
    name: "Order Management",
    icon: ShoppingCartIcon,
    hasSubroute: true,
    display: true,
    role: ["admin", "nutritionist", "crm", "lab", "externalNutritionist"],
    subroutes: [
      {
        name: "Cart Orders",
        hasSubroute: false,
        path: "/dashboard/orders/cart",
        component: OrdersDashboard,
        display: true,
        role: ["admin"],
      },
      {
        name: "New Cart Orders",
        hasSubroute: false,
        path: "/dashboard/orders/new-cart",
        component: NewOrdersRevamp,
        display: true,
        role: ["admin"],
      },
      {
        name: "Orders Detail",
        hasSubroute: false,
        path: "/dashboard/orders/all/:orderId",
        component: OrdersDashboardDetails,
        display: false,
        role: ["admin"],
      },
      {
        name: "New Orders Detail",
        hasSubroute: false,
        path: "/dashboard/orders/newOrder/:orderId",
        component: OrdersDashboardDetails,
        display: false,
        role: ["admin"]

      },
      {
        name:"Supplement Orders Details",
        hasSubroute:false,
        path:"/dashboard/orders/supplement/:orderId",
        component: OrdersDashboardDetails,
         display:false,
         role:["admin"],
      },
      {
        name:"Supplement Orders",
        hasSubroute:false,
        path:"/dashboard/orders/supplement-order",
        component:SupplementOrdersDashboard,
        display:true,
        role:["admin"],
      },
      {
        name: "Active Orders",
        hasSubroute: false,
        path: "/dashboard/orders/active",
        component: ActiveOrders,
        display: true,
        // role: ["admin", "nutritionist", "crm", "lab"],
        role: ["admin", "crm", "lab"],
      },
      {
        name: "New Active Orders",
        hasSubroute: false,
        path: "/dashboard/orders/new-active",
        component: NewActiveOrders,
        display: true,
        // role: ["admin", "nutritionist", "crm", "lab"],
        role: ["lab"],
      },

      {
        name: "Past Orders",
        hasSubroute: false,
        path: "/dashboard/orders/past",
        component: PastOrders,
        display: true,
        role: ["admin", "nutritionist", "crm", "lab", "externalNutritionist"],
      },
      {
        name : "Payment Pending Orders",
        hasSubroute:false,
        path:"/dashboard/payment-pending-orders",
        component:PaymentPendingOrders,
        display:true,
        role:["admin"],
      },
      {
       name : "Draft Order Details",
       hasSubroute:false,
       path:"/dashboard/payment-pending-orders/all/:orderId",
       component:DraftOrderDashboardDetails,
       display:false,
       role:["admin"],
      },
      {
        name: "Orders Calendar View",
        path: "/dashboard/orders/OrdersCalendarView",
        component: OrdersCalendarView,
        display: true,
        role: ["admin"],
      },
      {
        name: "Dashboard Detail",
        hasSubroute: false,
        path: "/dashboard/home/view/:orderId",
        component: DashboardDetail,
        display: false,
        role: ["nutritionist", "externalNutritionist"],
      },
      {
        name: "Test Results",
        hasSubroute: false,
        path: "/dashboard/orders/active/results/:order/:gender",
        component: TestResults,
        display: false,
        role: ["nutritionist", "lab", "externalNutritionist"],
      },
      {
        name: "Test Results",
        hasSubroute: false,
        path: "/dashboard/orders/past/results/:order/:gender",
        component: TestResults,
        display: false,
        role: ["nutritionist", "lab", "externalNutritionist"],
      },
      {
        name: "Wellbeing Coach Subscriptions",
        path: "/dashboard/subscriptions/nutritionist",
        component: NutritionistSubscriptions,
        display: true,
        role: ["admin"],
      },
      // {
      //   name: "Time Slots",
      //   path: "/dashboard/orders/time-slots",
      //   component: TimeSlots,
      //   display: true,
      //   role: ["admin"],
      // },
      {
        name: "Subscription Orders",
        hasSubroute: false,
        path: "/dashboard/subscription-orders",
        component: subscriptionOrders,
        display: true,
        role: ["admin"],
      },
      {
        name: "Client Upload Results",
        hasSubroute: false,
        path: "/dashboard/client-upload-results",
        component: ClientUploadResults,
        display: true,
        role: ["admin"],
      },
      {
        name:"Special Condition",
        hasSubroute: false,
        path:"/dashboard/client-password-reset",
        component:ClientPasswordReset,
        display: true,
        role: ["admin"],
      }
      
    ],
  },
  {
    name:"Ticketing Management",
    icon: AssignmentIndIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
     subroutes:[
      {
        name:"View Tickets",
        hasSubroute: false,
        path:"/dashboard/ticketing-management",
        component:ViewAllMyTickets,
        display: true,
        role:["admin"]
      }
     ]
  },
    {
        name: "Skincare Survey",
        icon: PeopleAltIcon,
        hasSubroute: false,
        path: "/dashboard/skincare/survey",
        component:SkincareSurvey,
        display: true,
         role: ["nutritionist"],
      },
  // {
  //   name: "Subscriptions",
  //   icon: ShopIcon,
  //   display: true,
  //   role: ["admin"],
  //   hasSubroute: true,

  //   subroutes: [
  //     // {
  //     //   name: "Package Subscriptions",
  //     //   path: "/dashboard/subscriptions/package",
  //     //   component: PackageSubscriptions,
  //     //   display: true,
  //     //   role: ["admin"],
  //     // },
  //     {
  //       name: "Wellbeing Coach Subscriptions",
  //       path: "/dashboard/subscriptions/nutritionist",
  //       component: NutritionistSubscriptions,
  //       display: true,
  //       role: ["admin"],
  //     },
  //   ],
  // },
  {
    name: "Surveys",
    icon: FavoriteIcon,
    hasSubroute: true,
    display: true,
    role: ["admin", "nutritionist"],

    subroutes: [
      {
        name: "Health Assessment Category",//"Categories",
        path: "/dashboard/health-profile/categories",
        component: Categories,
        display: true,
        role: ["admin"],
      },
      {
        name: "Health Assessment Questions",//"General Health Assessments",
        path: "/dashboard/health-profile/surveys/general",
        component: GeneralSurvey,
        display: true,
        role: ["admin"],
      },
      {
        name: "Health Assessments Results",
        path:
          "/dashboard/health-profile/surveys/results/general/:survey/:userId/:surveyId/:orderId/:type",
        component: GeneralSurveyResults,
        display: true,
        role: ["admin", "nutritionist"],
      },
      {
        name: "Customized Health Assessments",
        path: "/dashboard/health-profile/surveys/custom/surveys",
        component: CustomSurvey,
        display: true,
        role: ["nutritionist"],
      },
      {
        name: "Customized Questions",
        path:
          "/dashboard/health-profile/surveys/custom/surveys/questions/:survey",
        component: CustomQuestions,
        role: ["admin", "nutritionist"],
        display: false,
      },
      {
        name: "Health Profile Questions",
        path: "/dashboard/medical-profile/survey/questions",
        component: MedicalSurvey,
        display: true,
        role: ["admin"],
      },
      {
        name: "Health Profile Results",
        path: "/dashboard/medical-profile/survey/results/:userId",
        component: MedicalSurveyResults,
        display: true,
        role: ["admin", "nutritionist"],
      },
      {
        name: "Medical Survey Options",
        path: "/dashboard/medical-profile/survey/options/:question/:type",
        component: SurveyOptions,
        role: ["admin"],
        display: false,
      },
      {
        name: "Lifestyle Profile Questions",
        path: "/dashboard/lifestyle-profile/survey/questions",
        component: LifestyleSurvey,
        display: true,
        role: ["admin"],
      },
      {
        name: "Lifestyle Profile Results",
        path: "/dashboard/lifestyle-profile/survey/results/:userId",
        component: LifestyleSurveyResults,
        display: true,
        role: ["admin", "nutritionist"],
      },
      {
        name: "Lifestyle Survey Options",
        path: "/dashboard/lifestyle-profile/survey/options/:question/:type",
        component: SurveyOptions,
        role: ["admin"],
        display: false,
      },
      {
        name: "Survey Results",
        hasSubroute: false,
        path: "/dashboard/surveys-results/all/",
        component: OrderSurveyResults,
        display: true,
        role: ["admin", "nutritionist"],
      },
      {
        name: "Survey Results Dashboard",
        hasSubroute: false,
        path: "/dashboard/surveys-results/all/:userId/:questionnaireId/:questionaireType/:lastUpdated",
        component: SurveyResultDetails,
        display: false,
        role: ["admin", "nutritionist"],
      },
      {
        name: "Client Notes",
        path: "/dashboard/client-notes/:userId/:orderId",
        component: ClientNotesTable,
        role: ["nutritionist", "externalNutritionist"],
        display: false,
      },
      {
        name: "Client Notes",
        path: "/dashboard/client-notes/:userId/:orderId/:dependentId",
        component: ClientNotesTable,
        role: ["nutritionist","externalNutritionist"],
        display: false,
      },
    ]
  },
  {
    name: "Services",
    icon: RoomServiceIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Service Category",
        path: "/dashboard/services/service-category",
        component: ServiceCategories,
        display: true,
        role: ["admin"],
      },
      {
        name: "Conditions/Treatment Plans",
        path: "/dashboard/services/conditions",
        component: ConditionCategories,
        display: true,
        role: ["admin"],
      },
      {
        name: "Questionnaires",
        path: "/dashboard/services/questionnaires",
        component: Questionnaires,
        display: true,
        role: ["admin"],
      },
      {
        name: "Bundles",
        path: "/dashboard/services/bundles",
        component: Bundles,
        display: true,
        role: ["admin"],
      },
      {
        name: "Questionnaire Bundle Mapping",
        path: "/dashboard/services/questionnaire-bundle-mapping",
        component: BundleMapping,
        display: true,
        role: ["admin"],
      },
      {
        name: "Medicines",
        path: "/dashboard/services/medicines",
        component: Medicines,
        display: true,
        role: ["admin"],
      },
      {
        name: "Supplements",
        path: "/dashboard/services/supplements",
        component: SupplementCategories,
        display: true,
        role: ["admin"],
      },
      {
        name: "Other Items",
        path: "/dashboard/services/other-items",
        component: OtherItems,
        display: true,
        role: ["admin"],
      }
    ]
  },
  // {
  //   name: "Health Assessments",
  //   icon: FavoriteIcon,
  //   hasSubroute: true,
  //   display: true,
  //   role: ["admin", "nutritionist"],

  //   subroutes: [
  //     {
  //       name: "Health Assessment Category",//"Categories",
  //       path: "/dashboard/health-profile/categories",
  //       component: Categories,
  //       display: true,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Health Assessment Questions",//"General Health Assessments",
  //       path: "/dashboard/health-profile/surveys/general",
  //       component: GeneralSurvey,
  //       display: true,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Health Assessments Results",
  //       path:
  //         "/dashboard/health-profile/surveys/results/general/:survey/:userId/:surveyId/:orderId/:type",
  //       component: GeneralSurveyResults,
  //       display: true,
  //       role: ["admin", "nutritionist"],
  //     },
  //     {
  //       name: "Customized Health Assessments",
  //       path: "/dashboard/health-profile/surveys/custom/surveys",
  //       component: CustomSurvey,
  //       display: true,
  //       role: ["nutritionist"],
  //     },
  //     {
  //       name: "Customized Questions",
  //       path:
  //         "/dashboard/health-profile/surveys/custom/surveys/questions/:survey",
  //       component: CustomQuestions,
  //       role: ["admin", "nutritionist"],
  //       display: false,
  //     },
  //   ],
  // },
  // {
  //   name: "Health Profile",
  //   icon: LocalHospitalIcon,
  //   hasSubroute: true,
  //   display: true,
  //   role: ["admin", "nutritionist"],
  //   subroutes: [
  //     {
  //       name: "Health Profile Questions",
  //       path: "/dashboard/medical-profile/survey/questions",
  //       component: MedicalSurvey,
  //       display: true,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Health Profile Results",
  //       path: "/dashboard/medical-profile/survey/results/:userId",
  //       component: MedicalSurveyResults,
  //       display: true,
  //       role: ["admin", "nutritionist"],
  //     },
  //     {
  //       name: "Medical Survey Options",
  //       path: "/dashboard/medical-profile/survey/options/:question/:type",
  //       component: SurveyOptions,
  //       role: ["admin"],
  //       display: false,
  //     },
  //   ],
  // },
  // {
  //   name: "Lifestyle Profile",
  //   icon: FastfoodIcon,
  //   hasSubroute: true,
  //   display: true,
  //   role: ["admin", "nutritionist"],
  //   subroutes: [
  //     {
  //       name: "Lifestyle Profile Questions",
  //       path: "/dashboard/lifestyle-profile/survey/questions",
  //       component: LifestyleSurvey,
  //       display: true,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Lifestyle Profile Results",
  //       path: "/dashboard/lifestyle-profile/survey/results/:userId",
  //       component: LifestyleSurveyResults,
  //       display: true,
  //       role: ["admin", "nutritionist"],
  //     },
  //     {
  //       name: "Lifestyle Survey Options",
  //       path: "/dashboard/lifestyle-profile/survey/options/:question/:type",
  //       component: SurveyOptions,
  //       role: ["admin"],
  //       display: false,
  //     },
  //   ],
  // },
  
  // {
  //   name: "Tests & Packages",
  //   icon: ChromeReaderModeIcon,
  //   hasSubroute: true,
  //   display: false,
  //   role: ["admin"],
  //   subroutes: [
  //     {
  //       name: "Manage Packages",
  //       path: "/dashboard/tests-and-packages/packages",
  //       component: ManagePackages,
  //       display: true,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Manage Tests",
  //       path: "/dashboard/tests-and-packages/tests",
  //       component: ManageTests,
  //       display: true,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Manage Test Categories",
  //       path: "/dashboard/tests-and-packages/test-categories",
  //       component: ManageTestCategories,
  //       display: true,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Manage Bio-markers",
  //       path: "/dashboard/tests-and-packages/tests/biomarkers",
  //       component: ManageBioMarkers,
  //       display: false,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Food Intolerance Tests",
  //       path: "/dashboard/tests-and-packages/tests/food-intolerance",
  //       component: FoodIntolerance,
  //       display: false,
  //       role: ["admin"],
  //     },
  //   ],
  // },
  
  {
    name: "Package Management",
    icon: ChromeReaderModeIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Mini Package City Update",
        path: "/dashboard/PackageCreation/MinyPackageCityUpdate",
        component: MiniPackageCityUpdates,
        display: true,
        role: ["admin"],
      },
      {
        name: "Mini Package Update",
        path: "/dashboard/PackageCreation/MiniPackageUpdate",
        component: MiniPackages,
        display: true,
        role: ["admin"],
      },
      {
        name: "Product Categories",
        path: "/dashboard/PackageCreation/ProductCategory",
        component: ProductCategory,
        display: true,
        role: ["admin"],
      },
      {
        name: "Custom Packages",
        path: "/dashboard/CustomPackageCreation/Packages",
        component: Packages,
        display: true,
        role: ["admin"],
      },
      {
        name: "Custom Test Add-ons",
        path: "/dashboard/CustomTest-Add-ons",
        component: CustomTestAddonsIndex,
        display: true,
        role: ["admin"],
      },

      {
        name: "Coach Consultation Package",
        path: "/dashboard/CoachConsultationpackage",
        component: CoachConsultationPackage,
        display: true,
        role: ["admin"],
      },
      {
        name: "Blood Biomarker Packages",
        path: "/dashboard/PackageCreation/BloodBiomarkerPackages",
        component: BloodBiomarkerPackages,
        display: true,
        role: ["admin"], 
      },
      {
        name: "Health Coach Packages",
        path: "/dashboard/PackageCreation/HealthCoachPackages",
        component: HealthCoachPackages,
        display: true,
        role: ["admin"], 
      },
      {
        name: "Biomarker Categories",
        path: "/dashboard/tests-and-packages/test-categories",
        component: ManageTestCategories,
        display: true,
        role: ["admin"],
      },
      {
        name: "Blood Biomarker List",
        path: "/dashboard/tests-and-packages/tests",
        component: ManageTests,
        display: true,
        role: ["admin"],
      },
      {
        name: "Manage Bio-markers",
        path: "/dashboard/tests-and-packages/tests/biomarkers",
        component: ManageBioMarkers,
        display: false,
        role: ["admin"],
      },
      {
        name: "Non Blood Biomarker List",
        path: "/dashboard/non-blood-biomarkers/tests",
        component: ManageNonBloodTests,
        display: true,
        role: ["admin"],
      },
      {
        name: "Food Intolerance Tests",
        path: "/dashboard/tests-and-packages/tests/food-intolerance",
        component: FoodIntolerance,
        display: false,
        role: ["admin"],
      },
      {
        name: "List Of Food",
        path: "/dashboard/list-of-food",
        component: ListOfFood,
        display: true,
        role: ["admin"],
      },
      {
        name: "Food Categories",
        path: "/dashboard/food-categories",
        component: FoodCategories,
        display: true,
        role: ["admin"],
      },
      {
        name:"Sample Details",
        path: "/dashboard/sample-details",
        component: SampleDetails,
        display: true,
        role: ["admin"],
      }
    ],
  },
  // {
  //   name: "Custom Package Creation",
  //   icon: ChromeReaderModeIcon,
  //   hasSubroute: true,
  //   display: true,
  //   role: ["admin"],
  //   subroutes: [
  //      {
  //       name: "Packages",
  //       path: "/dashboard/CustomPackageCreation/Packages",
  //       component: Packages,
  //       display: true,
  //       role: ["admin"],
  //     },
  //   ],
  // },

  {
    name: "Lab Management",
    icon: ChromeReaderModeIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Time Slots",
        path: "/dashboard/orders/time-slots",
        component: TimeSlots,
        display: true,
        role: ["admin"],
      },
      {
        name: "Regions",
        path: "/dashboard/LabManagment/RegionManagment",
        component: RegionManagment,
        display: true,
        role: ["admin"],
      },
      {
        name: "Labs",
        path: "/dashboard/LabManagment/LabProfile",
        component: Labslots,
        display: true,
        role: ["admin"],
      },
      {
        name: "Homecare Services",
        path: "/dashboard/LabManagment/HomecareServices",
        component: HomeCareServicesIndex,
        display: true,
        role: ["admin"],
      },
      {
        name: "Homecare Costs",
        path: "/dashboard/LabManagment/homecare-costs",
        component: HomecareCosts,
        display: true,
        role: ["admin"],
      },
      {
        name: "Lab Availability Slots",
        path: "/dashboard/LabManagment/LabSlots",
        component: EditLabSlots,
        display: true,
        role: ["admin"],
      },
      {
        name: "Lab Availability Dashboard",
        path: "/dashboard/LabManagment/LabDashboard",
        component: Labdashboard,
        display: true,
        role: ["admin"],
      },
      {
        name: "Lab Costs",
        path: "/dashboard/LabCosts",
        component: LabCosts,
        display: true,
        role: ["admin"],
      },
      {
        name: "Nurse Management",
        path: "/dashboard/NurseManagement",
        component: NurseManagement,
        display: true,
        role: ["admin"],
      },
      {
        name:"Package LabSlots",
        path: "/dashboard/LabManagement/PackageLabSlots",
        component: LabSlotsPackages,
        display: true,
        role: ["admin"],
      }
    ],
  },
  {
    name: "Coach Management",
    icon: ChromeReaderModeIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Wellbeing coaches",
        hasSubroute: false,
        path: "/dashboard/users/nutritionists",
        component: Nutritionist,
        display: true,
        role: ["admin"],
      },
      {
        name: "Coach Availability Slots",
        path: "/dashboard/CoachManagment/CoachSlots",
        component: CoachSlots,
        display: true,
        role: ["admin"],
      },
    ],
  },
  {
    name: "Physio Management",
    icon: ChromeReaderModeIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Physiotherapist",
        hasSubroute: false,
        path: "/dashboard/physiotherapist",
        component: Physiotherapist,
        display: true,
        role: ["admin"],
      },
    ],
  },
  {
    name: "Website CMS",
    icon: WebIcon,
    role: ["admin"],
    display: true,
    hasSubroute: true,
    subroutes: [
      // {
      //   name: "Landing Page",
      //   icon: LandingPageIcon,
      //   role: ["admin"],
      //   display: true,
      //   hasSubroute: true,
      //   subroutes: [
      //     {
      //       name: "Slider",
      //       display: true,
      //       role: ["admin"],
      //       path: "/dashboard/landing-page/slider",
      //       component: Slider,
      //     },
      //     {
      //       name: "How It Work",
      //       display: true,
      //       role: ["admin"],
      //       path: "/dashboard/landing-page/how-it-works",
      //       component: HowItWorks,
      //     },
      //     {
      //       name: "What We Focus",
      //       display: true,
      //       role: ["admin"],
      //       path: "/dashboard/landing-page/what-we-focus",
      //       component: WhatWeFocus,
      //     },
      //   ],
      // },
      {
        name: "Website Categories",
        hasSubroute: false,
        path: "/dashboard/cms/website-categories",
        component: WebsiteCategories,
        display: true,
        role: ["admin"],
      },
      {
        name: "Packages CMS",
        hasSubroute: false,
        path: "/dashboard/cms/packages",
        component: PackagesCMS,
        display: true,
        role: ["admin"],
      },
      {
        name: "Landing Page",
        hasSubroute: false,
        path: "/dashboard/cms/landing-page",
        component: LandingPage,
        role: ["admin"],
        display: true,
      },
      {
        name: "Article Categories",
        hasSubroute: false,
        path: "/dashboard/cms/article-categories",
        component: ArticleCategories,
        display: true,
        role: ["admin"],
      },
      {
        name: "Articles",
        hasSubroute: false,
        path: "/dashboard/cms/articles",
        component: Articles,
        display: true,
        role: ["admin"],
      },
      {
        name: "Banners",
        hasSubroute: false,
        path: "/dashboard/cms/Banners",
        role: ["admin"],
        component: Banners,
        display: true,
      },
      {

        name: "Search Keywords",
        hasSubroute: false,
        path: "/dashboard/search-keywords",
        role: ["admin"],
        component: SearchKeywords,
        display: true,
      },
      {
         name:"SEO Landing Pages",
         hasSubroute: false,
         path: "/dashboard/cms/seo-landing-pages",
         role: ["admin"],
         component: SeoLandingPage,
         display: true,
      },
      {

        name:"Navigation Menu",
        hasSubroute: false,
        path: "/dashboard/cms/navigation-menu",
        role: ["admin"],
        component: CMSNavigationMenu,
        display: true,

      },
       {
        name:"Advance Landing Pages",
        hasSubroute: false,
        path: "/dashboard/cms-advance-landing-pages",
        role: ["admin"],
        component: AdvanceLandingPageHome,
        display: true,
     },
     {
      name:"Widgets",
      hasSubroute: false,
      path: "/dashboard/cms-advance-landing-pages/all/:pageId/:countryId",
      role: ["admin"],
      component: WidgetsList,
      display:false
     }
     

    ],
  },
  {
    name: "Availibilty Slots",
    icon: QuestionAnswerIcon,
    hasSubroute: false,
    path: "/dashboard/CoachManagment/CoachslotDetails",
    component: CoachslotDetails,
    display: true,
    role: ["nutritionist"],
  },
  {
    name: "FAQ",
    icon: HelpIcon,
    hasSubroute: true,
    path: "/dashboard/faq",
    component: FAQ,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "FAQ Details",
        display: true,
        role: ["admin"],
        path: "/dashboard/FAQ-details",
        component: FAQDetails,
      },
    ],
  },
  // {
  //   name: "Reviews",
  //   icon: StarHalfIcon,
  //   hasSubroute: false,
  //   display: true,
  //   role: ["admin"],
  //   path: "/dashboard/reviews/order-reviews",
  //   component: OrderReviews,
  // },
  // {
  //   name: "Reviews",
  //   icon: StarHalfIcon,
  //   hasSubroute: true,
  //   display: true,
  //   role: ["admin"],
  //   subroutes: [
  //     {
  //       name: "Order Reviews",
  //       path: "/dashboard/reviews/order-reviews",
  //       component: OrderReviews,
  //       display: true,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Nutritionist Reviews",
  //       path: "/dashboard/reviews/nutrionist-reviews",
  //       component: NutritionistReviews,
  //       display: true,
  //       role: ["admin"],
  //     },
  //     {
  //       name: "Nutritionist Ratings",
  //       path: "/dashboard/reviews/nutrionist-ratings",
  //       component: NutritionistRatings,
  //       display: true,
  //       role: ["admin"],
  //     },
  //   ],
  // },
  {
    name: "Ratings",
    // icon: HelpIcon,
    icon: StarsIcon,
    hasSubroute: true,
    path: "/dashboard/ratings",
    component: RatingsPanel,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "How Do you Hear About Us",
        display: true,
        role: ["admin"],
        path: "/dashboard/How-do-hear-about-us",
        component: HowDoYouHear,
      }
    ]
  },
  {
    name: "Reviews",
    icon: ReviewsIcon,
    hasSubroute: false,
    path: "/dashboard/reviews",
    component: Reviews,
    display: true,
    role: ["admin"],
  },
  {
    name: "Messages",
    icon: QuestionAnswerIcon,
    hasSubroute: false,
    path: "/dashboard/chat",
    component: Chat,
    display: true,
    role: ["nutritionist"],
    notificationId: "chat",
  },
  {
    name: "Customer Longevity Score",
    icon: PeopleAltIcon,
    hasSubroute: false,
    path: "/dashboard/customer-longevity-score",
    component: CustomersLongevityScore,
    display: true,
    role: ["nutritionist", "externalNutritionist"],
  },
  {
    name: "Customer Longevity Score Detail",
    hasSubroute: false,
    path: "/dashboard/customer-longevity-score/:customerId/:countryId/:longevityCategoryId",
    component: CustomersLongevityScoreDetail,
    display: false,
    role: ["nutritionist", "externalNutritionist"],
  },
  
    {
      name: "Customer Longevity Score Detail",
      hasSubroute: false,
      path: "/dashboard/dependent-longevity-score/:customerId/:countryId/:longevityCategoryId/:dependentId",
      component: CustomersLongevityScoreDetail,
      display: false,
      role: ["nutritionist", "externalNutritionist"],
    },
  


  // {
  //   name: "Landing Page",
  //   icon: LandingPageIcon,
  //   hasSubroute: true,
  //   display: true,
  //   role: ["admin"],
  //   subroutes: [
  //     {
  //       name: "Slider",
  //       display: true,
  //       role: ["admin"],
  //       path: "/dashboard/landing-page/slider",
  //       component: Slider,
  //     },
  //     {
  //       name: "How It Work",
  //       display: true,
  //       role: ["admin"],
  //       path: "/dashboard/landing-page/how-it-works",
  //       component: HowItWorks,
  //     },
  //     {
  //       name: "What We Focus",
  //       display: true,
  //       role: ["admin"],
  //       path: "/dashboard/landing-page/what-we-focus",
  //       component: WhatWeFocus,
  //     },
  //   ],
  // },
  {
    name: "Coupon Codes",
    // icon: CouponCodeIcon,
    icon: LocalOfferIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Coupon Code Generator",
        display: false,
        role: ["admin"],
        path: "/dashboard/coupon-codes/generator",
        component: CouponCodeGenerator,
      },
      {
        name: "Coupon Codes",
        display: true,
        role: ["admin"],
        path: "/dashboard/coupon-codes/coupon-code-list",
        component: CouponCodeIndex,
      },
    ],
  },
  {
    name: "Corporate Partners",
    icon: PeopleAltIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Valeo Ambassadors",
        display: true,
        role: ["admin"],
        path: "/dashboard/corporate-partners/ambassadors",
        component: ValeoAmbassadors,
      },
      {
        name: "Valeo Partners",
        display: true,
        role: ["admin"],
        path: "/dashboard/Valeo-partners",
        component: ValeoPartners,
      },
      {
        name: "Dewa",
        display: true,
        role: ["admin"],
        path: "/dashboard/Dewa-Dashboard",
        component: DewaPartnerPackages,
      },
      {
        name:"Valeo Corporates",
        display:true,
        role: ["admin"],
        path: "/dashboard/Valeo-Corporates",
        component: ValeoCorporates,
      }
    ],
  },
  {
    name: "Longevity Score",
    icon: PeopleAltIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Longevity Score",
        display: true,
        role: ["admin"],
        path: "/dashboard/longevity-score",
        component: LongevityScore,
      },
      {
        name: "Fitness Plan",
        display: true,
        role: ["admin"],
        path: "/dashboard/fitness-plan",
        component: FitnessPlan,
      },
    ],
  },
  {
    name : "Category",
    hasSubroute:false,
    path:"/dashboard/longevity-parameters/all/:categoryId/:countryId",
    component:LongevityParameters,
    display:false,
    role:["admin"],
   },
  {
    name: "Referral Codes",
    icon: PeopleAltIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Referral Codes",
        display: true,
        role: ["admin"],
        path: "/dashboard/referral-codes",
        component: ReferralCode,
      }
    ]
  },
  {
    name: "Application Settings",
    // icon: SettingsIcon,
    icon: SettingsIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Force/Recommend update version",
        display: true,
        role: ["admin"],
        path: "/dashboard/application-settings/force-recommend-update",
        component: ForceUpdate,
      },
      {
        name: "Mobile Promotions",
        display: true,
        role: ["admin"],
        path: "/dashboard/application-settings/mobile-promotions",
        component: MobilePromotions,
      },
      {
        name: "App Banners",
        display: true,
        role: ["admin"],
        path: "/dashboard/application-settings/app-banners",
        component: HomePageAppBanners,
      },
      {
        name: "Home Page Rankings",
        display: true,
        role: ["admin"],
        path: "/dashboard/application-settings/home-page-rankings",
        component: HomePageBannersRankings,
      },
    ],
  },
  {
    name: "Exchange Rates",
    icon: MoneyIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    subroutes: [
      {
        name: "Exchange Rates",
        display: true,
        role: ["admin"],
        path: "/dashboard/money-exchange-rates",
        component: ExchangeRates
      }
    ]
  },

  {
    name: "Home Care Portal",
    icon: ChromeReaderModeIcon,
    hasSubroute: true,
    display: true,
    role: ["homecare"],
    subroutes: [
      {
        name: "Upcoming Orders",
        display: true,
        role: ["homecare"],
        path: "/dashboard/home-care/upcoming-orders",
        component: UpcomingOrders,
      },
      {
        name: "Bookings",
        display: true,
        role: ["homecare"],
        path: "/dashboard/home-care/bookings",
        component: Bookings,
      },
      {
        name: "Professional Management",
        display: true,
        role: ["homecare"],
        path: "/dashboard/home-care/professional-management",
        component: ProfessionalManagement,
      },
      
    ]
  },
  {
    name:"Ticketing Management",
    icon: AssignmentIndIcon,
    hasSubroute: true,
    display: true,
    role: ["homecare"],
    subroutes: [
      {
        name: "Create New Ticket",
        display: true,
        role: ["homecare"],
        path: "/dashboard/home-care/create-ticket",
        component:CreateTicket
      },
      {
        name: "View Tickets",
        display: true,
        role: ["homecare"],
        path: "/dashboard/home-care/ticket-management",
        component:ViewMyTickets
      }
    ]
  },  
  {
    name: "Nurse Portal",
    icon: ChromeReaderModeIcon,
    hasSubroute: true,
    display: true,
    role: ["homeCareProfessional"],
    subroutes: [
      {
        name: "New Orders",
        icon: MoneyIcon,
        display: true,
        role: ["homeCareProfessional"],
        path:"/dashboard/home",
        component:HomecarePortal,
      },
      {
        name: "Completed Orders",
        icon: MoneyIcon,
        display: true,
        role: ["homeCareProfessional"],
        path:"/dashboard/homecare/completed-orders",
        component:HomecarePortal,
      },
      {
        name: "Notifications",
        icon: MoneyIcon,
        display: false,
        role: ["homeCareProfessional"],
        path:"/dashboard/nurse-notifications/:orderId",
        component:NurseNotifications,
      },
      {
        name: "Nurse Notifications",
        icon: MoneyIcon,
        display: false,
        role: ["homeCareProfessional"],
        path:"/dashboard/notifications",
        component:NursePanelNotifications,
      },
      {
        name: "Past Orders",
        icon: MoneyIcon,
        display: false,
        role: ["homeCareProfessional"],
        path:"/dashboard/nurse/past-orders",
        component:NursePastOrders,
      },
      {
        name: "Client Lab Details",
        icon: MoneyIcon,
        display: false,
        role: ["homeCareProfessional"],
        path:"/dashboard/nurse/client-lab-details/:nurseUserId/:orderId",
        component:ClientLabDetails,
      },
      {
        name: "Upload Sample Details",
        icon: MoneyIcon,
        display: false,
        role: ["homeCareProfessional"],
        path:"/dashboard/nurse/upload-sample-details/:orderId",
        component:UploadSampleDetails,
      },
      {
        name: "Report Issue",
        icon: MoneyIcon,
        display: false,
        role: ["homeCareProfessional"],
        path:"/dashboard/nurse/report-issue/:orderId",
        component:ReportIssue,
      },
      {
        name:"Change Member",
        icon: MoneyIcon,
        display: false,
        role: ["homeCareProfessional"],
        path:"/dashboard/nurse/change-member/:userId/:orderId",
        component:ChangeMemberDetails,
      }
    ]
  },
  {
    name: "HomeCare Mobile Portal",
    icon: PeopleAltIcon,
    hasSubroute: false,
    path: "/dashboard/homecare-mobile-portal",
    component: HomecareMobilePortal,
    display: true,
    role: ["homecare"],
  },
  {
    name:"Ticket Management Mobile Portal",
    icon: AssignmentIndIcon,
    hasSubroute: false,
    path:"/dashboard/homecare-mobile-portal/ticket-management",
    display: true,
    role: ["homecare"],
    component:HomecareTicketManagement
    
  },
  {
    name:"Ticket Management Mobile Portal",
    icon: AssignmentIndIcon,
    hasSubroute: false,
    path:"/dashboard/homecare-mobile-portal/ticket-management/:ticketId",
    display: true,
    role: ["homecare"],
    component:HomecareTicketView
  },
  {
    name: "Ticket Management Mobile Portal",
    display: true,
    icon: AssignmentIndIcon,
    role: ["homecare"],
    hasSubroute: false,
    path: "/dashboard/home-care/reopen-ticket/:ticketId",
    component:ReopenTicket
  },
  // {
  //   name: "New Orders",
  //   icon: MoneyIcon,
  //   hasSubroute:false,
  //   display: true,
  //   role: ["admin"],
  //   path:"/dashboard/homecare",
  //   component:HomecarePortal,
  // },
  // {
  //   name: "Homecare",
  //   icon: MoneyIcon,
  //   hasSubroute: false,
  //   display: true,
  //   role: ["homeCareProfessional"],
  //   path: "/dashboard/homecare/completed-orders",
  //   component: HomecarePortal,
  // },
 
];

export default routes;
