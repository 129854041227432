import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import PhoenixAPI from "../../utils/PhoenixAPI";
import ".././../pages/HomecareMwebPortal/index.scss"
import "../../pages/HomecareMwebPortal/notification.scss"

import { Link } from "react-router-dom";

import moment from "moment";


function UnResponsedNotofication(props){
    const {clientName, confirmedTime,orderId}= props.client || {}
    const {handleSaveNurseResponse={}}= props
    const [nurseTime, setNurseTime] = useState("");
    const [first, setFirst] = useState(false);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [nursePanelIsArabic,setNursePanelIsArabic] = useState("");

    useEffect(()=>{
      if(window.localStorage && localStorage.getItem("nursePanelIsArabic")){
        const isArabic = JSON.parse(localStorage.getItem("nursePanelIsArabic"));
        setNursePanelIsArabic(isArabic);
      }
    },[])

    const handleTimeChange = (e) => {
        const message = e.target.value;
        setNurseTime(message);
      };
    
      const handleSelect = (selectedItem, index) => {
        if (selectedItem === "yes") {
          setFirst(true);
          setSecond(false);
          setThird(false);
          setSelectedOption("Nurse on time");
          setNurseTime("");
        } else if (selectedItem === "running") {
          setFirst(false);
          setSecond(true);
          setThird(false);
          setSelectedOption("Nurse late by 10 mins");
          setNurseTime("");
        } else if (selectedItem === "more") {
          setFirst(false);
          setSecond(false);
          setThird(true);
          setSelectedOption("Nurse late by more than 15 mins");
        }
      };

    const getFormattedPurchasedDate = (purchasedDate) => {
        const date = moment(purchasedDate).format("MMM DD, YYYY");
        const time = moment(purchasedDate).format("hh:mm A");
        return `${date} | ${time}`;
      };
      const getTime=(confirmTime)=>{
        const date = moment(confirmTime).format("MMM DD, YYYY");
        const time = moment(confirmTime).format("hh:mm A");
        return `${date} at ${time}`;
      }

      const handleSave = (orderId) => {
        if(!selectedOption){
            return
        }
        const payload={
            "orderId":orderId,
            "selectedOption":selectedOption,
        }
        if(nurseTime){
            payload.nurseReason= nurseTime
        }
        handleSaveNurseResponse(payload)

      };
    
    const nextBookingText = `Your next booking is at ${getTime(confirmedTime)}. Are you on time ?`
    const arNextBookingText = `موعدك القادم في ${getTime(confirmedTime)}. هل أنت في الوقت المحدد؟`
    
    return (
        <div className={nursePanelIsArabic? "ar-clients-new-booking" : "clients-new-booking"}>
              <div className="client-name-date">
                <div className="name-txt">{clientName}</div>
                <div className="date-time">
                  <span className="month">
                  {getFormattedPurchasedDate(confirmedTime)}
                  </span>
                
                </div>
              </div>
              <div className="schedule-book">
                { nursePanelIsArabic? arNextBookingText : nextBookingText}
                {/* {`Your next booking is at ${getTime(confirmedTime)}. Are you on time ?`} */}
              </div>
              <div
                className={`${first && `selected-answer`} select-answer`}
                onClick={() => handleSelect("yes")}
              >
              {nursePanelIsArabic? "نعم" : "Yes"}
              </div>
              <div
                className={`${second && `selected-answer`} select-answer`}
                onClick={() => handleSelect("running")}
              >
               {nursePanelIsArabic? "سأتأخر عشر دقائق" : "I’m running 10 minutes late"}
              </div>
              <div
                className={`${third && `selected-answer`} select-answer`}
                onClick={() => handleSelect("more")}
              >
              {nursePanelIsArabic? "سوف يستغرق الأمر أكثر من 15 دقيقة" : "It will take me more than 15 mins"}
              </div>
              {third && (
                <>
                  <div className="schedule-book">
                    {nursePanelIsArabic? "اذكر كم من الوقت سيستغرق الأمر؟" : "Mention how much time will it take ?"}
                  </div>
                  <div className="input-time">
                    <input
                      type="text"
                      value={nurseTime}
                      onChange={(e) => handleTimeChange(e)}
                      placeholder="Mention Time"
                    />
                  </div>
                </>
              )}
              <div className="save-button" onClick={() => handleSave(orderId)}>
                {nursePanelIsArabic? " إحفظ " : "Save"}
              </div>
            </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return state;
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  };


  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(UnResponsedNotofication);