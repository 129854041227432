import React, { useState } from 'react';
import Notification from './Notification';
import {trackOpenMapsClick} from "analytics";
const GOOGLE_API_KEY = "AIzaSyBVHdUwoQBuSvxaKu6z4xO-P2KcKe1CigQ"

function OpenGoogleMapWithPin({ item = {}, user={} , nursePanelIsArabic }) {
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);

    const googleMapsUrl = item.userPinLocation;
    const handleMap = async () => {
      
  const deviceId= sessionStorage.getItem("device_id") ??"";
   
  const  trackingData={
    "homecareId":user?.homecare_service_id??"",
    "deviceId":deviceId,
    "nurseName":user?.username??"",
    "nurseId":user?.id??"",
     "deviceId":deviceId
   }
        try {

            if (!googleMapsUrl) {
                const noUserMap = nursePanelIsArabic? "لا يوجد موقع خريطة للمستخدم" : "There is no user's map  location"
                setMessage(noUserMap);
                setIsError(true);
                trackingData["mapsOpened"]="false";
                trackingData["locationProvided"]="false";
                trackingData["popMessage"]="There is no user's map  location";
                trackOpenMapsClick(trackingData)
            } else {
                const placeIdRegex = /place_id:(\S+)/;
                const match = googleMapsUrl.match(placeIdRegex);
                let placeId = ""
                if (match && match[1]) {
                    placeId = match[1];
                       let  res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLE_API_KEY}`)
                        let data = await res.json()
                    const geometry = data && data.results && data.results.length > 0 && data.results[0].geometry && data.results[0].geometry.location;
                    const { lat, lng } = geometry||{};
                    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;  
                    window.open(mapsUrl, '_self');
                    trackingData["mapsOpened"]="true";
                    trackingData["locationProvided"]="true";
                    trackingData["popMessage"]="opened successfully";
                    trackOpenMapsClick(trackingData)
                } else {
                    window.open(googleMapsUrl, '_self');
                    trackingData["mapsOpened"]="true";
                    trackingData["locationProvided"]="true";
                    trackingData["popMessage"]="opened successfully";
                    trackOpenMapsClick(trackingData)
                }
            }

        } catch (error) {
            setMessage("Error while fetching map location");
            setIsError(true);
            console.log("error while fetching map location", error)

        }
    }
    return (
        <>
            <div onClick={() => handleMap()}>
            <span style={{ color: "#2185D0", textDecoration: "underline" }}>
          {nursePanelIsArabic? "افتح في الخرائط" : "Open in maps"}
        </span>
            </div>
            {message && <Notification setMessage={setMessage} message={message} isError={isError} />}
        </>
    );
}

export default OpenGoogleMapWithPin;




