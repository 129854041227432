import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import Search from "components/Search/Search";
import AlertBox from "components/AlertBox/AlertBox";
import SelectBookingStatus from "./SelectBookingStatus";
import { bookingStatus, findBookingStatusByValue } from "./bookingStatus";
import { fetchCities } from "services/RegionService";
import { formatDate, formatSingleTimeslot } from "utils/commons";
import API from "utils/API";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import AssignProfessional from "./AssignProfessional";
import GoogleMapForm from "./GoogleMapForm";
import PdfPreview from "components/Common/PdfPreview";
import SelectPaymentStatus from "./SelectPaymentStatus";
import moment from "moment";
import {trackBookingsSearchKeyword, trackShowOrdersClick,trackAssignProfessionalClick,trackEditClick } from "analytics";


const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TWO_MEGA_BYTE = 2_097_152;
const BASE_URL_BOOKINGS = "/list-home-care-order-details/homecare";
const BASE_URL_BOOKING_DETAILS = "/home-care-order-details";
const BASE_URL_PROFESSIONALS = "/home-care-professional";
const BASE_URL_LABS = "/create-laboratory";
const BASE_URL_CHECK_AND_COMPLETE_PARENT_ORDER = "/admin/check-and-complete-parent-order/";
const GENDER_OPTIONS = [
  {
    key: "M",
    value: "M",
    text: "Male",
  },
  {
    key: "F",
    value: "F",
    text: "Female",
  },
];
const FILTER_OPTIONS = [
  {
    key: "ALL",
    value: "ALL",
    text: "All",
  },
  {
    key: "NEW",
    value: "NEW",
    text: "New",
  },
  {
    key: "ACTIVE",
    value: "ACTIVE",
    text: "Active",
  },
  {
    key: "COMPLETED",
    value: "COMPLETED",
    text: "Completed",
  },
  {
    key: "CANCELLED",
    value: "CANCELLED",
    text: "Cancelled",
  },
];
class Bookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookings: [],
      booking: {},
      professionals: [],
      cities: [],
      labs: [],
      errors: {},
      editables: {},
      filter: { status: "ALL" },
      loadError: null,
      searchTerm: null,
      isAssignProfessionalModalVisible: false,
      previousCartOrder:"",
      previousColor:"#FFCBA4",
      currentOrder:null,
      selectedTab:"key_time_and_location_form"
    };

    this.columns = [
      {
        name:"CartOrder Id",
        selector: "cart_order_id",
        sortField:"cart_order_id",
        sortable: true,
        minWidth:"80px",
        maxWidth:"120px",
        wrap:true,
      },
      {
        name: "Order ID",
        selector: "id",
        sortField: "id",
        sortable: true,
        maxWidth:"50px",
        wrap: true,
      },
      {
        name: "Customer Name",
        selector: "client_name",
        wrap: true,
        sortable: true,
        maxWidth:"150px",
        format: (order) => order.client_name ?? "",
      },
      {
        name: "Appointment Date & Time",
        selector: "confirmed_date_time",
        wrap: true,
        sortable: true,
        maxWidth: "160px",
        format: (order) => this.renderAppointmentTime(order),
      },
      {
        name: "Location",
        selector: "address_line1",
        wrap: true,
        sortable: false,
        minWidth:"300px",
        maxWidth: "500px",
        format: (order) => this.getFullAddress(order),
      },
      {
        name: "Product Name",
        selector:"package_name",
        wrap: true,
        maxWidth:"200px",
        format: (order) =>{return `${order.package_name ?? ""}${order.is_recollection_order ? " (recollection)" : ""}`},
      },

      {
        name: "Payment to be collected",
        selector: "payment_to_be_collected",
        wrap: true,
        sortable: true,
        maxWidth: "150px",
        format: (order) => {
          return order.payment_to_be_collected ? (order.is_mamopay_transaction_completed || order.is_recollection_order? <p style={{ color: "red", fontWeight: "bold" }}>0</p>:
            <p style={{ color: "red", fontWeight: "bold" }}>
              {order.payment_to_be_collected}
            </p>
          ) : "";
        }
      },
      {
        name: "Lab 1",
        selector: "lab_1_name",
        wrap: true,
        minWidth:"300px",
        maxWidth: "500px",
        sortable: true,
        format: (order) => (order.is_require_sample_collection==false && order.order_item_type=="Custom_Package")?"N/A":order.lab_1_name?? "",
      },
      {
        name: "Sample Details - Lab 1",
        selector: "sample_details_lab1",
        wrap: true,
        sortable: true,
        minWidth:"200px",
        maxWidth:"350px",
        format: (order) => order?.sample_details_lab1?order?.sample_details_lab1?.split('\n').map((line, index) => (<div key={index}>{line}</div>)) : "",
      },
      {
        name: "Special Instructions",
        selector: "special_instructions",
        wrap: true,
        sortable: true,
        minWidth:"200px",
        maxWidth:"300px",
        format: (order) => order.special_instructions ? <p style={{fontWeight: "bold" }}>{order.special_instructions}</p>: "",
      },
      {
        name: "Lab 2",
        selector: "lab_2_name",
        wrap: true,
        minWidth:"300px",
        maxWidth: "500px",
        sortable: true,
      },
      {
        name: "Sample Details - Lab 2",
        selector: "sample_details_lab2",
        wrap: true,
        sortable: true,
        maxWidth:"300px",
        format: (booking) => booking.sample_details_lab2 ?? "",
      },
      {
        name: "Status",
        selector: "nurse_appointment_status",
        sortable: true,
        minWidth: "200px",
        maxWidth: "300px",
        wrap:true,
        cell: (booking) => this.renderBookingStatus(booking),
      },
      {
        name: "Previous Nurse",
        selector: "previous_nurse",
        sortable: true,
        minWidth: "200px",
        maxWidth: "300px",
        wrap:true,
      },
      {
        name: "Professional Assigned",
        selector: "professional_name",
        wrap: true,
        sortable: true,
        maxWidth:"250px",
        format: (booking) => booking.professional_name ?? "",
      },
      {
        name: "Action",
        center: true,
        maxWidth: "200px",
        cell: (booking) => this.renderActionButton(booking),
      },
    ];
  }
  creatingCleverTapProfileUser=()=>{
    const userId= localStorage.getItem("userId");
    const email = localStorage.getItem("userEmail");
    return (window.clevertap.onUserLogin.push({
     "Site": {
       "Identity": userId,
       "Email": email,
       "MSG-email": false,
       "MSG-push": false,
       "MSG-sms": false,
       "MSG-whatsapp": false,
     },
     "cart_data": []
   }))
   }
  async componentDidMount() {
    await Promise.all([this.fetchUser(), this.fetchCities(), this.fetchLabs()]);
    await this.fetchProfessionals();
   const today=moment().format("YYYY-MM-DD")
    this.setState({selectedStartDate:today,selectedEndDate:today})
    this.fetchBookings();
    this.creatingCleverTapProfileUser()
  }

  fetchUser = async () => {
    try {
      this.showLoading();
      const response = await API.get("/users/");
      const { data: users = [] } = response;
      console.log("fetchUser:: Response: ", { response });
      this.setState({ user: users && users.length ? users[0] : null });
    } catch (error) {
      console.log("fetchUser:: Error on fetching users!", error);
      this.showErrorNotification("Error on fetching user!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchCities = async () => {
    try {
      this.showLoading();
      const cities = (await fetchCities()) ?? [];
      this.setState({ cities });
    } catch (error) {
      this.showErrorNotification("Error on fetching cities!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchProfessionals = async () => {
    this.showLoading();
    const { user } = this.state;
    if (!user || !user.homecare_service_id) {
      console.log("fetchProfessionals:: Invalid user or homecare service id", {
        user,
      });
      this.showErrorNotification("Invalid user or homecare service id!");
      return;
    }

    try {
      const url = `${BASE_URL_PROFESSIONALS}/homecareId/${user.homecare_service_id}`;
      const { data: professionals = [] } = await PhoenixAPI.get(url);
      this.setState({ professionals });
    } catch (error) {
      console.log("Error on fetching professionals!", error);
      this.showErrorNotification(
        "Error on fetching professionals: ",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchLabs = async () => {
    try {
      this.showLoading();
      const { data: labs = [] } = await API.get(BASE_URL_LABS);
      this.setState({ labs });
    } catch (error) {
      this.showErrorNotification("Error on fetching labs!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchBookings = async (iShowOrder=false) => {
    this.showLoading();
    const { user,selectedStartDate,
      selectedEndDate,} = this.state;
      console.log("selected start and end dates are",selectedStartDate,selectedEndDate);
    if (!user || !user.homecare_service_id) {
      console.log("fetchBookings:: Invalid user!", { user });
      this.showErrorNotification("Invalid user!", { user });
      return;
    }
    const trackingData={}
    if(iShowOrder){
      const deviceId = sessionStorage.getItem("device_id") ?? "";
      
        trackingData["homecareId"]= user?.homecare_service_id??"";
        trackingData["deviceId"]= deviceId;
        trackingData["homecare"]= user?.email??"";
        trackingData["homecareName"]= user?.username??"";
        trackingData["startDate"]=moment(selectedStartDate).format("YYYY-MM-DD");
        trackingData["endDate"]=moment(selectedEndDate).format("YYYY-MM-DD");
      
    }
  
    const params = {};

    if (selectedStartDate)
    params.fromDate = moment(selectedStartDate).format("YYYY-MM-DD");
  if (selectedEndDate)
    params.toDate = moment(selectedEndDate).format("YYYY-MM-DD");

    try {
      const url = `${BASE_URL_BOOKINGS}/${user.homecare_service_id}`;
      const { data: bookings = [] } = await PhoenixAPI.get(url,{params});
      bookings.forEach((booking) => {
        const { lab_1_id, lab_2_id, nurse_appointment_status } = booking;
        if (!nurse_appointment_status) {
          booking.nurse_appointment_status = bookingStatus.CREATED.value;
        }

        const lab1 = this.findLabById(lab_1_id);
        if (lab1) {
          const { name, contact_number, location } = lab1;
          booking.lab_1_name = [name, contact_number, location]
            .filter((it) => it ?? false)
            .join(", ");
        }

        const lab2 = this.findLabById(lab_2_id);
        if (lab2) {
          const { name, contact_number, location } = lab2;
          booking.lab_2_name = [name, contact_number, location]
            .filter((it) => it ?? false)
            .join(", ");
        }
      });  
      this.setState({ bookings });
      if(iShowOrder){
        trackingData["no_of_orders_shown"]= bookings && bookings.length;
        trackShowOrdersClick(trackingData)
      }
    } catch (error) {
      console.log("Error on fetching bookings!", error);
      this.showErrorNotification("Error on fetching bookings: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchBookingDetails = async (orderId) => {
    if (!orderId) {
      console.log("fetchBookingDetails:: Invalid order id!", orderId);
      return null;
    }

    const { homecare_service_id } = this.state.user ?? {};
    if (!homecare_service_id) {
      console.log(
        "fetchBookingDetails:: Invalid homecare service id!",
        homecare_service_id
      );
      return null;
    }

    try {
      this.showLoading();
      // /home-care-order-details/homecare/1/order/7713
      const url = `${BASE_URL_BOOKING_DETAILS}/homecare/${homecare_service_id}/order/${orderId}`;
      const { data: booking } = await PhoenixAPI.get(url);
      return booking;
    } catch (error) {
      console.log(
        "fetchBookingDetails:: Error on fetching booking details!",
        error
      );
      this.showErrorNotification(
        "Error on fetching booking details: ",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  findLabById = (labId) => {
    const labs = this.state.labs ?? [];
    return labs.find((lab) => lab.id === labId);
  };

  showAssignProfessionalModal = () => {
    this.setState({
      isAssignProfessionalModalVisible: true,
      selectedBooking: this.state.booking ?? null,
    });
  };

  handleOnCopyLocation = (mapUrl) => {
    if (mapUrl) {
      navigator.clipboard.writeText(mapUrl).then(
        () => this.showSuccessNotification("Copied to clipboard."),
        () => this.showErrorNotification("Failed to copy!")
      );
    } else {
      this.showErrorNotification("Invalid google map url!");
    }
  };

  handleOnGoogleMaps = (mapUrl) => {
    if (mapUrl) {
      window.open(mapUrl, "_blank", "noreferrer");
    } else {
      this.showErrorNotification("Invalid google map url!");
    }
  };

  handleAssignProfessional = async (payload) => {
    console.log("handleAssignProfessional:: Payload", payload);

    const { user, bookings ,selectedTab} = this.state;
    
   
    if (!user || !bookings || !bookings.length) {
      console.log("handleAssignProfessional:: Invalid user or bookings!");
      this.showErrorNotification("Invalid user or bookings!");
      return;
    }
    const deviceId = sessionStorage.getItem("device_id") ?? "";
    const trackingData={
      "homecareId": user?.homecare_service_id??"",
      "deviceId": deviceId,
      "homecare": user?.email??"",
      "homecareName": user?.username??"",
    };
    const { id, homecare_service_id } = user;
    const booking = bookings?.find((it) => it.id === payload.orderId);
    payload.userId = id;
    payload.homeCareId = homecare_service_id;
    payload.nurseAppointmentStatus = bookingStatus.NURSE_APPOINTED.value;
    if(selectedTab==="key_client_and_lab_info_form"){
      trackingData["client_lab_info_click"]=true
      trackingData["client_lab_info_click"]=true
    }
    else if(selectedTab==="key_time_and_location_form"){
      trackingData["client_lab_info_click"]=false;
      trackingData["time_location click"]=true
    }
   
    try {
      this.showLoading();
      if (booking.professional_id) {
        await PhoenixAPI.patch("/assign-nurse", payload);
      } else {
        await PhoenixAPI.post("/assign-nurse", payload);
      }

      this.hideModal();
      this.showSuccessNotification("Assigned successfully.")
      this.fetchBookings();
      if(booking.professional_id){
       trackingData["assign_professional_popup_save successfull"]="Assigned successfully";
       trackEditClick(trackingData)
      }
      else{
        trackingData["assign_professional_popup_save successfull"]="Assigned successfully";
        trackAssignProfessionalClick(trackingData)
      }
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
      if(booking.professional_id){
        trackingData["assign_professional_popup_save_successfull_error"]=error.message;
        trackEditClick(trackingData)
       }
       else{
         trackingData["assign_professional_popup_save_successfull_error"]=error.message;
         trackAssignProfessionalClick(trackingData)
       }
    } finally {
      this.hideLoading();
    }
  };

  handleHideAssignProfessionalModal = () => {
    this.setState({
      isAssignProfessionalModalVisible: false,
      selectedBooking: null,
    });
  };

  handleSearch = (queryString) => {
    this.setState({ searchApplied: true, searchTerm: queryString ?? "" });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const booking = { ...this.state.booking };
    booking[input.name] = input.value;
    this.setState({ booking });
  };

  handleSelectImage = ({ currentTarget: input }) => {
    const fieldName = input.name;
    if (input.files && input.files.length) {
      const imageFile = input.files[0];
      const booking = {
        ...this.state.booking,
        [fieldName]: imageFile,
      };
      this.setState({ booking });
      this.readImage(`${fieldName}_src`, imageFile);
    }
  };

  readImage = (fieldName, imageFile) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const booking = {
        ...this.state.booking,
        [fieldName]: [reader.result],
      };
      this.setState({ booking });
    }.bind(this);
    reader.readAsDataURL(imageFile);
  };

  handleView = async (id) => {
    const booking = await this.fetchBookingDetails(id);
    console.log("booking handleView", booking);

    if (booking) {
      const { lab_1_id, lab_2_id } = booking;
      const lab1 = this.findLabById(lab_1_id);
      if (lab1) {
        const { name, contact_number, location } = lab1;
        booking.lab_1_name = [name, contact_number, location]
          .filter((it) => it ?? false)
          .join(", ");
      }
      const lab2 = this.findLabById(lab_2_id);
      if (lab2) {
        const { name, contact_number, location } = lab2;
        booking.lab_2_name = [name, contact_number, location]
          .filter((it) => it ?? false)
          .join(", ");
      }

      booking.picture_of_sample_src = booking.picture_of_sample ?? null;
      if(booking.professional_id){
        const user= this.state.user ??{};
        const deviceId = sessionStorage.getItem("device_id") ?? "";
        const trackingData = {
          "homecareId": user?.homecare_service_id??"",
          "deviceId": deviceId,
          "homecare": user?.email??"",
          "homecareName": user?.username??"",
          "client_lab_info click":false,
          "assign_professional_click":false,
          "assign_professional_popup_save successfull":false,
          "assign_professional_popup_save_successfull_error":""
        }
        trackEditClick(trackingData)
      }
      else if(booking.order_status && booking.order_status.toLowerCase() !== "cancelled"){
        const user= this.state.user ??{};
        const deviceId = sessionStorage.getItem("device_id") ?? "";
        const trackingData = {
          "homecareId": user?.homecare_service_id??"",
          "deviceId": deviceId,
          "homecare": user?.email??"",
          "homecareName": user?.username??"",
          "time_location click":true,
          "client_lab_info click":false,
          "assign_professional_click":false,
          "assign_professional_popup_save successfull":false,
          "assign_professional_popup_save_successfull_error":""
        }
        trackAssignProfessionalClick(trackingData)
      }
      this.setState({ booking }, () => this.showModal());
    } else {
      this.showErrorNotification(`Order not found with id=${id}`);
    }
  };

  handlePhoneNumberChange = async (fieldName, value, data) => {
    console.log("numberCheck:: ", { value, data });
    const { dialCode } = data;
    if (dialCode && value) {
      this.setState({
        professional: {
          ...this.state.booking,
          [fieldName]: `+${dialCode}${value.slice(dialCode.length)}`,
        },
      });
    }
  };

  handleCompleteOrder = async () => {
    const { user } = this.state;
    if (!user || !user.homecare_service_id) {
      console.log("handleSave:: Invalid user or homecare service id", { user });
      this.showErrorNotification("Homecare service id is not found!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { booking } = this.state;
    const {
      id,
      delivery_date,
      delivery_time,
      delivered_to,
      lab_referral_code,
      temperature,
      picture_of_sample,
      homecare_payment_status,
    } = booking;

    const payload = {
      orderId: id,
      userId: user.id,
      homrCareId: user.homecare_service_id,
      deliveryDate: delivery_date,
      deliveryTime: delivery_time,
      deliveredTo: delivered_to,
      labReferralCode: lab_referral_code,
      temperature: temperature,
      pictureOfSample: picture_of_sample,
      nurseAppointmentStatus: bookingStatus.COMPLETED.value,
      homecarePaymentStatus: homecare_payment_status,
    };

    if (typeof payload.pictureOfSample !== "string") {
      delete payload.pictureOfSample;
    }

    try {
      const formData = new FormData();
      formData.append("orderSample", JSON.stringify(payload));
      if (typeof picture_of_sample !== "string") {
        formData.append("pictureOfSample", picture_of_sample);
      }
      const config = {
        method: "PATCH",
        url: "/order-sample-info",
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      await PhoenixAPI.request(config);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchBookings();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };


   validate = () => {
    const errors = {};
    const { delivery_date, delivery_time, delivered_to, picture_of_sample, homecare_payment_status } =
      this.state.booking;

    if (!delivery_date || delivery_date.trim() === "")
      errors.delivery_date = "Delivery Date cannot be blank!";

    if (!delivery_time || delivery_time.trim() === "")
      errors.delivery_time = "Delivery Time cannot be blank!";

    if (!delivered_to || !delivered_to.trim() === "")
      errors.delivered_to = "Name of the Person delivered to cannot be blank!";

    if (!picture_of_sample)
      errors.picture_of_sample = "Picture of Sample cannot be blank!";
    else if (
      picture_of_sample &&
      picture_of_sample.size &&
      picture_of_sample.size > TWO_MEGA_BYTE
    )
      errors.picture_of_sample = "Picture of Sample should be less than 2 MB!";

    if (((this.isBookingCOD(this.state.booking) && !this.state.booking.is_mamopay_transaction_completed )|| (!(this.state.booking.is_mamopay_transaction_completed)  && (this.isBookingCODMamopay(this.state.booking))) )) { 

      if (!homecare_payment_status || homecare_payment_status.trim() === "")
        errors.homecare_payment_status = "Payment Status cannot be blank!";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleOnBookingStatusSelected = async (booking, selectedStatus) => {
    console.log("handleOnBookingStatusSelected::", {selectedStatus, booking});

    if (bookingStatus.CHANGE_NURSE.value === selectedStatus) {
      this.setState(
        { booking }, 
        () => this.showAssignProfessionalModal()
      );
    } 

     else {
      if(booking.is_require_sample_collection!==null && !booking.is_require_sample_collection && selectedStatus==bookingStatus.SAMPLE_COLLECTED.value){
        const reqSample=false;
        this.updateBookingStatus(booking.id, selectedStatus,reqSample);
      }
      else{
        const reqSample=true;
      this.updateBookingStatus(booking.id, selectedStatus,reqSample);
      }
    }
  };

  updateBookingStatus = async (orderId, status,reqSample) => {
    console.log("updateBookingStatus::", { orderId, status, reqSample});

    try {
      this.showLoading();
      const url = `/update-nurse-appointment-status/${orderId}`;
      await PhoenixAPI.patch(url, { nurseAppointmentStatus: status });
      this.saveOrderStatusLogs(orderId);
      
      this.hideModal();
      this.showSuccessNotification("Status updated successfully.");
      if(!reqSample){
        await this.orderCompleteNurse(orderId);  
      }
        this.fetchBookings();
    } catch (error) {
      this.showErrorNotification(
        "Some error occurred on updating status: " + error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      booking: {},
      isAssignProfessionalModalVisible: false,
    });
  };

   orderCompleteNurse=async(orderId)=>{
 
    try{
      const statusvalue="COMPLETED";
      const userId=getUserId();
     await API.patch("order-list/" + orderId + "/", { order_status: statusvalue ,modified_by:userId
      })
    }
    catch(error){
      console.log("error",error);
    }
    }

  renderAppointmentTime = (order) => {
    if (!order) {
      console.log("getAppointmentTime:: Invalid order!", { order });
      return "-";
    }
    const [appointmentDate, appointmentTime] =
      order && order.confirmed_date_time
        ? order.confirmed_date_time.split(" ")
        : [null, null];
    const formattedDate = appointmentDate ? formatDate(appointmentDate) : "";
    const formattedTimeslot = appointmentTime
      ? formatSingleTimeslot(appointmentTime)
      : "";
    return formattedDate || formattedTimeslot ? (
      <>
        <p className="mb-1">
          {formattedDate}
          <br />
          {formattedTimeslot}
        </p>
        {order.is_cofirmed_date_time_updated && (
          <p style={{ color: "red", fontWeight: "bold" }}>
            Appointment Date and Time is modified for this order
          </p>
        )}
      </>
    ) : (
      ""
    );
  };

  getFullAddress = (order) => {
    if (!order) return "";

    const { address_line1, address_line2, building_apt, city, country } = order;
    const location = [address_line1, address_line2, building_apt, city, country]
      .filter((it) => it ?? false)
      // .join("\r\n");
      .join(", ");

    return location;
  };
  getFormattedLabDetails=(labdetails)=>{
    const splitlabname= labdetails?.includes(",")?labdetails?.split(","):[];
    console.log("splitlabnamecheck",splitlabname);
    let finallab1det="";
    if(splitlabname.length>=2){
       finallab1det= splitlabname.slice(0,2).join(",");

    }
    else if(splitlabname.length==1){
      finallab1det=splitlabname[0];
    }
    return finallab1det;
  }
  getInformation = (isBookingCompleted = false) => {
    const {
      user_name,
      gender,
      dob,
      phone_no,
      confirmed_date_time,
      package_name,
      lab_1_name,
      sample_details_lab1,
      lab_2_name,
      sample_details_lab2,
      map_url,
      is_show_client_information,
      maqsamUniqueCode=""
    } = this.state.booking ?? {};
   
    console.log("lab_1_name",lab_1_name, maqsamUniqueCode);
    const [appointmentDate, appointmentTime] = confirmed_date_time
      ? confirmed_date_time.split(" ")
      : [null, null];
    const clientName = `Client Name: ${is_show_client_information? (user_name  ??""):""}`;
    const clientGender = `Gender: ${gender ?? ""}`;
    const dateOfBirth = `Date of Birth: ${dob ? formatDate(dob) : ""}`;
    const mobile =  is_show_client_information ?(isBookingCompleted  ? null : `Mobile: ${phone_no  ?? ""} ${(maqsamUniqueCode && maqsamUniqueCode) ? `PIN: ${maqsamUniqueCode}`:""}`):`Mobile: `;
    const packageName = `Package: ${package_name ?? ""}`;
    const address = "Address: " + (is_show_client_information?this.getFullAddress(this.state.booking) :"");
    const formattedDate = appointmentDate ? formatDate(appointmentDate) : "";
    const formattedTime = appointmentTime
      ? formatSingleTimeslot(appointmentTime)
      : "";
   const dateTime = `Date & Time: ${formattedDate}  ${formattedTime}`.trim();
    const lab1 = `Lab 1: ${lab_1_name?this.getFormattedLabDetails(lab_1_name) : ""}`.trim();
    const sampleDetailsLab1 = `Sample Details - Lab 1: ${sample_details_lab1 ?? ""}`.trim();
    const lab2 = `Lab 2: ${lab_2_name ?this.getFormattedLabDetails(lab_2_name): ""}`.trim();
    const sampleDetailsLab2 = `Sample Details - Lab 2: ${sample_details_lab2 ?? ""}`.trim();
    const pinLocation =  `Pin Location: ${is_show_client_information?(map_url ?? "").trim():""}`;
    const labInformation = [
      clientName,
      clientGender,
      dateOfBirth,
      mobile,
      // packageName,
      address,
      dateTime,
      lab1,
      sampleDetailsLab1,
      lab_2_name?lab2:"",
      lab_2_name?sampleDetailsLab2:"",
      pinLocation,
    ]
      .filter((it) => it !== null && it !=="")
      .join("\r\n");
    return labInformation;
  };

  findCityById = (cityId) => {
    const cities = this.state.cities ?? [];
    return cities.find((city) => city.id === cityId);
  };

  getCityNameByCityId = (cityId) => {
    const city = this.findCityById(cityId);
    if (!city) {
      console.log("getCityNameByCityId:: City not found with id=", cityId);
      return "";
    }
    return city?.city_name ?? "";
  };

  getFormattedCategories = (categories) => {
    return categories && categories.length ? categories.join(", ") : "";
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderBookingStatus = (booking) => {
    if (!booking) return null;

    const { nurse_appointment_status, order_status } = booking;
    if (order_status && order_status.toLowerCase() === "cancelled") {
      return (
        <span className={"ui small red label"} style={{ fontWeight: "normal" }}>
          {bookingStatus.CANCELLED.name}
        </span>
      );
    }

    if (
      !nurse_appointment_status ||
      bookingStatus.CREATED.value === nurse_appointment_status
    ) {
      return (
        <span
          className={"ui small blue label"}
          style={{ fontWeight: "normal" }}
        >
          {bookingStatus.CREATED.name}
        </span>
      );
    }
    if (bookingStatus.COMPLETED.value === nurse_appointment_status) {
      return (
        <span
          className={"ui small green label"}
          style={{ fontWeight: "normal" }}
        >
          {bookingStatus.COMPLETED.name}
        </span>
      );
    }

    return (
      <SelectBookingStatus
        key={booking.id}
        id={`select-booking-status-${booking.id}`}
        selectedStatus={
          booking.nurse_appointment_status ?? bookingStatus.CREATED.value
        }
        onSelect={(selectedStatus) =>
          this.handleOnBookingStatusSelected(booking, selectedStatus)
        }
        orderItemType={booking.order_item_type}
        isRequiredSampleCollection={booking.is_require_sample_collection ??false}
        

      />
    );
  };

  renderActionButton = (booking) => {
    if (!booking) {
      console.log("renderActionButton:: Invalid booking!", booking);
      return;
    }
    const { order_status, professional_id } = booking;
    let title = "";

    if (order_status && order_status.toLowerCase() === "cancelled") {
      title = "View";
    } else if (professional_id) {
      title = "Edit";
    } else {
      title = (
        <p>
          Assign a<br />
          Professional
        </p>
      );
    }
    return (
      <div>
        <button
          onClick={() => this.handleView(booking.id)}
          className="btn"
          style={{ backgroundColor: "#CEE741" }}
        >
          {title}
        </button>
      </div>
    );
  };

  isUploadSampleInformationSectionDisabled = (booking) => {
    const { order_status, nurse_appointment_status } = booking ?? {};

    const isOrderCancelled =
      order_status && order_status.toLowerCase() === "cancelled";
    const status = findBookingStatusByValue(nurse_appointment_status);
    return status
      ? isOrderCancelled ||
          status.position < bookingStatus.NURSE_ACKNOWLEDGED.position
      : true;
  };

  isBookingCOD = (booking) => {
    const { payment_method } = booking ?? {};
    return payment_method && payment_method.toLowerCase() === "cod";
  }

  isBookingCODMamopay=(booking) => {
    const { payment_method } = booking?? {};
    return payment_method && payment_method.toLowerCase() === "cod_mamopay";

  }

  getFilteredBookings = () => {
    const { bookings, filter } = this.state;
    const { status } = filter ?? {};

    const filteredBookings =
      bookings && status
        ? bookings.filter((booking) => {
            const { nurse_appointment_status, order_status } = booking;
            if (status === "ALL") return true;
            if (status === "NEW")
              return (
                nurse_appointment_status === null ||
                nurse_appointment_status === undefined ||
                nurse_appointment_status === bookingStatus.CREATED.value
              );
            if (status === "ACTIVE")
              return (
                nurse_appointment_status &&
                nurse_appointment_status !== bookingStatus.CREATED.value &&
                nurse_appointment_status !== bookingStatus.COMPLETED.value
              );
            if (status === "COMPLETED")
              return (
                nurse_appointment_status &&
                nurse_appointment_status === bookingStatus.COMPLETED.value
              );
            if (status === "CANCELLED")
              return order_status && order_status.toLowerCase() === "cancelled";
            return false;
          })
        : bookings;
    return this.getSearchedBookings(filteredBookings);
  };

  getSearchedBookings = (bookings) => {
    if (!bookings || !bookings.length) return [];

    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return bookings;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return bookings.filter((booking) => {
      const {
        id,
        lab_1_name,
        lab_2_name,
        sample_details_lab1,
        sample_details_lab2,
        professional_name,
      } = booking;
      return (
        (id && id.toString().includes(newValue)) ||
        (lab_1_name && lab_1_name.toLowerCase().indexOf(newValue) !== -1) ||
        (lab_2_name && lab_2_name.toLowerCase().indexOf(newValue) !== -1) ||
        (sample_details_lab1 &&
          sample_details_lab1.toLowerCase().indexOf(newValue) !== -1) ||
        (sample_details_lab2 &&
          sample_details_lab2.toLowerCase().indexOf(newValue) !== -1) ||
        (professional_name &&
          professional_name.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  getCoordinates = (booking) => {
    let { lat, lng, map_url } = booking ?? {};
  
    if (!lat || !lng) {
      // If latitude or longitude are not present then try to extract them from map_url.
      if (map_url) {
        const match = map_url.match(/(?:q=|@)(-?\d+\.\d+),(-?\d+\.\d+)/);
  
        if (match) {
          lat = Number(match[1]);
          lng = Number(match[2]);
          console.log("getCoordinates: Extracted coordinates: ", { lat, lng });
        } else {
          console.warn("getCoordinates: Failed to extract coordinates from:", map_url);
        }
      } else {
        console.warn("getCoordinates: No coordinates or map_url provided:", booking);
      }
    }
  
    return { lat: lat || null, lng: lng || null };
  };

  getActiveBookingsForCustomer = (customerId) => {
    const bookings = this.state.bookings ?? [];
    return bookings
      .filter(it => Number(customerId) === Number(it.user_id))
      .filter(it => !it.nurse_appointment_status
        || "created" === it.nurse_appointment_status
          ?.toLowerCase())
      .map(it => Number(it.id));
  }

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    const { nurse_appointment_status } = this.state.booking ?? {};
    const statusList = [
      bookingStatus.COMPLETED.value,
      bookingStatus.CANCELLED.value,
    ];
    if (statusList.includes(nurse_appointment_status)) return null;

    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  renderAssignProfessionalButton = (booking) => {
    const { nurse_appointment_status, order_status } = booking;
    if (order_status && order_status.toLowerCase() === "cancelled") {
      return null;
    }

    const status = findBookingStatusByValue(nurse_appointment_status);
    if (status && status.position > bookingStatus.NURSE_ACKNOWLEDGED.position) {
      return null;
    }
    return (
      <button
        onClick={this.showAssignProfessionalModal}
        className="btn btn-lg btn-success px-4 py-2 mx-auto mt-2"
      >
        Assign Professional
      </button>
    );
  };

  saveOrderStatusLogs = async (orderId) => {
    if (!orderId) {
      console.log("saveOrderStatusLogs:: Invalid order id", {orderId});
      return;
    }

    try {
      await PhoenixAPI.post(
        `${BASE_URL_CHECK_AND_COMPLETE_PARENT_ORDER}${orderId}/`
      )
    } catch (error) {
      console.log("saveOrderStatusLogs:: Error on saving order status logs!", error);
    }
  }
  
  handleShowOrders = () => {
    const ishoworder=true;
    this.fetchBookings(ishoworder)
  };

  handleDateSelect = ({ target: input }) => {
    if (input.name === "selectedStartDate" && this.state.selectedEndDate) {
      if (moment(input.value).isAfter(this.state.selectedEndDate)) {
        this.setState({
          selectedStartDate: input.value,
          selectedEndDate: input.value,
        });
      }
    }

    this.setState({ [input.name]: input.value });
  };

  handleSearchEnter=()=>{
    const { searchTerm, user } = this.state;
    const deviceId = sessionStorage.getItem("device_id") ?? "";
    const trackingData = {
      "searchKeyword": searchTerm,
      "homecareId": user?.homecare_service_id ??"",
      "deviceId": deviceId,
      "homecare": user?.email??"",
      "homecareName": user?.username??"",
      
    }
    trackBookingsSearchKeyword(trackingData)
  }
  handleSelectTab=(key)=>{
   this.setState({selectedTab:key})
  }
  render() {
    const {
      booking,
      professionals,
      editables,
      errors,
      filter,
      searchTerm,
      searchApplied,
      loadError,
      showModal,
      isAssignProfessionalModalVisible,
      selectedStartDate,
      selectedEndDate,
    } = this.state;

    const bookings = this.getFilteredBookings();
    const isBookingCancelled =
      booking?.order_status?.toLowerCase() === "cancelled";
    const isBookingCompleted =
      bookingStatus.COMPLETED.value.toLowerCase() === booking?.nurse_appointment_status?.toLowerCase();
    const [appointmentDate, appointmentTime] =
      booking && booking.confirmed_date_time
        ? booking.confirmed_date_time.split(" ")
        : [null, null];
    const isBookingCOD = this.isBookingCOD(booking);
    const isBookingCODMamopay = this.isBookingCODMamopay(booking);
    const {is_show_client_information=false}= booking || {}
    const timeAndLocationForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="appointment_date">Appointment Date</label>
            <input
              value={appointmentDate ?? ""}
              id="appointment_date"
              name="appointment_date"
              type="date"
              placeholder="Appointment Date"
              className="form-control py-2"
              disabled={true}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="appointment_time">Appointment Time</label>
            <input
              // value={appointmentTime ? formatSingleTimeslot(appointmentTime) : ""}
              value={appointmentTime ?? ""}
              id="appointment_time"
              name="appointment_time"
              type="time"
              placeholder="Appointment Time"
              className="form-control py-2"
              disabled={true}
            />
          </div>
        </div>
        {
          <GoogleMapForm
            mapUrl={booking.map_url}
            coordinates={this.getCoordinates(booking)}
            onCopyLocation={this.handleOnCopyLocation}
            onGoogleMaps={this.handleOnGoogleMaps}
          />
        }
        <div className="row">
          <div className="form-group col">
            <label htmlFor="full_address">Full Address</label>
            <textarea
              value={this.getFullAddress(booking) ?? ""}
              id="full_address"
              name="full_address"
              type="date"
              placeholder="Full Address"
              className="form-control py-2"
              readOnly={true}
            ></textarea>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="map_url">Pin Location</label>
            <textarea
              value={booking.map_url ?? ""}
              id="map_url"
              name="map_url"
              placeholder="Pin Location"
              className="form-control py-2"
              readOnly={true}
            ></textarea>
          </div>
        </div>
        <div className="row">
          {this.renderAssignProfessionalButton(booking)}
        </div>
      </div>
    );

    const clientInfoForm = (
      <>
       {<div className="row">
          <div className="form-group col-6">
            <label htmlFor="user_name">Name</label>
            <input
              value={booking.user_name ?? ""}
              id="user_name"
              name="user_name"
              type="text"
              placeholder="Name"
              className={`${is_show_client_information ?"form-control py-2":"form-control py-2 hide-client-info"}`}
              disabled={true}
            />
          </div>
          {isBookingCompleted || isBookingCancelled || (
            <>
             <div className="form-group col">
              <label htmlFor="phone_no">Phone Number</label>
              <input
                value={booking.phone_no ?? ""}
                id="phone_no"
                name="phone_no"
                type="text"
                placeholder="Phone Number"
                className={`${is_show_client_information ?"form-control py-2":"form-control py-2 hide-client-info"}`}
                disabled={true}
              />
            </div>
                  {booking.maqsamUniqueCode?<div className="form-group col">
                  <label htmlFor="maqsamUniqueCode">PIN</label>
                  <input
                    value={booking.maqsamUniqueCode ?? ""}
                    id="maqsamUniqueCode"
                    name="maqsamUniqueCode"
                    type="text"
                    placeholder="PIN"
                    className={`${is_show_client_information ?"form-control py-2":"form-control py-2 hide-client-info"}`}
                    disabled={true}
                  />
                </div>:""}
            </>
           
          )}
        </div>}
        {isBookingCancelled || (
          <>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="gender">Gender</label>
                <Dropdown
                  value={booking.gender ?? null}
                  id="gender"
                  name="gender"
                  placeholder="Gender"
                  search
                  selection
                  options={GENDER_OPTIONS}
                  disabled={true}
                  className="form-control"
                />
              </div>
              <div className="form-group col">
                <label htmlFor="dob">Date of Birth</label>
                <input
                  value={booking.dob ?? ""}
                  id="dob"
                  name="dob"
                  type="date"
                  placeholder="Date of Birth"
                  className="form-control py-2"
                  disabled={true}
                />
              </div>
            </div>
            {<div className="row">
              <div className="form-group col-6">
                <label htmlFor="front_id_s3_url">Emirates ID Front</label>
                <div  className={`${is_show_client_information ?"":"hide-client-info"}`}>
                  {booking.front_id_s3_url && (
                    booking.front_id_s3_url.toLowerCase().endsWith(".pdf")
                      ? <PdfPreview id="selected_front_pdf" pdfFileUrl={booking.front_id_s3_url} />
                      : (
                        <img
                          id="front_id_s3_url"
                          name="front_id_s3_url"
                          src={booking.front_id_s3_url ?? null}
                          alt="Selected"
                          className="img-thumbnail mx-auto"
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            objectFit: "cover",
                          }}
                        />)
                  )}
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="back_id_s3_url">Emirates ID Back</label>
                <div  className={`${is_show_client_information ?"":"hide-client-info"}`}>
                  {booking.back_id_s3_url && (
                    booking.back_id_s3_url.toLowerCase().endsWith(".pdf")
                      ? <PdfPreview id="selected_front_pdf" pdfFileUrl={booking.back_id_s3_url} />
                      : (<img
                        id="back_id_s3_url"
                        name="back_id_s3_url"
                        src={booking.back_id_s3_url ?? null}
                        alt="Not Available"
                        className="img-thumbnail mx-auto"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                        }}
                      />)
                  )}
                </div>
              </div>
            </div>}
          </>
        )}
      </>
    );

   

    const paymentInfoForm = (
      <div className="row">
        <div className="form-group col">
          <label htmlFor="payment_to_be_collected" style={{ color: "red", fontWeight: "bold" }}>
            Payment to be collected
          </label>
          <input
            value={booking.is_mamopay_transaction_completed || booking.is_recollection_order?"0":booking.payment_to_be_collected ?? ""}
            id="payment_to_be_collected"
            name="payment_to_be_collected"
            type="text"
            placeholder="Payment to be collected"
            className="form-control py-2"
            disabled={true}
            style={{ color: "red" }}
          />
        </div>
      </div>
    );

    const labAndSampleDetailsForm = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab_1_id">Lab 1</label>
            <input
              value={booking.is_require_sample_collection==false?"N/A":this.findLabById(booking.lab_1_id)?.name ?? "" }
              id="lab_1_id"
              name="lab_1_id"
              type="text"
              placeholder="Lab 1"
              className="form-control py-2"
              disabled={true}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="sample_details_lab1">Sample Details 1</label>
            <textarea
              value={booking.sample_details_lab1 ?? ""}
              id="sample_details_lab1"
              name="sample_details_lab1"
              type="text"
              placeholder="Sample Details 1"
              className="form-control py-2"
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab_2_id">Lab 2</label>
            <input
              value={this.findLabById(booking.lab_2_id)?.name ?? ""}
              id="lab_2_id"
              name="lab_2_id"
              type="text"
              placeholder="Lab 2"
              className="form-control py-2"
              disabled={true}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="sample_details_lab2">Sample Details 2</label>
            <input
              value={booking.sample_details_lab2 ?? ""}
              id="sample_details_lab2"
              name="sample_details_lab2"
              type="text"
              placeholder="Sample Details 2"
              className="form-control py-2"
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="special_instructions">Special Instructions</label>
            <textarea
              value={booking.special_instructions ?? ""}
              id="special_instructions"
              name="special_instructions"
              type="text"
              placeholder="Special Instructions"
              className="form-control"
              readOnly={true}
              style={{ fontWeight: "bold" }}
            ></textarea>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="sample_details_lab2">Product Name</label>
            <input
              value={booking.package_name ?? ""}
              id="product_name"
              name="product_name"
              type="text"
              placeholder="product name"
              className="form-control py-2"
              disabled={true}
            />
          </div>

        </div>

        <div className="row mt-2">
          <div className="form-group col">
            <label htmlFor="information">Information</label>
            <textarea
              value={this.getInformation(isBookingCompleted) ?? ""}
              id="information"
              name="information"
              type="text"
              className={"form-control"}
              rows={isBookingCompleted ? "10" : "11"}
              readOnly={true}
            ></textarea>
          </div>
        </div>
        <div className="row">
          {this.renderAssignProfessionalButton(booking)}
        </div>
      </>
    );

    const clientAndLabInfoForm = (
      <div className="container mt-4">
        {clientInfoForm}
        {isBookingCancelled || (
          <>
            <div className="row mt-2">
              <div className="col separator">
                <span>Payment Information</span>
              </div>
            </div>
            {paymentInfoForm}
          </>
        )}
        {isBookingCancelled || (
          <>
            <div className="row mt-2">
              <div className="col separator">
                <span>Lab & Sample Details</span>
              </div>
            </div>
            {labAndSampleDetailsForm}
          </>
        )}
      </div>
    );

    const uploadSampleInfoForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="delivery_date">Delivery Date*</label>
            <div className="input-group">
              <input
                value={booking.delivery_date ?? ""}
                onChange={this.handleChange}
                id="delivery_date"
                name="delivery_date"
                type="date"
                placeholder="Delivery Date"
                className={
                  "form-control py-2" +
                  (errors.delivery_date ? " has-error" : "")
                }
                disabled={!editables.delivery_date}
              />
              {this.renderPencilIcon("delivery_date")}
            </div>
            <span className="help-block">{errors.delivery_date}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="delivery_time">Delivery Time*</label>
            <div className="input-group">
              <input
                value={booking.delivery_time ?? ""}
                onChange={this.handleChange}
                type="time"
                id="delivery_time"
                name="delivery_time"
                disabled={!editables.delivery_time}
                className={
                  "form-control" + (errors.delivery_time ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("delivery_time")}
            </div>
            <span className="help-block">{errors.delivery_time}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="delivered_to">
              Name of the Person delivered to*
            </label>
            <div className="input-group">
              <input
                value={booking.delivered_to ?? ""}
                onChange={this.handleChange}
                id="delivered_to"
                name="delivered_to"
                type="text"
                placeholder="Name of the Person delivered to"
                className={
                  "form-control py-2" +
                  (errors.delivered_to ? " has-error" : "")
                }
                disabled={!editables.delivered_to}
              />
              {this.renderPencilIcon("delivered_to")}
            </div>
            <span className="help-block">{errors.delivered_to}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="lab_referral_code">Lab Bar/Referral Code</label>
            <div className="input-group">
              <input
                value={booking.lab_referral_code ?? ""}
                onChange={this.handleChange}
                id="lab_referral_code"
                name="lab_referral_code"
                type="text"
                placeholder="Lab Bar/Referral Code"
                className={
                  "form-control py-2" +
                  (errors.lab_referral_code ? " has-error" : "")
                }
                disabled={!editables.lab_referral_code}
              />
              {this.renderPencilIcon("lab_referral_code")}
            </div>
            <span className="help-block">{errors.lab_referral_code}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="temperature">Temperature</label>
            <div className="input-group">
              <input
                value={booking.temperature ?? ""}
                onChange={this.handleChange}
                id="temperature"
                name="temperature"
                type="text"
                placeholder="Temperature"
                className={
                  "form-control py-2" + (errors.temperature ? " has-error" : "")
                }
                disabled={!editables.temperature}
              />
              {this.renderPencilIcon("temperature")}
            </div>
            <span className="help-block">{errors.temperature}</span>
          </div>
          {((isBookingCOD && !booking?.is_mamopay_transaction_completed ) ||  (isBookingCODMamopay && !booking?.is_mamopay_transaction_completed ))&& <div className="form-group col-6">
            <label htmlFor="homecare_payment_status">Payment Status*</label>
            <div className="input-group">
              <SelectPaymentStatus
                key={booking.id}
                id={`select-payment-status-${booking.id}`}
                selectedStatus={booking.homecare_payment_status ?? null}
                onSelect={(selectedStatus) => {
                  this.setState({ booking: { ...booking, homecare_payment_status: selectedStatus } })
                }}
                className={
                  "form-control py-2" + (errors.temperature ? " has-error" : "")
                }
                isDisabled={!editables.homecare_payment_status}
              />
              {this.renderPencilIcon("homecare_payment_status")}
            </div>
            <span className="help-block">{errors.homecare_payment_status}</span>
          </div>}
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="picture_of_sample">Picture of Sample*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="picture_of_sample"
                name="picture_of_sample"
                type="file"
                accept="image/*"
                disabled={!editables.picture_of_sample}
                className={
                  "form-control" +
                  (errors.picture_of_sample ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("picture_of_sample")}
            </div>
            <span className="help-block">{errors.picture_of_sample}</span>
          </div>
          <div className="form-group col">
            {booking?.picture_of_sample_src && (
              <>
                <label htmlFor="picture_of_sample_src">
                  Picture of Sample - Preview
                </label>
                <div>
                  <img
                    id="picture_of_sample_src"
                    name="picture_of_sample_src"
                    src={booking.picture_of_sample_src}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {bookingStatus.COMPLETED.value === booking.nurse_appointment_status || (
          <div className="row">
            <button
              onClick={this.handleCompleteOrder}
              className="btn btn-lg btn-success px-4 py-2 mx-auto mt-2"
            >
              Complete Order
            </button>
          </div>
        )}
      </div>
    );

    return (
      <div className="manage-package">
        <div className="row" style={{ gap: "20px" , marginBottom:"30px"}}>
          <div className="" style={{ marginTop: "5px" }}>
            Start Date
          </div>
          <div className="">
            <div className="input-group user-input-container">
              <input
                value={selectedStartDate}
                onChange={this.handleDateSelect}
                // min={this.minStartDate}
                // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
                type="date"
                id="selectedStartDate"
                name="selectedStartDate"
                className="form-control py-3"
              />
            </div>
          </div>
          <div className="" style={{ marginTop: "5px" }}>
            End Date
          </div>
          <div className="">
            <input
              value={selectedEndDate}
              onChange={this.handleDateSelect}
              min={selectedStartDate ?? null}
              // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
              type="date"
              id="selectedEndDate"
              name="selectedEndDate"
              className="form-control py-3"
            />
          </div>
          <div className="">
            <button
              onClick={this.handleShowOrders}
              // disabled={!isEditable}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Show Orders
            </button>
          </div>
        </div>
        <div className="page-header">
          <div className="col-3 ml-0 pl-0">
            <Dropdown
              value={filter.status ?? null}
              onChange={(event, value) => {
                this.setState({
                  filter: {
                    ...filter,
                    status: value.value,
                  },
                });
              }}
              id="filter-dropdown"
              name="filter-dropdown"
              search
              selection
              options={FILTER_OPTIONS}
              className="form-control"
            />
          </div>
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                handleSearchEnter={this.handleSearchEnter}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={bookings}
            columns={this.columns}
            // defaultSortField="id"
            // defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + bookings.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            

            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Order ID: </span>
                {booking?.id ?? ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_bookings" onSelect={(key)=>this.handleSelectTab(key)}>
              <Tab
                eventKey="key_time_and_location_form"
                title="Time & Location"
              >
                {timeAndLocationForm}
              </Tab>
              <Tab
                eventKey="key_client_and_lab_info_form"
                title="Client & Lab Information" 
              >
                {clientAndLabInfoForm}
              </Tab>
             {  booking?.is_require_sample_collection ||booking?.is_require_sample_collection==null  ?<Tab
                eventKey="key_upload_sample_info_form"
                // title="Upload Sample Information" // Renamed after Nadin's suggestion.
                title="Upload Lab Drop-off Information"
                disabled={this.isUploadSampleInformationSectionDisabled(
                  booking
                )}
              >
                {uploadSampleInfoForm}
              </Tab>:<></>}
            </Tabs>
          </Modal.Body>
        </Modal>
        <AssignProfessional
          orderId={booking.id ?? null}
          professionals={professionals ?? []}
          booking={this.state.selectedBooking ?? null}
          activeBookingsForCustomer={this.getActiveBookingsForCustomer(booking.user_id) ?? []}
          isVisible={isAssignProfessionalModalVisible}
          onHide={this.handleHideAssignProfessionalModal}
          onSave={this.handleAssignProfessional}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
