import React, { Component } from "react";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import { getUserId } from "../../services/UserService";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import API from "utils/API";
import { getClientByEmailOnCartOrders } from "services/UserService";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { Card, Modal } from "react-bootstrap";
import Select from "react-select";
import CustomLoader from "components/CustomLoader/CustomLoader";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const BASE_CLIENT_RESET_URL = "nimda-password-reset/";

const TRANSFER_TYPE_OPTIONS = [
  {
    key: "Cart Order",
    value: "Cart Order",
    label: "Cart Order",
  },
  {
    key: "Child Order",
    value: "Child Order",
    label: "Child Order",
  },
];
class ClientPasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      emailResetSearchTerm: null,
      selectedClient: null,
      clientOptions: [],
      resetPassword: null,
      errors: {},
      isTransferModal: false,
      transferOrder: {},
      isCustomLoader: false,
      transferredLogs: [],
    };
    this.columns = [
      {
        name: "Transfer Date ",
        selector: "transfer_timestamp",
        sortable: true,
      },
      {
        name: "Transfer Done by",
        selector: "transfer_done_by",
        wrap: true,
        sortable: true,
      },
      {
        name: "Order Id transferred",
        selector: "order_id",
        wrap: true,
        sortable: true,
      },
      {
        name: "Transferred from",
        selector: "transfer_from",
        wrap: true,
        sortable: true,
      },
      {
        name: "Transferred to",
        selector: "transfer_to",
        sortable: true,
      },
    ];
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.fetchAclUser();
  }

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.ClientPasswordReset;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };
  handleSearchClientsByEmail = () => this.fetchClientUsersByEmail();

  fetchClientUsersByEmail = async () => {
    const { emailResetSearchTerm } = this.state;
    try {
      this.showLoading();
      const clients = await getClientByEmailOnCartOrders(emailResetSearchTerm);
      console.log("CreateOrderViewModal:: fetchClientUsersByEmail", clients);
      this.setState({ clients: clients ?? [] }, () =>
        this.createClientOptions()
      );
    } catch (error) {
      console.log("Error on fetching client users by email:", error);
    } finally {
      this.hideLoading();
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  validateResetForm = () => {
    const { selectedClient, resetPassword } = this.state;
    const errors = {};
    if (!selectedClient) {
      errors.email = "please select email";
    }
    if (!resetPassword) {
      errors.resetPassword = " password is required field";
    } else if (!/^[a-zA-Z0-9_@./#&+-]*$/g.test(resetPassword)) {
      errors.resetPassword = "Invalid Password!";
    } else if (resetPassword.length < 8) {
      errors.resetPassword =
        "Invalid Password! (can not be smaller than 8 characters).";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSavePasswordReset = async () => {
    const { selectedClient, resetPassword } = this.state;
    console.log("Selected client and password ", selectedClient, resetPassword);
    const errors = this.validateResetForm();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      await API.patch(`${BASE_CLIENT_RESET_URL}${selectedClient.id}/`, {
        email: selectedClient.email,
        password: resetPassword,
      });
      this.hideLoading();
      this.showSuccessNotification("Password Reset Successfully");
      this.hidePasswordResetModal();
    } catch (error) {
      console.log("Error on resetting password", error);
      this.hideLoading();
      this.setState({ error: "Error resetting password, please try again" });
    }
  };

  handlePasswordChange = () => {
    this.showPasswordResetModal();
  };

  showPasswordResetModal = () => {
    this.setState(
      { emailResetSearchTerm: null, resetPassword: null, selectedClient: null },
      () => this.viewPasswordResetModal()
    );
  };
  viewPasswordResetModal = () => {
    const { emailResetSearchTerm, selectedClient, resetPassword } = this.state;
    this.setState({
      emailResetSearchTerm,
      resetPassword,
      selectedClient,
      passwordResetModal: true,
    });
  };

  hidePasswordResetModal = () => {
    this.setState({
      emailResetSearchTerm: null,
      selectedClient: null,
      clientOptions: null,
      resetPassword: null,
      passwordResetModal: false,
      errors: {},
    });
  };

  createClientOptions = () => {
    const { clients = [] } = this.state;
    const clientOptions = clients.map(this.createClientOption);
    this.setState({ clientOptions });
  };

  createClientOption = (client) => {
    if (!client) return;
    const { username, first_name, last_name, email } = client;
    const name =
      username || `${first_name || ""} ${last_name || ""}`.trim() || "";
    return {
      key: client.id,
      value: client.id,
      label: `${email} (${name})`,
    };
  };

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  findClient = (clientId) => {
    const { clients = [] } = this.state;
    return clients.find((client) => client.id === clientId);
  };
  handleClientSelected = (selectedClientId) => {
    const selectedClient = this.findClient(selectedClientId);
    this.setState({ selectedClient });
  };

  handlSearchorder = async () => {
    const transferOrder = this.state.transferOrder ?? {};
    const { cartOrderId = null, transferType = "" } = transferOrder;
    if (!cartOrderId || !transferType) {
      this.showErrorNotification("Please select cart order and transfer type");
      return;
    }
    if (cartOrderId && transferType) {
      try {
        this.setState({ isCustomLoader: true });
        const { data: clientOrderDetails = {} } = await PhoenixAPI.get(
          `transfer-order/details?orderType=${transferType}&orderId=${cartOrderId}`
        );
        const newTransferOrder = {
          ...transferOrder,
          clientOrderDetails: clientOrderDetails,
        };
        this.setState({ transferOrder: newTransferOrder });
      } catch (error) {
        console.log("Error on uploading attachments", error.message);
      } finally {
        this.setState({ isCustomLoader: false });
      }
    }
  };
  handleTransferOrderFromAnotherClient = () => {
    this.setState({
      isTransferModal: true,
      emailResetSearchTerm: null,
      selectedClient: null,
    });
  };
  hideTransferModal = () => {
    this.setState({
      isTransferModal: false,
      transferOrder: {},
      emailResetSearchTerm: null,
      selectedClient: null,
      clientOptions: null,
    });
  };
  createTransferType = (transferOrder) => {
    return {
      key: transferOrder.transferType,
      value: transferOrder.transferType,
      label: transferOrder.transferType,
    };
  };
  handleTransferType = (transferType) => {
    const newTransferOrder = {
      ...this.state.transferOrder,
      transferType: transferType,
    };
    this.setState({ transferOrder: newTransferOrder });
  };

  handleCartOrder = (event) => {
    const target = event.target;
    const newTransferOrder = {
      ...this.state.transferOrder,
      cartOrderId: target.value,
    };
    this.setState({ transferOrder: newTransferOrder });
  };

  handleTransferOrder = () => {
    const transferOrder = this.state.transferOrder;
    const {clientOrderDetails={}}= transferOrder;
    if (clientOrderDetails && clientOrderDetails?.is_transfer_order) {
      this.showErrorNotification("This Order is already Transferred Successfully");
      return;
    }
    this.setState({ transferOrderModalConfirm: true });
  };
  hideTransferModalConfrim = () => {
    this.setState({ transferOrderModalConfirm: false });
  };

  handleTransferToNewClient = async () => {
    const { transferOrder = {}, selectedClient = {} } = this.state;
    const adminUserId = getUserId();
    const {
      cartOrderId = null,
      transferType = "",
      clientOrderDetails,
    } = transferOrder;
    if (cartOrderId && transferType) {
      const payload = {
        orderType: transferType,
        newUserId: selectedClient?.id,
        originalUserId: clientOrderDetails?.user_id,
        adminUserId: adminUserId,
      };
      if (transferType === "Cart Order") {
        payload["cartOrderId"] = cartOrderId;
      } else if (transferType === "Child Order") {
        payload["orderId"] = cartOrderId;
      }

      try {
        this.setState({ isCustomLoader: true });
        const response = await PhoenixAPI.patch("transfer-order", payload);

        this.hideTransferModalConfrim();
        this.hideTransferModal();
        this.showSuccessNotification("Transfer order sent successfully");
      } catch (error) {
        console.log("Error on transfering order", error);
        this.showErrorNotification("Error on transferring the order");
        this.setState({
          isCustomLoader: false,
          error: "Error on transfering order, please try again",
        });
      } finally {
        this.setState({ isCustomLoader: false });
      }
    }
  };

  handleViewLogs = async () => {
    try {
      this.setState({ isCustomLoader: true });

      const { data } = await PhoenixAPI.get("transfer-order/logs");
      const { content: transferredLogs = [] } = data ?? {};
      this.setState({ transferredLogs });
    } catch (error) {
      console.log("Error on fetching logs", error);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.setState({ isCustomLoader: false });
    }
  };
  render() {
    const {
      emailResetSearchTerm,
      selectedClient,
      resetPassword,
      clientOptions,
      passwordResetModal,
      errors,
      isTransferModal,
      transferOrder,
      transferOrderModalConfirm,
      transferredLogs,
      loadError,
    } = this.state;
    const { clientOrderDetails = {} } = transferOrder ?? {};
    const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

    const passwordResetForm = (
      <>
        <div className="row">
          <div className="form-group col">
            <label>Email</label>
            <div className="input-group mb-3">
              <input
                value={emailResetSearchTerm ?? ""}
                onChange={(e) =>
                  this.setState({ emailResetSearchTerm: e.target.value })
                }
                type="text"
                id="emailResetSearchTerm"
                name="emailResetSearchTerm"
                className="form-control "
                placeholder="Search Email"
              />
              <div className="input-group-append">
                <i
                  className="fa fa-search input-group-text"
                  onClick={this.handleSearchClientsByEmail}
                />
              </div>
            </div>
            <span className="help-block">{errors.email}</span>
          </div>
          <div className="form-group col">
            <label>Select Email</label>
            <Select
              key={`select_client_email_${selectedClient?.id ?? -1}`}
              value={this.createClientOption(selectedClient)}
              onChange={(option) => this.handleClientSelected(option.value)}
              options={clientOptions ?? []}
              styles={selectStyles}
              placeholder="Select Email"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label>Password *</label>
            <input
              type="password"
              id="resetPassword"
              name="resetPassword"
              className="form-control "
              placeholder="Enter Password"
              value={resetPassword ?? ""}
              onChange={this.handleChange}
              autocomplete="new-password"
            />
            <span className="help-block">{errors.resetPassword}</span>
          </div>
        </div>
      </>
    );

    const transferModalFrom = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label>Select Transfer Type</label>
            <Select
              value={transferOrder && this.createTransferType(transferOrder)}
              onChange={(event) => this.handleTransferType(event.value)}
              options={TRANSFER_TYPE_OPTIONS ?? []}
              styles={selectStyles}
              placeholder="Select Transfer Type"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">Enter Child Order/Cart Order Id</div>
          <div className="form-group col">
            <div className="input-group">
              <input
                value={transferOrder?.cartOrderId || ""}
                onChange={this.handleCartOrder}
                id="cartOrderId"
                name="cartOrderId"
                type="text"
                placeholder="Enter Order Id"
                className={
                  "form-control py-2" + (errors.cartOrderId ? " has-error" : "")
                }
                disabled={false}
              />
            </div>
            <span className="help-block">{errors.cartOrderId}</span>
          </div>
          <div className="form-group col">
            <button
              onClick={this.handlSearchorder}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Search
            </button>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <div
              style={{
                border: "2px solid teal",
                borderStyle: "double",
                padding: "5px",
              }}
            >
              <div>Client Order Details</div>
              <div>Client Name: {clientOrderDetails?.client_name}</div>
              <div>Package Name: {clientOrderDetails?.package_name}</div>
              <div>Order Date: {clientOrderDetails?.order_date}</div>
              {clientOrderDetails.is_transfer_order &&
              clientOrderDetails.is_transfer_order ? (
                <div>This Order is already Transferred</div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="row">Transfer Client Details</div>

        <div className="row">
          <div className="form-group col">
            <label>Email</label>
            <div className="input-group mb-3">
              <input
                value={emailResetSearchTerm ?? ""}
                onChange={(e) =>
                  this.setState({ emailResetSearchTerm: e.target.value })
                }
                type="text"
                id="emailResetSearchTerm"
                name="emailResetSearchTerm"
                className="form-control "
                placeholder="Search Email"
              />
              <div className="input-group-append">
                <i
                  className="fa fa-search input-group-text"
                  onClick={this.handleSearchClientsByEmail}
                />
              </div>
            </div>
            <span className="help-block">{errors.email}</span>
          </div>
          <div className="form-group col">
            <label>Select Email</label>
            <Select
              key={`select_client_email_${selectedClient?.id ?? -1}`}
              value={this.createClientOption(selectedClient)}
              onChange={(option) => this.handleClientSelected(option.value)}
              options={clientOptions ?? []}
              styles={selectStyles}
              placeholder="Select Email"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <div
              style={{
                border: "2px solid teal",
                borderStyle: "double",
                padding: "5px",
              }}
            >
              <div>New Client Order Details</div>
              <div>
                Client Name:{" "}
                {selectedClient?.username || selectedClient?.first_name}
              </div>
              <div>Email: {selectedClient?.email}</div>
              <div>Mobile Number: {selectedClient?.phone_no}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col"></div>
          <div className="form-group col"></div>
          <div className="form-group col">
            <button
              onClick={this.handleTransferOrder}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Transfer Order
            </button>
          </div>
        </div>
      </div>
    );
    const transferModalConfirmForm = (
      <div>
        <div style={{ textAlign: "center" }}>
          Are you sure you want to transfer this order to this new client ?
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "50px",
            padding: "15px",
          }}
        >
          <div>
            <button
              onClick={this.handleTransferToNewClient}
              className="btn button btn-success "
            >
              Yes
            </button>
          </div>
          <div>
            <button
              onClick={this.hideTransferModalConfrim}
              className="btn button btn-danger"
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
    return (
      <div>
        <div className="manage-package">
          <div>
            {" "}
            <p> Update Password for Clients </p>
            <div>
              <button
                className="btn px-5"
                style={{ backgroundColor: "#CEE741" }}
                onClick={this.handlePasswordChange}
              >
                Enter
              </button>
            </div>
          </div>

          <Modal
            size="lg"
            show={passwordResetModal}
            onHide={this.hidePasswordResetModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Password Reset</Modal.Title>
              <button
                onClick={() => {
                  this.handleSavePasswordReset();
                }}
                disabled={!this.hasPageAccessRight("edit")}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Header>
            <Modal.Body>{passwordResetForm}</Modal.Body>
          </Modal>
        </div>
        <div
          className=""
          style={{ display: "flex", justifyContent: "center", gap: "100px" }}
        >
          <div>
            {" "}
            <p> Transfer order from one client to another </p>
            <div>
              <button
                className="btn px-5"
                style={{ backgroundColor: "#CEE741" }}
                onClick={this.handleTransferOrderFromAnotherClient}
              >
                Enter
              </button>
            </div>
          </div>
          <div>
            {" "}
            <p> View Transfer Logs</p>
            <div>
              <button
                className="btn px-5"
                style={{ backgroundColor: "#CEE741" }}
                onClick={this.handleViewLogs}
              >
                Enter
              </button>
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          show={isTransferModal}
          onHide={this.hideTransferModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Transfer Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>{transferModalFrom}</Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={transferOrderModalConfirm}
          onHide={this.hideTransferModalConfrim}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>{transferModalConfirmForm}</Modal.Body>
        </Modal>

        <div style={{ marginTop: "120px" }}>
          <Card body>
            <DataTable
              data={transferredLogs || []}
              columns={this.columns}
              defaultSortField="status"
              sortIcon={<ArrowDownward></ArrowDownward>}
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              pagination
              responsive
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
        </div>

        <CustomLoader show={this.state.isCustomLoader} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPasswordReset);
