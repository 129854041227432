import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PhoenixAPI from "utils/PhoenixAPI";
import { Card } from "react-bootstrap";

import PdfPreview from "components/Common/PdfPreview";
import { formatDate, formatSingleTimeslot } from "utils/commons";
import { connect } from "react-redux";
function HomecareInfoResample(props) {

    const{ResampleOrder}=props;
    const{
        homecareName,
        professionalname,
        deliveryDate,
        deliveryTime,
        homecarePaymentStatus,
        homecareStatus,
        labReferralCode,
        temperature,
        pictureOfSample,
        nurseAppointmentStatus,
        nursePhoneNumber,
        nurseWhatsappNumber,
        deliveredTo

        }=ResampleOrder;

        const isPdfFile =
        pictureOfSample?.type === "application/pdf" ||
           (typeof pictureOfSample === "string" &&
             pictureOfSample.toLowerCase().endsWith(".pdf"));
const homecareInfoDetails = (
    <>
      <div className="row mx-2 pb-2">
        <div className="col">Status: {nurseAppointmentStatus ?? ""}</div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">HomeCare: {homecareName ?? ""}</div>
        <div className="col">
          Nurse Name:{" "}
          { professionalname ?professionalname  : ""}
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Phone Number: {nursePhoneNumber??""}
        </div>
        <div className="col">
          WhatsApp Number:{" "}
          { nurseWhatsappNumber?? "" }
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Delivery Date:{" "}
          {deliveryDate
            ? formatDate(deliveryDate)
            : ""}
        </div>
        <div className="col">
          Delivery Time:{" "}
          { deliveryTime
            ? formatSingleTimeslot(deliveryTime)
            : ""}
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Name of the person they’ve delivered to:{" "}
          {deliveredTo? deliveredTo : ""}
        </div>
        <div className="col">
          Lab Bar/Referral Code:{" "}
          { labReferralCode ? labReferralCode : ""}
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Temperature:{" "}
          { temperature ? temperature : ""}
        </div>
        <div className="col">
          Picture of Sample:{" "}
          { pictureOfSample && pictureOfSample ? (
           isPdfFile?
           
           <PdfPreview
           pdfFile={isPdfFile ? pictureOfSample : null}
           pdfFileUrl={isPdfFile ? null : pictureOfSample}
         />
           
           :<div>
              <img
                id="picture_of_sample"
                name="picture_of_sample"
                src={pictureOfSample}
                alt="Selected"
                className="img-thumbnail mx-auto"
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  objectFit: "cover",
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row mx-2 pb-2">
        <div className="col">
          Payment Status:{" "}
          { homecarePaymentStatus ? homecarePaymentStatus : ""}
        </div>
      </div>
    </>
  );


  return (
    <Card className="px-0 py-0 mb-0">
      <Card.Body className="px-0 py-0">
        <div className="row ml-1 my-2">
          <div className="col">
            <p className="h6">HomeCare Information</p>
          </div>
        </div>
        {homecareInfoDetails}
      </Card.Body>
    </Card>);

}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomecareInfoResample);