import React, { useEffect, useState } from "react";
import PhoenixAPI from "../../../utils/PhoenixAPI";
import API from "utils/API";
import { getUserId, getUserEmail, getUserName  } from "services/UserService";
import { useHistory } from "react-router-dom";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { trackReportIssueClick } from "analytics";
import "./reportIssue.scss";
function ReportIssue(props) {
  const [isShowModal, setIsShowModal] = useState(false);
  const [user, setUser] = useState({});
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [unablePayment, setUnablePayment] = useState(false);
  const [healthIssue, setHealthIssue] = useState(false);
  const [others, setOthers] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [reportAnswer, setReportAnswer] = useState("");
  const [enableBtn,setEnableBtn]= useState(false);
  const [orderId, setOrderId]= useState(null);
  const [clientDetails,setClientDetails]= useState(null);
  const [nursePanelIsArabic,setNursePanelIsArabic] = useState("");
  const history = useHistory();
  const nurseUserId= getUserId()

  useEffect(()=>{
    if(window.localStorage && localStorage.getItem("nursePanelIsArabic")){
      const isArabic = JSON.parse(localStorage.getItem("nursePanelIsArabic"));
      setNursePanelIsArabic(isArabic);
    }
  },[])

  useEffect(()=>{
    const {orderId}=props.match.params;
    if(orderId){
      getOrderDetails(orderId)
      fetchAllOrders()
      setOrderId(orderId)
    }
 },[orderId])

 const getOrderDetails=async(orderId)=>{
  if(!orderId){
    return;
  }
    try{
     const {data:order={}}= await PhoenixAPI.get(`order-sample-info/order-id/${orderId}`)
     const {reportAnIssue=null,reportIssueNotes=null}=order || {};
     if(reportAnIssue==="Change Payment Method"){
      setChangePaymentMethod(true);
      setUnablePayment(false);
      setHealthIssue(false);
      setOthers(false);
      setSelectedOption("changePaymentMethod");
      setEnableBtn(true);
      setReportAnswer(reportIssueNotes)
     }
     else if(reportAnIssue==="Unable to collect sample"){
      setChangePaymentMethod(false);
      setUnablePayment(true);
      setHealthIssue(false);
      setOthers(false);
      setSelectedOption("unablePayment");
      setEnableBtn(true);
      setReportAnswer(reportIssueNotes)
     }
     else if(reportAnIssue==="Health Issues"){
      setChangePaymentMethod(false);
      setUnablePayment(false);
      setHealthIssue(true);
      setOthers(false);
      setSelectedOption("healthIssue");
      setEnableBtn(true);
      setReportAnswer(reportIssueNotes)
     }
     else if(reportAnIssue==="Connect me to Valeo team"){
      setChangePaymentMethod(false);
      setUnablePayment(false);
      setHealthIssue(false);
      setOthers(true);
      setSelectedOption("others");
      setEnableBtn(true);
      setReportAnswer(reportIssueNotes)
     }     
    }
    catch(error){
    console.log("Error on fetching order", error)

    }
 }

 const fetchAllOrders=async()=>{
  let  url=`nurse/new-orders/${nurseUserId}` 
  try{
   setIsLoading(true)
   const { data: orders = [] } =await PhoenixAPI.get(url);
   console.log("OrderId:", orderId);
   const filteredOrder=orders.filter((order)=>order.orderId===parseInt(orderId))
     setClientDetails(filteredOrder[0])
 }
 catch(error){
   console.log("Error occurred in fetcching orders", error.message)
 }
 finally{
  setIsLoading(false)
 }
  }
 
  function handleBackButton() {
    return history.goBack();
  }

  const handleSelect = (selectedItem, index) => {
    if (selectedItem === "changePaymentMethod") {
      setChangePaymentMethod(true);
      setUnablePayment(false);
      setHealthIssue(false);
      setOthers(false);
      setSelectedOption("changePaymentMethod");
      setEnableBtn(false);
      setReportAnswer("")
    } else if (selectedItem === "unablePayment") {
      setChangePaymentMethod(false);
      setUnablePayment(true);
      setHealthIssue(false);
      setOthers(false);
      setSelectedOption("unablePayment");
      setEnableBtn(false);
      setReportAnswer("")
    } else if (selectedItem === "healthIssue") {
      setChangePaymentMethod(false);
      setUnablePayment(false);
      setHealthIssue(true);
      setOthers(false);
      setSelectedOption("healthIssue");
      setEnableBtn(false);
      setReportAnswer("")
    } else if (selectedItem === "others") {
      setChangePaymentMethod(false);
      setUnablePayment(false);
      setHealthIssue(false);
      setOthers(true);
      setSelectedOption("others");
      setEnableBtn(false);
      setReportAnswer("")
    }
  };

  const handleReportAnswer = (e) => {
    const message = e.target.value;
    setEnableBtn(true)
    setReportAnswer(message);
  };

 const handleSubmitIssue=async()=>{
    let issue="";
    if(changePaymentMethod){
        issue="Change Payment Method"
    }
    else if(unablePayment){
        issue="Unable to collect sample"
    }
    else if(healthIssue){
        issue="Health Issues"
    }
    else if(others){
        issue="Connect me to Valeo team"
    }
    const deviceId= sessionStorage.getItem("device_id");
    const trackingData={
      "deviceId": deviceId,
       "nurseName":getUserName(),
       "nurseEmail":getUserEmail(),
       "nurseId":getUserId(),
       "optionSelected":issue,
       "submitIssue":"click",
       "reportAnswer":reportAnswer,
    }

    trackReportIssueClick(trackingData)

   try{
     const response=await PhoenixAPI.patch(`nurse/report-an-issue/${orderId}?issue=${issue}&issueNotes=${reportAnswer}&packageName=${clientDetails.packageName ??""}&clientName=${clientDetails.userName ??""}&nurseUserId=${nurseUserId}`)
      console.log("response:", response);
      return history.goBack();
   }    
   catch(error){
    console.log("error in submitting the issue", error.message);
   }
  }

  const handleCancel=()=>{
    const deviceId= sessionStorage.getItem("device_id");
    const trackingData={
      "deviceId": deviceId,
       "nurseName":getUserName(),
       "nurseEmail":getUserEmail(),
       "nurseId":getUserId(),
       "cancelIssue":"click",
    }
    trackReportIssueClick(trackingData)
    history.goBack()
  }

  return (
    <>
      <div className={nursePanelIsArabic? "ar-report-issue-main" : "report-issue-main"}>
        <div className={nursePanelIsArabic? "ar-top-header" : "top-header"}>
          <div className="header-content">
            <div className="content" onClick={() => handleBackButton()}>
              <div className="lt">&lt;</div>
              <div className="header-title">{nursePanelIsArabic? "الإبلاغ عن مشكلة" : "Report Issue"}</div>
            </div>
          </div>
        </div>

        <div className="issue-options">
          <div
            className={`${
              changePaymentMethod && `selected-option`
            } select-option`}
            onClick={() => handleSelect("changePaymentMethod")}
          >
            {nursePanelIsArabic? "غير طريقة الدفع" : "Change Payment Method"}
          </div>
          {changePaymentMethod && (
            <>
              <div className="schedule-book">{nursePanelIsArabic? "ملاحظة" : "Note"} *</div>
              <div className="input-time">
                <textarea
                  type="text"
                  value={reportAnswer ?? ""}
                  onChange={(e) => handleReportAnswer(e)}
                  placeholder={nursePanelIsArabic? "اكتب تعليقًا..." : "write comment..."}
                />
              </div>
            </>
          )}
          <div
            className={`${unablePayment && `selected-option`} select-option`}
            onClick={() => handleSelect("unablePayment")}
          >
            {nursePanelIsArabic? "لا يمكن إكمال العينة" : "Unable to complete sample"}
          </div>
          {unablePayment && (
            <>
              <div className="schedule-book">{nursePanelIsArabic? "ملاحظة" : "Note"} *</div>
              <div className="input-time">
                <textarea
                  type="text"
                  value={reportAnswer ?? ""}
                  onChange={(e) => handleReportAnswer(e)}
                  placeholder={nursePanelIsArabic? "اكتب تعليقًا..." : "write comment..."}
                />
              </div>
            </>
          )}
          <div
            className={`${healthIssue && `selected-option`} select-option`}
            onClick={() => handleSelect("healthIssue")}
          >
            {nursePanelIsArabic? "مشاكل صحية" : "Health Issues"}
          </div>
          {healthIssue && (
            <>
              <div className="schedule-book">{nursePanelIsArabic? "ملاحظة" : "Note"} *</div>
              <div className="input-time">
                <textarea
                  type="text"
                  value={reportAnswer ?? ""}
                  onChange={(e) => handleReportAnswer(e)}
                  placeholder={nursePanelIsArabic? "اكتب تعليقًا..." : "write comment..."}
                />
              </div>
            </>
          )}
          <div
            className={`${others && `selected-option`} select-option`}
            onClick={() => handleSelect("others")}
          >
            {" "}
            {nursePanelIsArabic? "اتصل بفريق Valeo" : "Connect me to Valeo team"}
          </div>
          {others && (
            <>
              <div className="schedule-book">{nursePanelIsArabic? "ملاحظة" : "Note"} *</div>
              <div className="input-time">
                <textarea
                  type="text"
                  value={reportAnswer ?? ""}
                  onChange={(e) => handleReportAnswer(e)}
                  placeholder={nursePanelIsArabic? "اكتب تعليقًا..." : "write comment..."}
                />
              </div>
            </>
          )}
        </div>
        <div className='submit-issue-div'>
      <div className='report-issue' onClick={()=>handleCancel()} > {nursePanelIsArabic? "إلغاء" : "Cancel"} </div>
      <div className={`${
             enableBtn  ? `upload-lab-btn`:
             `upload-lab-btn-disabled`}`}
             onClick={()=>enableBtn?handleSubmitIssue():""}
             > {nursePanelIsArabic? "تقديم المشكلة" : "Submit Issue"} </div>
    </div>
      </div>
      {/* {message && <Notification setMessage={setMessage} message={message} isError={isError}  />} */}
      <CustomLoader show={isLoading} />
    </>
  );
}

export default ReportIssue;
