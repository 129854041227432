import React, { Component } from 'react';
import { Card, Modal, Tab, Row, Col, Nav, Tabs } from 'react-bootstrap';
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import Select from 'react-select';
import Search from "components/Search/Search";
import { filterList } from "utils/List";
import CountryDropdown from "components/Common/CountryDropdown";
import moment from "moment";
import _, { iteratee } from "lodash";
import { formatDate, compareStrings } from 'utils/commons';
import Status from 'components/Common/Status';
import SelectBundles from './SelectBundles';
import SelectSupplements from './SelectSupplements';
import SelectMedicines from './SelectMedicines';
import TransactionsTable from './TransactionsTable';
import SelectMiniPackages from '../../components/Common/SelectMiniPackages';
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import {fetchCoachUsers} from "services/UserService";
import SelectCoach from "../Orders/SelectCoach";
import BloodBiomarkerPackages from 'pages/Packages/BloodBiomarkerPackages';
import { fetchBloodPackages } from 'services/productService';
import CouponExclusionForm from "./CouponExclusionForm";
import Logs from 'components/Logs/Logs';
import SelectCoachConsultationPackages from "../../components/Common/SelectCoachConsultationPackages"
import {fetchCoachPackages} from "services/CoachConsultationPackageService";
import SelectProductCategory from 'pages/PackageCreation/SelectProductCategory';
import SelectSupplementCategories from "../../components/Common/SelectSupplementCategories";
import { fetchValeoPartners } from "services/ValeoPartnersService";


const BASE_URL_COUPONS = "coupon-code/";
const BASE_URL_COUPONS_SUMMARY = "coupon-code/summary/";
const BASE_URL_BUNDLES = "api/v1/bundles/";
const BASE_URL_SUPPLEMENTS = "supplement/";
const BASE_URL_SUPPLEMENTS_V2 = "supplement/admin/v3/";
const BASE_URL_MEDICINES = "medicine/";
const BASE_URL_PACKAGES_EXCLUSION="coupon-code/exclusion/";
const BASE_URL_BLOOD_PACKAGES="/v2/bloods/packages";
const BASE_URL_CUSTOM_PACKAGES="/v2/custom/packages";
const BASE_URL_COACH_CONSULTATION_PACKAGES="/v2/coach-consultation-package";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const STATUS_OPTIONS = [
    {
        key: true,
        value: true,
        text: "Active",
    },
    {
        key: false,
        value: false,
        text: "Inactive",
    },
];

const COUPON_TYPE_OPTIONS = [
    {
        key: "Fixed",
        value: "Fixed",
        text: "Fixed",
    },
    {
        key: "Percentage",
        value: "Percentage",
        text: "Percentage",
    },
    {
        key: "Absolute",
        value: "Absolute",
        text: "Absolute",
    },
];

const COUPON_APPLICABILITY_OPTIONS = [
    {
        key: "APPLY_TO_ALL",
        value: "APPLY_TO_ALL",
        text: "Apply to all packages/items in the cart",
    },
    {
        key: "APPLY_TO_SINGLE_MAX",
        value: "APPLY_TO_SINGLE_MAX",
        text: "Apply to a single package/item (Max)",
    },
    {
        key: "APPLY_TO_SINGLE_MIN",
        value: "APPLY_TO_SINGLE_MIN",
        text: "Apply to a single package/item (Min)",
    },
    {
        key: "APPLY_TO_SINGLE_WITH_MULTIPLE_QUANTITIES_MAX",
        value: "APPLY_TO_SINGLE_WITH_MULTIPLE_QUANTITIES_MAX",
        text: "Apply to a single package/item with multiple quantities in them (Max)",
    },
    {
        key: "APPLY_TO_SINGLE_WITH_MULTIPLE_QUANTITIES_MIN",
        value: "APPLY_TO_SINGLE_WITH_MULTIPLE_QUANTITIES_MIN",
        text: "Apply to a single package/item with multiple quantities in them (Min)",
    },
   
];

const COUPON_USER_LEVEL_APPLICABILITY_OPTIONS = [
    {
        key: "NONE",
        value: "NONE",
        text: "NONE",
    },
    {
        key: "USERS_WITHOUT_ANY_ORDER",
        value: "USERS_WITHOUT_ANY_ORDER",
        text: "Users without any order",
    },
    {
        key: "USERS_WITH_AT_LEAST_ONE_SUCCESSFULL_ORDER",
        value: "USERS_WITH_AT_LEAST_ONE_SUCCESSFULL_ORDER",
        text: "Users with at-least one successful order",
    },
]

const COUPON_CATEGORY_OPTIONS = [
    {
        key: "Discount",
        value: "Discount",
        text: "Discount",
    },
    {
        key: "Cashback",
        value: "Cashback",
        text: "Cashback",
    },
];
const PARTNER_PACKAGE_OPTIONS=[
    {
     "key":"All_Packages",
     "value":"All_Packages",
     "label":"All Packages"

    },
    {
    "key":"Excluded_Partner_Packages",
    "value":"Excluded_Partner_Packages",
    "label":"Excluded Partner Packages"
    }
]
class CouponCodes extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: "couponName",
                method: "isEmpty",
                validWhen: false,
                message: "Coupon Name is required",
            },
            {
                field: "discountType",
                method: "isEmpty",
                validWhen: false,
                message: "Select a Discount Type",
            },
            // {
            //     field: "description",
            //     method: "isEmpty",
            //     validWhen: false,
            //     message: "Description is required",
            // },
            {
                field: "couponAmount",
                method: "isEmpty",
                validWhen: false,
                message: "Coupon Amount is required",
            },
            {
                field: "couponExpiryDate",
                method: "isEmpty",
                validWhen: false,
                message: "Coupon Expiry Date is required",
            },
            {
                field: "minSpend",
                method: "isEmpty",
                validWhen: false,
                message: "Minimum Amount Spend is required",
            },
            {
                field: "maxSpend",
                method: "isEmpty",
                validWhen: false,
                message: "Maximum Amount Spend is required",
            },
            {
                field: "packages",
                method: "isEmpty",
                validWhen: false,
                message: "Select a Product",
            },
            {
                field: "custom_package",
                method: "isEmpty",
                validWhen: false,
                message: "Select a Package",
            },
            // {
            //     field: "addons",
            //     method: "isEmpty",
            //     validWhen: false,
            //     message: "Select an Addod",
            // },
            {
                field: "usageLimit",
                method: "isEmpty",
                validWhen: false,
                message: "Usage Limit is required",
            },
            {
                field: "userLimit",
                method: "isEmpty",
                validWhen: false,
                message: "User Limit is required",
            },
        ]);

        this.state = {
            aclUser: null,
            pageAccessRights: null,
            accessDenied: false,
            isEditable: true,
            showModal: false,
            showConfirm: false,
            products: [],
            addons: [],
            packages: [],
            custom_package: [],
            data: [],
            data1: [],
            data2: [],
            data3: [],
            couponName: "",
            description: "",
            discountType: "",
            couponAmount: "",
            couponExpiry: "",
            couponExpiryDate: "",
            minSpend: "",
            maxSpend: "",
            welcomeCoupon: false,
            isActive: false,
            usageLimit: "",
            userLimit: "",
            packs: [],
            custom_packs: [],
            addon: [],
            validation: this.validator.valid(),
            isDisabled: false,
            editId: null,
            searchTerm: "",
            searchExpanded: true,
            searchApplied: false,
            countries: [],
            selectedCountry: null,
            newUser: false,
            editCouponCode: false,
            editDescription: false,
            editStatus: false,
            editCouponApplicability: false,
            editCouponUserApplicability: false,
            editCouponType: false,
            editCouponValue: false,
            editCouponExpiryDate: false,
            editWelcomeCoupon: false,
            editMinSpend: false,
            editMaxSpend: false,
            editPackages: false,
            editCustomPackages: false,
            editAddons: false,
            editUserLimitPerCoupon: false,
            editUsageLimitPerCoupon: false,
            validationErrors: {},
            couponCategory: "DISCOUNT",
            maxDiscountAllowed: null,
            editCouponCategory: false,
            editMaxDiscountAllowed: false,
            isB2BCoupon:false,
            externalCoaches:[],
            wellbeingCoaches:[],
            defaultCoachId:null,
            defaultSecondaryCoachId:null,
            editDefaultCoach:false,
            editdefaultSecondary:false,

            exclusionCouponBloodPackages:[],
            exclusionCouponHealthPackages:[],
            exclusionCouponBundles:[],
            exclusionCouponCustomPackages:[],
            exclusionCouponMedicines:[],
            exclusionCouponSupplements:[],
    
            isPromotionalCoupon:false,
            pageIdForLogs: null,
            editCoachConsultationPackages:false,
            coachConsultationPackages:[],
            allCoachConsultationsPackages:[],
            exclusionCoachConsultationPackages:[],
            filterByProduct:false,
            filterByCategory:false,
            allProductCategories:[],
            productCategories:[],
            allSupplementCategories:[],
            supplementCategories:[],
        };

        this.columns = [
            {
                name: "Id",
                selector: "id",
                sortable: true,
                maxWidth: "100px",
            },
            {
                name: "Name",
                selector: "coupon_code",
                sortable: true,
            },
            {
                name: "Discount Type",
                selector: "discount_type",
                sortable: true,
            },
            {
                name: "Amount",
                selector: "coupon_amount",
                sortable: true,
            },
            {
                name: "Expiry",
                selector: "coupon_expiry",
                sortable: true,
                format: row => row.coupon_expiry ? formatDate(row.coupon_expiry) : "-",
            },
            {
                name: "Status",
                selector: "is_active",
                center: true,
                sortable: true,
                maxWidth: "150px",
                cell: (coupon) => <Status isActive={coupon.is_active} />,
            },
            {
                name: "View",
                center: true,
                cell: (coupon) => this.renderViewButton(coupon),
            }
        ];

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.formReset = this.formReset.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createFormData = this.createFormData.bind(this);
        this.handleEditData = this.handleEditData.bind(this);
        this.fetchExclusionPackages=this.fetchExclusionPackages.bind(this);
        this.getCouponCodes = this.getCouponCodes.bind(this);
        this.handleAddonChange = this.handleAddonChange.bind(this);
        this.handlePackageChange = this.handlePackageChange.bind(this);
        this.handleCustomPackageChange = this.handleCustomPackageChange.bind(this);
        this.generateCouponCode = this.generateCouponCode.bind(this);
        
        this.submitted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedCountry !== prevState.selectedCountry) {
            this.createPackagesOptions();
            this.fetchSupplementsV2();
           // this.fetchExclusionPackages();
           this.fetchBloodPackagesV2();
           this.fetchCustomPackagesV2();
           this.fetchCoachConsultationPackages();
            this.getCouponCodes();
            
        }
    }
    fetchWellbeingCoaches = async () => {
        try {
          const wellbeingCoaches = (await fetchCoachUsers()) || [];
          let coachDetails = [{ text: "None", value: null, key: "None",defaultCoachId : null }];
      wellbeingCoaches && wellbeingCoaches.map((item) => {
        let coach = {};
        coach["text"] = item.userName;
        coach["value"] = item.id;
        coach["key"] = item.userName;
        coach["defaultCoachId"] = item.id;
        coachDetails.push(coach)
      })
          this.setState({ wellbeingCoaches:coachDetails  });
        } catch (error) {
          this.showErrorNotification(
            "Error on fetching wellbeing coaches: ",
            error.message
          );
          this.setState({ loadError: "Some error has occured. Please try again" });
        }
      };
    fetchExternalCoaches=async()=>{
        try {
          const {data:externalCoaches=[]} = await PhoenixAPI.get("external/coach/users/") || [];
          let coachDetails = [{ text: "None", value: null, key: "None", defaultSecondaryCoachId: null }];
      externalCoaches && externalCoaches.map((item) => {
        let coach = {};
        coach["text"] = item.userName;
        coach["value"] = item.id;
        coach["key"] = item.userName;
        coach["defaultSecondaryCoachId"] = item.id;
        coachDetails.push(coach)
      })

          this.setState({ externalCoaches:coachDetails });
        } catch (error) {
          this.showErrorNotification(
            "Error on fetching external coaches: ",
            error.message
          );
          this.setState({ loadError: "Some error has occured. Please try again" });
        }
      }
    
      componentWillReceiveProps(nextProps) {
        if (this.props.selectedCountry !== nextProps.selectedCountry) {
            this.setState({
                countries: nextProps.countries,
                selectedCountry: nextProps.selectedCountry
            })
        }
    }

    componentDidMount() {
        Promise.all([
            // this.fetchAclUser(),
            // this.getCountries(), 
            this.fetchBundles(), 
            this.fetchMedicines(), 
            // this.fetchSupplements(), 
            this.fetchSupplementsV2(),
            this.fetchMiniPackages(),
            this.fetchBloodPackagesV2(),
            this.fetchCustomPackagesV2(),
            this.fetchCoachConsultationPackages(),
        ]);
        
        let packages = [];
        let addons = [];

        // API.get("subscription-packages/")
        // PhoenixAPI.get("/v2/bloods/packages")
        //     .then(response => {
        //         // const subscriptionPackages = response.data || [];
        //         // console.log("Blood Biomarker Packages: ", subscriptionPackages);
        //         // // const data = filterList(subscriptionPackages, "active");
        //         const {All_Packages:subscriptionPackages=[], Dewa:dewaBloodPackages=[],Tamara:tamaraBloodPackages=[],Non_Partner_Packages:nonPartnerBloodPackages=[]} = response.data || [];
        //         const data = subscriptionPackages;  
        //         this.setState({ data1: data,allBloodPackages:subscriptionPackages,dewaBloodPackages, tamaraBloodPackages, nonPartnerBloodPackages }, () => this.createPackagesOptions());
        //     })
        //     .catch(error => {
        //         console.log("Error on fetching blood packages: ", error);
        //     });
            
        API.get("add-on-packages/")
            .then(response => {
                const addons = response.data || [];
                console.log("Addons: ", addons);
                const data = filterList(addons.filter(addon => addon.is_parent), "active");
                this.setState({ data2: data }, () => this.createPackagesOptions());
            })
            .catch(error => {
                console.log("Error on fetching add-on packages: ", error);
            })

            // PhoenixAPI.get("/v2/custom/packages")
            // .then(response => {
            //     const {All_Packages:customPackages=[], Dewa:dewaCustomPackages=[],Tamara:tamaraCustomPackages=[],Non_Partner_Packages:nonPartnerCustomPackages=[]} = response.data || [];
             
            //     this.setState({ data3: customPackages,allCustomPackages:customPackages??[],dewaCustomPackages,tamaraCustomPackages,nonPartnerCustomPackages }, () => this.createPackagesOptions());
            // })
            // .catch(error => {
            //     console.log("Error on fetching custom packages", error);
            // })
        // this.fetchExclusionPackages();
        this.getCouponCodes();
        this.fetchExternalCoaches();
        this.fetchWellbeingCoaches();
       
        this.fetchProductCategories();
        this.fetchSupplementCategories();
        this.fetchValeoPartners()
    }
    
    hasPageAccessRight = (accessRightName) => {
        const { aclUser, pageAccessRights } = this.state;
        // If user is not configured as an acl user then he has all the rights as previous.
        if (!aclUser || isUserSuperAdmin(aclUser)) return true;
        if(!aclUser || isUserAdmin(aclUser)) return true;
        if (pageAccessRights && pageAccessRights.length && accessRightName)
            return pageAccessRights.includes(accessRightName.toLowerCase());
        return false;
    }

    fetchExclusionPackages=async()=>{
        const { selectedCountry } = this.state;
        console.log("fetch exclusion");
        if (!selectedCountry || !selectedCountry.id) {
            console.log("fetchExclusionPackages:: Cannot fetch exclusion! Invalid country");
            return;
        }
        try {
            this.showLoading();
            const url = `${BASE_URL_PACKAGES_EXCLUSION}country/${selectedCountry.id}`
            const { data: all_packages_exclusion } =await PhoenixAPI.get(url);
            console.log("Exclusion Packages", all_packages_exclusion)
            const {
                bloodPackages = [],
                coachPackages = [],
                customPackages = [],
                bundles = [],
                supplements = [],
                medicines = [],
                coachConsultationPackages=[]
            } = all_packages_exclusion;
            this.setState({
                exclusionCouponBloodPackages: bloodPackages,
                exclusionCouponCustomPackages: customPackages,
                exclusionCouponBundles: bundles,
                exclusionCouponHealthPackages: coachPackages, // Assuming miniPackages corresponds to exclusionHealthPackages
                exclusionCouponSupplements: supplements,
                exclusionCouponMedicines: medicines,
                exclusionCoachConsultationPackages:coachConsultationPackages
            });
        }
        catch (error) {
        console.log("Error on fetching supplements!", error);
        } finally {
        this.hideLoading();
        }

  }

    fetchAclUser = async () => {
        const userId = getUserId();
        if (!userId) {
            console.log("fetchAclUser:: Invalid user id!", { userId });
            return;
        }

        try {
            const pageName = Page.COUPON_CODES;
            const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
            const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
            const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
            const accessDenied = isAccessDenied(aclUser, pageAccessRights);
            this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
                const isEditable = this.hasPageAccessRight("edit");
                this.setState({ isEditable });
            });
        } catch (error) {
            console.log("fetchAclUser:: Error on fetching acl user!", error);
        }
    }
   
     fetchBundles = async () => {
      try {
        const { data: all_bundles } = await PhoenixAPI.get(BASE_URL_BUNDLES);
        this.setState({all_bundles},()=>this.createBundleOptions())
      } catch (error) {
        console.log("Error on fetching bundles", error);
      }
    };

    

     fetchMedicines = async () => {
        try {
          const { data: all_medicines } = await PhoenixAPI.get(BASE_URL_MEDICINES);
         this.setState({all_medicines},()=>this.createMedicineOptions())
        } catch (error) {
          console.log("Error on fetching medicines", error);
        }
      };

       fetchSupplements = async () => {
        try {
          const { data: all_supplements } = await PhoenixAPI.get(BASE_URL_SUPPLEMENTS);
          this.setState({all_supplements},()=>this.createSupplementOptions())
        } catch (error) {
          console.log("Error on fetching supplements", error);
        }
      };

    fetchBloodPackagesV2 = async () => {
        const { selectedCountry } = this.state;
        if (!selectedCountry || !selectedCountry.id) {
            console.log("fetch Blood Packages:: Cannot fetch supplements! Invalid country");
            return;
        }
        try {
            const { data = {} } = await PhoenixAPI.get(BASE_URL_BLOOD_PACKAGES, { params: { country: selectedCountry.id } })
            const { All_Packages: subscriptionPackages = [], Dewa: dewaBloodPackages = [], Tamara: tamaraBloodPackages = [], Non_Partner_Packages: nonPartnerBloodPackages = [] } = data ?? {};

            this.setState({ data1: subscriptionPackages, allBloodPackages: subscriptionPackages, dewaBloodPackages, tamaraBloodPackages, nonPartnerBloodPackages }, () => this.createPackagesOptions());

        }
        catch (error) {
            console.log("Error on fetching blood packages!", error);
        } finally {
            this.hideLoading();
        }
    }

    fetchCustomPackagesV2 = async () => {
        const { selectedCountry } = this.state;
        if (!selectedCountry || !selectedCountry.id) {
            console.log("fetch Custom Packages:: Cannot fetch custom packages! Invalid country");
            return;
        }
        try {
            const { data = {} } = await PhoenixAPI.get(BASE_URL_CUSTOM_PACKAGES, { params: { country: selectedCountry.id } })
            const { All_Packages: customPackages = [], Dewa: dewaCustomPackages = [], Tamara: tamaraCustomPackages = [], Non_Partner_Packages: nonPartnerCustomPackages = [] } = data ?? [];

            this.setState({ data3: customPackages, allCustomPackages: customPackages ?? [], dewaCustomPackages, tamaraCustomPackages, nonPartnerCustomPackages }, () => this.createPackagesOptions());

        }
        catch (error) {
            console.log("Error on fetching custom packages!", error);
        } finally {
            this.hideLoading();
        }
    }
    fetchSupplementsV2 = async () => {
        const { selectedCountry } = this.state;
        if (!selectedCountry || !selectedCountry.id) {
            console.log("fetchSupplementsV2:: Cannot fetch supplements! Invalid country");
            return;
        }

        try {
            this.showLoading();
            const url = `${BASE_URL_SUPPLEMENTS_V2}country/${selectedCountry.id}`;
            const {data} = await PhoenixAPI.get(url);
            const {All_Packages:all_supplements=[], Dewa:dewa_supplements=[],Tamara:tamara_supplements=[],Non_Partner_Packages:non_partner_supplements=[]}=data ??{};

            this.setState({ all_supplements, dewa_supplements, tamara_supplements, non_partner_supplements},()=>this.createSupplementOptions())
        } catch (error) {
            console.log("Error on fetching supplements!", error);
        } finally {
            this.hideLoading();
        }
    };

    fetchCoachConsultationPackages=async()=>{
        const { selectedCountry } = this.state;
        if (!selectedCountry || !selectedCountry.id) {
            console.log("fetch Blood Packages:: Cannot fetch supplements! Invalid country");
            return;
        }
        try {
            const {data} = await PhoenixAPI.get(BASE_URL_COACH_CONSULTATION_PACKAGES, { params: { country: selectedCountry.id } });
            const {All_Packages:allCoachConsultationsPackages=[], Dewa:dewaCoachConsultationPackages=[],Tamara:tamaraCoachConsultationPackages=[],Non_Partner_Packages:nonPartnerCoachConsulationPackages=[]}=data ??{};
           
            this.setState({ allCoachConsultationsPackages,dewaCoachConsultationPackages,tamaraCoachConsultationPackages,nonPartnerCoachConsulationPackages })
          } catch (error) {
            console.log("Error on fetching Coach Consultations", error);
          } 
    }
    fetchMiniPackages = async () => {
        try {
            const { data: all_mini_packages } = await PhoenixAPI.get("/api/v1/mini-packages/active");
            this.setState({ all_mini_packages })
        } catch (error) {
            console.log("Error on fetching mini packages!", error);
        }
    };

    fetchProductCategories=async()=>{
        try {
            const {data:allProductCategories=[]} = await  API.get("products/");
            this.setState({ allProductCategories})
        } catch (error) {
            console.log("Error on fetching product categories!", error);
        }
    }

   fetchSupplementCategories=async()=>{
    try {
        const { data: allSupplementCategories} = await PhoenixAPI.get("supplement-category/v2/admin");
        this.setState({ allSupplementCategories })
    } catch (error) {
        console.log("Error on fetching supplement categories", error);
    }
   }

   fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
      const partnerOptions = partners && partners.map((it) => {
        return PARTNER_PACKAGE_OPTIONS.push({ key: it.name, value: it.name, label: it.name })
      })

    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };
    getCouponCodes() {
        const { selectedCountry } = this.state;
        if (!selectedCountry || !selectedCountry.id) {
            console.log("getCouponCodes:: Invalid country id!", { selectedCountry })
            return;
        }

        this.showLoading();
        PhoenixAPI.get(BASE_URL_COUPONS_SUMMARY, { params: { country: selectedCountry.id } })
            .then(response => {
                const data = response.data || [];
                this.setState({ data: data.map(this.fromNewToOldCouponStructure) });
            })
            .catch(error => {
                console.log("Error on fetching coupon codes", error);
            })
            .finally(() => this.hideLoading());
    }

    fromNewToOldCouponStructure = (newCoupon) => {
        return {
            id: newCoupon.id,
            // currency: coupon.currency,
            // currency_arabic: coupon.currencyArabic,
            coupon_code: newCoupon.couponCode,
            description: newCoupon.description,
            discount_type: newCoupon.discountType,
            coupon_amount: newCoupon.couponAmount,
            coupon_expiry: newCoupon.couponExpiryDate,
            min_amt_spend: newCoupon.minSpend,
            max_amt_spend: newCoupon.maxSpend,
            coupon_usage_limit: newCoupon.couponUsageLimit,
            user_usage_limit: newCoupon.couponUserLimit,
            total_usage: newCoupon.totalUsage,
            is_active: newCoupon.status,
            is_welcome: newCoupon.welcomeCoupon,
            created_at: newCoupon.createdAt,
            modified_at: newCoupon.modifiedAt,
            country: newCoupon.country,
            packages: newCoupon.bloodPackages,
            custom_package: newCoupon.customPackages,
            addons: newCoupon.coachPackages,
            // total_usage_by_user: [],
            bundles: newCoupon.bundles ?? [],
            medicines: newCoupon.medicines ?? [],
            supplements: newCoupon.supplements ?? [],
            couponCategory: newCoupon.couponCategory,
            couponApplicability: newCoupon.couponApplicability,
            couponUserApplicability: newCoupon.couponUserApplicability,
            maxDiscountAllowed: newCoupon.maxDiscountAllowed,
            miniPackages: newCoupon.miniPackages,
            isB2BCoupon: newCoupon.isB2BCoupon,
            defaultCoachId:newCoupon.defaultCoachId,
            defaultSecondaryCoachId:newCoupon.defaultSecondaryCoachId,
            isPromotionalCoupon:newCoupon.isPromotionalCoupon,
            coachConsultationPackages: newCoupon.coachConsultationPackages??[],
            categoryId:newCoupon.categoryId??[],
            supplementCategoryId:newCoupon.supplementCategoryId??[],
            filterBy:newCoupon.filterBy??"",

        }
    }

    fromOldToNewCouponStructure = (oldCoupon) => {
        return {
            id: oldCoupon.id,
            // currency: coupon.currency,
            // currency_arabic: coupon.currencyArabic,
            couponCode: oldCoupon.coupon_code,
            description: oldCoupon.description,
            discountType: oldCoupon.discount_type,
            couponAmount: oldCoupon.coupon_amount,
            couponExpiryDate: oldCoupon.coupon_expiry,
            minSpend: oldCoupon.min_amt_spend,
            maxSpend: oldCoupon.max_amt_spend,
            couponUsageLimit: oldCoupon.coupon_usage_limit,
            couponUserLimit: oldCoupon.user_usage_limit,
            totalUsage: oldCoupon.total_usage,
            status: oldCoupon.is_active,
            welcomeCoupon: oldCoupon.is_welcome,
            createdAt: oldCoupon.created_at,
            modifiedAt: oldCoupon.modified_at,
            country: oldCoupon.country,
            bloodPackages: oldCoupon.packages,
            customPackages: oldCoupon.custom_package,
            coachPackages: oldCoupon.addons,
            // total_usage_by_user: [],
            bundles: oldCoupon.bundles ?? [],
            medicines: oldCoupon.medicines ?? [],
            supplements: oldCoupon.supplements ?? [],
            isB2BCoupon:oldCoupon.isB2BCoupon??false,
            defaultCoachId:oldCoupon.defaultCoachId,
            defaultSecondaryCoachId:oldCoupon.defaultSecondaryCoachId,
            isPromotionalCoupon:oldCoupon.isPromotionalCoupon
        }
    }

    /*
    getCouponCodes() {
        this.props.toggleLoading({
            isLoading: true,
        });

        API.get("coupon-code/")
            .then(response => {
                let data = response.data;
                this.setState({
                    data: data,
                })

                this.props.toggleLoading({
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log("Error on fetching coupon codes", error);
            })
    }
    */

    createPackagesOptions =async () => {
        if(this.state?.showModal)
        await this.fetchExclusionPackages();
        const packages = this.createBloodBiomarkerPackageOptions();
        const custom_package = this.createCustomPackageOptions();
        const addons = this.createHealthCoachPackageOptions();
        this.setState({ packages, custom_package, addons});
    }

    createBloodBiomarkerPackageOptions = () => {
        const { data1: bloodBiomarkerPackages = [],data3: customPackages = [], selectedCountry,exclusionCouponBloodPackages } = this.state;

        const packagesForCrrentCountry = (bloodBiomarkerPackages && selectedCountry)
            ? bloodBiomarkerPackages.filter(p => p.country === selectedCountry.id && !p.is_coupon_excluded && !exclusionCouponBloodPackages.includes(p.id))
            : [];
        

        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.is_active);
        activePackages.sort((first, second) =>
            compareStrings(first.internal_name, second.internal_name, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.internal_name, second.internal_name, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            // "label": p.subscription_name,
            "label": p.internal_name,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];
    }

    createCustomPackageOptions = () => {
        const { data3: customPackages = [], selectedCountry,exclusionCouponCustomPackages } = this.state;

        const packagesForCrrentCountry = (customPackages && selectedCountry)
            ? customPackages.filter(p => p.country === selectedCountry.id &&  !p.is_coupon_excluded && !exclusionCouponCustomPackages.includes(p.id))
            : [];
        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.status === "Active");
        activePackages.sort((first, second) =>
            compareStrings(first.name, second.name, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.name, second.name, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            "label": p.name,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];
    }

    createHealthCoachPackageOptions = () => {
        const { data2: healthCoachPackages = [], selectedCountry,exclusionCouponHealthPackages } = this.state;

        const packagesForCrrentCountry = (healthCoachPackages && selectedCountry)
            ? healthCoachPackages.filter(p => p.country === selectedCountry.id && !p.is_coupon_excluded && !exclusionCouponHealthPackages.includes(p.id))
            : [];
        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.is_active);
        activePackages.sort((first, second) =>
            compareStrings(first.addon_name, second.addon_name, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.addon_name, second.addon_name, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            "label": p.addon_name,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];
    }
    
    createBundleOptions = () => {
        const { all_bundles: bundles = [], selectedCountry,exclusionCouponBundles } = this.state;
        const packagesForCrrentCountry = (bundles && selectedCountry)
            ? bundles.filter(p => p.country === selectedCountry.id && !p.is_coupon_excluded)
            : [];
        

        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.status === "Active" && !exclusionCouponBundles.includes(it.id) );
        activePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            // "label": p.subscription_name,
            "label": p.internalName,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];


    }

    createMedicineOptions=()=>{
        const{all_medicines:medicines=[], selectedCountry,exclusionCouponMedicines} = this.state;
        const packagesForCrrentCountry = (medicines && selectedCountry)
            ? medicines.filter(p => p.country === selectedCountry.id && !p.is_coupon_excluded && !exclusionCouponMedicines.includes(p.id))
            : [];
        

        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.status === "Active");
        activePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            // "label": p.subscription_name,
            "label": p.internalName,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];

    }

    createSupplementOptions=()=>{
        const{all_supplements:supplements=[], selectedCountry,exclusionCouponSupplements} = this.state;
        const packagesForCrrentCountry = (supplements && selectedCountry)
            ? supplements.filter(p => p.country === selectedCountry.id && !p.is_coupon_excluded && !exclusionCouponSupplements.includes(p.id))
            : [];
        

        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) =>it.status === "Active" );
        activePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            // "label": p.subscription_name,
            "label": p.internalName,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];


    }

    showModal() {
        
        this.setState({
            showModal: true,
            validationErrors: {},
        },()=>this.createPackagesOptions());
       
    }

    
    showConfirm = () => this.setState({ showConfirm: true });

    hideConfirm = () => this.setState({ showConfirm: false });

    

    hideModal() {
        this.formReset();
        this.setState({ 
            showModal: false,
            newCoupon: false,
            editCouponCode: false,
            editDescription: false,
            editStatus: false,
            editCouponApplicability: false,
            editCouponUserApplicability: false,
            editCouponType: false,
            editCouponValue: false,
            editCouponExpiryDate: false,
            editWelcomeCoupon: false,
            editMinSpend: false,
            editMaxSpend: false,
            editPackages: false,
            editCustomPackages: false,
            editAddons: false,
            editUserLimitPerCoupon: false,
            editUsageLimitPerCoupon: false,
            editCouponCategory: false,
            editMaxDiscountAllowed: false,
            editMiniPackages: false,
            validationErrors: {},
            isB2BCoupon:false,
            defaultCoachId:null,
            defaultSecondaryCoachId:null,
            editDefaultCoach:false,
            editdefaultSecondary:false,


            editCouponExclusion:false,
            

            isPromotionalCoupon:false,
            pageIdForLogs:null,
            coachConsultationPackages:[],
            editCoachConsultationPackages:false,
            filterByProduct:false,
            filterByCategory:false,
            partnerPackageName:""
        });
    }

    handleCreateNewCoupon = () => {
        this.setState({ newCoupon: true });
        this.showModal();
    }

    
    

    

    handleChange(event) {
        if (event.target) {
            if (event.target.name === "couponExpiry") {
                const target = event.target.value;
                let newDate = new Date(target);
                let date = newDate.toISOString();
                this.setState({
                    couponExpiryDate: event.target.value,
                    couponExpiry: date
                })
            }
            else if (event.target.name === "welcomeCoupon") {
                const target = event.target;
                this.setState({
                    [target.name]: !this.state.welcomeCoupon
                })
            }
            else if (event.target.name === "isActive") {
                const target = event.target;
                this.setState({
                    [target.name]: target.value === "active" ? true : false
                })
            }
            else {
                const target = event.target;
                this.setState({
                    [target.name]: target.value
                })
            }
        }
    }
    handleAddonChange(event) {
        this.setState({
            addon: event
        })
    }
    handlePackageChange(event) {

        this.setState({
            packs: event
        })
    }

    


    handleCustomPackageChange(event) {

        this.setState({
            custom_packs: event
        })
    }

    

    createFormData(editId) {
        let packages = [];
        let addons = [];
        let customPackages = [];

        for (let i = 0; i < this.state.packs.length; i++)
            packages.push(this.state.packs[i].value)

        for (let i = 0; i < this.state.addon.length; i++)
            addons.push(this.state.addon[i].value)

        for (let i = 0; i < this.state.custom_packs.length; i++)
            customPackages.push(this.state.custom_packs[i].value)

        // We are updating a coupon
        if (editId !== null) {
           return {
                "id": this.state.editId,
                "coupon_code": this.state.couponName,
                "description": this.state.description,
                "discount_type": this.state.discountType,
                "coupon_amount": this.state.couponAmount,
                "coupon_expiry": new Date(this.state.couponExpiryDate).toISOString(),
                "min_amt_spend": this.state.minSpend,
                "max_amt_spend": this.state.maxSpend,
                "is_welcome": this.state.welcomeCoupon,
                "is_active": this.state.isActive,
                "packages": packages,
                "addons": addons,
                "custom_package": customPackages,
                "coupon_usage_limit": this.state.usageLimit,
                "user_usage_limit": this.state.userLimit,
                "country": this.state.countryId,
                "isB2BCoupon":this.state.isB2BCoupon,
                "defaultCoachId":this.state.defaultCoachId,
                "defaultSecondaryCoachId":this.state.defaultSecondaryCoachId,
                "isPromotionalCoupon":this.state.isPromotionalCoupon
            };
        } else {
            // We are creating a new coupon
            const formData = new FormData();
            formData.append("coupon_code", this.state.couponName);
            formData.append("description", this.state.description);
            formData.append("discount_type", this.state.discountType);
            formData.append("coupon_amount", this.state.couponAmount);
            formData.append("coupon_expiry", new Date(this.state.couponExpiryDate).toISOString());
            formData.append("min_amt_spend", this.state.minSpend);
            formData.append("max_amt_spend", this.state.maxSpend);
            formData.append("is_welcome", this.state.welcomeCoupon);
            formData.append("is_active", this.state.isActive);
            
            packages.map(value => formData.append("packages", value));
            addons.map(value => formData.append("addons", value));
            customPackages.map(value => formData.append("custom_package", value));

            formData.append("coupon_usage_limit", this.state.usageLimit);
            formData.append("user_usage_limit", this.state.userLimit);
            formData.append("isB2BCoupon",this.state.isB2BCoupon);
            formData.append("defaultCoachId",this.state.defaultCoachId);
            formData.append("defaultSecondaryCoachId",this.state.defaultSecondaryCoachId);
            if (this.state.selectedCountry)
                formData.append("country", this.state.selectedCountry.id);
            formData.append("isPromotionalCoupon",this.state.isPromotionalCoupon);
            return formData;
        }
    }

    validateCouponCode = (couponCode) => {
        const { editId } = this.state;
        const MIN_LENGTH = 4;
        const MAX_LENGTH = 20;
        let isValidCouponCode = false;
        const validationErrors = this.state.validationErrors;

        if (this.isEmpty(couponCode))
            validationErrors["couponCode"] = "Coupon Code is a required field!";
        else if (!/^[a-z0-9]+$/i.test(couponCode))
            validationErrors["couponCode"] = "Invalid Coupon Code! (should only contain letters and numbers).";
        else if (couponCode.length < MIN_LENGTH)
            validationErrors["couponCode"] = `Invalid Coupon Code! (should be minimum ${MIN_LENGTH} characters long).`;
        else if (couponCode.length > MAX_LENGTH)
            validationErrors["couponCode"] = `Invalid Coupon Code! (should be maximum ${MAX_LENGTH} characters long).`;
        else {
            const coupon = this.findCouponByCode(couponCode);
            if ((!editId && coupon) || (editId && coupon && coupon.id !== editId))
                validationErrors["couponCode"] = "Duplicate Coupon Code! (This coupon code already exixts for this country)."
            else {
                validationErrors["couponCode"] = "";
                isValidCouponCode = true;
            }
        }

        return isValidCouponCode;
    }

    findCouponByCode = (couponCode) => {
        const { data: allCoupons, selectedCountry } = this.state;
        return (allCoupons && selectedCountry)
            ? allCoupons.filter(coupon => coupon.country === selectedCountry.id).find(coupon => coupon.coupon_code === couponCode)
            : null;
    }

    validateDescription = (description) => {
        const MAX_LENGTH = 1000;
        let isValidDescription = false;
        const validationErrors = this.state.validationErrors;

        if (description && description.trim().length > MAX_LENGTH)
            validationErrors["description"] = `Invalid Description! (should be maximum ${MAX_LENGTH} characters long).`;
        else {
            validationErrors["description"] = "";
            isValidDescription = true;
        }

        return isValidDescription;
    }

    validateCouponType = (couponType) => {
        let isValidCouponType = false;
        const validationErrors = this.state.validationErrors;

        if (this.isEmpty(couponType))
            validationErrors["couponType"] = "Coupon Type is a required field!";
        else {
            validationErrors["couponType"] = "";
            isValidCouponType = true;
        }

        return isValidCouponType;
    }

    validateCouponValue = (couponValue) => {
        let isValidCouponValue = false;
        const validationErrors = this.state.validationErrors;

        if (this.isEmpty(couponValue))
            validationErrors["couponValue"] = "Coupon Value is a required field!";
        else if (Number.isNaN(Number(couponValue)))
            validationErrors["couponValue"] = "Invalid Coupon Value! (not a valid number).";
        else if (Number(couponValue) <= 0 || Number(couponValue) > 10000)
            validationErrors["couponValue"] = "Invalid Coupon Value! (should be between 1 and 10,000)."
        else {
            validationErrors["couponValue"] = "";
            isValidCouponValue = true;
        }
        return isValidCouponValue;
    }

    validateCouponExpiryDate = (date) => {
        let isValidCouponExpiryDate = false;
        const validationErrors = this.state.validationErrors;

        if (!date || date.trim() === "")
            validationErrors["couponExpiryDate"] = "Coupon Expiry Date is a required field!";
        else {
            validationErrors["couponExpiryDate"] = "";
            isValidCouponExpiryDate = true;
        }
        return isValidCouponExpiryDate;
    }

    validateUsageRestrictions = ({ discountType, minSpend, maxSpend, maxDiscountAllowed }) => {
        let isValidMinSpend = this.validateNumber(minSpend, 0, 1000000, "minSpend", "Min. Spend");
        let isValidMaxSpend = this.validateNumber(maxSpend, 0, 1000000, "maxSpend", "Max. Spend");
        let isValidMaxDiscountAllowed = this.validateNumber(maxDiscountAllowed, 0, 1000000, "maxDiscountAllowed", "Max. Discount Allowed");

        const validationErrors = this.state.validationErrors;
        if (isValidMinSpend && maxSpend !== null && maxSpend !== undefined && maxSpend !== "") {
            if (Number(minSpend) > Number(maxSpend)) {
                validationErrors.minSpend = validationErrors.minSpend 
                    ? validationErrors.minSpend
                    : "Invalid Min. Spend! (should not be greater than Max. Spend)";
                isValidMinSpend = false;
            } else {
                validationErrors.minSpend = "";
                isValidMinSpend = true;
            }
        }
        if (isValidMaxSpend && minSpend !== null && minSpend !== undefined && minSpend !== "") {
            if (Number(maxSpend) < Number(minSpend)) {
                validationErrors.maxSpend = validationErrors.maxSpend 
                    ? validationErrors.maxSpend 
                    : "Invalid Max. Spend! (should not be smaller than Min. Spend)";
                isValidMaxSpend = false;
            } else {
                validationErrors.maxSpend = "";
                isValidMaxSpend = true;
            }
        }

        if (discountType === "Absolute") {
            validationErrors.minSpend = "";
            isValidMinSpend = true;
            validationErrors.maxSpend = "";
            isValidMaxSpend = true;
            validationErrors.maxDiscountAllowed = "";
            isValidMaxDiscountAllowed = true;
        }
        
        return isValidMinSpend && isValidMaxSpend && isValidMaxDiscountAllowed;
    }

    validateUsageLimits = ({ userLimit, usageLimit }) => {
        const isValidUserLimit = this.validateNumber(userLimit, 0, null, "userLimit", "User Limit per Coupon");
        const isValidUsageLimit = this.validateNumber(usageLimit, 0, null, "usageLimit", "Usage Limit per Coupon");
        
        return isValidUserLimit && isValidUsageLimit;
    }

    validateNumber = (number, min, max, field, label) => {
        let isValidNumber = false;
        const validationErrors = this.state.validationErrors;

        if (!number || number === "")
            validationErrors[field] = `${label} is a required field!`;
        else if (Number.isNaN(Number(number)))
            validationErrors[field] = `Invalid ${label}! (not a valid number).`;
        else if (min !== null && Number(number) < min)
            validationErrors[field] = `Invalid ${label}! (should be greater than ${min}).`;
        else if (max !== null && Number(number) > max)
            validationErrors[field] = `Invalid ${label}! (should not be greater than ${max})`;
        else {
            validationErrors[field] = "";
            isValidNumber = true;
        }
        return isValidNumber;
    }

    isEmpty = (value) => !value || value.trim() === "";

    validateCouponApplicability = () => {
        const { discountType, couponApplicability, validationErrors } = this.state;
        if (discountType === "Absolute") {
            validationErrors.couponApplicability = "";
            return true;
        }
    
        if (!couponApplicability) {
            validationErrors.couponApplicability = "Coupon Applicability is a required field!";
            return false;
        } else {
            validationErrors.couponApplicability = "";
            return true;
        }
    }

    validatePackageTypes = () => {
        const { 
            packs: bloodPackages = [], 
            addon: coachPackages = [], 
            custom_packs: customPackages = [],
            bundles = [],
            supplements = [],
            medicines = [],
            miniPackages = [],
            categoryId=[],
            supplementCategoryId=[],
        } = this.state;
        return bloodPackages.length || coachPackages.length || customPackages.length ||
         bundles.length || supplements.length || medicines.length || miniPackages.length || categoryId.length || supplementCategoryId.length;
    }

    

    handleSubmit() {
        let editId = this.state.editId;
        let validation;
        try {
            validation = this.validator.validate(this.state);
        } catch (error) {
            console.log("Ignoring error from validator, because we are using custom validation.", error);
        }
        this.submitted = true;
        this.setState({ validation: validation });

        const allValidated = this.validateCouponCode(this.state.couponName)
            & this.validateDescription(this.state.description)
            & this.validateCouponType(this.state.discountType)
            & this.validateCouponValue(this.state.couponAmount)
            & this.validateCouponExpiryDate(this.state.couponExpiryDate)
            & this.validateUsageRestrictions(this.state)
            & this.validateUsageLimits(this.state)
            & this.validateCouponApplicability();

        if (!allValidated) {
            this.props.showNotificationMessage({
                notificationMessage: "There are some invalid form fields!",
                successMessage: false,
                showNotification: true,
                isLoading: false,
            });
            return;
        }

        const validPackageTypes = this.validatePackageTypes();
        if (!validPackageTypes) {
            this.showErrorNotification("At least one package or category needs to be selected!");
            return;
        }
        
        if (allValidated) {
            var apiOptions = {};
            if (editId !== null) {
                // let formData = this.createFormData(editId);
                const formData = this.createCouponData(editId);
                apiOptions = {
                    method: "patch",
                    url: "coupon-code/" + editId + "/",
                    data: formData,
                    headers: {
                        accept: "application/json",
                        "Accept-Language": "en-US,en;q=0.8",
                        // "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
                    }
                }
            }
            else {
                // let formData = this.createFormData(editId);
                let formData = this.createCouponData(editId);
                delete formData.id;
                apiOptions = {
                    method: "post",
                    url: "coupon-code/",
                    data: formData,
                    headers: {
                        accept: "application/json",
                        "Accept-Language": "en-US,en;q=0.8",
                        // "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
                    }
                }
            }
            const notificationMessage = this.state.newCoupon ? "Coupon Created Successfully" : "Coupon Updated Successfully";
            PhoenixAPI.request(apiOptions)
                .then(response => {
                    this.props.showNotificationMessage({
                        notificationMessage,
                        successMessage: true,
                        showNotification: true,
                        isLoading: false,
                    });
                    this.hideModal();
                    this.getCouponCodes();

                })
                .catch(error => {
                    console.log("Error on saving coupon: ", error);
                    this.props.toggleLoading({ isLoading: false });
                    this.props.showNotificationMessage({
                        notificationMessage: "Something went wrong. Please try again later",
                        successMessage: false,
                        showNotification: true
                    });
                    if (error.response && error.response.data) {
                        const validationErrors = { ...this.state.validationErrors };
                        const { coupon_code: couponCodeErrors } = error.response.data;
                        if (couponCodeErrors)
                            validationErrors.couponCode = couponCodeErrors.join(", ");

                        this.setState({ validationErrors });
                    }
                })
        }
    }

    handleDeleteData(id) {
        this.hideModal();

        API.delete("coupon-code/" + id + "/")
            .then(response => {
                this.getCouponCodes();
                this.props.showNotificationMessage({
                    notificationMessage: "Coupon Deleted Successfully.",
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
            })
            .catch((error) => {
                console.log("Error on deleting coupon code", error);
            })
    }

    async handleEditData(c) {
        
        try {
            this.showLoading();
           
            this.setState({
                pageIdForLogs : c.id
            }) 


            let { data: coupon } = await PhoenixAPI.get(`${BASE_URL_COUPONS}${c.id}/`);
            if (!coupon) {
                console.log("handleEditData:: Coupon not found with id=", c.id);
                return;
            }
          
            coupon = this.fromNewToOldCouponStructure(coupon);
            
            let amt = "";
            let package1 = [];
            let custom_package = [];
            let addon = [];
            let val1 = [];
            let val2 = [];
            let val3 = [];
            // This line was not showing the correct date sometimes (like in night or evening). don't know why
            // let date = new Date(coupon.coupon_expiry).toISOString().substring(0, 10);
            let date = moment(coupon.coupon_expiry).format("YYYY-MM-DD");
            if (coupon.coupon_amount.includes("%")) {
                amt = coupon.coupon_amount.substring(0, coupon.coupon_amount.length - 1);
            }
            if (coupon.coupon_amount.includes("AED")) {
                amt = coupon.coupon_amount.substring(0, coupon.coupon_amount.length - 3).trim();
            } else {
                amt = coupon.coupon_amount;
            }
            package1 = this.state.data1.filter(ele => coupon.packages.find(val => ele.id === val));
            custom_package = this.state.data3.filter(ele => coupon.custom_package.find(val => ele.id === val));
            addon = this.state.data2.filter(ele => coupon.addons.find(val => ele.id === val));
            for (let i = 0; i < package1.length; i++) {
                // val1.push({ "label": package1[i].subscription_name, "value": package1[i].id });
                val1.push({ "label": package1[i].internal_name, "value": package1[i].id });
            }
            for (let i = 0; i < addon.length; i++) {
                val2.push({ "label": addon[i].addon_name, "value": addon[i].id });
            }
            for (let i = 0; i < custom_package.length; i++) {
                val3.push({ "label": custom_package[i].name, "value": custom_package[i].id });
            }
            this.showModal();
            
            this.setState({
                newCoupon: false,
                editId: coupon.id,
                couponName: coupon.coupon_code,
                description: coupon.description,
                discountType: coupon.discount_type,
                couponAmount: amt,
                couponExpiryDate: date,
                minSpend: coupon.min_amt_spend,
                maxSpend: coupon.max_amt_spend,
                welcomeCoupon: coupon.is_welcome,
                isActive: coupon.is_active,
                packs: val1,
                custom_packs: val3,
                addon: val2,
                usageLimit: coupon.coupon_usage_limit,
                userLimit: coupon.user_usage_limit,
                countryId: coupon.country,
                couponApplicability: coupon.couponApplicability,
                couponUserApplicability: coupon.couponUserApplicability,
                bundles: coupon.bundles ?? [],
                medicines: coupon.medicines ?? [],
                supplements: coupon.supplements ?? [],
                miniPackages: coupon.miniPackages ?? [],
                couponCategory: coupon.couponCategory ?? null,
                maxDiscountAllowed: coupon.maxDiscountAllowed ?? null,
                isB2BCoupon:coupon.isB2BCoupon??false,
                defaultCoachId:coupon.defaultCoachId??null,
                defaultSecondaryCoachId:coupon.defaultSecondaryCoachId??null,
                isPromotionalCoupon:coupon.isPromotionalCoupon??false,
                coachConsultationPackages: coupon.coachConsultationPackages ??[],
                categoryId:coupon.categoryId??[],
                supplementCategoryId:coupon.supplementCategoryId??[],
                partnerPackageName:"All Packages",
                filterBy:coupon.filterBy ?? "",
                filterByProduct:coupon?.filterBy==="product"??false,
                filterByCategory:coupon?.filterBy==="category"??false

            })
        } catch (error) {
            console.log("handleEditData:: Error on fetching coupon details!", error);
        } finally {
            this.hideLoading();
        }
    }

    formReset() {
        this.submitted = false;
        this.setState({
            couponName: "",
            description: "",
            discountType: "",
            couponAmount: "",
            couponExpiry: "",
            couponExpiryDate: "",
            minSpend: "",
            maxSpend: "",
            welcomeCoupon: false,
            isActive: false,
            usageLimit: "",
            userLimit: "",
            editId: null,
            packs: [],
            custom_packs: [],
            addon: [],
            bundles: [],
            supplements: [],
            medicines: [],
            miniPackages: [],
            countryId: null,
            validation: this.validator.valid(),
            couponApplicability: null,
            couponUserApplicability: null,
            couponCategory: "DISCOUNT",
            maxDiscountAllowed: null,
            isB2BCoupon:false,
            defaultCoachId:null,
            defaultSecondaryCoachId:null,
            isPromotionalCoupon:false
        });
    }

    generateCouponCode(e) {
        e.preventDefault();
        PhoenixAPI.get("get-coupon-code/")
            .then(response => {
                const coupon = response.data["couponCode"];
                this.setState({ couponName: coupon });
            })
            .catch(error => {
                console.log("Error on generating coupon code", error);
            });
    }

    /*
    Old API to generate coupon code.
    generateCouponCode(e) {
        e.preventDefault();
        API.get("get-coupon-code/")
            .then(response => {
                let coupon = response.data["Coupon Code"];
                this.setState({
                    couponName: coupon
                })
            })
            .catch(error => {
                console.log("Error on generating coupon code", error);
            });
    }
    */

    handleSearch = (queryString) => {
        this.setState({
            searchApplied: true,
            searchTerm: queryString ? queryString : ""
        });
    };

    handleSearchClear = () => {
        this.setState({
            searchTerm: "",
            // searchExpanded: true,
            searchApplied: false,
        });
        this.search.searchInput.focus();
    };

    handleSearchExit = (e) => {
        const value = e.target.value;
        if (!value || !value.length)
            this.setState({
                searchTerm: "",
                // searchExpanded: false,
                searchApplied: false
            });
        else
            this.setState({ searchExpanded: true });
    };

    makeEditable = (key) => this.setState({ [key]: true });

    getCountries = async () => {
        try {
            const { data: countries } = await API.get("countries/");
            const selectedCountry = countries && countries.find(country => country.is_default);
            this.setState({ countries, selectedCountry });
            
        } catch (e) {
            console.log("Error fetching countries from backend.", e)
        }
    }

    handleCountrySelection = (selectedCountry) => this.setState({ selectedCountry });

    getFilteredCoupons = () => {
        const {
            data: allCoupons,
            selectedCountry,
            searchApplied,
            searchTerm,
        } = this.state;

        let filteredCoupons = allCoupons;
        if (selectedCountry && selectedCountry.id)
            filteredCoupons = filteredCoupons.filter(coupon => coupon.country ? coupon.country === selectedCountry.id : false);
        if (searchApplied)
            filteredCoupons = this.filterCouponsByCode(filteredCoupons, searchTerm);

        return filteredCoupons;
    }

    filterCouponsByCode = (coupons, codeToSearch) => coupons
        .filter((coupon) => coupon.coupon_code ?
            coupon.coupon_code.toLowerCase().startsWith(codeToSearch.toLowerCase()) :
            false
        );
    
    findCountryName = (countryId) => {
        const { countries } = this.state;
        const country = (countries && countryId) ? countries.find(country => country.id === countryId) : null;
        return country ? country.country_name : null;
    }

    
    handleAllActive = (packageType) => {
        const { selectedCountry,exclusionCouponBloodPackages,exclusionCouponCustomPackages,exclusionCouponHealthPackages,exclusionCouponBundles,exclusionCouponMedicines,exclusionCouponSupplements, exclusionCoachConsultationPackages } = this.state;
        if (packageType === "bloodbiomarker") {
            const { data1: allBloodBiomarkers = [] } = this.state;
            console.log("line no bloodbiomarkers:", allBloodBiomarkers)
            const bloodbiomarkerIds = allBloodBiomarkers
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.is_active === true && !it.is_coupon_excluded  )
                .filter(it => !exclusionCouponBloodPackages.includes(it.id))
                // .map(it => ({ value: it.id, label: it.subscription_name }))
                .map(it => ({ value: it.id, label: it.internal_name }))

            this.setState({ packs: bloodbiomarkerIds })
        }
        else if (packageType === "addons") {
            const { data2: allAddons = [] } = this.state;
            const addonIds = allAddons
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.is_active === true && !it.is_coupon_excluded)
                .filter(it => !exclusionCouponHealthPackages.includes(it.id))
                .map(it => ({ value: it.id, label: it.addon_name }))
            this.setState({ addon: addonIds })
        }
        else if (packageType === "custom") {
            const { data3: allCustomPackages = [] } = this.state;
            const customPackageIds = allCustomPackages
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.status === "Active" && !it.is_coupon_excluded)
                .filter(it => !exclusionCouponCustomPackages.includes(it.id))
                .map(it => ({ value: it.id, label: it.name }));
            this.setState({ custom_packs: customPackageIds });
        }
        else if (packageType === "bundles") {
            const allBundles = this.state.all_bundles ?? [];
            const allActiveBundleIds = allBundles
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.status === "Active" && !it.isCouponExcluded)
                .filter(it => !exclusionCouponBundles.includes(it.id))
                .map(it => it.id);
            this.setState({ bundles: allActiveBundleIds });
        }
        else if (packageType === "medicines") {
            const allMedicines = this.state.all_medicines ?? [];
            const allActiveMedicineIds = allMedicines
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.status === "Active" && !it.isCouponExcluded)
                .filter(it => !exclusionCouponMedicines.includes(it.id))
                .map(it => it.id);
            this.setState({ medicines: allActiveMedicineIds });
        }
        else if (packageType === "supplements") {
            const allSupplements = this.state.all_supplements ?? [];
            const allActiveSupplementIds = allSupplements
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.status === "Active" && !it.isCouponExcluded)
                .filter(it => !exclusionCouponSupplements.includes(it.id))
                .map(it => it.id);
            this.setState({ supplements: allActiveSupplementIds });
        } else if (packageType === "miniPackages") {
            const allMiniPackages = this.state.all_mini_packages ?? [];
            const allActiveMiniPackageIds = allMiniPackages
                .filter(it => it.isActive && it.countryId === selectedCountry?.id)
                .map(it => it.id);
            this.setState({ miniPackages: allActiveMiniPackageIds });
        }
        else if(packageType==="coachConsultationPackages"){
            const coachConsultationPackages= this.state.allCoachConsultationsPackages??[]
            const coachConsultationPackageIds = coachConsultationPackages
            .filter(it => it.isActive && it.countryId === selectedCountry?.id)
            .filter(it => !exclusionCoachConsultationPackages.includes(it.id))
            .map(it => it.id);
        this.setState({ coachConsultationPackages: coachConsultationPackageIds });
        }
        else if (packageType === "productCategories") {
            const allProductCategories = this.state.allProductCategories ?? [];

            const allProductCategoryIds = allProductCategories.filter(it => it.country === selectedCountry?.id)
                .filter((it) => it.status === "Active")
                .map(it => it.id);

            this.setState({ categoryId: allProductCategoryIds })
        }
        else if (packageType === "supplementCategories") {
            const allSupplementCategories = this.state.allSupplementCategories ?? [];
            const allSupplementCategoryIds = allSupplementCategories.filter(it => it.country === selectedCountry?.id)
                .filter((it) => it.status === "Active")
                .map(it => it.id);

            this.setState({ supplementCategoryId: allSupplementCategoryIds })
        }
    }
    
    showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);

    showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);

    showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
            notificationMessage,
            successMessage: isSuccessMessage,
            showNotification: true,
        });

    showLoading = () => this.props.toggleLoading({ isLoading: true });

    hideLoading = () => this.props.toggleLoading({ isLoading: false });

    renderViewButton = (coupon) => (
        <div>
            <button onClick={ () => this.handleEditData(coupon) } className="btn px-5" style={{ backgroundColor: "#CEE741" }}>
                View
            </button>
        </div>
    );

    renderPencilIcon = (fieldName) =>
        this.state.isEditable && !this.state.isNewWebsiteCategory ? (
            <div
                onClick={() => this.setEditable(fieldName)}
                style={{ cursor: "pointer" }}
                className="input-group-append"
            >
                <i className="input-group-text fa fa-pencil"></i>
            </div>
        ) : (
            ""
        );

    setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
    };

    createCouponData = (editId) => {
        let bloodPackages = [];
        let addons = [];
        let customPackages = [];
        let allCitiesMiniPackages=[];

        for (let i = 0; i < this.state.packs.length; i++)
            bloodPackages.push(this.state.packs[i].value)

        for (let i = 0; i < this.state.addon.length; i++)
            addons.push(this.state.addon[i].value)

        for (let i = 0; i < this.state.custom_packs.length; i++)
            customPackages.push(this.state.custom_packs[i].value)

            console.log("create coupon",this.state.miniPackages);
            if(this.state.miniPackages !== undefined && this.state.miniPackages !== null && this.state.miniPackages.length>0){
           const miniPackagename= this.state.all_mini_packages.filter((mini)=>this.state.miniPackages.includes(mini.id)).map((minipackage)=>minipackage.name);
            allCitiesMiniPackages= this.state.all_mini_packages.filter((mini)=>miniPackagename.includes(mini.name)).map((minipackage)=>minipackage.id);

           
            console.log("all minipackages",allCitiesMiniPackages);}
        return {
            "id": this.state.editId,
            "couponCode": this.state.couponName,
            "description": this.state.description,
            "discountType": this.state.discountType,
            "couponAmount": Number(this.state.couponAmount),
            "couponApplicability": this.state.couponApplicability,
            "couponUserApplicability": this.state.couponUserApplicability === "NONE" 
                ? null 
                : this.state.couponUserApplicability ?? null,
            "couponExpiryDate": new Date(this.state.couponExpiryDate).toISOString(),
            "minSpend": this.state.minSpend,
            "maxSpend": this.state.maxSpend,
            "welcomeCoupon": this.state.welcomeCoupon,
            "status": this.state.isActive,
            "couponUsageLimit": this.state.usageLimit,
            "couponUserLimit": this.state.userLimit,
            "country": editId ? this.state.countryId : this.state.selectedCountry.id,
            "bloodPackages": bloodPackages,
            "coachPackages": addons,
            "customPackages": customPackages,
            "bundles": this.state.bundles ?? [],
            "supplements": this.state.supplements ?? [],
            "medicines": this.state.medicines ?? [],
            "miniPackages": allCitiesMiniPackages ?? [],
            "couponCategory": this.state.couponCategory,
            "maxDiscountAllowed": this.state.maxDiscountAllowed ? Number(this.state.maxDiscountAllowed) : null,
            "isB2BCoupon":this.state.isB2BCoupon,
            "defaultCoachId":this.state.defaultCoachId,
            "defaultSecondaryCoachId":this.state.defaultSecondaryCoachId,
             "isPromotionalCoupon": this.state.isPromotionalCoupon,
             "coachConsultationPackages":this.state.coachConsultationPackages,
             "categoryId":this.state.categoryId,
             "supplementCategoryId":this.state.supplementCategoryId,
             "filterBy":this.state.filterBy??"",

        };
    }
    handlePartnerPackageOptions = (value) => {
        let { all_supplements, dewa_supplements, tamara_supplements, non_partner_supplements,
            allCoachConsultationsPackages, dewaCoachConsultationPackages, tamaraCoachConsultationPackages, nonPartnerCoachConsulationPackages,
            data1, dewaBloodPackages, tamaraBloodPackages, nonPartnerBloodPackages,
            data3, dewaCustomPackages, tamaraCustomPackages, nonPartnerCustomPackages,
            allBloodPackages,allCustomPackages
        } = this.state;
        let partnerPackageName = ""
        if (value === "All_Packages") {
            partnerPackageName = "All Packages"
            all_supplements = all_supplements ?? [];
            data1 = allBloodPackages?? [];
            data3 = allCustomPackages?? [];
            allCoachConsultationsPackages=allCoachConsultationsPackages??[]
        }
        else if (value === "Excluded_Partner_Packages") {
            partnerPackageName = "Excluded Partner Packages";
            all_supplements = non_partner_supplements ?? [];
            allCoachConsultationsPackages = nonPartnerCoachConsulationPackages ?? [];
            data1 = nonPartnerBloodPackages ?? [];
            data3 = nonPartnerCustomPackages ?? []
        }
        else {
            partnerPackageName = value;
            if (value == "Dewa") {
                all_supplements = dewa_supplements;
                allCoachConsultationsPackages = dewaCoachConsultationPackages ?? [];
                data1 = dewaBloodPackages ?? [];
                data3 = dewaCustomPackages ?? []
            }
            else if (value == "Tamara") {
                all_supplements = tamara_supplements ?? [];
                allCoachConsultationsPackages = tamaraCoachConsultationPackages ?? [];
                data1 = tamaraBloodPackages ?? [];
                data3 = tamaraCustomPackages ?? []
            }
        }
        this.setState({ partnerPackageName, all_supplements, data1, data3, allCoachConsultationsPackages },()=>this.createPackagesOptions());

    }
    createPartnerPackageOptions = (partnerPackage) => {
        return {
            key: partnerPackage.partnerPackageName,
            value: partnerPackage.partnerPackageName,
            label: partnerPackage.partnerPackageName,
        }
    }
    render() {
        const { countries, selectedCountry, newCoupon, isEditable,filterByProduct, filterByCategory } = this.state;

        let validation = {};
        try {
            validation = this.submitted
                ? this.validator.validate(this.state)
                : this.state.validation;
        } catch (error) {
            console.log("Ignoring error from validator, because we are using custom validation.", error);
        }

        const filteredCoupons = this.getFilteredCoupons();

        const selectStyles = {
            container: base => ({
                ...base,
                flex: 1,
            }),
        };

        const detailsForm =
            <div className="container mt-4">
                <div className="row">
                    <div className="form-group col">
                        <label>Coupon Code*</label>
                        <div className="input-group user-input-container">
                            <input
                                type="text"
                                placeholder="Enter Coupon Code"
                                className={
                                    "form-control " +
                                    (this.state.editCouponCode || newCoupon ? "non-editable " : "editable ") +
                                    (this.state.validationErrors.couponCode ? "has-error" : "")
                                }
                                name="couponName"
                                value={this.state.couponName}
                                onChange={this.handleChange}
                                disabled={!newCoupon && !this.state.editCouponCode && this.state.couponCategory === "Cashback"} />
                            {isEditable && !newCoupon && this.state.couponCategory !== "Cashback" && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editCouponCode")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.couponCode}</span>
                    </div>
                    <div className="form-group col">
                        <label></label><br />
                        <button
                            type="button"
                            onClick={this.generateCouponCode}
                            className={
                                "form-control btn btn-info mt-2 py-1" +
                                (this.state.editCouponCode || newCoupon ? "non-editable " : "editable ")
                            }
                            disabled={
                                isEditable && (this.state.editCouponCode || newCoupon || this.state.couponCategory !== "Cashback") ? false : true
                            }
                        >
                            Generate Coupon
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label>Description (Optional)</label>
                        <div className="input-group user-input-container">
                            <textarea
                                type="text"
                                placeholder="Enter Description"
                                className={
                                    "form-control " +
                                    (this.state.editDescription || newCoupon ? "non-editable " : "editable ") +
                                    (this.state.validationErrors.description ? "has-error" : "")
                                }
                                name="description"
                                value={this.state.description}
                                onChange={this.handleChange}
                                disabled={!newCoupon && !this.state.editDescription && this.state.couponCategory === "Cashback"}
                                 />
                            {isEditable && !newCoupon && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editDescription")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.description}</span>
                    </div>
                    <div className="form-group col">
                        <label>Status</label><br />
                        <div className="input-group user-input-container">
                            <Dropdown
                                value={this.state.isActive}
                                onChange={(event, value) => {
                                    this.setState({ isActive: value.value });
                                }}
                                id="isActive"
                                name="isActive"
                                placeholder="Status"
                                search
                                selection
                                options={STATUS_OPTIONS}
                                disabled={this.state.editStatus || newCoupon ? false : true}
                                className={"form-control " + (this.state.editStatus || newCoupon ? "non-editable " : "editable ")}
                            />
                            {isEditable && !newCoupon && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editStatus")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-6">
                        <label>Coupon Category*</label>
                        <div className="input-group user-input-container">
                            <Dropdown
                                value={this.state.couponCategory}
                                onChange={(event, value) => {
                                    this.setState({ couponCategory: value.value });
                                }}
                                id="couponCategory"
                                name="couponCategory"
                                placeholder="Coupon Category"
                                search
                                selection
                                options={COUPON_CATEGORY_OPTIONS}
                                disabled={(this.state.editCouponCategory || newCoupon) && this.state.couponApplicability !== "b1g1" ? false : true}
                                className={
                                    "form-control " +
                                    ((this.state.editCouponCategory || newCoupon) && this.state.couponApplicability !== "b1g1" ? "non-editable " : "editable ") +
                                    (this.state.validationErrors.couponCategory ? "has-error" : "")
                                }
                            />
                            {isEditable && !newCoupon && this.state.couponApplicability !== "b1g1" && this.state.couponCategory !== "Cashback" && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editCouponCategory")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.couponCategory}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label>Coupon Type*</label>
                        <div className="input-group user-input-container">
                            <Dropdown
                                value={this.state.discountType}
                                onChange={(event, value) => {
                                    this.setState({ discountType: value.value });
                                }}
                                id="discountType"
                                name="discountType"
                                placeholder="Coupon Type"
                                search
                                selection
                                options={COUPON_TYPE_OPTIONS}
                                disabled={(this.state.editCouponType || newCoupon) && this.state.couponApplicability !== "b1g1" ? false : true}
                                className={
                                    "form-control " + 
                                    ((this.state.editCouponType || newCoupon) && this.state.couponApplicability !== "b1g1" ? "non-editable " : "editable ") +
                                    (this.state.validationErrors.couponType ? "has-error" : "")
                                }
                            />
                            {isEditable && !newCoupon && this.state.couponApplicability !== "b1g1" && this.state.couponCategory !== "Cashback" && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editCouponType")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.couponType}</span>
                    </div>
                    <div className="form-group col">
                        <label>Coupon Value*</label>
                        <div className="input-group user-input-container">
                            <input
                                type="number"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                                }}
                                className={
                                    "form-control " +
                                    ((this.state.editCouponValue || newCoupon) && this.state.couponApplicability !== "b1g1" ? "non-editable " : "editable ") +
                                    (this.state.validationErrors.couponValue ? "has-error" : "")
                                }
                                name="couponAmount"
                                value={this.state.couponAmount ?? ""}
                                min={0}
                                onChange={this.handleChange}
                                // disabled={!newCoupon && !this.state.editCouponValue} 
                                disabled={(this.state.editCouponValue || newCoupon) && this.state.couponApplicability !== "b1g1" ? false : true} />
                            {isEditable && !newCoupon && this.state.couponApplicability !== "b1g1" && this.state.couponCategory !== "Cashback" && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editCouponValue")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.couponValue}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label>Coupon Expiry Date*</label>
                        <div className="input-group user-input-container">
                            <input
                                type="date"
                                min={moment().add(1, "d").format("YYYY-MM-DD")}
                                className={
                                    "form-control " +
                                    (this.state.editCouponExpiryDate || newCoupon ? "non-editable " : "editable ") +
                                    (this.state.validationErrors.couponExpiryDate ? "has-error" : "")
                                }
                                name="couponExpiryDate"
                                value={this.state.couponExpiryDate}
                                onChange={this.handleChange} 
                                disabled={!newCoupon && !this.state.editCouponExpiryDate} />
                            {isEditable && !newCoupon && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editCouponExpiryDate")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.couponExpiryDate}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="country">Country</label>
                        <input
                            value={
                                newCoupon
                                    ? selectedCountry ? selectedCountry.country_name : ""
                                    : this.findCountryName(this.state.countryId)
                            }
                            id="country"
                            name="country"
                            type="text"
                            readOnly
                            className="form-control" />
                    </div>
                </div>
                <div className="row">
                <div className="form-group col">
                <div className="input-group">
              <input
                value={true}
                checked={this.state.isB2BCoupon}
                onChange={(event, value) => {
                  this.setState({
                    isB2BCoupon:event.target.checked}
                  );
                }}
                id="isB2BCoupon"
                name="isB2BCoupon"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; is this a B2B Coupon?
               </div> 

                    </div>
                </div>
            <div className="row">
            <div className="form-group col">
            <label>Default Primary Coach</label>
            <div className='input-group user-input-container'>
            <Dropdown
                value={this.state.defaultCoachId ||null}
                onChange={(event, value) => {
                  this.setState({ defaultCoachId: value.value });
                }}
                id={`defaultCoach`}
                placeholder="None"
                search
                selection
                options={this.state.wellbeingCoaches}
                disabled={!newCoupon && !this.state.editDefaultCoach}
                className={
                  "form-control " + (this.state.editDefaultCoach || newCoupon ? "non-editable " : "editable ") 
                
                }
              />
              {isEditable && !newCoupon && (
                  <div className="input-group-append" onClick={() => this.makeEditable("editDefaultCoach")}>
                      <i className="input-group-text fa fa-pencil"></i>
                  </div>
              )}
            </div>
          </div>
            <div className="form-group col">
            <label>Default Secondary Coach</label>
            <div className='input-group user-input-container'>
            <Dropdown
                value={this.state.defaultSecondaryCoachId ||null}
                onChange={(event, value) => {
                  this.setState({ defaultSecondaryCoachId: value.value });
                }}
                id={`defaultSecondaryCoachId`}
                placeholder="None"
                search
                selection
                options={this.state.externalCoaches}
              className={
                "form-control " +
                (this.state.editdefaultSecondary || newCoupon ? "non-editable " : "editable ") 
                
                }
            disabled={!newCoupon && !this.state.editdefaultSecondary}
            />
            {isEditable && !newCoupon && (
                  <div className="input-group-append" onClick={() => this.makeEditable("editdefaultSecondary")}>
                      <i className="input-group-text fa fa-pencil"></i>
                  </div>
              )}
             </div> 
             </div>
            </div>
           
            <div className="row">
                <div className="form-group col">
                <div className="input-group">
              <input
                value={true}
                checked={this.state.isPromotionalCoupon}
                onChange={(event, value) => {
                  this.setState({
                    isPromotionalCoupon:event.target.checked}
                  );
                }}
                id="isPromotionalCoupon"
                name="isPromotionalCoupon"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; is this a Promotional Coupon?
               </div> 

                    </div>
                </div>
            
                {(!newCoupon && this.state.welcomeCoupon) &&
                    <div className="row mt-4">
                        <div className="form-group col">
                            <div className="custom-control custom-checkbox">
                                <input
                                    value={this.state.welcomeCoupon}
                                    // onChange={this.handleChange}
                                    id="welcomeCoupon"
                                    name="welcomeCoupon"
                                    type="checkbox"
                                    checked={this.state.welcomeCoupon}
                                    disabled={true}
                                    className="custom-control-input ml-4" />
                                <label className="custom-control-label">Welcome Coupon</label>
                            </div>
                        </div>

                        <div className="form-group col">
                            <p>
                                This is the current welcome coupon that is sent with all welcome emails, per country.
                                To change this reach out to backend team.
                            </p>
                        </div>
                    </div>
                }
             

            </div>;
        const filterByProductForm = (
          <div>
            <div className="row">
              <div className="form-group col">
                <label>Blood Biomarker Packages</label>
                <div className="input-group user-input-container">
                  <Select
                    isMulti
                    name="packages"
                    className={
                      (this.state.editPackages || newCoupon
                        ? "non-editable "
                        : "editable ") +
                      (validation?.packages?.isInvalid && "has-error")
                    }
                    isDisabled={
                      this.state.editPackages || newCoupon ? false : true
                    }
                    value={this.state.packs}
                    onChange={this.handlePackageChange}
                    options={this.state.packages}
                    styles={selectStyles}
                  />
                  <span className="help-block">
                    {validation?.packages?.message}
                  </span>
                  {isEditable &&
                    !newCoupon &&
                    this.state.couponCategory !== "Cashback" && (
                      <div
                        className="input-group-append"
                        onClick={() => this.makeEditable("editPackages")}
                      >
                        <i className="input-group-text fa fa-pencil"></i>
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group col">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={(e) => this.handleAllActive("bloodbiomarker")}
                  className={"form-control btn btn-info mt-2 py-1"}
                  disabled={
                    !isEditable ||
                    (!newCoupon && this.state.couponCategory === "Cashback")
                  }
                >
                  Add All Active Blood Biomarker Packages
                </button>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label>Custom Packages</label>
                <div className="input-group user-input-container">
                  <Select
                    isMulti
                    name="custom_package"
                    className={
                      (this.state.editCustomPackages || newCoupon
                        ? "non-editable "
                        : "editable ") +
                      (validation?.custom_package?.isInvalid && "has-error")
                    }
                    isDisabled={
                      this.state.editCustomPackages || newCoupon ? false : true
                    }
                    value={this.state.custom_packs}
                    onChange={this.handleCustomPackageChange}
                    options={this.state.custom_package}
                    styles={selectStyles}
                  />
                  <span className="help-block">
                    {validation?.packages?.message}
                  </span>
                  {isEditable &&
                    !newCoupon &&
                    this.state.couponCategory !== "Cashback" && (
                      <div
                        className="input-group-append"
                        onClick={() => this.makeEditable("editCustomPackages")}
                      >
                        <i className="input-group-text fa fa-pencil"></i>
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group col">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={(e) => this.handleAllActive("custom")}
                  className={"form-control btn btn-info mt-2 py-1"}
                  disabled={
                    !isEditable ||
                    (!newCoupon && this.state.couponCategory === "Cashback")
                  }
                >
                  Add All Active custom Packages
                </button>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label>Health Coach Packages</label>
                <div className="input-group user-input-container">
                  <Select
                    isMulti
                    name="addons"
                    className={
                      this.state.editAddons || newCoupon
                        ? "non-editable "
                        : "editable "
                      // + (validation.addons.isInvalid && "has-error")
                    }
                    isDisabled={
                      this.state.editAddons || newCoupon ? false : true
                    }
                    value={this.state.addon}
                    onChange={this.handleAddonChange}
                    options={this.state.addons}
                    styles={selectStyles}
                  />
                  {/* <span className="help-block">{validation.addons.message}</span> */}
                  {isEditable &&
                    !newCoupon &&
                    this.state.couponCategory !== "Cashback" && (
                      <div
                        className="input-group-append"
                        onClick={() => this.makeEditable("editAddons")}
                      >
                        <i className="input-group-text fa fa-pencil"></i>
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group col">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={(e) => this.handleAllActive("addons")}
                  className={"form-control btn btn-info mt-2 py-1"}
                  disabled={
                    !isEditable ||
                    (!newCoupon && this.state.couponCategory === "Cashback")
                  }
                >
                  Add All Active Health Coach Packages
                </button>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="bundles">Bundles</label>
                <div className="input-group user-input-container">
                  <SelectBundles
                    countryId={selectedCountry?.id}
                    selectedBundleIds={this.state.bundles ?? []}
                    onBundleSelect={(options) =>
                      this.setState({ bundles: options.map((it) => it.value) })
                    }
                    isDisabled={
                      this.state.editBundles || newCoupon ? false : true
                    }
                    id="bundles"
                    name="bundles"
                    className={
                      this.state.editBundles || newCoupon
                        ? "non-editable "
                        : "editable "
                    }
                  />
                  {isEditable &&
                    !newCoupon &&
                    this.state.couponCategory !== "Cashback" && (
                      <div
                        className="input-group-append"
                        onClick={() => this.makeEditable("editBundles")}
                      >
                        <i className="input-group-text fa fa-pencil"></i>
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group col">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={(e) => this.handleAllActive("bundles")}
                  className={"form-control btn btn-info mt-2 py-1"}
                  disabled={
                    !isEditable ||
                    (!newCoupon && this.state.couponCategory === "Cashback")
                  }
                >
                  Add All Active Bundles
                </button>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="supplements">Supplements</label>
                <div className="input-group user-input-container">
                  <SelectSupplements
                    countryId={selectedCountry?.id}
                    selectedSupplementIds={this.state.supplements ?? []}
                    onSupplementSelect={(options) =>
                      this.setState({
                        supplements: options.map((it) => it.value),
                      })
                    }
                    isDisabled={
                      this.state.editSupplements || newCoupon ? false : true
                    }
                    id="supplements"
                    name="supplements"
                    className={
                      this.state.editSupplements || newCoupon
                        ? "non-editable "
                        : "editable "
                    }
                  />
                  {isEditable &&
                    !newCoupon &&
                    this.state.couponCategory !== "Cashback" && (
                      <div
                        className="input-group-append"
                        onClick={() => this.makeEditable("editSupplements")}
                      >
                        <i className="input-group-text fa fa-pencil"></i>
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group col">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={(e) => this.handleAllActive("supplements")}
                  className={"form-control btn btn-info mt-2 py-1"}
                  disabled={
                    !isEditable ||
                    (!newCoupon && this.state.couponCategory === "Cashback")
                  }
                >
                  Add All Active Supplements
                </button>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="medicines">Medicines</label>
                <div className="input-group user-input-container">
                  <SelectMedicines
                    countryId={selectedCountry?.id}
                    selectedMedicineIds={this.state.medicines ?? []}
                    onMedicineSelect={(options) =>
                      this.setState({
                        medicines: options.map((it) => it.value),
                      })
                    }
                    isDisabled={
                      this.state.editMedicines || newCoupon ? false : true
                    }
                    id="medicines"
                    name="medicines"
                    className={
                      this.state.editMedicines || newCoupon
                        ? "non-editable "
                        : "editable "
                    }
                  />
                  {isEditable &&
                    !newCoupon &&
                    this.state.couponCategory !== "Cashback" && (
                      <div
                        className="input-group-append"
                        onClick={() => this.makeEditable("editMedicines")}
                      >
                        <i className="input-group-text fa fa-pencil"></i>
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group col">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={(e) => this.handleAllActive("medicines")}
                  className={"form-control btn btn-info mt-2 py-1"}
                  disabled={
                    !isEditable ||
                    (!newCoupon && this.state.couponCategory === "Cashback")
                  }
                >
                  Add All Active Medicines
                </button>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="coachConsultationPackages">
                  Coach Consultation Packages
                </label>
                <div className="input-group user-input-container">
                  <SelectCoachConsultationPackages
                    countryId={selectedCountry?.id}
                    selectedCoachConsultationIds={
                      this.state.coachConsultationPackages ?? []
                    }
                    onCoachConsultationSelect={(options) =>
                      this.setState({
                        coachConsultationPackages: options.map(
                          (it) => it.value
                        ),
                      })
                    }
                    isDisabled={
                      this.state.editCoachConsultationPackages || newCoupon
                        ? false
                        : true
                    }
                    id="coachConsultationPackages"
                    name="coachConsultationPackages"
                    className={
                      this.state.editCoachConsultationPackages || newCoupon
                        ? "non-editable "
                        : "editable "
                    }
                    couponCodePage={true}
                  />
                  {isEditable &&
                    !newCoupon &&
                    this.state.couponCategory !== "Cashback" && (
                      <div
                        className="input-group-append"
                        onClick={() =>
                          this.makeEditable("editCoachConsultationPackages")
                        }
                      >
                        <i className="input-group-text fa fa-pencil"></i>
                      </div>
                    )}
                </div>
              </div>
              <div className="form-group col">
                <label></label>
                <br />
                <button
                  type="button"
                  onClick={(e) =>
                    this.handleAllActive("coachConsultationPackages")
                  }
                  className={"form-control btn btn-info mt-2 py-1"}
                  disabled={
                    !isEditable ||
                    (!newCoupon && this.state.couponCategory === "Cashback")
                  }
                >
                  Add All Active Coach Consultation Packages
                </button>
              </div>
            </div>
            <div className="row">  
                    <div className="form-group col">
                        <label htmlFor="miniPackages">Mini Packages</label>
                        <div className="input-group user-input-container">
                            <SelectMiniPackages
                                miniPackages={this.state.all_mini_packages?.filter(it => it.countryId === selectedCountry?.id) ?? []}
                                selectedMiniPackageIds={this.state.miniPackages ?? []}
                                onSelect={(options) => this.setState({ miniPackages: options.map(it => it.value) })}
                                isDisabled={this.state.editMiniPackages || newCoupon ? false : true}
                                id="miniPackages"
                                name="miniPackages"
                                className={
                                    (this.state.editMiniPackages || newCoupon ? "non-editable " : "editable ")
                                }
                            />
                            {isEditable && !newCoupon && this.state.couponCategory !== "Cashback" && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editMiniPackages")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="form-group col">
                        <label></label><br />
                        <button
                            type="button"
                            onClick={(e) => this.handleAllActive("miniPackages")}
                            className={
                                "form-control btn btn-info mt-2 py-1"
                            }
                            disabled={!isEditable || (!newCoupon && (this.state.couponCategory === "Cashback"))}
                        >
                            Add All Active Mini Packages
                        </button>
                    </div>
                </div>
          </div>
        );
        const filterByCategoryForm = (
            <div>
                <div className="row">
                <div className="form-group col">
                        <label htmlFor="productCatgeories">
                          Product Categories
                        </label>
                        <div className="input-group user-input-container">
                            <SelectProductCategory
                                countryId={selectedCountry?.id}
                                selectedProductCategoryId={
                                    this.state.categoryId ?? []
                                }
                                onSelect={(options) =>
                                   
                                    this.setState({
                                        categoryId: options.map(
                                            (it) => it.value
                                        ),
                                    })
                                }
                                isDisabled={
                                    this.state.editProductCategories || newCoupon
                                        ? false
                                        : true
                                }
                                id="productCategories"
                                name="productCategories"
                                className={
                                    this.state.editProductCategories || newCoupon
                                        ? "non-editable "
                                        : "editable "
                                }
                            
                            />
                            {isEditable &&
                                !newCoupon &&
                                this.state.couponCategory !== "Cashback" && (
                                    <div
                                        className="input-group-append"
                                        onClick={() =>
                                            this.makeEditable("editProductCategories")
                                        }
                                    >
                                        <i className="input-group-text fa fa-pencil"></i>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="form-group col">
                        <label></label>
                        <br />
                        <button
                            type="button"
                            onClick={(e) =>
                                this.handleAllActive("productCategories")
                            }
                            className={"form-control btn btn-info mt-2 py-1"}
                            disabled={
                                !isEditable ||
                                (!newCoupon && this.state.couponCategory === "Cashback")
                            }
                        >
                            Add All Product Categories
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="productCatgeories">
                          Supplement Categories
                        </label>
                        <div className="input-group user-input-container">
                            <SelectSupplementCategories
                                countryId={selectedCountry?.id}
                                selectedSupplementCategoryIds={
                                    this.state.supplementCategoryId ?? []
                                }
                                onSelect={(options) =>
                                    this.setState({
                                        supplementCategoryId: options.map(
                                            (it) => it.value
                                        ),
                                    })
                                }
                                isDisabled={
                                    this.state.editSupplementCategories || newCoupon
                                        ? false
                                        : true
                                }
                                id="supplementCategories"
                                name="supplementCategories"
                                className={
                                    this.state.editSupplementCategories || newCoupon
                                        ? "non-editable "
                                        : "editable "
                                }
                            
                            />
                            {isEditable &&
                                !newCoupon &&
                                this.state.couponCategory !== "Cashback" && (
                                    <div
                                        className="input-group-append"
                                        onClick={() =>
                                            this.makeEditable("editSupplementCategories")
                                        }
                                    >
                                        <i className="input-group-text fa fa-pencil"></i>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="form-group col">
                        <label></label>
                        <br />
                        <button
                            type="button"
                            onClick={(e) =>
                                this.handleAllActive("supplementCategories")
                            }
                            className={"form-control btn btn-info mt-2 py-1"}
                            disabled={
                                !isEditable ||
                                (!newCoupon && this.state.couponCategory === "Cashback")
                            }
                        >
                            Add All Supplement Categories
                        </button>
                    </div>
                </div>
            </div>
        )
        const usageRestrictionsForm =
            <div className="container mt-4">
                <div className="row">
                    <div className="form-group col">
                        {/* <label>Min. Spend*</label> */}
                        <label>{`Min. Spend${this.state.discountType !== "Absolute" ? "*" : ""}`}</label>
                        <div className="input-group user-input-container">
                            <input
                                type="number"
                                placeholder="Enter Minimum Spend amount"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                                }}
                                className={
                                    "form-control " +
                                    ((this.state.editMinSpend || newCoupon) && (this.state.discountType !== "Absolute") ? "non-editable " : "editable ") +
                                    (this.state.validationErrors.minSpend ? "has-error" : "")
                                }
                                name="minSpend"
                                value={this.state.minSpend ?? ""}
                                onChange={this.handleChange} 
                                disabled={(!newCoupon && !this.state.editMinSpend) || this.state.discountType === "Absolute"} />
                            {isEditable && !newCoupon && this.state.discountType !== "Absolute" && this.state.couponCategory !== "Cashback" && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editMinSpend")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.minSpend}</span>
                    </div>
                    <div className="form-group col">
                        {/* <label>Max. Spend*</label> */}
                        <label>{`Max. Spend${this.state.discountType !== "Absolute" ? "*" : ""}`}</label>
                        <div className="input-group user-input-container">
                            <input
                                type="number"
                                placeholder="Enter Maximum Spend amount"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                                }}
                                className={
                                    "form-control " +
                                    ((this.state.editMaxSpend || newCoupon) && (this.state.discountType !== "Absolute") ? "non-editable " : "editable ") +
                                    (this.state.validationErrors.maxSpend ? "has-error" : "")
                                }
                                name="maxSpend"
                                value={this.state.maxSpend ?? ""}
                                onChange={this.handleChange}
                                disabled={(!newCoupon && !this.state.editMaxSpend) || this.state.discountType === "Absolute"} />
                            {isEditable && !newCoupon && this.state.discountType !== "Absolute" && this.state.couponCategory !== "Cashback" && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editMaxSpend")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.maxSpend}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-6">
                        {/* <label htmlFor="couponApplicability">Coupon Applicability*</label> */}
                        <label htmlFor="couponApplicability">
                            {`Coupon Applicability${this.state.discountType !== "Absolute" ? "*" : ""}`}
                        </label>
                        <div className="input-group user-input-container">
                            <Dropdown
                                value={this.state.couponApplicability}
                                onChange={(event, value) => {
                                    if (value.value === "b1g1") {
                                        const discountType = "Percentage";
                                        const couponAmount = "100";
                                        this.setState({ discountType, couponAmount });
                                    }
                                    
                                    this.setState({ couponApplicability: value.value });
                                }}
                                id="couponApplicability"
                                name="couponApplicability"
                                placeholder="Coupon Applicability"
                                search
                                selection
                                options={COUPON_APPLICABILITY_OPTIONS}
                                // disabled={(this.state.editCouponApplicability || newCoupon) ? false : true}
                                disabled={(!newCoupon && !this.state.editCouponApplicability) || this.state.discountType === "Absolute"}
                                className={"form-control " +     
                                ((this.state.editCouponApplicability || newCoupon) && (this.state.discountType !== "Absolute") ? "non-editable " : "editable ")}
                            />
                            {isEditable && !newCoupon && this.state.discountType !== "Absolute" && this.state.couponCategory !== "Cashback" && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editCouponApplicability")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.couponApplicability}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="maxDiscountAllowed">
                            {`Max. Discount Allowed${this.state.discountType !== "Absolute" ? "*" : ""}`}
                        </label>
                        <div className="input-group user-input-container">
                            <input
                                type="number"
                                placeholder="Enter Maximum Discount Allowed"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                                }}
                                className={
                                    "form-control " +
                                    ((this.state.editMaxDiscountAllowed || newCoupon) && (this.state.discountType !== "Absolute") ? "non-editable " : "editable ") +
                                    (this.state.validationErrors.maxDiscountAllowed ? "has-error" : "")
                                }
                                name="maxDiscountAllowed"
                                value={this.state.maxDiscountAllowed ?? ""}
                                onChange={this.handleChange}
                                disabled={(!newCoupon && !this.state.editMaxDiscountAllowed) || this.state.discountType === "Absolute"} />
                            {isEditable && !newCoupon && this.state.discountType !== "Absolute" && this.state.couponCategory !== "Cashback" && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editMaxDiscountAllowed")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.maxDiscountAllowed}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="couponUserApplicability">Coupon Applicability (User Level)</label>
                        <div className="input-group user-input-container">
                            <Dropdown
                                value={this.state.couponUserApplicability ?? "NONE"}
                                onChange={(event, value) => {
                                    this.setState({ couponUserApplicability: value.value });
                                }}
                                id="couponUserApplicability"
                                name="couponUserApplicability"
                                placeholder="Coupon Applicability"
                                search
                                selection
                                options={COUPON_USER_LEVEL_APPLICABILITY_OPTIONS}
                                disabled={(this.state.editCouponUserApplicability || newCoupon) ? false : true}
                                className={"form-control " +
                                    ((this.state.editCouponUserApplicability || newCoupon) ? "non-editable " : "editable ")}
                            />
                            {isEditable && !newCoupon && (
                                <div className="input-group-append" onClick={() => this.makeEditable("editCouponUserApplicability")}>
                                    <i className="input-group-text fa fa-pencil"></i>
                                </div>
                            )}
                        </div>
                        <span className="help-block">{this.state.validationErrors.editCouponUserApplicability}</span>
                    </div>
                    <div className='form-group col'>
                    <label htmlFor="partnerPackages">
                          Partner Packages
                        </label>
                        <div className='input-group user-input-container'>
                            <Select
                            value={this.createPartnerPackageOptions(this.state)}
                            onChange={((event)=>this.handlePartnerPackageOptions(event.value))}
                            options={PARTNER_PACKAGE_OPTIONS || []}
                            styles={selectStyles}
                            placeholder="Select Category"
                            searchable
                            lazyLoad
                            isDisabled={
                                this.state.editPartnerPackages || newCoupon
                                    ? false
                                    : true
                            }
                            />
                                     {isEditable &&
                                !newCoupon &&
                                this.state.couponCategory !== "Cashback" && (
                                    <div
                                        className="input-group-append"
                                        onClick={() =>
                                            this.makeEditable("editPartnerPackages")
                                        }
                                    >
                                        <i className="input-group-text fa fa-pencil"></i>
                                    </div>
                                )} 
                            </div> 
                   
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="form-group col-6">
                        <div className="input-group">
                            <input
                                value={true}
                                checked={filterByProduct ?? false}
                                onChange={(event, value) => {
                                    this.setState({
                                        filterByProduct: event.target.checked,
                                        filterByCategory: false,
                                        filterBy:"product"
                                    });
                                }}
                                id="filterByProduct"
                                name="filterByProduct"
                                type="radio"
                                className="larger-checkbox"
                            />
                            &nbsp;&nbsp;  Filter by Product
                        </div>

                    </div>

                    <div className="form-group col-6">
                        <div className="input-group">
                            <input
                                value={true}
                                checked={filterByCategory || false}
                                onChange={(event, value) => {
                                    this.setState({
                                        filterByProduct: false,
                                        filterByCategory: event.target.checked,
                                        filterBy:"category",
                                    });
                                }}
                                id="filterByCategory"
                                name="filterByCategory"
                                type="radio"
                                className="larger-checkbox"
                            />
                            &nbsp;&nbsp;  Filter by Category
                        </div>
                    </div>
                </div>
              
              {filterByProduct &&filterByProductForm}
              {filterByCategory && filterByCategoryForm}
            </div>;
        
        const usageLimitsForm = <div className="container mt-4">
            <div className="row">
                <div className="form-group col">
                    <label>User Limit per Coupon*</label>
                    <div className="input-group user-input-container">
                        <input
                            type="number"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) event.preventDefault();
                            }}
                            className={
                                "form-control " +
                                (this.state.editUserLimitPerCoupon || newCoupon ? "non-editable " : "editable ") +
                                (this.state.validationErrors.userLimit ? "has-error" : "")
                            }
                            name="userLimit"
                            // readOnly={!newCoupon}
                            value={this.state.userLimit ?? ""}
                            onChange={this.handleChange}
                            disabled={!newCoupon && !this.state.editUserLimitPerCoupon} />
                        {isEditable && !newCoupon && this.state.couponCategory !== "Cashback" &&  (
                            <div className="input-group-append" onClick={() => this.makeEditable("editUserLimitPerCoupon")}>
                                <i className="input-group-text fa fa-pencil"></i>
                            </div>
                        )}
                    </div>
                    <span className="help-block">{this.state.validationErrors.userLimit}</span>
                </div>
                <div className="form-group col">
                    <label>Usage Limit per Coupon*</label>
                    <div className="input-group user-input-container">
                        <input
                            type="number"
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) event.preventDefault();
                            }}
                            className={
                                "form-control " + 
                                (this.state.editUsageLimitPerCoupon || newCoupon ? "non-editable " : "editable ") +
                                (this.state.validationErrors.usageLimit ? "has-error" : "")
                            }
                            name="usageLimit"
                            value={this.state.usageLimit ?? ""}
                            onChange={this.handleChange}
                            disabled={!newCoupon && !this.state.editUsageLimitPerCoupon} />
                        {isEditable && !newCoupon && this.state.couponCategory !== "Cashback" && (
                            <div className="input-group-append" onClick={() => this.makeEditable("editUsageLimitPerCoupon")}>
                                <i className="input-group-text fa fa-pencil"></i>
                            </div>
                        )}
                    </div>
                    <span className="help-block">{this.state.validationErrors.usageLimit}</span>
                </div>
            </div>
            <div>
            </div>
        </div>;

        const transactionsTableForm = <div className="container mt-4">
            {this.state.editId
                ? <TransactionsTable couponId={this.state.editId} />
                : <span>Selected coupon id is null or undefined!</span>
            }
        </div>;

        if (this.state.accessDenied) {
            return <AccessDenied />
        }

        
           

        return (
            <div className="coupon-generator font-poppins">
                {/* <CountryDropdown countries={countries} selectedCountry={selectedCountry} onSelect={this.handleCountrySelection} /> */}
                <div className="page-header">
                    <div className="actions">
                        <div className="action-item">
                            <Search
                                searchExpanded={this.state.searchExpanded}
                                searchTerm={this.state.searchTerm}
                                handleSearch={this.handleSearch}
                                clearSearch={this.handleSearchClear}
                                handleSearchExit={this.handleSearchExit}
                                ref={(input) => { this.search = input; }}
                            ></Search>
                        </div>
                        <div className="action-item">
                            <button style={{ backgroundColor: "#8FD14F" }} className="btn btn-md new-user" onClick={this.handleCreateNewCoupon} disabled={!isEditable}>
                                <i className="fa fa-plus-circle"></i>Add New
                            </button>
                        </div>
                    </div>
                </div>
                <Card body>
                    <DataTable
                        data={filteredCoupons}
                        columns={this.columns}
                        defaultSortField="is_active"
                        defaultSortAsc={false}
                        sortIcon={<ArrowDownward></ArrowDownward>}
                        pagination
                        paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                        paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                        responsive />
                       
                
                {<CouponExclusionForm 
                bloodBiomarkerPackages={this.state.data1 || []}
                healthCoachPackages={this.state.data2 ||[]}
                customPackages={this.state.data3||[]}
                allMedicines={this.state?.all_medicines||[]}
                allSupplements={this.state?.all_supplements||[]}
                allBundles={this.state.all_bundles||[]}
                selectedCountry={this.state.selectedCountry}
                allCoachConsultationsPackages={this.state.allCoachConsultationsPackages||[]}
                isEditable={this.state.isEditable}
                />}
                </Card>
                <Modal size="lg" show={this.state.showModal} onHide={this.hideModal} className="user-data-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="user-title"></div>
                            {
                                this.state.editId &&
                                <button type="submit" onClick={this.showConfirm} disabled={!isEditable} className="btn mr-2 modal-delete-btn">
                                    Delete
                                </button>
                            }
                            <button type="submit" onClick={this.handleSubmit} disabled={!isEditable} className="btn modal-save-btn">
                                Save
                            </button>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <form onSubmit={this.handleSubmit}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="details">
                                <Nav variant="tabs" className="justify-content-left">
                                    <Nav.Item>
                                        <Nav.Link eventKey="details">Details</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="usage_restrictions">Usage Restrictions</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="usage_limits">Usage Limits</Nav.Link>
                                    </Nav.Item>
                                    {this.state.editId &&
                                        <Nav.Item>
                                            <Nav.Link eventKey="best_recommended_discounts">Best Recommended Discounts</Nav.Link>
                                        </Nav.Item>
                                    }
                                    {this.state.editId &&
                                        <Nav.Item>
                                            <Nav.Link eventKey="transactions">Transactions</Nav.Link>
                                        </Nav.Item>
                                    }
                                </Nav>
                                <Row>
                                    <Col>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="details">
                                                {detailsForm}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="usage_restrictions">
                                                {usageRestrictionsForm}
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="usage_limits">
                                                {usageLimitsForm}
                                            </Tab.Pane>
                                            {this.state.editId &&
                                                <Tab.Pane eventKey="best_recommended_discounts">
                                                    {bestRecommendedDiscountsForm}
                                                </Tab.Pane>
                                            }
                                            {this.state.editId &&
                                                <Tab.Pane eventKey="transactions">
                                                    {transactionsTableForm}
                                                </Tab.Pane>
                                            }
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </form> */}
                        <Tabs id="tabs_couponcode">
                            <Tab
                                eventKey="key_form_details"
                                title="Details"
                            >
                                {detailsForm}
                            </Tab>
                            <Tab eventKey="key_form_usage_restrictions" title="Usage Restrictions">
                                {usageRestrictionsForm}
                            </Tab>
                            <Tab eventKey="key_form_usage_limits" title="Usage Limits">
                                {usageLimitsForm}
                            </Tab>
                            {/* <Tab eventKey="key_seo_best_recommended_discounts" title="Best Recommended Discounts">
                                {bestRecommendedDiscountsForm}
                            </Tab> */}
                            <Tab eventKey="key_form_transactions" title="Transactions">
                                {transactionsTableForm}
                            </Tab>
                            {this.state.pageIdForLogs!=null? 
                                <Tab eventKey="logs-coupon" title="Logs">
                                    <div className='container mt-4 '>
                                        <div className='row'  >
                                            <Logs pageId={this.state.pageIdForLogs} pageType="COUPON_CODE" />
                                        </div>

                                    </div>
                                    
                                </Tab>
                            :""}
                        </Tabs>
                    </Modal.Body>
                </Modal>
                
                <Modal size="sm" show={this.state.showConfirm} onHide={this.hideConfirm} className="user-data-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={() => {
                                this.hideConfirm();
                                this.handleDeleteData(this.state.editId);
                            }}
                            className="btn button btn-danger"
                        >
                            Yes
                        </button>
                        <button onClick={this.hideConfirm} className="btn btn-secondary button">No</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: value =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponCodes);
