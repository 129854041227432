import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import { Dropdown } from "semantic-ui-react";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import API from "utils/API";
import FormValidator from "utils/FormValidator";
import FormData from "form-data";
import { filterList } from "utils/List";
import ActionBar from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import AlertBox from "components/AlertBox/AlertBox";
import DataTable from "react-data-table-component";
import AddonForm from "./AddonForm";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import CountryDropdown from "components/Common/CountryDropdown";
import SelectSampleType from "components/Common/SelectSampleType";
import { compareStrings } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";
import ToggleSwitch from "components/Common/ToggleSwitch";
import SubscriptionForm from "components/Common/SubscriptionForm";
import PackageCityDetails from "components/Common/PackageCityDetails";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import SelectMiniPackages from "components/Common/SelectMiniPackages";

import SelectBloodPackages from "components/Common/SelectBloodPackages";

import SelectCustomPackages from "components/Common/SelectCustomPackages";

import NonBiomarkerTestDetails from "components/Common/NonBiomarkerTestDetails";
import { fetchValeoPartners } from "services/ValeoPartnersService";
import Select from "react-select"
import  PackageOrders  from "components/Common/PackageOrders";
import SelectSecondaryCategory from "components/Common/SelectSecondaryCategory";


import OtherImagesPackages from "pages/Services/OtherImagesPackages";

import { fetchSupplementsSummaryByCountry } from "services/SupplementService";
import SupplementSuggestion from "components/Common/SupplementSuggestion";
import PackageBreaking from "components/Common/PackageBreaking";


import  SelectSupplements from "../CouponCode/SelectSupplements";

// import SelectCoachConsultation from "../../components/Common/SelectCoachConsultation";
import PackageSampleDetails from "../../components/Common/PackageSampleDetails";
import SelectCoachConsultation from "../../components/Common/SelectCoachConsultation"
import internalCategoryOptions from "utils/InterenalCategory";

// importing logs component
import Logs from "components/Logs/Logs";

import RichTextEditor from "react-rte";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_PACKAGE_SAMPLE_TYPES = "package-sample-type/"
const validTypes = ['video/mp4','video/webm'];
const imageValidTypes = ['image/jpeg','image/png','image/gif','image/svg+xml','image/webp'];
const sampleDetails_url="admin/sample-details";
class BloodBiomarkerPackages extends Component {
  constructor(props) {
    super(props);
    this.formFields = [
      {
        field: "subscription_name",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Name is required.",
      },
      {
        field: "subscription_name_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Name is required.",
      },
      {
        field: "subscription_description",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Description is required.",
      },
      {
        field: "subscription_description_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Description is required.",
      },
      {
        field: "gender",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Gender is required.",
      },
      {
        field: "price",
        method: "isEmpty",
        validWhen: false,
        message: "Price is required.",
      },
      {
        field: "price",
        method: "isFloat",
        validWhen: true,
        isRtl: false,
        min: 0,
        message: "Invalid price.",
      },
      {
        field: "image",
        type: "image",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Image is required.",
      },
      {
        field: "tests",
        type: "array",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Tests are required.",
      },
    ];

    this.formFieldsCustom = [
      {
        field: "subscription_name",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Name is required.",
      },
      {
        field: "subscription_name_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Name is required.",
      },
      {
        field: "subscription_description",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Description is required.",
      },
      {
        field: "subscription_description_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Description is required.",
      },
      {
        field: "gender",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Gender is required.",
      },
      {
        field: "image",
        type: "image",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Image is required.",
      },
    ];

    this.formFieldsWellbeing = [
      {
        field: "subscription_name",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Name is required.",
      },
      {
        field: "subscription_name_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Name is required.",
      },
      {
        field: "subscription_description",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Description is required.",
      },
      {
        field: "subscription_description_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Description is required.",
      },
      {
        field: "gender",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Gender is required.",
      },
      {
        field: "image",
        type: "image",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Image is required.",
      },
      // {
      //   field: "addonPlans",
      //   type: "array",
      //   method: "isEmpty",
      //   validWhen: false,
      //   args: [{ min: 0 }],
      //   message: "Atleast one plan is required."
      // }
    ];

    this.validator = new FormValidator(this.formFields);
    this.validatorCustom = new FormValidator(this.formFieldsCustom);
    this.validatorwellbeing = new FormValidator(this.formFieldsWellbeing);
    
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      subscription_name: "",
      subscription_name_ar: "",
      subscription_description_ar: "",
      subscription_description: "",
      internal_name: "",
      what_happens_next: "",
      what_happens_next_ar: "",
      appointment_descr: "",
      appointment_descr_ar: "",
      biomarkers_note_en: "",
      biomarkers_note_ar: "",
      reports_timeline_note_en: "",
      reports_timeline_note_ar: "",
      price: "",
      retail_price: "",
      old_price: "",
      product: null,
      tests: [],
      allTests: [],
      rankObject: {},
      rankSortable: {},
      image: "",
      imgSrc: "",
      gender: "",
      isWellbeing: false,
      addonPlans: [],
      validation: this.validator.valid(),
      validationCustom: this.validatorCustom.valid(),
      validationWellbeing: this.validatorwellbeing.valid(),
      testList: [],
      edit_id: null,
      default_coach_id:null,
      defaultCoachOptions: [],
      arrGenderList: [
        {
          text: "Both",
          value: "B",
          key: "B",
        },
        {
          text: "Male",
          value: "M",
          key: "M",
        },
        {
          text: "Female",
          value: "F",
          key: "F",
        },
      ],
      statusOptions: [
        {
          text: "Active",
          value: true,
          key: "active",
        },
        {
          text: "Inactive",
          value: false,
          key: "inactive",
        },
      ],
      bestSellerOptions:[
        {
          text: "YES",
          value: true,
          key: "yes",
        },
        {
          text: "NO",
          value: false,
          key: "no",
        },

      ],
      discountOptions: [
        {
          text: "Percentage",
          value: "percentage",
          key: "percentage",
        },
        {
          text: "Fixed",
          value: "fixed",
          key: "fixed",
        },
      ],

      productOptionsEdit:[],
      productOptions: [],
      packages: [],
      arrEditPackage: {},
      formHeading: "Create Package",
      showModal: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      isLoaded: false,
      loadError: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      isCustom: false,
      errors: {},
      editables: {},
      countries: [],
      selectedCountry: null,
      isNewPackage: false,
      fasting_hours: null,
      member_applicable: null,
      member: [],
      miniPackages: [],
      custom_addon_packages:[],
      is_tests_are_final: false,
      is_allow_mini_package_addition: false,

      is_non_blood_biomarker_addition:false,
      non_blood_biomarkers:[],
      minipackages: [],
      is_bundle: false,
      bundle: [],
      is_allow_custom_package_addition:false,
      minipackages: [],
      customPackages:[],

      valeoPartners:[],
      valeoPartnerOptions:[],
      bloodPackageIdOrders:[],
      packageIdTotalRows:0,
      internal_category:"None",
      primary_media_type:null,
      packages_media:[],
      video_s3_url:"",
      thumbnail_s3_url:"",
      secondaryCategoryOptions:[],
      is_b2b_package:false,

      default_secondary_coach_id:null,
      secondaryCoachOptions:[],



      subscriptionPackageBundles:[],
      customPackagesForPackageBreaking:[],

      is_eligible_for_cod:true,
      is_vat_excluded:false,

      is_allow_coach_cons_pkg_addition:false,
      is_allow_supplement_addition:false,
      nonBloodBiomarkerOptions:[],
      pageIdForLogs : null,
      nonBloodBiomarkerOptions:[],

      descriptionTxtEn: RichTextEditor.createEmptyValue(),
      descriptionTxtAr: RichTextEditor.createEmptyValue(),
      whatHappensNextTxtEn: RichTextEditor.createEmptyValue(),
      whatHappensNextTxtAr: RichTextEditor.createEmptyValue(),
      // appointmentDescriptionTxtEn: RichTextEditor.createEmptyValue(),
      // appointmentDescriptionTxtAr: RichTextEditor.createEmptyValue(),
      biomarkersNoteTxtEn: RichTextEditor.createEmptyValue(),
      biomarkersNoteTxtAr: RichTextEditor.createEmptyValue(),

    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "100px",
      },
      {
        name:"UID",
        selector:"package_uuid",
        sortable: true,
        width: "150px",

      },
      {
        name: "Name",
        selector: "subscription_name",
        wrap: true,
        sortable: true,
      },
      // {
      //   name: "Product",
      //   selector: "product",
      //   sortable: true,
      //   format: (p) => this.renderProduct(p.product),
      // },
      {
        name: "Product Category",
        selector: "product_title",
        wrap: true,
        sortable: true,
      },
      {
        name:"Secondry Category",
        selector:"secondary_categories_name",
        wrap: true,
        sortable: true,
       
      },
      {
        name: "Price",
        selector: "price",
        width: "125px",
        center: true,
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        sortable: true,
        width: "125px",
        center: true,
      },
      {
        name: "Status",
        selector: "is_active",
        sortable: true,
        maxWidth: "150px",
        center: true,
        cell: (p) => this.renderStatus(p.is_active),
      },
      {
        name: "View",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
    ];

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.selecteImage = this.selecteImage.bind(this);
    // this.savePackage = this.savePackage.bind(this);
    // this.updatePackage = this.updatePackage.bind(this);
    this.packageOperation = this.packageOperation.bind(this);
    this.formReset = this.formReset.bind(this);
    this.loadTestList = this.loadTestList.bind(this);
    this.getPackageList = this.getPackageList.bind(this);
    this.editPackage = this.editPackage.bind(this);
    this.updateList = this.updateList.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  async componentDidMount() {
    this.fetchAclUser();
    this.fetchMiniPackages();
    await this.loadCountries();
    this.loadPackageSampleTypes();
    await this.loadProducts();
    this.loadData();
    this.fetchValeoPartners();
    this.fetchDefaultCoachDetails()
    this.fetchDefaultSecondaryCoachDetails();
    this.fetchSampleDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry, tests } = this.state;
    if (selectedCountry !== prevState.selectedCountry)
      this.createProductOptionsList();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.BLOOD_BIOMARKER_PACKAGES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchMiniPackages = async () => {
    try {
      this.showLoading();
      const { data: miniPackages = [] } = await PhoenixAPI.get("/api/v1/mini-packages");
      this.setState({ miniPackages }, () => this.updatePopularMiniPackagesDropdownList());
    } catch (error) {
      console.log("fetchMiniPackages:: Error on fetching mini packages!", error);
      this.showErrorNotification("Error on fetching mini packages!" + error.message ?? "");
    } finally {
      this.hideLoading();
    }
  };

  fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
        const partnerOptions=partners.map((it)=>{
          return {key:it.id, value:it.id, label:it.name}
        })
        partnerOptions.push({key:"select", value:"select", label:"Select"})
        this.setState({valeoPartners:partners,valeoPartnerOptions:partnerOptions})
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchSubscriptionItems = async(packageId) => {
    if (!packageId) {
      console.log("fetchSubscriptionItems:: Invalid package id: ", packageId);
      return null;
    }
    
    this.setState({
      pageIdForLogs : packageId
    })

    try {
      this.showLoading();
      const url = `/subscription-items/Blood_Package/${packageId}`;
      const { data } = await PhoenixAPI.get(url);
      console.log("fetchSubscriptionItems:: Fetched subscriptions items: ", data);
      return data;
    } catch (error) {
      console.log("fetchSubscriptionItems:: Error on fetching subscription items!", error);
      this.showErrorNotification("Error on fetching subscription items! " + error.message);
      return null;
    } finally {
      this.hideLoading();
    }
  }
  fetchSampleDetails=async()=>{
    try {
      this.showLoading();
      const { data: sampleDetails = [] } = await PhoenixAPI.get(`${sampleDetails_url}`);
      if(sampleDetails.length>0){
        const activeOptions = sampleDetails
  .filter(sample => sample.isActive)
  .map(sample => ({
    key: sample.id,
    value: sample.id,
    text: sample.sampleName,
    group: "Active Samples"  // Custom attribute for logical grouping
  }));

const inactiveOptions = sampleDetails
  .filter(sample => !sample.isActive)
  .map(sample => ({
    key: sample.id,
    value: sample.id,
    text: sample.sampleName,
    group: "Inactive Samples"  // Custom attribute for logical grouping
  }));

// Combine active and inactive options
const sampleDetailsOptions = [
  { key: 'active-header', text: 'Active Samples', disabled: true },
  ...activeOptions,
  { key: 'inactive-header', text: 'Inactive Samples', disabled: true },
  ...inactiveOptions
];
        
      this.setState({ sampleDetails,sampleDetailsOptions});

      }
      else{
        this.setState({sampleDetails: [], sampleDetailOptions: []});
      }
      
    } catch (error) {
      console.log(
        "fetchCorporatePartners:: Error on fetching corporate partners!",
        error
      );
      this.showErrorNotification(
        "Error on fetching corporate partners! " + error.message ?? ""
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
   }


  fetchSupplements=async()=>{
    const { selectedCountry } = this.state;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchSupplements:: Invalid country!", { selectedCountry });
      return;
    }
    try {
      this.showLoading();
      const { data: supplements = [] } = await fetchSupplementsSummaryByCountry(
        countryId
      );
       
      this.setState({supplements});
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching supplements: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  }
  saveSubscriptionItems = async (packageId, isNewPackage = false) => {
    if (!packageId) {
      console.log("saveSubscriptionItems:: Invalid package id: ", packageId);
      return null;
    }

    const { enable_subscription, subscription_items } = this.state;
    if (subscription_items && subscription_items.length) {
      subscription_items.forEach(it => it.bloodPackageId = packageId)
    }
    const url = (isNewPackage || enable_subscription)
      ? `/subscription-items/`
      : `/disable-subscription/Blood_Package/${packageId}`;
    const payload = url.startsWith("/disable-subscription")
      ? subscription_items ?? []
      : { 
          enableSubscription: enable_subscription, 
          packageType: "Blood_Package",
          packageId,
          subscriptionItems: subscription_items ?? []
      };
    try {
      const apiOptions = {
        method: isNewPackage ? "POST" : "PATCH",
        url,
        data: payload,
      };
      await PhoenixAPI.request(apiOptions);
    } catch (error) {
      this.showErrorNotification("Error on saving subscription items!" + error.message);
    }
  }

  saveCityPackgeDetails = async (packageId) => {
    if (!packageId) {
      console.log("saveCityPackageDetails:: Invalid package id: ", packageId);
      return null
    }

    const { bloodPackageCities, isNewPackage } = this.state;
    if (isNewPackage) {
      if (bloodPackageCities && bloodPackageCities.length) {
        bloodPackageCities.forEach(it => it.bloodPackageId = packageId)
      }
      const url = `list-blood-packages-cities`
      const payload = {
        bloodPackageId: packageId,
        bloodPackagesCities: bloodPackageCities ?? []
      };
      try {
        const apiOptions = {
          method: "POST",
          url,
          data: payload,
        };
        await PhoenixAPI.request(apiOptions);
      } catch (error) {
        this.showErrorNotification("Error on saving city prices of blood Pacage!" + error.message);
      }
    }
  }

  loadCountries = async () => {
    const url = "countries/";
    const isDefaultCountry = (country) => country.is_default;
    const countryMapper = (country) => ({
      id: country.id,
      country_name: country.country_name,
      currency: country.currency,
      is_active: country.is_active,
      is_default: country.is_default,
    });

    try {
      const { data: allCountries } = await API.get(url);
      const countries = allCountries ? allCountries.map(countryMapper) : [];
      const defaultCountry = countries.find(isDefaultCountry);

      this.setState({
        countries,
        selectedCountry: defaultCountry,
      },()=>{this.fetchSupplements();this.fetchNonBloodBiomarkers()});
    } catch (error) {
      console.error("Error on fetching countries from this URL: ", url);
      console.error("Error on fetching countries", error);
    }
  };

  loadPackageSampleTypes = async () => {
    try {
      const { data: packageSampleTypes = [] } = await API.get(BASE_URL_PACKAGE_SAMPLE_TYPES);
      this.setState({ packageSampleTypes });
    } catch (error) {
      console.error("Error on fetching package sample types", error);
      throw error;
    }
  };

  loadProducts = async () => {
    const url = "products/";
    const productMapper = (product) => ({
      id: product.id,
      name: product.name,
      title: product.title,
      status: product.status,
      country: product.country,
    });

    try {
      const { data: allProducts } = await API.get(url);
      const products = allProducts ? allProducts.map(productMapper) : [];

      this.setState({ products }, () => this.createProductOptionsList());
    } catch (error) {
      console.error("Error on fetching products from this URL: ", url);
      console.error("Error on fetching products", error);
    }
  };


  loadData() {
    this.loadTestList();

  }

  loadTestList() {
    API.get("tests/")
      .then((response) => {
        const activeList = filterList(response.data, "active");

        let testList = activeList.map((value) => {
          return {
            text: value.name,
            value: value.id.toString(),
            key: value.id,
          };
        });

        this.setState({ testList: testList, allTests: response.data });
        this.getPackageList();
      })
      .catch((error) => {
        console.log("Error on fetching tests", error);
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  fetchNonBloodBiomarkers = async () => {
    const {selectedCountry}= this.state;
    try {
      const { data: nonBloodBiomarkers = [] } = await PhoenixAPI.get("non-blood-biomarkers");
      const filteredActiveNonBloodBiomarkers = nonBloodBiomarkers && nonBloodBiomarkers.filter((item) => item.isActive === true);
      const filteredNonBloodBiomarkersForCountry = filteredActiveNonBloodBiomarkers.filter((item)=>item.countryId===selectedCountry.id)
      console.log("nonBloodBiomarkersnonBloodBiomarkers", { filteredNonBloodBiomarkersForCountry})
      const nonBloodBiomarkerOptions = filteredNonBloodBiomarkersForCountry.length>0 && filteredNonBloodBiomarkersForCountry.map((item) => {
        return {
          text: item.internalName,
          value: item.id.toString(),
          key: item.id
        }
      })
      this.setState({ nonBloodBiomarkerOptions, nonBloodBiomarkers })
    }
    catch (error) {
      this.showErrorNotification("Error on fetching non-blood-biomarkers", error.message)
      console.log("Error on fetching non-blood-biomarkers", error.message)
    }
  }
  handleMediaChange = (event,text) => {

   this.setState({primary_media_type:text})
  
 };
 
 
 handleIconChange = (e,mediaText) => {
     e.stopPropagation();
     const file = e.target.files[0];
     if(!validTypes.includes(file.type)){
       this.showErrorNotification("please add video files");
     }
     else{
     if (file) {
       // setIcon(file);
 
      this.uploadIcon( file,mediaText);
     }
    }
   }
    uploadFiles=async(formData)=>{
 
     const response = await PhoenixAPI.post('/upload-media',formData, {
       headers: {
         accept: "application/json",
       "Accept-Language": "en-US,en;q=0.8",
         'Content-Type': 'multipart/form-data'
       }
     });
     return response;
    }
 
   uploadIcon = async ( iconFile,mediaText) => {
     
     try {
    const formData=new FormData();
     formData.append("media",iconFile)
    const response= await  this.uploadFiles(formData)
       //setDeals({ ...deals, tagIconUrl: response.data.s3Url })
       console.log("response",response);
       if(mediaText=="Primary Image"){
     this.setState( {image:response.data.message});
   
     this.showSuccessNotification("Image Uploaded Successfully");
       }
 
       if(mediaText=="Video URL"){
         //const supplement=  {...this.state.supplement, video:response.data.message}
         this.setState({video_s3_url:response.data.message})
         this.showSuccessNotification("Video Uploaded Successfully");
 
       }
       
 
 
       
     } catch (error) {
       console.log("Error uploading image:", error);
     }
   }

  getPackageList() {
    const { searchApplied, searchTerm,selectedCountry} = this.state;
    this.props.toggleLoading({ isLoading: true });
    PhoenixAPI.get("/custom/packages")
    .then((response)=>{
      let testList=response.data||[]
      // let customList=response.data||[]
      // console.log("customlist",customList)
      console.log("custom packages:", testList)
      const dropDownList=testList && testList.length>0 && testList.filter((item)=>item.country==selectedCountry.id)
      const customPackagesForPackageBreaking= dropDownList && dropDownList.map((item)=>{
        return {...item, "package_type":"Custom_Package"}
      })
      this.setState({
        customPackagesDropdownList:testList && testList.length>0 && testList.filter((item)=>item.country==selectedCountry.id),
        customList:testList,
        customPackagesForPackageBreaking
      })
    }).catch((error)=>{
      console.log("error on fetching custom packages",error);
    })
    PhoenixAPI.get("/bloods/packages")
      .then((response) => {
        console.log("Subscription Packages: ", response.data);
        let newList = response.data || [];
        const dropDownList = newList && newList.length>0 && newList.filter((item)=>item.country==selectedCountry.id);
        const bloodPackagesDropdownList=dropDownList.map((item)=>{
          return {...item,package_type:"Blood_Package"}
        })
        
        const ranks = newList.filter(p => p.rank).map(p => p.rank);
        const minRank = Math.min(...ranks) || 0;
        const maxRank = Math.max(...ranks) || 0;

        newList.sort((a, b) => {
          return a.rank - b.rank;
        });
        let rankArray = [];
        let rankSotable = [];
        let rank = 1;
        newList.map((item) => {
          if (item.is_active) { 
            rankSotable[rank] = item.id;
            rankArray.push({
              text: rank,
              value: rank,
              key: rank,
            });
            rank = rank + 1;
          }
        });
        this.setState({
          rankObject: rankArray,
          rankSortable: rankSotable,
          position_value: rank - 1,
          minRank,
          maxRank,
          bloodPackagesDropdownList
        });
        
        const activeList = filterList(newList, "active");
        const archivedList = filterList(newList, "archived");
        this.setState({
          packages: newList.map(p => {
            const product = this.findProduct(p.product);
            return {...p, product_title: product ? product.title : ""};
          }),
          activeList,
          archivedList,
          isLoaded: true,
        });

        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching packages", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
  
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });

        this.props.toggleLoading({
          isLoading: false,
        });
      });
  }

  /*
  getPackageList() {
    //fetch  normal/custom packages, wellbeing packages
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true,
    });

    API.get("subscription-packages/")
      .then((response) => {
        console.log("Subscription Packages: ", response.data);
        let newList = response.data || [];

        const ranks = newList.filter(p => p.rank).map(p => p.rank);
        const minRank = Math.min(...ranks) || 0;
        const maxRank = Math.max(...ranks) || 0;

        newList.sort((a, b) => {
          return a.rank - b.rank;
        });
        let rankArray = [];
        let rankSotable = [];
        let rank = 1;
        newList.map((item) => {
          if (item.is_active) { 
            rankSotable[rank] = item.id;
            rankArray.push({
              text: rank,
              value: rank,
              key: rank,
            });
            rank = rank + 1;
          }
        });
        this.setState({
          rankObject: rankArray,
          rankSortable: rankSotable,
          rank: rank - 1,
          minRank,
          maxRank,
        });
        
        const activeList = filterList(newList, "active");
        const archivedList = filterList(newList, "archived");
        this.setState({
          packages: newList.map(p => {
            const product = this.findProduct(p.product);
            return {...p, product_title: product ? product.title : ""};
          }),
          activeList,
          archivedList,
          isLoaded: true,
        });

        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching packages", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });

        this.props.toggleLoading({
          isLoading: false,
        });
      });
  }
  */

  getPackage = async (id) => {
    if (id === null || id === undefined) {
      console.log("getPackage:: Invalid packageId=", id);
      return;
    }
    try {
      this.props.toggleLoading({ isLoading: true });
      const { data } = await API.get(`subscription-packages/${id}`);
      const {minipackages=[],custom_pkg_addons=[], coach_cons_pkg_addons=[],supplement_addons=[]}=data
      // if(minipackages.length>0||custom_pkg_addons.length>0){
      //   this.setState(
      //     { is_tests_are_final: true }, 
      //     () => this.updatePopularMiniPackagesDropdownList()
      //   )
      // }
      if(minipackages.length>0){
        this.setState({is_allow_mini_package_addition:true})
      }
      if(custom_pkg_addons.length>0){
        this.setState({is_allow_custom_package_addition:true})
      }

      if(coach_cons_pkg_addons.length>0){
        this.setState({is_allow_coach_cons_package_addition:true})
      }

      if(supplement_addons.length>0){
        this.setState({is_allow_supplement_package_addition:true})
      }

      return data
    } catch (error) {
      console.log(`Error on fetching package with id=${id}`, error);
      this.props.showNotificationMessage({
        notificationMessage: `Error on fetching package with id=${id} ` + error.message,
        successMessage: false,
        showNotification: true,
      });
    } finally {
      this.props.toggleLoading({ isLoading: false });
    }
  }

  createProductOptionsList = () => {
    const { products, selectedCountry, product: productId, isNewPackage } =
      this.state;
    const productsForCountry =
      products && selectedCountry
        ? products.filter((product) => product.country === selectedCountry.id)
        : [];

    if (!isNewPackage && productId) {
      const product = this.findProduct(productId);
      if (product && !productsForCountry.find(product))
        productsForCountry.push(product);
    }
      
    const activeProducts = productsForCountry.filter(
      (product) => product.status === "Active"
    );
    activeProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const inactiveProducts = productsForCountry.filter(
      (product) => product.status === "Inactive"
    );
    inactiveProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const noneOption = {
      key: "none",
      value: "none",
      text: "NONE",
    };
    
    const productOptions = [...activeProducts, ...inactiveProducts].map((product) => ({
      key: product.id,
      value: product.id,
      text: `${product.name}${
        product.status === "Inactive" ? " (Inactive)" : ""
      }`,
    }));
    this.setState({ productOptions: [noneOption, ...productOptions] , productOptionsEdit: [noneOption, ...productOptions], secondaryCategoryOptions:activeProducts});
    console.log("productOptions: are ", productOptions);
  };

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
    // this.setState({ [target.name]: target.value }, () => {
    //   if (target.name === "retail_price" || target.name === "price")
    //     this.caluclateDiscount();
    // });
  }

  getTestName(id) {
    const allTests = this.state.allTests;

    if (allTests.length > 0) {
      let test = allTests.find((item) => {
        return item.id.toString() === id;
      });

      if (test) {
        if (test.is_active) return test.name;
        else return test.name + " (Archived)";
      }
    }
    return null;
  }

  selecteImage(e) {
    let image = e.target.files[0];
    if(!imageValidTypes.includes(image.type))
    {
      this.showErrorNotification("please add image files");
    }
    else{
    this.setState({ image });
    
    let reader = new FileReader();
    reader.onloadend = function () {
      this.setState({
        imgSrc: [reader.result],
      });
    }.bind(this);
    reader.readAsDataURL(image);
  }
  }

  validate = () => {
    const errors = {};
    const { 
      subscription_name, 
      subscription_name_ar,
      subscription_description,
      subscription_description_ar,
      internal_name,
      what_happens_next,
      what_happens_next_ar,
      appointment_descr,
      appointment_descr_ar,
      biomarkers_note_en,
      biomarkers_note_ar,
      reports_timeline_note_en,
      reports_timeline_note_ar,
      zoho_account_id,
      image,
      imgSrc,
      position_value,
      gender,
      is_active,
      tests,
      sample_type,
      fasting,
      fasting_hours,
      member_applicable,
      is_bundle,
      bundle,
      display_name,
      display_name_ar,
      special_tag,
      special_tag_ar,

      customPackages,

      is_best_seller,
      internal_category,
      primary_media_type,
      video_s3_url,
      subscriptionPackageBundles,

      descriptionTxtEn,
      descriptionTxtAr,
      whatHappensNextTxtEn,
      whatHappensNextTxtAr,
      appointmentDescriptionTxtEn,
      appointmentDescriptionTxtAr,
      biomarkersNoteTxtEn,
      biomarkersNoteTxtAr,

    } = this.state;

    if (!subscription_name || subscription_name.trim() === "")
      errors.subscription_name = "External Name (English) is a required field!";
    else if (!/^[a-zA-Z0-9!|@#$%^&*)(+=._-\s]*$/g.test(subscription_name))
      errors.subscription_name = "External Name (English) can only take letters, numbers, symbols, and spaces!";
    else if (subscription_name.length > 200)
      errors.subscription_name = "External Name (English) can not take more than 200 characters!";
    
    if (!internal_name || internal_name.trim() === "")
      errors.internal_name = "Internal Name is a required field!";
    else if (!/^[a-zA-Z0-9!|@#$%^&*)(+=._-\s]*$/g.test(internal_name))
      errors.internal_name = "Internal Name can only take letters, numbers, symbols, and spaces!";
    else if (internal_name.length > 200)
      errors.internal_name = "Internal Name can not take more than 200 characters!";

    if (!subscription_name_ar || subscription_name_ar.trim() === "")
      errors.subscription_name_ar = "External Name (Arabic) is a required field!";
    else if (subscription_name_ar.length > 200)
      errors.subscription_name_ar = "External Name (Arabic) can not take more than 200 characters!";

    if (position_value === null || position_value === undefined || position_value.toString().trim() === "")
      errors.rank = "Rank is a required field!";

    if (gender === null || gender === undefined || gender.toString().trim() === "")
      errors.gender = "Gender is a required field!";

    if (is_active === null || is_active === undefined)
      errors.is_active = "Status is a required field!";
    if(zoho_account_id && zoho_account_id.length>45){
      errors.zoho_account_id="Zoho  Account Id is limited to 45 characters";

    }
      else if(!zoho_account_id){
        errors.zoho_account_id="Zoho  Account Id is a required field!";
      }
    // Validate description form.
    if (!subscription_description || subscription_description.trim() === "")
      errors.subscription_description = "Description (English) is a required field!";
    else if (subscription_description.length > 5000)
      errors.subscription_description = "Description (English) can not take more than 5000 characters!";
     
    if (!subscription_description_ar || subscription_description_ar.trim() === "")
      errors.subscription_description_ar = "Description (Arabic) is a required field!";
    else if (subscription_description_ar.length > 5000)
      errors.subscription_description_ar = "Description (Arabic) can not take more than 5000 characters!";

    if (what_happens_next && what_happens_next.length > 5000)
      errors.what_happens_next = "What happens next? (English) can not take more than 5000 characters!";

    if (what_happens_next_ar && what_happens_next_ar.length > 5000)
      errors.what_happens_next_ar = "What happens next? (Arabic) can not take more than 5000 characters!";

    if (appointment_descr && appointment_descr.length > 1000)
      errors.appointment_descr = "Appointment Description? (English) can not take more than 1000 characters!";

    if (appointment_descr_ar && appointment_descr_ar.length > 1000)
      errors.appointment_descr_ar = "Appointment Description? (Arabic) can not take more than 1000 characters!";

    if (!biomarkers_note_en || biomarkers_note_en.trim() === "")
      errors.biomarkers_note_en = "Biomarkers Note (English) is a required field!";
    else if (biomarkers_note_en.length > 100)
      errors.biomarkers_note_en = "Biomarkers Note (English) can not take more than 100 characters!";

    if (!biomarkers_note_ar || biomarkers_note_ar.trim() === "")
      errors.biomarkers_note_ar = "Biomarkers Note (Arabic) is a required field!";
    else if (biomarkers_note_ar.length > 100)
      errors.biomarkers_note_ar = "Biomarkers Note (Arabic) can not take more than 100 characters!";

    if (!reports_timeline_note_en || reports_timeline_note_en.trim() === "")
      errors.reports_timeline_note_en = "Reports Timeline Note (English) is a required field!";
    else if (reports_timeline_note_en.length > 100)
      errors.reports_timeline_note_en = "Reports Timeline Note (English) can not take more than 100 characters!";

    if (!reports_timeline_note_ar || reports_timeline_note_ar.trim() === "")
      errors.reports_timeline_note_ar = "Reports Timeline Note (Arabic) is a required field!";
    else if (reports_timeline_note_ar.length > 100)
      errors.reports_timeline_note_ar = "Reports Timeline Note (Arabic) can not take more than 100 characters!";
    if(primary_media_type=="Image"){
    if (!imgSrc){
      errors.image = "Image is a required field!";
    }
  }
    if(primary_media_type=="Video"){
      if(!video_s3_url ){
       errors.videoUrl = "Video is a required field!";
      }
      if (!imgSrc){
        errors.videoThumbnailImage = "Video Thumbnail is a required field!";
      }
    }
    // Validate tests form.
    if (tests === null || tests.length === 0)
      errors.tests = "Tests is a required field!";

    // Validate location and pricing form.
    const { retail_price, price, discount_type, discount_value } = this.state;

    if (retail_price === null || retail_price === undefined || retail_price.toString().trim() === "")
      errors.retail_price = "Retail Price is a required field!"
    else if (Number.isNaN(Number(retail_price)))
      errors.retail_price = "Retail Price should be a valid number!";
    else if (retail_price < 0)
      errors.retail_price = "Retail Price should not be a negative number!";

    if (price === null || price === undefined || price.toString().trim() === "")
      errors.price = "Final Price is a required field!"
    else if (Number.isNaN(Number(price)))
      errors.price = "Final Price should be a valid number!";
    else if (price < 0)
      errors.price = "Final Price should not be a negative number!";

    if (discount_type === null || discount_type === undefined || discount_type.toString().trim() === "")
      errors.discount_type = "Discount Type is a required field!"

    if (discount_value === null || discount_value === undefined || discount_value.toString().trim() === "")
      errors.discount_value = "Discount Value is a required field!"
    else if (Number.isNaN(Number(discount_value)))
      errors.discount_value = "Discount Value should be a valid number!";
    else if (discount_value < 0)
      errors.discount_value = "Discount value should not be a negative number!"

    if (sample_type === null || sample_type === undefined)
      errors.sample_type = "Sample Type is a required field!";

    if (fasting && (fasting_hours === null || fasting_hours === undefined))
      errors.fasting_hours = "Fasting hours is a required field!";
    else if (fasting && Number.isNaN(Number(fasting_hours)))
      errors.fasting_hours = "Fasting hours should be a valid number!";
    else if (fasting && fasting_hours < 1)
      errors.fasting_hours = "Fasting hours should be greater than 0!";

    if (this.state.enable_subscription) {
      const subscriptionItems = this.state.subscription_items;
      const activeItemCount =
        subscriptionItems && subscriptionItems.length
          ? subscriptionItems.filter((it) => it.status === "Active").length
          : 0;
      if (activeItemCount === 0) {
        errors.subscription_items = "At least one active item is required!";
      }
    }

    if (member_applicable === null || member_applicable === undefined)
      errors.member_applicable = "Members Applicable is a required field!";
    else if (Number.isNaN(member_applicable))
      errors.member_applicable = "Members Applicable should be a valid number!"
    else if (member_applicable < 1)
      errors.member_applicable = "Members Applicable should be greater than 0!"

    // if (is_bundle) {
    //   const numberOfBundles = subscriptionPackageBundles ? subscriptionPackageBundles.length : 0; 
    //   if (subscriptionPackageBundles !== member_applicable) {
    //     errors.bundle = "No of members applicable should be equals to the number of blood packages added.";
    //   }
    // }
    if (!display_name || display_name.trim() === "")
      errors.display_name = "Display Name (English) is a required field!";
    else if (display_name.length > 100)
      errors.display_name = "Display Name (English) can not take more than 100 characters!";

    if (!display_name_ar || display_name_ar.trim() === "")
      errors.display_name_ar = "Display Name (Arabic) is a required field!";
    else if (display_name_ar.length > 100)
      errors.display_name_ar = "Display Name (Arabic) can not take more than 100 characters!";

    if (special_tag && special_tag.length > 100)
      errors.special_tag = "Special Tag (English) can not take more than 100 characters!";

    if (special_tag_ar && special_tag_ar.length > 100)
      errors.special_tag_ar = "Special Tag (Arabic) can not take more than 100 characters!";
    if(customPackages && customPackages.length>3){
      errors.customPackages="You can not add more than three items"
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }

  packageOperation(operation) {
    const validation = this.validator.validate(this.state);
    this.submitted = true;
    this.setState({ validation });

    const errors = this.validate();
    this.setState({ errors: errors || {}});

    if (errors) {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true,
      });
      return;
    }

    if (validation.isValid)
      this.callSubscriptionPackageAPI("normal", operation);
  }
  isMemberPresent=(memberId)=> {
    const {subscriptionPackageBundles=[]}= this.state
    return subscriptionPackageBundles.length>0 && subscriptionPackageBundles.some(item => item.member === memberId);
}
  async callSubscriptionPackageAPI(packageType, operation) {
    //operations can be add or update
    //packageTypes can be custom/normal
    let apiOptions = {};
  
    let formdata = new FormData();
    let {
      edit_id,
      tests,
      subscription_name,
      subscription_name_ar,
      subscription_description,
      subscription_description_ar,
      internal_name,
      what_happens_next,
      what_happens_next_ar,
      appointment_descr,
      appointment_descr_ar,
      biomarkers_note_en,
      biomarkers_note_ar,
      reports_timeline_note_en,
      reports_timeline_note_ar,
      position_value,
      gender,
      image,
      is_active,
      zoho_account_id,
      price,
      retail_price,
      discount_type,
      discount_value,
      country,
      product,
      selectedCountry,
      sample_type,
      fasting,
      fasting_hours,
      member,
      member_applicable,
      display_name,
      display_name_ar,
      special_tag,
      special_tag_ar,
      is_allow_mini_package_addition,

      is_non_blood_biomarker_addition,
      non_blood_biomarkers,
      is_bundle,
      bundle,
      partner_id,

      is_allow_custom_package_addition,
      minipackages,
      customPackages,
      custom_pkg_addons,

      is_best_seller,
      internal_category,
      default_coach_id,
      isNewPackage,

      secondary_categories,

    

      video_s3_url,
      primary_media_type,
      thumbnail_s3_url,
      thumbnail,
      packages_media,
     
      is_default_suggested_supplement_required, 
      mappingSupplementSuggestions,
      is_b2b_package,

      default_secondary_coach_id,


      subscriptionPackageBundles,
      is_eligible_for_cod,

      package_uuid,
      is_vat_excluded,
      is_allow_coach_cons_pkg_addition,
      is_allow_supplement_addition, supplement_addons,
      coach_cons_pkg_addons,
      mappingPackageSamples,
      non_blood_biomarker,

      descriptionTxtEn,
      descriptionTxtAr,
      whatHappensNextTxtEn,
      whatHappensNextTxtAr,
      appointmentDescriptionTxtEn,
      appointmentDescriptionTxtAr,
      biomarkersNoteTxtEn,
      biomarkersNoteTxtAr
    } = this.state;
    if(is_bundle && subscriptionPackageBundles.length===0) {
      this.showErrorNotification("Please add bundle packages to member")
      return 
    }
    
    if(is_bundle){
      for (let i = 1; i <= member_applicable; i++) {
        const result = this.isMemberPresent(i);
        console.log(`Is member ${i} present? ${result}`);
        if(!result){
          this.showErrorNotification(`Add Package for Member ${i}`)
          return
        }
    }
    }
 

  const uniqueMembers = [...new Set(subscriptionPackageBundles.map(item => item.member))];
  const isCheckAllMembers = uniqueMembers&& uniqueMembers.length!== member_applicable;
  if(isCheckAllMembers && is_bundle){
  this.showErrorNotification("No of members applicable should be equals to the number of  packages added.")
  return
}

  if(is_bundle){
    console.log("checking all members ",subscriptionPackageBundles)
  }

    if (operation === "add") {
      formdata.append("is_custom", false);
      formdata.append("is_wellbeing", false);
      if (selectedCountry)
        formdata.append("country", selectedCountry.id);
    }

    formdata.append("member_applicable", member_applicable);
    if (member && member.length) {
      for (const m of member) {
        m.package_id = edit_id ?? null;
        formdata.append("member_gender", JSON.stringify(m));
      }
    }
    if(edit_id){
     formdata.append("package_uuid",package_uuid);
    }
    formdata.append("subscription_name", subscription_name);
    formdata.append("subscription_name_ar", subscription_name_ar);
    formdata.append("subscription_description", subscription_description);
    formdata.append("subscription_description_ar",subscription_description_ar);
    formdata.append("internal_name", internal_name)
    formdata.append("what_happens_next", what_happens_next);
    formdata.append("what_happens_next_ar", what_happens_next_ar);
    formdata.append("appointment_descr", appointment_descr);
    formdata.append("appointment_descr_ar", appointment_descr_ar);
    formdata.append("biomarkers_note_en", biomarkers_note_en);
    formdata.append("biomarkers_note_ar", biomarkers_note_ar);
    formdata.append("reports_timeline_note_en", reports_timeline_note_en);
    formdata.append("reports_timeline_note_ar", reports_timeline_note_ar);
    formdata.append("position_value", position_value);
    formdata.append("gender", gender);
    formdata.append("zoho_account_id",zoho_account_id);
    formdata.append("is_active", is_active);
    formdata.append("price", price);
    formdata.append("retail_price", retail_price);
    formdata.append("discount_type", discount_type);
    formdata.append("discount_value", discount_value);
    formdata.append("product", product);
    formdata.append("sample_type", sample_type);
    formdata.append("fasting", fasting);
    formdata.append("fasting_hours", fasting_hours ?? 0);
    formdata.append("is_bundle", is_bundle ?? false);
    formdata.append("is_best_seller",is_best_seller);
    
      if(!default_coach_id){
        formdata.append("default_coach_id","");
      }else{
        formdata.append("default_coach_id", default_coach_id);
      }
    
   if(tests && tests.length>0){
     const finaltest=tests.filter((it)=>it !== undefined);
     
     finaltest.map((value)=>{formdata.append("tests",value);});
    }
   
    formdata.append("is_allow_mini_package_addition", is_allow_mini_package_addition ?? false);
    formdata.append("is_non_blood_biomarker_addition",is_non_blood_biomarker_addition ?? false);
    if(non_blood_biomarkers && non_blood_biomarkers.length>0){
      for(let bio of non_blood_biomarkers){
        let flag=false;
        if(bio.is_active=="Active")
           flag=true;
        else if(bio.is_active)
           flag=true;
        bio={...bio, is_active:flag
         }
      formdata.append("non_blood_biomarkers",JSON.stringify(bio));
      }
    }
    
    if (minipackages && minipackages.length) {
      
      const filteredMinipackage=this.state.miniPackages.filter((minipackage)=>minipackage.countryId==this.state.selectedCountry.id);
     
     const selectedMiniPackageName= filteredMinipackage.filter((filterMini)=>minipackages.includes(filterMini.id)).map((mini)=>mini.name);
     const finalMiniPackage=filteredMinipackage.filter((selectpackage)=>selectedMiniPackageName.includes(selectpackage.name)).map((mini)=>mini.id);
      
     finalMiniPackage.forEach((it) => formdata.append("minipackages", it));
    }
    formdata.append("is_allow_custom_package_addition", is_allow_custom_package_addition ?? false);
    if (customPackages && customPackages.length>0) {
      customPackages.forEach((it) => formdata.append("custom_pkg_addons", it));
    }
    
    if (image && image.name && primary_media_type=="Image") {
      formdata.append("image", image, image.name);
      formdata.append("image_s3", image, image.name);
    }

    if (bundle && bundle.length) {
      bundle.forEach((b) => formdata.append("bundle", b));
    }
    
    formdata.append("display_name", display_name ?? "");
    formdata.append("display_name_ar", display_name_ar ?? "");
    formdata.append("special_tag", special_tag ?? "");
    formdata.append("special_tag_ar", special_tag_ar ?? "");
    if (internal_category == "None") {
      formdata.append("internal_category", "");
    } else {
      formdata.append("internal_category", internal_category);
    }
    if(partner_id){
      formdata.append("partner_id", partner_id ?? ""); 
    }

    if (secondary_categories && secondary_categories.length>0) {
      secondary_categories.forEach((it) => formdata.append("secondary_categories", it));
    }
    if(packages_media && packages_media.length>0){
      for(let media of packages_media){
      formdata.append("packages_media", JSON.stringify(media));
      }
    }
      formdata.append("primary_media_type", primary_media_type);
     
      if(primary_media_type=="Video"){
      formdata.append("video_s3_url",video_s3_url);

      
    //  formdata.append("thumbnail_s3_url",image,image.name);
       if(image && image.name){
        formdata.append("image", image, image.name);
      formdata.append("image_s3", image, image.name);

      }

    }
      // if (secondary_categories && secondary_categories.length>0) {
      //   secondary_categories.forEach((it) => formdata.append("secondary_categories", it));
      // }
      formdata.append("is_default_suggested_supplement_required", is_default_suggested_supplement_required ?? false);

      if (mappingSupplementSuggestions && mappingSupplementSuggestions.length>0) {
        mappingSupplementSuggestions.forEach((it) => formdata.append("subscription_package_supplements", JSON.stringify(it)));
      }

      if(mappingPackageSamples && mappingPackageSamples.length>0) {
        mappingPackageSamples.forEach((it) => formdata.append("sample_details", JSON.stringify(it)));
      }

      formdata.append("is_b2b_package", is_b2b_package ?? false);



     
        if(!default_secondary_coach_id){
          formdata.append("default_secondary_coach_id","");
        }else{
          formdata.append("default_secondary_coach_id", default_secondary_coach_id);
        }
      


    
     const mappingSubscriptionPackageBundles =subscriptionPackageBundles.map((item)=>{
          
       const itemObject = {
        "appointment_date_value":item.appointment_date_value,
        "id":item?.id??null,
        "is_active":item.is_active,
        "member":item.member,
         "package_id": Number(item.package_id),
         "package_name" :item.package_name,
          "package_type":item.package_type
       }
        return itemObject
     })
    if(is_bundle){
      if (mappingSubscriptionPackageBundles && mappingSubscriptionPackageBundles.length>0) {
        mappingSubscriptionPackageBundles.forEach((it) => formdata.append("subscription_package_bundles", JSON.stringify(it)));
      }
    }
      

      formdata.append("is_eligible_for_cod", is_eligible_for_cod ?? true);
      formdata.append("is_vat_excluded",is_vat_excluded ?? false);

        formdata.append("is_allow_coach_cons_pkg_addition", is_allow_coach_cons_pkg_addition ?? false);
        formdata.append("is_allow_supplement_addition",is_allow_supplement_addition??false)
        if (supplement_addons && supplement_addons.length>0) {
          supplement_addons.forEach((it) => formdata.append("supplement_addons", it));
        }
         
         
          if(coach_cons_pkg_addons && coach_cons_pkg_addons && is_allow_coach_cons_pkg_addition){
            formdata.append("coach_cons_pkg_addons",coach_cons_pkg_addons )
          }
            
          


    if (non_blood_biomarker && non_blood_biomarker.length > 0) {
      const finaltest = non_blood_biomarker.filter((it) => it !== undefined);
      console.log("final test", finaltest);
      finaltest.map((value) => { formdata.append("non_blood_biomarker", value) });
    }

      formdata.append("description_richtext_en",descriptionTxtEn?.toString("html"));
      formdata.append("description_richtext_ar",descriptionTxtAr?.toString("html"))
      formdata.append("what_happens_next_richtext_en",whatHappensNextTxtEn?.toString("html"))
      formdata.append("what_happens_next_richtext_ar",whatHappensNextTxtAr?.toString("html"))

      
      // formdata.append("appointment_descr_richtext_en",appointmentDescriptionTxtEn?.toString("html"));
      // formdata.append("appointment_descr_richtext_ar",appointmentDescriptionTxtAr?.toString("html"))
      formdata.append("biomarkers_note_richtext_en",biomarkersNoteTxtEn?.toString("html"))
      formdata.append("biomarkers_note_richtext_ar",biomarkersNoteTxtAr?.toString("html"))

    if (operation === "update") {
      
      apiOptions = {
        method: "patch",
        url: "subscription-packages/" + edit_id + "/",
        data: formdata,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
        },
      };
    } else if (operation === "add") {
      apiOptions = {
        method: "post",
        url: "subscription-packages/",
        data: formdata,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
        },
      };
    }

    const notificationMessage = edit_id 
      ? "Package successfully updated." 
      : "Package successfully created."
    try {
      this.props.toggleLoading({ isLoading: true });
      const { data: bloodPackage } = await API.request(apiOptions);
      await this.saveSubscriptionItems(bloodPackage?.id, operation === "add");
      await this.saveCityPackgeDetails(bloodPackage?.id, operation==="add");
      this.showSuccessNotification(notificationMessage);
      this.hideModal();
      this.loadData();
      // Now there is no need to call this API for updating the rank.
      // else {
      //   let rankSortable = this.state.rankSortable;
      //   let from = rankSortable.indexOf(response.data.id);
      //   let to = this.state.rank;
      //   rankSortable.splice(to, 0, rankSortable.splice(from, 1)[0]);
      //   let rankObject = {};
      //   rankSortable.map((item, index) => {
      //     rankObject[item] = index;
      //   });
      //   API.post("set-package-rank/", rankObject).then((response) => {
      //     this.props.showNotificationMessage({
      //       notificationMessage: "Package successfully updated.",
      //       successMessage: true,
      //       showNotification: true,
      //     });
      //     this.loadData();
      //     this.props.toggleLoading({
      //       isLoading: false,
      //     });
      //   });
      // }
    } catch (error) {
      console.log("Error on saving package", error);
      this.showErrorNotification("Error on saving package." + error.message);
    } finally {
      this.hideLoading();
    }
  }

  formReset() {
    this.submitted = false;
    this.setState({
      subscription_name: "",
      subscription_name_ar: "",
      subscription_description: "",
      subscription_description_ar: "",
      internal_name: "",
      what_happens_next: "",
      what_happens_next_ar: "",
      appointment_descr: "",
      appointment_descr_ar: "",
      biomarkers_note_en: "",
      biomarkers_note_ar: "",
      reports_timeline_note_en: "",
      reports_timeline_note_ar: "",
      zoho_account_id:"",
      price: "",
      retail_price: "",
      old_price: "",
      country: null,
      discount_type: null,
      discount_value: null,
      product: null,
      tests: [],
      image: "",
      imgSrc: "",
      gender: "",
      validation: this.validator.valid(),
      edit_id: null,
      isNewPackage: false,
      errors: {},
      editables: {},
      sample_type: null,
      fasting: false,
      fasting_hours: null,
      enable_subscription: false,
      subscription_items: null,
      bloodPackageCities:null,
      member_applicable: null,
      member: [],
      display_name: "",
      display_name_ar: "",
      special_tag: "",
      special_tag_ar: "",
      is_tests_are_final: false,
      is_allow_mini_package_addition: false,
      is_allow_custom_package_addition:false,
      customPackages:[],
      is_non_blood_biomarker_addition:false,
      non_blood_biomarkers:[],

      minipackages: [],
      is_bundle: false,
      bundle: [],
      partner_id:null,
      valeoPartnerId:null,
      valeoPartnerName:"",
      internal_category:"None",
      default_coach_id:null,
      secondary_categories:[],
      is_b2b_package:false,
      default_secondary_coach_id:null,

      is_eligible_for_cod:true,
      is_vat_excluded:false,

      
      is_allow_coach_cons_pkg_addition:false,
      is_allow_supplement_addition:false,
      supplement_addons:[],
      coach_cons_pkg_addons:null,


      descriptionTxtEn: RichTextEditor.createEmptyValue(),
      descriptionTxtAr: RichTextEditor.createEmptyValue(),
      whatHappensNextTxtEn: RichTextEditor.createEmptyValue(),
      whatHappensNextTxtAr: RichTextEditor.createEmptyValue(),
      appointmentDescriptionTxtEn: RichTextEditor.createEmptyValue(),
      appointmentDescriptionTxtAr: RichTextEditor.createEmptyValue(),
      biomarkersNoteTxtEn: RichTextEditor.createEmptyValue(),
      biomarkersNoteTxtAr: RichTextEditor.createEmptyValue(),
    });
    const imageInput = document.getElementById("imageInput");
    if (imageInput) imageInput.value = "";
  }

  toggleActiveStatus(item) {
    //toggle active status of wellbeing packages
    //toggle active status of normal packages
    const { successMessage } = this.state;
    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true,
    });
    var formData = new FormData();
    formData.append("is_active", !item.is_active);
    if (item.is_wellbeing) {
      API.patch("add-on-packages/" + item.id + "/", formData)
        .then((response) => {
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: successMessage,
            successMessage: true,
            showNotification: true,
          });
          this.getPackageList();
        })
        .catch((error) => {
          console.log("Error on saving package", error);
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "We have an ongoing order with this package.",
            successMessage: false,
            showNotification: true,
          });
        });
    } else {
      var formData = new FormData();
      formData.append("is_active", !item.is_active);
      API.patch("subscription-packages/" + item.id + "/", formData)
        .then((response) => {
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: successMessage,
            successMessage: true,
            showNotification: true,
          });
          this.getPackageList();
        })
        .catch((error) => {
          console.log("Error on saving package");
          this.props.showNotificationMessage({
            notificationMessage: "We have an ongoing order with this package.",
            successMessage: false,
            showNotification: true,
          });
          this.props.toggleLoading({
            isLoading: false,
          });
        });
    }
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null,
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;
    if (confirmItem.is_active) {
      confirmTitle = "Archive Package";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Package successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Package";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Package successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle,
    });
  }

  newFormattedList(list, tests) {
    let item;
    const newList = [...list];

    for (let test of tests) {
      item = {
        text: this.getTestName(test),
        value: test,
        key: test,
      };

      if (newList.find((element) => element.key === test)) continue;

      newList.push(item);
    }

    return newList;
  }

  updateList() {
    this.getPackageList();
  }

  fetchPackageOrderDetails=async(packageId)=>{
    if(!packageId){
      return null;
    }
    
      try{
        this.showLoading();
        const url=`package-orders/Blood_Package/${packageId}?page=1&size=25&ordering=-id`;
        const {data}= await PhoenixAPI.get(url);
        console.log("fetchBloodPackageOrders:: Fetched Blood Package order: ", data);

        return {content:data.content,totalRows:data.totalElements};
       }
       catch(error){
        console.log("fetchPackageOrders:: Fetched orders: ", error);
        this.showErrorNotification("Error on fetching orders of package! " + error.message);
        return null;
       }
       finally{
        this.hideLoading();
       }
  }
  
  fetchBloodPackageCities=async(packageId)=>{
     if(!packageId){
      return null;
     }
     try{
      this.showLoading();
      const url=`/blood-packages-cities/bloodPackageId/${packageId}`;
      const {data}= await PhoenixAPI.get(url);
      console.log("fetchBloodPackageCities:: Fetched Blood Packages: ", data);
      return data;
     }
     catch(error){
      console.log("fetchBloodPackageCities:: Fetched Blood Packages: ", error);
      this.showErrorNotification("Error on fetching BloodPackage items! " + error.message);
      return null;
     }
     finally{
      this.hideLoading();
     }
  }
  fetchDefaultCoachDetails = async () => {
   

    try {
      this.showLoading();
      const url = `nutritionist-user-name/`;
      const { data } = await PhoenixAPI.get(url);
      let coachDetails = [{ text: "None", value: null, key: "None", default_coach_id: null, is_active:false}];
      data && data.map((item) => {
        let coach = {};
        coach["text"] = item.full_name;
        coach["value"] = item.id;
        coach["key"] = item.full_name;
        coach["default_coach_id"] = item.id;
        coach["is_active"] = item.is_active;
        coachDetails.push(coach)
      })
      this.setState({defaultCoachOptions:coachDetails})
    
    }
    catch (error) {
      this.showErrorNotification("Error on fetching default coach details ! " + error.message);
      return null;
    }
    finally {
      this.hideLoading();
    }
  }

  fetchDefaultSecondaryCoachDetails = async () => {

    try {
      const { data: externalCoaches = [] } = await PhoenixAPI.get("external/coach/users/") || [];
      let coachDetails = [{ text: "None", value: null, key: "None", default_secondary_coach_id: null }];
      externalCoaches && externalCoaches.map((item) => {
        let coach = {};
        coach["text"] = item.userName;
        coach["value"] = item.id;
        coach["key"] = item.userName;
        coach["default_secondary_coach_id"] = item.id;
        coachDetails.push(coach)
      })
       this.setState({secondaryCoachOptions:coachDetails})
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching external coaches: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  }

  productNameList=()=>{
    const { products, selectedCountry } =
      this.state;
    const productsForCountry =
      products && selectedCountry
        ? products.filter((product) => product.country === selectedCountry.id)
        : [];

  
      
    const activeProducts = productsForCountry.filter(
      (product) => product.status === "Active"
    );
    activeProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const inactiveProducts = productsForCountry.filter(
      (product) => product.status === "Inactive"
    );
    inactiveProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    return activeProducts
  }
  async editPackage(packageId) {
    // const arrEditPackage = this.state.packages[index];
    // const selectedPackage = this.state.packages.find(p => p.id === packageId);
    let testList=[];
    const secondaryCategoryOptions = this.productNameList()
    
    try{
      const response=await PhoenixAPI.get("/custom/packages")
      testList = response.data || [];
    }catch(error){
      console.log("Error while fetching custom packages data",error)
    }
    
    const selectedPackage = await this.getPackage(packageId);

    //three types of editing options are available
    //1. Normal package edit
    //2. Addon package edit(Wellbeing Coach)
    //3. Custom package edit
    const {valeoPartners}= this.state || {}
    const partnerId= selectedPackage.partner_id ?? null;
    const selectedPartner= valeoPartners.find((it)=>it.id===partnerId);
    if (selectedPackage && selectedPackage.id) {
      const subscriptionDetails = await this.fetchSubscriptionItems(selectedPackage.id);
      const cityPackageDetails= await this.fetchBloodPackageCities(selectedPackage.id);
      const packageOrderDetails= await this.fetchPackageOrderDetails(selectedPackage.id);
      const defaultCoachDetails = await this.fetchDefaultCoachDetails() || [];
      const defaultSecondaryCoach=await this.fetchDefaultSecondaryCoachDetails() || [];
      const testsArray=[];
      selectedPackage.tests.length>0 && selectedPackage.tests.map((test) => {
        if(test.id !== null || undefined){
          testsArray.push(test.id.toString())
        }
      }
      )
      const filteredSecondaryCategoryOptions= secondaryCategoryOptions && secondaryCategoryOptions.filter((item)=>item.id!==selectedPackage?.product)
      const subscriptionSupplementSuggestions=selectedPackage?.subscription_package_supplements ?? [];

     const subscriptionPackageBundles= selectedPackage?.subscription_package_bundles ?? [];

      const filteredPrimaryCategoryOptions = this.state.productOptions &&
        this.state.productOptions.filter((item) => {
          // Check if `selectedPackage.secondary_categories` exists
          if (selectedPackage.secondary_categories) {
            return !selectedPackage.secondary_categories.includes(item.key);
          }
          return true; // If `secondary_categories` doesn't exist, keep the item
        });
    console.log("product options",filteredPrimaryCategoryOptions);
      let nonBloodBiomarkerArray=[]
      selectedPackage.non_blood_biomarker.length>0 && selectedPackage.non_blood_biomarker.map((test) => {
        if(test.id !== null || undefined){
          nonBloodBiomarkerArray.push(test.toString())
        }
      }
      )

     const bloodpackageSamples= selectedPackage?.sample_details??[];
      this.setState(
        {
          edit_id: selectedPackage.id,
           tests: testsArray,
          subscription_name: selectedPackage.subscription_name,
          subscription_name_ar: selectedPackage.subscription_name_ar,
          internal_name: selectedPackage.internal_name,
          subscription_description: selectedPackage.subscription_description,
          subscription_description_ar: selectedPackage.subscription_description_ar,
          what_happens_next: selectedPackage.what_happens_next,
          what_happens_next_ar: selectedPackage.what_happens_next_ar,
          appointment_descr: selectedPackage.appointment_descr,
          appointment_descr_ar: selectedPackage.appointment_descr_ar,
          biomarkers_note_en: selectedPackage.biomarkers_note_en,
          biomarkers_note_ar: selectedPackage.biomarkers_note_ar,
          reports_timeline_note_en: selectedPackage.reports_timeline_note_en,
          reports_timeline_note_ar: selectedPackage.reports_timeline_note_ar,
          position_value: selectedPackage.position_value,
          zoho_account_id:selectedPackage.zoho_account_id,
          gender: selectedPackage.gender,
          image: selectedPackage.primary_media_type === "Image" ? selectedPackage.image : selectedPackage.image_s3_url,
          imgSrc: selectedPackage.primary_media_type === "Image" ? selectedPackage.image : selectedPackage.image_s3_url,
          
          
          is_active: selectedPackage.is_active,
          isCustom: selectedPackage.is_custom,
          price: selectedPackage.price,
          retail_price: selectedPackage.retail_price,
          old_price: selectedPackage.price,
          discount_type: selectedPackage.discount_type,
          discount_value: selectedPackage.discount_value,
          country: selectedPackage.country,
          product: selectedPackage.product,
          formHeading: "Edit Package",
          isWellbeing: selectedPackage["is_wellbeing"]
            ? selectedPackage["is_wellbeing"]
            : false,
          addonPlans: selectedPackage["addon_plans"],
          sample_type: selectedPackage.sample_type,
          fasting: selectedPackage.fasting,
          fasting_hours: selectedPackage.fasting_hours,
          enable_subscription: subscriptionDetails?.enableSubscription ?? false,
          subscription_items: subscriptionDetails?.subscriptionItems ?? null,
          bloodPackageCities:cityPackageDetails,
          
          member_applicable: selectedPackage.member_applicable,

          member: selectedPackage.member ? [...selectedPackage.member] : [],

          display_name: selectedPackage.display_name,
          display_name_ar: selectedPackage.display_name_ar,
          special_tag: selectedPackage.special_tag,
          special_tag_ar: selectedPackage.special_tag_ar,

          is_bundle: selectedPackage.is_bundle ?? false,
          bundle: selectedPackage.bundle ?? [],



          // is_allow_mini_package_addition: selectedPackage.is_allow_mini_package_addition ?? false,
          // is_allow_custom_package_addition: selectedPackage.is_allow_custom_package_addition ?? false,
          minipackages: selectedPackage.minipackages ?? [],
          customPackages: selectedPackage.custom_pkg_addons?? [],
          customPackagesDropdownList:testList??[],
          valeoPartnerId: selectedPartner?.id || null,
          valeoPartnerName:selectedPartner?.name || "",
          customPackagesDropdownList:testList && testList.length>0 && testList.filter(item=>item.country==selectedPackage.country)||[],

          is_allow_mini_package_addition: selectedPackage.is_allow_mini_package_addition ?? false,
          is_non_blood_biomarker_addition:selectedPackage.is_non_blood_biomarker_addition??false,
          non_blood_biomarkers:selectedPackage.non_blood_biomarkers,
          partner_id:selectedPackage.partner_id ?? null,
          valeoPartnerId: selectedPartner?.id || null,
          valeoPartnerName:selectedPartner?.name || "",
          is_best_seller:selectedPackage.is_best_seller,

          bloodPackageIdOrders:packageOrderDetails.content,
          packageIdTotalRows:packageOrderDetails.totalRows,
          default_coach_id: selectedPackage.default_coach_id,
          internal_category: selectedPackage.internal_category,

          secondary_categories:selectedPackage.secondary_categories,

          video_s3_url:selectedPackage.video_s3_url,
          thumbnail_s3_url:selectedPackage.thumbnail_s3_url,
          packages_media:selectedPackage.packages_media,
          primary_media_type:selectedPackage.primary_media_type,

          secondaryCategoryOptions:filteredSecondaryCategoryOptions,
          is_default_suggested_supplement_required:selectedPackage.is_default_suggested_supplement_required,
          mappingSupplementSuggestions:subscriptionSupplementSuggestions,
          is_b2b_package:selectedPackage.is_b2b_package ?? false,
          productOptionsEdit:filteredPrimaryCategoryOptions,
          

          default_secondary_coach_id:selectedPackage.default_secondary_coach_id,


          subscriptionPackageBundles,
          is_eligible_for_cod:selectedPackage.is_eligible_for_cod ?? true,

          package_uuid:selectedPackage.package_uuid,

          is_vat_excluded:selectedPackage.is_vat_excluded ?? false,


          is_allow_coach_cons_pkg_addition:selectedPackage.is_allow_coach_cons_pkg_addition,
          is_allow_supplement_addition:selectedPackage.is_allow_supplement_addition,

          supplement_addons:selectedPackage.supplement_addons,
          coach_cons_pkg_addons:selectedPackage?.coach_cons_pkg_addons[0]??"",


          
          mappingPackageSamples:bloodpackageSamples,


          non_blood_biomarker:nonBloodBiomarkerArray,

          descriptionTxtEn: RichTextEditor.createValueFromString(selectedPackage?.description_richtext_en  ?? "","html"),
          descriptionTxtAr: RichTextEditor.createValueFromString(selectedPackage?.description_richtext_ar  ?? "","html"),
          whatHappensNextTxtEn:RichTextEditor.createValueFromString(selectedPackage?.what_happens_next_richtext_en ?? "","html"),
          whatHappensNextTxtAr: RichTextEditor.createValueFromString(selectedPackage?. what_happens_next_richtext_ar  ?? "","html"),
          // appointmentDescriptionTxtEn: RichTextEditor.createValueFromString(selectedPackage?.appointment_descr_richtext_en ?? "","html"),
          // appointmentDescriptionTxtAr: RichTextEditor.createValueFromString(selectedPackage?.appointment_descr_richtext_ar  ?? "","html"),
          biomarkersNoteTxtEn: RichTextEditor.createValueFromString(selectedPackage?.biomarkers_note_richtext_en  ?? "","html"),
          biomarkersNoteTxtAr: RichTextEditor.createValueFromString(selectedPackage?.biomarkers_note_richtext_ar  ?? "","html"),
 
        },
        () => this.showModal()
      );
    }
  }

  handleSelectPartner=(partnerId)=>{
  
    const {valeoPartnerOptions, valeoPartners}=this.state || {}
    if(partnerId==="select"){
   
      this.setState({...this.state,partner_id:null, valeoPartnerId: null,valeoPartnerName:"SELECT"})
    }
    else{
      const selectedId = valeoPartners.find(
        (it) => it.id === partnerId 
      ) ?? {};
      this.setState({...this.state,partner_id:selectedId.id ?? null, valeoPartnerId:selectedId.id ?? null,valeoPartnerName:selectedId.name ?? ""})
      }
      
    }

  hideModal() {
    this.formReset();
    this.setState({ showModal: false, pageIdForLogs:null });
  }

  showModal(type) {
    const rankOptions = this.createRankOptions();
    this.setState({
      isCustom: false,
      isWellbeing: false,
      showModal: true,
      rankObject: rankOptions,
    });
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    const { showItems } = this.state;
    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Archived") list = this.state.archivedList;
    else list = this.state.packages;

    if (!list) return;

    let newList = [];

    for (let item of list) {
      const { subscription_name = "", subscription_name_ar = "" } = item;
      if (
        subscription_name.toLowerCase().indexOf(newValue) !== -1 ||
        subscription_name_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  findProduct = (id) =>
    id && this.state.products
      ? this.state.products.find((product) => product.id === id)
      : null;

  renderStatus = (isPackageActive) => {
    if (isPackageActive)
      return (
        <div className="badge badge-success pt-1">
          <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
          Active
        </div>
      );
    else
      return (
        <div className="badge badge-warning pt-1">
          <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
          Inactive
        </div>
      );
  };

  renderProduct = (productId) => {
    const product = this.findProduct(productId);
    return product ? product.title : "";
  };

  renderViewButton = (packageId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.editPackage(packageId);
        }}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewPackage ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  getCountry = (id) => {
    const { countries } = this.state;
    return countries && countries.find((country) => country.id === id);
  };

  handleCountrySelection = (country) =>
    this.setState({ selectedCountry: country }, ()=>{this.fetchSupplements();this.getPackageList();this.fetchNonBloodBiomarkers()});

  handleSubscriptionsItemsUpdated = (subscriptionItems = []) => {
    this.setState({ subscription_items: subscriptionItems });
  };

  handleCityPackageItemsUpdated = (cityPackageItems = []) => {
    this.setState({ bloodPackageCities: cityPackageItems});
  };

  
  // handleNonBloodBiomarkerTestUpdated=(nonBloodTest=[])=>{
  //   console.log("non biomarker",nonBloodTest);
   
     
  //   this.setState({non_blood_biomarkers:nonBloodTest});
  // }
  getFilteredPackages = () => {
    const { packages, selectedCountry } = this.state;
    const packagesForCountry = (packages && selectedCountry)
      ? packages.filter(p => p.country === selectedCountry.id)
      : [];
    return this.getSearchedPackages(packagesForCountry);
  }

  getSearchedPackages = (packages) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return packages;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return packages.filter(p => {
      const { id, subscription_name = "", subscription_name_ar = "", product_title = "" } = p;
      return (id && id.toString() === newValue) ||
      subscription_name.toLowerCase().indexOf(newValue) !== -1 ||
      subscription_name_ar.toLowerCase().indexOf(newValue) !== -1 ||
      (product_title && product_title.toLowerCase().indexOf(newValue) !== -1)
    });
  }

  caluclateDiscount = () => {
    const { retail_price, price: final_price, discount_type } = this.state;
    const errors = {};
    
    if (discount_type === null || discount_type === undefined || discount_type.toString() === "")
      errors.discount_type = "Discount Type is a required field!";

    if (retail_price === null || retail_price === undefined || retail_price.toString() === "")
      errors.retail_price = "Retail Price is a required field!";
    else if (Number.isNaN(Number(retail_price)))
      errors.retail_price = "Retail Price should be a valid number!";

    if (final_price === null || final_price === undefined || final_price.toString() === "")
      errors.price = "Final Price is a required field!";
    else if (Number.isNaN(Number(final_price)))
      errors.price = "Final Price should be a valid number!";
    else if (Number(final_price) > Number(retail_price)) {
      console.log("Final price is greater then retail price", final_price, retail_price);
      errors.price = "Final Price can not be greater then Retail Price!"
    }

    const previousErrors = Object.keys(this.state.errors)
      .filter(key => key !== "retail_price" && key !== "price" && key !== "discount_type")
      .reduce((obj, key) => {
        obj[key] = this.state.errors[key];
        return obj;
      }, {});

    this.setState({ errors: { ...previousErrors, ...errors } });
    if (Object.keys(errors).length !== 0) return;

    const retailPrice = Number(retail_price);
    const finalPrice = Number(final_price);      
    if (discount_type === "percentage") {
      this.setState({ discount_value: Math.round(100 * (retailPrice - finalPrice) / retailPrice) });
    } else if (discount_type === "fixed") {
      this.setState({ discount_value: Math.round(Math.abs(finalPrice - retailPrice)) });
    } else {
      console.log("Unknown discount type: ", discount_type);
    }
  }

  createRankOptions = () => {
    const { minRank, maxRank, isNewPackage } = this.state;
    if (!minRank || !maxRank) return;

    const until = isNewPackage ? maxRank + 1 : maxRank;
    let rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });
    
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  }

  updateFastingHours = () => {
    if (!this.state.fasting)
      this.setState({ fasting_hours: null });
  }

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  getDefaultMemberGender = (index = 1) => {
    return { member_index: index, gender: "B", is_active: true };
  }

  updatePopularMiniPackagesDropdownList = () => {
    const { selectedCountry, miniPackages, minipackages: popularMiniPackages,customPackages,customPackagesDropdownList,customList} = this.state;
    const miniPackagesForCurrentCountry = miniPackages && miniPackages.length && selectedCountry 
      ? miniPackages.filter((it) => it.countryId === selectedCountry.id)
      : []
    const tests = this.state.tests && this.state.tests.length
      ? this.state.tests.map((testId) => Number(testId))
      : [];
    const miniPackagesDropdownList = tests && tests.length
      ? miniPackagesForCurrentCountry.filter((miniPackage) => {
        const { tests: miniPackageTests } = miniPackage;
        if (miniPackageTests && miniPackageTests.length) {
          return !miniPackageTests.every(it => tests.includes(it));
        }
        return true;
      })
      : miniPackagesForCurrentCountry;

    const newPopularMiniPackages = popularMiniPackages && popularMiniPackages.length
      ? popularMiniPackages.filter((it) => miniPackagesDropdownList.findIndex((mIt) => mIt.id === it) >= 0)
      : [];
    this.setState({ miniPackagesDropdownList, minipackages: newPopularMiniPackages,customPackagesDropdownList:customList && customList.length>0 && customList.filter(item=>item.country==selectedCountry.id),customPackages});
  }

  deleteUnwantedPopularMiniPackages = () => {

  }
  

  createPartnerOption=(bloodPackage)=>{
    return {
     key:bloodPackage.valeoPartnerId,
     value:bloodPackage.valeoPartnerId ,
     label:bloodPackage.valeoPartnerName ,
    }
   }

   handleOtherImagesUpdated = (otherImages = []) => {
    
    this.setState({ packages_media:otherImages });
  };

  handleSupplementSuggestions=(supplements)=>{
   this.setState({mappingSupplementSuggestions:supplements})
  } 
  
  handlePackageSampleDetailsUpdate = (sampleDetails) => { 
    this.setState({mappingPackageSamples:sampleDetails})
  }

  handlePackagesUpdated=(packages)=>{
    this.setState({subscriptionPackageBundles:packages})
  }

  handleRichText = (value, name) => {
    this.setState({ [name]: value });
  };

  render() {
    let finalList = [];
    const packageCountry = this.getCountry(this.state.country);
    
    const {
      isLoaded,
      loadError,
      searchApplied,
      edit_id,
      resultCount,
      showItems,
      countries,
      selectedCountry,
      errors,
      editables,
      isNewPackage,
      isEditable,
      member,
      packages,
      valeoPartnerOptions,
      bloodPackageIdOrders,
      packageIdTotalRows,
      secondary_categories,
      secondaryCategoryOptions,
      is_default_suggested_supplement_required, supplements,
      subscriptionPackageBundles,
      bloodPackagesDropdownList,
      customPackagesForPackageBreaking,
      member_applicable,
      sampleDetails,
      sampleDetailsOptions,
    } = this.state;

    const  selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded)
      finalList = this.getFilteredPackages();

    const genderInputs = member && member.length
      ? member.map((it1, index) => {
        return <div className="row">
          <div className="form-group col-6">
            <label htmlFor={`gender_${index}`}>{`Member ${index + 1} Gender*`}</label>
            <div className="input-group">
              <Dropdown
                value={it1.gender}
                onChange={(event, value) => {
                  const updatedMember = member.map((it2, itIndex) => {
                    if (index === itIndex) return {...it2, gender: value.value};
                    return it2;
                  })
                  this.setState({ member: updatedMember });
                }}
                id={`gender_${index}`}
                placeholder="Gender"
                search
                selection
                options={this.state.arrGenderList}
                disabled={!isNewPackage && !editables[`gender_${index}`]}
                className={
                  "form-control" + (errors[`gender_${index}`] ? " has-error" : "")
                }
              />
              {this.renderPencilIcon(`gender_${index}`)}
            </div>
            <span className="help-block">{errors[`gender_${index}`]}</span>
          </div>
        </div>
      })
      : null;
    
    const countryPackages = packages && selectedCountry
      ? packages.filter((it) => it.country === selectedCountry.id)
      : [];
    const bloodPackageBundleSections = (
      <>
        <div className="row mt-2">
          <div className="form-group col-4">Is Bundle?</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="is_bundle"
              toggled={this.state.is_bundle ?? false}
              onToggle={(isToggled) =>
                this.setState({ is_bundle: isToggled })
              }
              disabled={!isEditable}
            />
          </div>
          {this.state.is_bundle && (
            <div  className="container mt-4">
              <PackageBreaking packages={subscriptionPackageBundles} onPackageUpdated={this.handlePackagesUpdated} isEditable={isEditable} bloodPackages={bloodPackagesDropdownList} customPackages={customPackagesForPackageBreaking} membersApplicable={member_applicable} />
            </div>
          )}
        </div>
      </>
    );

    const detailsForm =
      <div className="container mt-4">
        <div className="row">
        <div className="form-group col">
            <label htmlFor="internal_name">Internal Name *</label>
            <div className="input-group">
              <input
                value={this.state.internal_name}
                onChange={this.handleChange}
                id="internal_name"
                name="internal_name"
                type="text"
                placeholder="Internal Name"
                disabled={!isNewPackage && !editables.internal_name}
                className={
                  "form-control input py-2" +
                  (errors.internal_name ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("internal_name")}
            </div>
            <span className="help-block">{errors.internal_name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="is_active">Status*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.is_active}
                onChange={(event, value) => {
                  this.setState({ is_active: value.value });
                }}
                id="is_active"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={!isNewPackage && !editables.is_active}
                className={
                  "form-control" + (errors.is_active ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("is_active")}
            </div>
            <span className="help-block">{errors.is_active}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="subscription_name">External Name (English)*</label>
            <div className="input-group">
              <input
                value={this.state.subscription_name}
                onChange={this.handleChange}
                id="subscription_name"
                name="subscription_name"
                type="text"
                placeholder="External Name in English"
                disabled={!isNewPackage && !editables.subscription_name}
                className={
                  "form-control py-2" +
                  (errors.subscription_name ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("subscription_name")}
            </div>
            <span className="help-block">{errors.subscription_name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="subscription_name_ar">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={this.state.subscription_name_ar}
                onChange={this.handleChange}
                id="subscription_name_ar"
                name="subscription_name_ar"
                type="text"
                placeholder="External Name in Arabic"
                disabled={!isNewPackage && !editables.subscription_name_ar}
                className={
                  "form-control input-arabic py-2" +
                  (errors.subscription_name_ar ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("subscription_name_ar")}
            </div>
            <span className="help-block">{errors.subscription_name_ar}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="display_name">Display Name (English)*</label>
            <div className="input-group">
              <input
                value={this.state.display_name ?? ""}
                onChange={this.handleChange}
                id="display_name"
                name="display_name"
                type="text"
                placeholder="Display in English"
                className={
                  "form-control py-2" + (errors.display_name ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.display_name}
              />
              {this.renderPencilIcon("display_name")}
            </div>
            <span className="help-block">{errors.display_name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="display_name_ar">Display Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={this.state.display_name_ar ?? ""}
                onChange={this.handleChange}
                id="display_name_ar"
                name="display_name_ar"
                type="text"
                placeholder="Display Name in Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.display_name_ar ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.display_name_ar}
              />
              {this.renderPencilIcon("display_name_ar")}
            </div>
            <span className="help-block">{errors.display_name_ar}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="special_tag">Special Tag (English)</label>
            <div className="input-group">
              <input
                value={this.state.special_tag ?? ""}
                onChange={this.handleChange}
                id="special_tag"
                name="special_tag"
                type="text"
                placeholder="Special Tag in English"
                className={
                  "form-control py-2" + (errors.special_tag ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.special_tag}
              />
              {this.renderPencilIcon("special_tag")}
            </div>
            <span className="help-block">{errors.special_tag}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="special_tag_ar">Special Tag (Arabic)</label>
            <div className="input-group">
              <input
                value={this.state.special_tag_ar ?? ""}
                onChange={this.handleChange}
                id="special_tag_ar"
                name="special_tag_ar"
                type="text"
                placeholder="Special Tag in Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.special_tag_ar ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.special_tag_ar}
              />
              {this.renderPencilIcon("special_tag_ar")}
            </div>
            <span className="help-block">{errors.special_tag_ar}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="product">Product Category</label>
            <div className="input-group">
              <Dropdown
                value={this.state.product || "none"}
                onChange={(event, value) => {
                  const updatedSecondaryCategoryOptions = value.value === "none"
                    ? secondaryCategoryOptions // If "none" is selected, keep original options
                    : this.productNameList().filter((catOption) => catOption.id !== value.value)

                  this.setState({
                    product: value.value === "none" ? null : value.value,
                    secondaryCategoryOptions: updatedSecondaryCategoryOptions
                  })
                }}
                id="product"
                name="product"
                placeholder="Product"
                search
                selection
                options={this.state.productOptionsEdit}
                disabled={!isNewPackage && !editables.product}
                className={
                  "form-control" + (errors.product ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("product")}
            </div>
            <span className="help-block">{errors.product}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.position_value}
                onChange={(event, value) => {
                  this.setState({ position_value: value.value });
                }}
                id="rank"
                placeholder="rank"
                search
                selection
                options={this.state.rankObject}
                disabled={!isNewPackage && !editables.rank}
                className={
                  "form-control" + (errors.rank ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
        </div>
        {/* <div className="row">
        <div className="form-group col-6">
            <label htmlFor="gender">Gender*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.gender}
                onChange={(event, value) => {
                  this.setState({ gender: value.value });
                }}
                id="gender"
                placeholder="Gender"
                search
                selection
                options={this.state.arrGenderList}
                disabled={!isNewPackage && !editables.gender}
                className={
                  "form-control" + (errors.gender ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("gender")}
            </div>
            <span className="help-block">{errors.gender}</span>
          </div>
        </div> */}
         <div className="row">
        <div className="form-group col-6">
            <label htmlFor="partnerId">Select Partner Id</label>
            <Select
                key={`my_unique_select_key__${this.state.partner_id}`}
                value={this.state.partner_id===null?this.createPartnerOption(this.state):this.createPartnerOption(this.state)}
                onChange={(event) => {
                  this.handleSelectPartner(event.value);
                }}
                options={valeoPartnerOptions || []}
                style={selectStyles}
                placeholder="Select Parnter"
                searchable
                lazyLoad
              />
              {/* {this.renderPencilIcon("partner_id")} */}
            
            <span className="help-block">{errors.partner_id}</span>
          </div>
          <div className="form-group col-6">
            
            <label htmlFor="is_best_seller"> Is Bestseller</label>
            <div className="input-group">
              <Dropdown
                value={this.state.is_best_seller}
                onChange={(event, value) => {
                  this.setState({ is_best_seller: value.value });
                }}
                id="is_best_seller"
                placeholder="Best Seller"
                search
                selection
                options={this.state.bestSellerOptions}
                disabled={!isNewPackage && !editables.is_best_seller}
                className={
                  "form-control" + (errors.is_best_seller ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("is_best_seller")}
            </div>
              
            
            <span className="help-block">{errors.is_best_seller}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="member_applicable">Members Applicable*</label>
            <div className="input-group">
              <input
                value={this.state.member_applicable ?? ""}
                onChange={(event) => {
                  let numberOfMembers = event.target.valueAsNumber;
                  if (numberOfMembers < 1) numberOfMembers = 1;
                  const newMember = [...member];
                  if (numberOfMembers > newMember.length) {
                    while (numberOfMembers > newMember.length) {
                      newMember.push(this.getDefaultMemberGender(newMember.length + 1));
                    }
                  }
                  if (numberOfMembers < newMember.length) {
                    while (numberOfMembers < newMember.length) {
                      newMember.pop();
                    }
                  }
                  this.setState({ 
                    member_applicable: numberOfMembers,
                    member: newMember,
                  })
                }}
                id="member_applicable"
                name="member_applicable"
                type="number"
                min="1"
                placeholder="Members Applicable"
                disabled={!isNewPackage && !editables.member_applicable}
                className={
                  "form-control py-2" +
                  (errors.member_applicable ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("member_applicable")}
            </div>
            <span className="help-block">{errors.member_applicable}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="Zoho AccountId">Zoho Account ID *</label>
            <div className="input-group">
              <input
                value={this.state.zoho_account_id ?? ""}
                onChange={this.handleChange}
                id="zoho_account_id"
                name="zoho_account_id"
                type="number"
                maxLength="45"
                placeholder="Zoho account Id"
                className={
                  "form-control py-2" + (errors.zoho_account_id ? " has-error" : "")
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                disabled={!isNewPackage && !editables.zoho_account_id}
              />
              {this.renderPencilIcon("zoho_account_id")}
            </div>
            <span className="help-block">{errors.zoho_account_id}</span>
          </div>
        </div>
        {<div className="row">
          <div className="form-group col-6">
            <label htmlFor="is_active">Default Coach</label>
            <div className="input-group">
              <Dropdown
                value={this.state.default_coach_id ||null}
                onChange={(event, value) => {
                  console.log("value",value)
                  this.setState({ default_coach_id: value.value });
                }}
                id={`default_coach_id`}
                placeholder="None"
                search
                selection
                options={this.state.defaultCoachOptions}
                disabled={!isNewPackage && !editables.default_coach_id}
                className={
                  "form-control" + (errors.default_coach_id ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("default_coach_id")}
            </div>
          
        </div>
        </div>
        }
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internal_category">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={this.state.internal_category || "None"}
                onChange={(event, value) => {
                  this.setState({ internal_category: value.value });
                }}
                id="internal_category"
                placeholder="Internal Category"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewPackage && !editables.internal_category}
                className={
                  "form-control" + (errors.internal_category ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("internal_category")}
            </div>
            <span className="help-block">{errors.internal_category}</span>
          </div>
         
          <div className="form-group col">
             <label>Select Secondary Category</label>
             <div className="input-group">
             <SelectSecondaryCategory
              categories={secondaryCategoryOptions??[]}
              selectedCategories={secondary_categories??[]}
              onSelect={(values) => {
                const filteredProductOptions = values.length > 0
                  ? this.state.productOptions.filter((item) => {
                    // Filter out items whose id matches any value in `values`
                    return !values.some((value) => value.value === item.key);
                  })
                  : this.state.productOptions;
                  console.log("values are",values);
                  console.log("product options",filteredProductOptions);
                this.setState({secondary_categories: values.map((it) => it.value),
                    productOptionsEdit: filteredProductOptions,
                    
                  });
                }}
              isDisabled={!isNewPackage && !editables.secondary_categories}
                className={
                  "form-control" + (errors.secondary_categories ? " has-error" : "")
                }
                menuPlacement={"top"}
             />
               {this.renderPencilIcon("secondary_categories")}
             </div>
             <span className="help-block">{errors.secondary_categories}</span>
          </div>
        </div>
        {genderInputs}
        {this.state.member_applicable && this.state.member_applicable >= 1 ? bloodPackageBundleSections : null}
        <div className="row">
          <div className="form-group col">
            <div className="input-group">
              <input
                value={true}
                checked={this.state.is_b2b_package}
                onChange={(event, value) => {
                  this.setState({
                    is_b2b_package: event.target.checked
                  }
                  );
                }}
                id="is_b2b_package"
                name="is_b2b_package"
                type="checkbox"
                className="larger-checkbox"
              />
              &nbsp;&nbsp; is this a B2B Package?
            </div>

          </div>
          {
          <div className="form-group col">
            <label htmlFor="is_active">Default Secondary Coach</label>
            <div className="input-group">
              <Dropdown
                value={this.state.default_secondary_coach_id ||null}
                onChange={(event, value) => {
                  this.setState({ default_secondary_coach_id: value.value });
                }}
                id={`default_secondary_coach_id`}
                placeholder="None"
                search
                selection
                options={this.state.secondaryCoachOptions}
                disabled={!isNewPackage && !editables.default_secondary_coach_id}
                className={
                  "form-control" + (errors.default_secondary_coach_id ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("default_secondary_coach_id")}
            </div>
            <span className="help-block">{errors.default_secondary_coach_id}</span>
          </div>
        }
        </div>
      </div>
      

    const descriptionForm =
      <div className="container mt-4">
       
        <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Description (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.descriptionTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "descriptionTxtEn")
                  }
                  disabled={!isNewPackage && !editables.descriptionTxtEn}
                />
                {this.renderPencilIcon("descriptionTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Description (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.descriptionTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "descriptionTxtAr")
                  }
                  disabled={!isNewPackage && !editables.descriptionTxtAr}
                />
                {this.renderPencilIcon("descriptionTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>What happens next ? (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.whatHappensNextTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "whatHappensNextTxtEn")
                  }
                  disabled={!isNewPackage && !editables.whatHappensNextTxtEn}
                />
                {this.renderPencilIcon("whatHappensNextTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>What happens next ? (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.whatHappensNextTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "whatHappensNextTxtAr")
                  }
                  disabled={!isNewPackage && !editables.whatHappensNextTxtAr}
                />
                {this.renderPencilIcon("whatHappensNextTxtAr")}
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Appointment Description (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.appointmentDescriptionTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "appointmentDescriptionTxtEn")
                  }
                  disabled={!isNewPackage && !editables.appointmentDescriptionTxtEn}
                />
                {this.renderPencilIcon("appointmentDescriptionTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Appointment Description (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.appointmentDescriptionTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "appointmentDescriptionTxtAr")
                  }
                  disabled={!isNewPackage && !editables.appointmentDescriptionTxtAr}
                />
                {this.renderPencilIcon("appointmentDescriptionTxtAr")}
              </div>
            </div>
          </div> */}
              <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Biomarkers Note (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.biomarkersNoteTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "biomarkersNoteTxtEn")
                  }
                  disabled={!isNewPackage && !editables.biomarkersNoteTxtEn}
                />
                {this.renderPencilIcon("biomarkersNoteTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Biomarkers Note (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.biomarkersNoteTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "biomarkersNoteTxtAr")
                  }
                  disabled={!isNewPackage && !editables.biomarkersNoteTxtAr}
                />
                {this.renderPencilIcon("biomarkersNoteTxtAr")}
              </div>
            </div>
          </div>
           <div className="row">
          <div className="form-group col">
            <label htmlFor="subscription_description">Description (English)*</label>
            <div className="input-group">
              <textarea
                value={this.state.subscription_description}
                onChange={this.handleChange}
                id="subscription_description"
                name="subscription_description"
                type="text"
                placeholder="Description in English"
                readOnly={!isNewPackage && !editables.subscription_description}
                className={
                  "form-control" +
                  (errors.subscription_description ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("subscription_description")}
            </div>
            <span className="help-block">{errors.subscription_description}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="subscription_description_ar">Description (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={this.state.subscription_description_ar}
                onChange={this.handleChange}
                id="subscription_description_ar"
                name="subscription_description_ar"
                type="text"
                placeholder="Description in Arabic"
                readOnly={!isNewPackage && !editables.subscription_description_ar}
                className={
                  "form-control input-arabic" +
                  (errors.subscription_description_ar ? "has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("subscription_description_ar")}
            </div>
            <span className="help-block">{errors.subscription_description_ar}</span>
          </div>
        </div> 
         <div className="row">
          <div className="form-group col">
            <label htmlFor="what_happens_next">What happens next? (English)</label>
            <div className="input-group">
              <textarea
                value={this.state.what_happens_next}
                onChange={this.handleChange}
                id="what_happens_next"
                name="what_happens_next"
                type="text"
                placeholder="What happens next? in English"
                readOnly={!isNewPackage && !editables.what_happens_next}
                className={
                  "form-control" +
                  (errors.what_happens_next ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("what_happens_next")}
            </div>
            <span className="help-block">{errors.what_happens_next}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="what_happens_next_ar">What happens next? (Arabic)</label>
            <div className="input-group">
              <textarea
                value={this.state.what_happens_next_ar}
                onChange={this.handleChange}
                id="what_happens_next_ar"
                name="what_happens_next_ar"
                type="text"
                placeholder="What happens next? in Arabic"
                readOnly={!isNewPackage && !editables.what_happens_next_ar}
                className={
                  "form-control input-arabic" +
                  (errors.what_happens_next_ar ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("what_happens_next_ar")}
            </div>
            <span className="help-block">{errors.what_happens_next_ar}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="appointment_descr">Appointment Description? (English)</label>
            <div className="input-group">
              <textarea
                value={this.state.appointment_descr}
                onChange={this.handleChange}
                id="appointment_descr"
                name="appointment_descr"
                type="text"
                placeholder="Appointment Description? in English"
                readOnly={!isNewPackage && !editables.appointment_descr}
                className={
                  "form-control" +
                  (errors.appointment_descr ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("appointment_descr")}
            </div>
            <span className="help-block">{errors.appointment_descr}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="appointment_descr_ar">Appointment Description? (Arabic)</label>
            <div className="input-group">
              <textarea
                value={this.state.appointment_descr_ar}
                onChange={this.handleChange}
                id="appointment_descr_ar"
                name="appointment_descr_ar"
                type="text"
                placeholder="Appointment Description? in Arabic"
                readOnly={!isNewPackage && !editables.appointment_descr_ar}
                className={
                  "form-control input-arabic" +
                  (errors.appointment_descr_ar ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("appointment_descr_ar")}
            </div>
            <span className="help-block">{errors.appointment_descr_ar}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="biomarkers_note_en">Biomarkers Note (English)*</label>
            <div className="input-group">
              <textarea
                value={this.state.biomarkers_note_en}
                onChange={this.handleChange}
                id="biomarkers_note_en"
                name="biomarkers_note_en"
                type="text"
                placeholder="Biomarkers Note in English"
                readOnly={!isNewPackage && !editables.biomarkers_note_en}
                className={
                  "form-control" +
                  (errors.biomarkers_note_en ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("biomarkers_note_en")}
            </div>
            <span className="help-block">{errors.biomarkers_note_en}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="biomarkers_note_ar">Biomarkers Note (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={this.state.biomarkers_note_ar}
                onChange={this.handleChange}
                id="biomarkers_note_ar"
                name="biomarkers_note_ar"
                type="text"
                placeholder="Biomarkers Note in Arabic"
                readOnly={!isNewPackage && !editables.biomarkers_note_ar}
                className={
                  "form-control input-arabic" +
                  (errors.biomarkers_note_ar ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("biomarkers_note_ar")}
            </div>
            <span className="help-block">{errors.biomarkers_note_ar}</span>
          </div>
        </div>
         <div className="row">
          <div className="form-group col">
            <label htmlFor="reports_timeline_note_en">Reports Timeline Note (English)*</label>
            <div className="input-group">
              <textarea
                value={this.state.reports_timeline_note_en}
                onChange={this.handleChange}
                id="reports_timeline_note_en"
                name="reports_timeline_note_en"
                type="text"
                placeholder="Reports Timeline Note in English"
                readOnly={!isNewPackage && !editables.reports_timeline_note_en}
                className={
                  "form-control" +
                  (errors.reports_timeline_note_en ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("reports_timeline_note_en")}
            </div>
            <span className="help-block">{errors.reports_timeline_note_en}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="reports_timeline_note_ar">Reports Timeline Note (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={this.state.reports_timeline_note_ar}
                onChange={this.handleChange}
                id="reports_timeline_note_ar"
                name="reports_timeline_note_ar"
                type="text"
                placeholder="Reports Timeline Note in Arabic"
                readOnly={!isNewPackage && !editables.reports_timeline_note_ar}
                className={
                  "form-control input-arabic" +
                  (errors.reports_timeline_note_ar ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("reports_timeline_note_ar")}
            </div>
            <span className="help-block">{errors.reports_timeline_note_ar}</span>
          </div>
        </div>
         
    
        
        {/*<div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image*</label>
            <div className="input-group">
              <input
                onChange={this.selecteImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewPackage && !editables.image}
                className={
                  "form-control" + (errors.image ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col ">
            {this.state.imgSrc && (
              <>
                <label>Selected Thumbnail Image</label>
                <div className="form-image-container">
                  <img
                    src={this.state.imgSrc}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
        </div>*/}

<div className="row mt-3 mb-3">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>

        <div className="row">
        <div className="form-group col">
        <h6>Primary Image/Video:</h6>
        
          <div className="row">
          <div className="col-3">
            <label>
              
                <input
                    type="radio"
                    name="media"
                    value="Video"
                    checked={this.state.primary_media_type === "Video"}
                    onChange={(e)=>this.handleMediaChange(e,"Video")}
                />  Video 
          </label>  </div>
          <div className="col-3">
            <label>
         
                <input
                    type="radio"
                    name="media"
                    value="Image"
                    checked={this.state?.primary_media_type == "Image"}
                    onChange={(e)=>this.handleMediaChange(e,"Image")}
                /> Image 
            </label> </div>
            </div>


          
        </div></div>
        
       { this.state.primary_media_type=="Image" ?(<div className="row">
          <div className="form-group col">
            <label htmlFor="imageInputPrimary">Primary Image*</label>
            <div className="input-group">
              <input
               
                onChange={ this.selecteImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewPackage && !editables.image  }
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {this.state.imgSrc && (
              <>
                <label htmlFor="selected_package_image">
                  Selected Thumbnail Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={this.state.imgSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>):
        (<div  className="row">
           <div className="form-group col">
           <label htmlFor="Video File">Video File*</label>
           <div className="input-group">
              <input
              
                onChange={(e) => this.handleIconChange(e, 'Video URL')}
                id="Video URL"
                name="videoUrl"
                type="file"
                accept="video/*"
                disabled={!isNewPackage && !editables.videoUrl}
                className={"form-control" + (errors.videoUrl ? " has-error" : "")}
              />
              {this.renderPencilIcon("videoUrl")}
            </div>
            <span className="help-block">{errors.videoUrl}</span>
            <br/>
            <label htmlFor="videoThumbnailImage">Video Thumbnail*</label>
           <div className="input-group">
              <input
                onChange={ this.selecteImage}
                id="videoThumbnailImage"
                name="videoThumbnailImage"
                type="file"
                accept="image/*"
                disabled={!isNewPackage && !editables.videoThumbnailImage }
                className={"form-control" + (errors.videoThumbnailImage ? " has-error" : "")}
              />
              {this.renderPencilIcon("videoThumbnailImage")}
            </div>
            <span className="help-block">{errors.videoThumbnailImage}</span>

       </div>
       <div className="form-group col">
        {this.state.video_s3_url? (<><div>
                <label htmlFor="selected_package_image">
                  Selected Video
                </label></div><video controls width="100%" height="150" key={this.state.video_s3_url}>
          <source src={this.state.video_s3_url} type="video/mp4" />
          </video></>):null}
       {this.state.imgSrc && this.state.primary_media_type=="Video" && (
              <>
                <div>
                  Selected Thumbnail Image
                </div>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={this.state.imgSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "150px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                </>)}

       </div>
       </div>

          )}
        <div className="row">
          <div className="col">
            <OtherImagesPackages
              id="english"
              label="Other Images"
              images={this.state.packages_media ?? []}
              onImagesUpdated={this.handleOtherImagesUpdated}
              isEditable={isEditable}
              selectedMedia={"Image"}
              packageId={this.state.edit_id}
            />
          </div>
        </div>

        
      </div>;
      const nonBloodBiomarkerDetails=(
        <>
        <div className="row mt-2">
            <div className="col separator">
              <span>Non Biomarker Test Details</span>
            </div>
          </div>
          <NonBiomarkerTestDetails 
          nonBloodBiomarkerTests={this.state.non_blood_biomarkers}
          nonBloodBiomarkerTestUpdated={this.handleNonBloodBiomarkerTestUpdated}
          isEditable={this.state.is_non_blood_biomarker_addition}
          />
        </>
      );
    
    const addOnsForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="tests">Tests*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.tests}
                onChange={(event, value) => {
                  this.setState({ tests: value.value });
                }}
                id="tests"
                placeholder="Select tests"
                search
                fluid
                multiple
                selection
                options={
                  this.state.edit_id
                    ? this.newFormattedList(this.state.testList, this.state.tests)
                    : this.state.testList
                }
                disabled={!isNewPackage && !editables.tests}
                className={
                  "form-control" + (errors.tests ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("tests")}
            </div>
            <span className="help-block">{errors.tests}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <button type="button" 
              onClick={(event) => {
                this.setState(
                  { is_tests_are_final: true }, 
                  () => this.updatePopularMiniPackagesDropdownList()
                )
              }}
              className={`btn btn-lg ${this.state.is_tests_are_final ? "btn-success" : "btn-secondary"}`}>
                Added All Tests
            </button>
          </div>
        </div>
        
       
        <div className="row mt-2">
          <div className="form-group col-4">Allow Mini Package Addition</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableOneTime"
              toggled={this.state.is_allow_mini_package_addition ?? false}
              disabled={!this.state.is_tests_are_final ? true : !isEditable}
              onToggle={(isToggled) =>
                this.setState({ is_allow_mini_package_addition: isToggled })
              }
            />
          </div>
          {this.state.is_allow_mini_package_addition && (
            <div className="form-group col-6">
              <label>Popular Mini Package List</label>
              <div className="input-group">
                <SelectMiniPackages
                  miniPackages={this.state.miniPackagesDropdownList ?? []}
                  selectedMiniPackageIds={this.state.minipackages ?? []}
                  onSelect={(options) => {
                    this.setState({
                      minipackages: options.map((it) => it.value),
                    });
                  }}
                  menuPlacement={this.state.tests && this.state.tests.length > 8 ? "top" : "bottom"}
                  showOnlyActive={true}
                  isDisabled={!isNewPackage && !editables.minipackages}
                  className={
                    "form-control" +
                    (errors.minipackages ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("minipackages")}
              </div>
              <span className="help-block">{errors.minipackages}</span>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="form-group col-4">Allow Custom Package Addition</div>
          <div className="form-group col-2">
            <ToggleSwitch
              // id="enableOneTime"
              toggled={this.state.is_allow_custom_package_addition ?? false}
              disabled={!this.state.is_tests_are_final ? true : !isEditable}
              onToggle={(isToggled) =>
                this.setState({ is_allow_custom_package_addition: isToggled })
              }
            />
          </div>
          {this.state.is_allow_custom_package_addition && (
            <div className="form-group col-6">
              <label>Popular Custom Package List</label>
              <div className="input-group">
                <SelectCustomPackages
                  customPackages={this.state.customPackagesDropdownList ?? []}
                  selectedCustomPackageIds={this.state.customPackages?? []}
                  onSelect={(options) => {
               
                    this.setState({
                      customPackages: options.map((it) => it.value),
                    });
                  }}
                  menuPlacement={this.state.tests && this.state.tests.length > 8 ? "top" : "bottom"}
                  showOnlyActive={true}
                  isDisabled={!isNewPackage && !editables.custompackages}
                  className={
                    "form-control" +
                    (errors.custompackages ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("custompackages")}
              </div>
              <span className="help-block">{errors.customPackages}</span>
            </div>
          )}
        </div>

        <div className="row mt-2">
          <div className="form-group col-4">Allow Coach Consultation Addition</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="coachSuggestion"
              toggled={this.state.is_allow_coach_cons_pkg_addition ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({ is_allow_coach_cons_pkg_addition: isToggled })
              }
            />
          </div>
          {this.state.is_allow_coach_cons_pkg_addition && (
            <div className="form-group col-6">
              <label>Popular Coach Consultation List</label>
              <div className="input-group">
                <SelectCoachConsultation
                 countryId={selectedCountry?.id}
                  selectedCoachConsultationIds={this.state?.coach_cons_pkg_addons ?? ""}
                  onCoachConsultationSelect={(event) => {
                    this.setState({
                      coach_cons_pkg_addons: event.value,
                    });
                  }}
                  menuPlacement={this.state.tests && this.state.tests.length > 8 ? "top" : "bottom"}
                  showOnlyActive={true}
                  isDisabled={!isNewPackage && !editables.coachConsultationpackages}
                  className={
                    "form-control" +
                    (errors.coachConsultationpackages ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("coachConsultationpackages")}
              </div>
              <span className="help-block">{errors.coachConsultationpackages}</span>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="form-group col-4">Allow Supplement Addition</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="supplementSection"
              toggled={this.state.is_allow_supplement_addition ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({ is_allow_supplement_addition: isToggled })
              }
            />
          </div>
          {this.state.is_allow_supplement_addition && (
            <div className="form-group col-6">
              <label>Popular Supplements List</label>
              <div className="input-group">
                <SelectSupplements
                  id="suggestedSupplements"
                  name="suggestedSupplements"
                  countryId={selectedCountry?.id}
                  selectedSupplementIds={this.state.supplement_addons ?? []}
                  onSupplementSelect={(options) => {
                    this.setState({
                      supplement_addons: options.map((it) => it.value),
                    });
                  }}
                  menuPlacement={this.state.tests && this.state.tests.length > 8 ? "top" : "bottom"}
                  showOnlyActive={true}
                  isDisabled={!isNewPackage && !editables.supplements}
                  className={
                    "form-control" +
                    (errors.supplements ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("supplements")}
              </div>
              <span className="help-block">{errors.supplements}</span>
            </div>
          )}
        </div>

         <div className="row">
         <div className="form-group col-4">Allow Non Blood Biomarker addition</div>
         <div className="form-group col-2">
            <ToggleSwitch
              id="nonBloodBiomarker"
              toggled={this.state.is_non_blood_biomarker_addition ?? false}
              //disabled={!this.state.is_tests_are_final ? true : !isEditable}
              onToggle={(isToggled) =>
                this.setState({ is_non_blood_biomarker_addition: isToggled })
              }
            />
           
          </div>
          

        </div>

        {this.state.is_non_blood_biomarker_addition && <div className="row">
          <div className="form-group col">
            <label htmlFor="tests">Non Blood Biomarkers *</label>
            <div className="input-group">
              <Dropdown
                value={this.state.non_blood_biomarker}
                onChange={(event, value) => {
                  this.setState({ non_blood_biomarker: value.value });
                }}
                id="nonBloodBiomarkers"
                placeholder="Select Non Blood Biomarkers"
                search
                fluid
                multiple
                selection
                // options={
                //   this.state.edit_id
                //     ? this.newFormattedNonBloodBiomarkerList(this.state.nonBloodBiomarkerOptions, this.state.nonBloodBiomarkers)
                //     : this.state.nonBloodBiomarkerOptions
                // }
                options={this.state.nonBloodBiomarkerOptions}
                disabled={!isNewPackage && !editables.tests}
                className={
                  "form-control" + (errors.tests ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("tests")}
            </div>
            <span className="help-block">{errors.tests}</span>
          </div>
        </div>}
        {/* {nonBloodBiomarkerDetails} */}
      </div>;

    const formPricingOneTimeContent = (
      <>
        
        <div className="row">
          <div className="form-group col">
            <label htmlFor="retail_price">Retail Price*</label>
            <div className="input-group">
              <input
                value={this.state.retail_price}
                onChange={this.handleChange}
                id="retail_price"
                name="retail_price"
                type="text"
                placeholder="Retail Price"
                disabled={!isNewPackage && !editables.retail_price}
                className={
                  "form-control py-2" +
                  (errors.retail_price ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("retail_price")}
            </div>
            <span className="help-block">{errors.retail_price}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="price">Final Price*</label>
            <div className="input-group">
              <input
                value={this.state.price}
                onChange={this.handleChange}
                id="price"
                name="price"
                type="text"
                placeholder="Final Price"
                disabled={!isNewPackage && !editables.price}
                className={
                  "form-control py-2" +
                  (errors.price ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("price")}
            </div>
            <span className="help-block">{errors.price}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="discount_type">Discount Type*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.discount_type}
                onChange={(event, value) => { this.setState({ discount_type: value.value }); }}
                id="discount_type"
                placeholder="Percentage or Fixed"
                search
                selection
                options={this.state.discountOptions}
                disabled={!isNewPackage && !editables.discount_type}
                className={
                  "form-control" +
                  (errors.discount_type ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discount_type")}
            </div>
            <span className="help-block">{errors.discount_type}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="discount_value">Discount Value*</label>
            <div className="input-group">
              <input
                value={this.state.discount_value}
                onChange={this.handleChange}
                id="discount_value"
                name="discount_value"
                type="number"
                placeholder="Discount Value"
                disabled={!isNewPackage && !editables.discount_value}
                className={
                  "form-control " +
                  (errors.discount_value ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discount_value")}
            </div>
            <span className="help-block">{errors.discount_value}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label>Old Pricing Value</label>
            <input
              value={this.state.old_price}
              id="old_price"
              name="old_price"
              type="number"
              placeholder="Old Pricing Value"
              readOnly
              className="form-control py-2"
            />
          </div>
          <div className="form-group col"></div>
        </div>
      </>
    );

    // Currently one time pricing is always enabled so we are not using this.
    // But keeping it here for future purpose.
    const formPricingOneTime = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>One Time</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">One Time Pricing</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enable_one_time"
              toggled={this.state.enable_one_time ?? false}
              onToggle={(isToggled) =>
                this.setState({ enable_one_time: isToggled })
              }
              disabled={!isEditable}
            />
          </div>
        </div>
        {this.state.enable_one_time && formPricingOneTimeContent}
      </>
    );

    const formPricingSubscriptionContent = (
      <div className="container mt-2">
        <span className="help-block">{errors.subscription_items}</span>
        <SubscriptionForm
          subscriptions={this.state.subscription_items || []}
          onSubscriptionUpdated={this.handleSubscriptionsItemsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formPricingSubscription = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>Subscription</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">Subscription</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableSubscription"
              toggled={this.state.enable_subscription ?? false}
              onToggle={(isToggled) =>
                this.setState({ enable_subscription: isToggled })
              }
              disabled={!isEditable}
            />
          </div>
        </div>
        {this.state.enable_subscription && formPricingSubscriptionContent}
      </>
    );

    const bloodPackageCitiesDetails=(
      <>
      <div className="row mt-2">
          <div className="col separator">
            <span>City Level Details</span>
          </div>
        </div>
        <PackageCityDetails
          cityPackageDetails={this.state.bloodPackageCities || []}
          onCityPackageDetailsUpdated={this.handleCityPackageItemsUpdated}
          packageType={"bloodPackage"}
          packageId={this.state.edit_id || null}
          selectedCountry={selectedCountry}
          isEditable={isEditable}
        />
      </>
    )
    const packageOrderDetailsForm=<>
    <PackageOrders
    packageOrderDetails={bloodPackageIdOrders}
    packageType={"Blood_Package"}
    packageId={edit_id}
    totalRows={packageIdTotalRows}
    />

    </>
    

    const locationAndPricingForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <div className="input-group">
              <input
                value={true}
                checked={!this.state.is_eligible_for_cod || false}
                onChange={(event, value) => {
                  this.setState({
                    is_eligible_for_cod: !event.target.checked
                  });
                }}
                id="isCODRemove"
                name="isCODRemove"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Remove COD for this Product
               </div> 
            </div>
            <div className="form-group col-6">
            <div className="input-group">
              <input
                value={true}
                checked={this.state.is_vat_excluded || false}
                onChange={(event, value) => {
                  this.setState({
                    is_vat_excluded: event.target.checked
                  });
                }}
                id="isVATRemove"
                name="isVATRemove"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; No VAT for this product
               </div> 
            </div>
          </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={isNewPackage 
                ? selectedCountry && selectedCountry.country_name
                : packageCountry && packageCountry.country_name
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="currency">Currency</label>
            <input
              value={isNewPackage
                ? selectedCountry && selectedCountry.currency
                : packageCountry && packageCountry.currency
              }
              type="text"
              id="currency"
              name="currency"
              readOnly
              className="form-control"
            />
          </div>
        </div>
        {formPricingOneTimeContent}
        {bloodPackageCitiesDetails}
        {formPricingSubscription}
      </div>;

    const fastingHoursForm =
      <div className={isNewPackage ? "row mt-2" : "row"}>
        <div className="form-group col-6">
          <label htmlFor="fasting_hours">Fasting Hours*</label>
          <div className="input-group">
            <input
              value={this.state.fasting_hours ?? null}
              onChange={(event) => {
                this.setState({ fasting_hours: event.currentTarget.value ? Number(event.currentTarget.value) : null });
              }}
              id="fasting_hours"
              name="fasting_hours"
              type="number"
              placeholder="Fasting Hours"
              disabled={!isNewPackage && !editables.fasting_hours}
              className={
                "form-control py-2" +
                (errors.fasting_hours ? " has-error" : "")
              }
            />
            {this.renderPencilIcon("fasting_hours")}
          </div>
          <span className="help-block">{errors.fasting_hours}</span>
        </div>
      </div>;

  const coachSupplementSuggestionsForm=(
    <>
      <div className="row mt-2">
          <div className="col separator">
            <span>Coach Suggested Supplements</span>
          </div>
        </div>
           <div className="input-group">
              <input
                value={true}
                checked={is_default_suggested_supplement_required && is_default_suggested_supplement_required || false}
                onChange={(event, value) => {
                  this.setState({
                    is_default_suggested_supplement_required:event.target.checked
                  });
                }}
                id="isRequired"
                name="isRequired"
                type="checkbox"
                className="larger-checkbox"
              />
              <label style={{marginLeft:"10px"}}>is Default Suggested Supplements Required</label>
            </div>
            <SupplementSuggestion
                suggestions={this.state.mappingSupplementSuggestions??[]}
                countryId={selectedCountry?.id ?? null}
                suggestedSupplements={supplements}
                suggestionsUpdate={this.handleSupplementSuggestions}
                isEditable={isEditable}
                
              />
            <PackageSampleDetails
              packageSamples={this.state.mappingPackageSamples?? []}
              countryId={selectedCountry?.id ?? null}
              allSamples={sampleDetails}
              packageSamplesUpdate={this.handlePackageSampleDetailsUpdate} 
              isEditable={isEditable}
              sampleOptions={sampleDetailsOptions}
            />


    </>
  )
    const sampleTypeForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="sample_type">Sample Type*</label>
            <div className="input-group">
              <SelectSampleType
                sampleTypes={this.state.packageSampleTypes ?? []}
                selectedSampleType={this.state.sample_type}
                onSampleTypeSelect={(selectedOption) => {
                  this.setState({ sample_type: selectedOption.value });
                }}
                id="sample_type"
                name="sample_type"
                isDisabled={!isNewPackage && !editables.sample_type}
                className={
                  "form-control" + (errors.sample_type ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("sample_type")}
            </div>
            <span className="help-block">{errors.sample_type}</span>
          </div>
        </div>
        <div className="row mt-1">
          <span style={{ marginLeft: "1rem", marginRight: "0.5rem" }}>Fasting Required*</span>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="yes"
              name="yes"
              value='yes'
              onChange={(event, value) => this.setState({ fasting: event.target.checked }, () => this.updateFastingHours())}
              disabled={!isNewPackage && !editables.fasting}
              checked={this.state.fasting} />
            <label htmlFor="yes" className="custom-control-label mr-5">Yes</label>
          </div>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="no"
              name="no"
              value='no'
              onChange={(event, value) => 
                this.setState({ fasting: !event.target.checked }, () => this.updateFastingHours())}
              disabled={!isNewPackage && !editables.fasting}
              checked={!this.state.fasting} />
            <label htmlFor="no" className="custom-control-label">No</label>
          </div>
          <div className="form-group col ml-4">
            {this.renderPencilIcon("fasting")}
          </div>
          <div className="form-group col"></div>
        </div>
        {this.state.fasting && fastingHoursForm}
        {coachSupplementSuggestionsForm}
      </div>;

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          {/* <h5 className="mb-4">Manage Packages</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => { this.search = input; }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={() => this.setState({ 
                  isNewPackage: true, 
                  is_active: false, 
                  is_best_seller:false,
                  gender: "B",
                  price: 0,
                  retail_price: 0,
                  discount_type: "percentage",
                  discount_value: 0,
                  position_value: this.state.maxRank + 1,
                  fasting: false,
                  member_applicable: 1,
                  member: new Array(1).fill(this.getDefaultMemberGender()),
                  partner_id:null,
                  internal_category:"None",
                  primary_media_type:"Image",
                }, () => this.showModal("normal"))}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>   
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="is_active"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={searchApplied ? (
              <p className="table-header">
                {"Found " + finalList.length + " results"}
              </p>
            ) : (
              ""
            )}
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal 
          size="lg" 
          show={this.state.showModal} 
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={
                this.state.edit_id
                  ? () => this.packageOperation("update")
                  : () => this.packageOperation("add")
              }
              disabled={!isEditable}
              className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="key_details_form"
            >
              <Nav variant="tabs" className="justify-content-left">
                <Nav.Item>
                  <Nav.Link eventKey="key_details_form">Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_description_form">
                    Description
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_addon_form">Tests</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_sample_type_form">Sample Type</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_location_pricing_form">
                    Location & Pricing
                  </Nav.Link>
                </Nav.Item>
               {!isNewPackage && <Nav.Item>
                  <Nav.Link eventKey="package_order_details_form">
                    Orders
                  </Nav.Link>
                </Nav.Item>}
                {this.state.pageIdForLogs!=null? 
                  <Nav.Item>
                    <Nav.Link eventKey="blood-package-logs"> Logs </Nav.Link>
                  </Nav.Item>
                :""}
              </Nav>
              <Row>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="key_details_form">
                      {detailsForm}
                    </Tab.Pane>
                    <Tab.Pane eventKey="key_description_form">
                      {descriptionForm}
                    </Tab.Pane>
                    <Tab.Pane eventKey="key_addon_form">{addOnsForm}</Tab.Pane>
                    <Tab.Pane eventKey="key_sample_type_form">{sampleTypeForm}</Tab.Pane>
                    <Tab.Pane eventKey="key_location_pricing_form">
                      {locationAndPricingForm}
                    </Tab.Pane>
                    {!isNewPackage && <Tab.Pane eventKey="package_order_details_form">
                      {packageOrderDetailsForm}
                    </Tab.Pane>}
                    {this.state.pageIdForLogs!=null? 
                      <Tab.Pane eventKey="blood-package-logs" title="Logs">
                        <Logs pageId={this.state.pageIdForLogs} pageType='BLOOD_PACKAGE' />
                      </Tab.Pane>
                    :""}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Restore
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BloodBiomarkerPackages);
