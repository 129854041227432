import React, { Component } from "react"
import Search from "components/Search/Search";
import API from "utils/API";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import {CharLimit} from "utils/CharachterLimit";
const BASE_URL_HOWITWORKS = "how-it-works/";

class HowItWorks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            howitworks: [],
            howitwork: {},
            errors: {},
            editables: {},
            showModal: false,
            loadError: null,
            searchTerm: null,
            primary_imgSrc: "",
            minRank: 1,
            maxRank: 0,
        };

        this.columns = [
            {
                name: "ID",
                selector: "id",
                width: "100px",
                sortable: true,
            },
            {
                name: "Title",
                selector: "heading",
                wrap: true,
                sortable: true,
            },
            {
                name: "Description",
                selector: "description",
                wrap: true,
                sortable: true
            },
            {
                name: "Image",
                cell: (row) => {
                    return (
                        <div className="form-image-container">
                            <img
                                src={row.image}
                                className="form-image"
                                style={{
                                    width: "100%",
                                    maxHeight: "200px",
                                    objectFit: "cover",
                                  }}
                            />
                        </div>
                    )
                },
                center: true
            },
            {
                name: "Actions",
                maxWidth: "100px",
                center: true,
                allowOverflow: true,
                cell: row => (
                    <ActionBar alignRight className="action-menu valeo-dropdown">
                        <ActionBar.Toggle disabled={!props.isEditable} variant="">
                            <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                        </ActionBar.Toggle>
                        <ActionBar.Menu>
                            <ActionBar.Item
                                onClick={() => this.handleView(row.id)}
                            >
                                <EditIcon className="icon-small"></EditIcon>Edit
                            </ActionBar.Item>

                            <ActionBar.Item
                                className="danger"
                                onClick={() => this.handleDeleteData(row.id)}
                            >
                                <DeleteIcon></DeleteIcon>
                                Delete
                            </ActionBar.Item>
                        </ActionBar.Menu>
                    </ActionBar>
                )
            }
        ]
    }

    componentDidMount() {
        this.fetchhowitworks()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedCountry !== nextProps.selectedCountry) {
            this.setState({
                countries: nextProps.countries,
                selectedCountry: nextProps.selectedCountry
            })
        }
    }

    fetchhowitworks = async () => {
        try {
            this.showLoading();
            const response = await API.get(BASE_URL_HOWITWORKS)
            const howitworks = response.data || []
            const ranks = howitworks.filter((it) => it.position_value).map((it) => it.position_value);
            const minRank = Math.min(...ranks) || 0;
            const maxRank = Math.max(...ranks) || 0;
            this.setState({ howitworks, minRank, maxRank })
            this.hideLoading()
        }
        catch (error) {
            console.error(
                "Error on fetching howitworks from this URL: ",
                BASE_URL_HOWITWORKS
            );
            console.error("Error on fetching  howitworks", error.message);
            this.hideLoading();
            this.showErrorNotification(
                "Error on loading howitworks: ",
                error.message
            );
            this.setState({
                loadError: "Some error has occured. Please try again",
            });
        }
    }

    handleChange = ({ currentTarget: input }) => {
        const howitwork = { ...this.state.howitwork };
        howitwork[input.name] = input.value;
        this.setState({ howitwork });
    };

    handleSearch = (queryString) => {
        this.setState({
            searchApplied: true,
            searchTerm: queryString ? queryString : "",
        });
    };

    handleSearchClear = () => {
        this.setState({
            searchTerm: "",
            searchApplied: false,
        });
        this.search.searchInput.focus();
    };

    handleSearchExit = (e) => {
        const value = e.target.value;
        if (!value || !value.length)
            this.setState({
                searchTerm: "",
                searchApplied: false,
            });
    };

    handleAddNew = () => {
        const howitwork = {
            // is_active: false,
            position_value: this.state.maxRank + 1,
        };
        this.setState({ howitwork, isNewhowitwork: true }, () =>
            this.showModal()
        );
        // console.log("howitwork:", howitwork)
    };

    handleView = (id) => {
        const howitworks = this.state.howitworks || [];
        const howitwork = howitworks.find((it) => it.id === id);
        if (howitwork) {
            const country = this.findCountry(howitwork.country);
            this.setState(
                {
                    isNewhowitwork: false,
                    howitwork: {
                        ...howitwork,
                        country_name: country ? country.country_name : "",
                    },
                    primary_imgSrc: howitwork.image,
                },
                () => this.showModal()
            );
        }
    };

    showModal = () => {
        // const rankOptions = this.createRankOptions();
        this.setState({ showModal: true });
    };

    handleDeleteData(id) {
        API.delete("how-it-works/" + id + "/")
            .then(response => {
                this.fetchhowitworks();
                this.props.showNotificationMessage({
                    notificationMessage: "Section Deleted successfully.",
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log("Some error occurred",error.message);
            })
    }

    hideModal = () => {
        this.setState({ showModal: false });
        this.resetForm();
    };

    resetForm = () => {
        this.setState({
            errors: {},
            editables: {},
            howitwork: {},
            isNewhowitwork: false,
            primary_imgSrc: "",
        });
    };
    // createRankOptions = () => {
    //     const { minRank, maxRank, isNewpromotion } = this.state;

    //     if (
    //         minRank === null ||
    //         minRank === undefined ||
    //         minRank === "" ||
    //         maxRank === null ||
    //         maxRank === "" ||
    //         maxRank === undefined
    //     )
    //         return;

    //     const until = isNewpromotion ? maxRank + 1 : maxRank;
    //     let rankOptions = [];
    //     for (let i = minRank; i <= until; i++)
    //         rankOptions.push({ key: i, value: i, text: i });

    //     if (rankOptions.length === 0)
    //         rankOptions.push({ key: 1, value: 1, text: 1 });

    //     return rankOptions;
    // };

    findCountry = (id) => {
        const countries = this.state.countries || [];
        return countries.find((country) => country.id === id);
    };

    handleSave = async () => {
        if (!this.state.selectedCountry) {
            this.showErrorNotification("Please select a country!");
            return;
        }
        const errors = this.validate();
        // console.log("errors:", errors)
        this.setState({ errors: errors || {} });
        if (errors) {
            this.showErrorNotification("There are some invalid form fields!");
            return;
        }
        const { howitwork } = this.state;
        const isValidId =
            howitwork.id !== null && howitwork.id !== undefined;
        const apiOptions = isValidId
            ? this.createApiOptions(
                "patch",
                `${BASE_URL_HOWITWORKS}${howitwork.id}/`
            )
            : this.createApiOptions("post", BASE_URL_HOWITWORKS);

        try {
            this.showLoading()
            await API.request(apiOptions);
            this.hideLoading();
            this.hideModal();
            this.showSuccessNotification(
                isValidId ? "Updated Successfully." : "Created Successfully."
            );
            this.fetchhowitworks();
        }
        catch (error) {
            this.hideLoading();
            this.showErrorNotification("Some error occurred: " + error.message);
            console.log("Some error occurred",error.message)
        }
    };

    validate = () => {
        const errors = {};
        const TWO_MEGA_BYTE = 2048000;
        const { heading, heading_ar, description, description_ar, image } = this.state.howitwork
        const { primary_imgSrc } = this.state;

        // if (!name || name.trim() === "")
        //     errors.name = "Name (English) is required field!";
        // else if (name.length > 100)
        //     errors.name = "Name (English) can not take more than 100 characters!";

        if (!heading || heading.trim() === "")
            errors.heading = "Title (English) is required field!"
        else if (heading && heading.length > CharLimit.TWO_HUNDRED_LIMIT)
            errors.heading = "Title (English) can not take more than 200 characters!"

        if (!heading_ar || heading_ar.trim() === "")
            errors.heading_ar = "Title (Arabic) is required field!"
        else if (heading_ar && heading_ar.length > CharLimit.TWO_HUNDRED_LIMIT)
            errors.heading_ar = "Title (Arabic) can not take more than 200 characters!"

        if (!description || description.trim() === "")
            errors.description = "Description (English) is required field"

        else if (description && description.length > CharLimit.FIVE_HUNDERED_LIMIT)
            errors.description = "Description (English) can not take more than 500 characters"

        if (!description_ar || description_ar.trim() === "")
            errors.description_ar = "Description (Arabic) is required field"

        else if (description_ar && description_ar.length > CharLimit.FIVE_HUNDERED_LIMIT)
            errors.description_ar = "Description (Arabic) can not take more than 500 characters"

        if (!image && !primary_imgSrc)
            errors.image = "Image is required field"

        else if (image && image.size && image.size > TWO_MEGA_BYTE)
            errors.image = "Image should be less than 2MB"

        // if (!image_arabic && !imgSrc_arabic)
        //     errors.image_arabic = "howitworkal Graphic (Arabic) is required field"
        // else if (image_arabic && image_arabic.size && image_arabic.size > TWO_MEGA_BYTE)
        //     errors.image_arabic = "Image should be less than 2MB"
        return Object.keys(errors).length === 0 ? null : errors;
    }

    createApiOptions = (method, url) => {
        const formData = this.createFormData();
        return {
            method,
            url,
            data: formData,
            headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            },
        };
    };

    createFormData = () => {
        const { isNewhowitwork, howitwork, selectedCountry } = this.state;
        const { image } = this.state.howitwork
        const formData = new FormData();

        if (isNewhowitwork)
            formData.append("country", selectedCountry ? selectedCountry.id : null);

        formData.append("heading", howitwork.heading);
        formData.append("heading_ar", howitwork.heading_ar)
        formData.append("description", howitwork.description);
        formData.append("description_ar", howitwork.description_ar);
        if (image && image.name) {
            formData.append("image", image, image.name);
            formData.append("image_s3", image, image.name);
        }
        // formData.append("is_active", howitwork.is_active);
        // formData.append("rank",howitwork.rank)
        return formData;
    };

    setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
    };

    renderPencilIcon = (fieldName) => {
        const isEditable = this.props.isEditable ?? true;
        return isEditable && !this.state.isNewhowitwork ? (
            <div
                onClick={() => this.setEditable(fieldName)}
                style={{ cursor: "pointer" }}
                className="input-group-append"
            >
                <i className="input-group-text fa fa-pencil"></i>
            </div>
        ) : (
            ""
        );
    }

    getFilteredhowitworks = () => {
        const { howitworks, selectedCountry } = this.state;
        const workForCurrentCountry =
            howitworks && selectedCountry
                ? howitworks.filter((p) => p.country === selectedCountry.id)
                : [];
        return this.getSearchedhowitworks(workForCurrentCountry);
    };

    getSearchedhowitworks = (howitworks) => {
        const { searchApplied, searchTerm } = this.state;
        if (!searchApplied || !searchTerm) return howitworks;
        const newValue = searchTerm.slice(0).trim().toLowerCase();
        return howitworks.filter((howitwork) => {
            const { id, heading, description } = howitwork;
            return (
                (heading && heading.toLowerCase().indexOf(newValue) !== -1) ||
                (description && description.toLowerCase().indexOf(newValue) !== -1) ||
                (id && id.toString() === newValue)
            );
        });
    };

    showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);

    showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);

    showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
            notificationMessage,
            successMessage: isSuccessMessage,
            showNotification: true,
        });

    showLoading = () => this.props.toggleLoading({ isLoading: true });

    hideLoading = () => this.props.toggleLoading({ isLoading: false });

    selectPrimaryImage = (e) => {
        if (e.target.files && e.target.files.length) {
            const image = e.target.files[0];
            const newhowitwork = { ...this.state.howitwork, image }
            this.setState({ howitwork: newhowitwork });
            const reader = new FileReader();
            reader.onloadend = function () {
                this.setState({
                    primary_imgSrc: [reader.result],
                });
            }.bind(this);
            reader.readAsDataURL(image);
        }
    }

    render() {
        const { countries, selectedCountry, searchTerm, editables, errors, isNewhowitwork, howitwork } = this.state;
        const finalList = this.getFilteredhowitworks();
        const isEditable = this.props.isEditable ?? false;
        const detailsForm = (
            <form onSubmit={this.handleSubmit}>
                <div className="container mt-4">
                    <div className="row">
                        <div className="form-group col">
                            <label htmlFor="title">Card Title (English) *</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="heading"
                                    className={
                                        "form-control py-2" + (errors.heading ? " has-error" : "")
                                    }
                                    placeholder="Title in English"
                                    value={howitwork.heading || ""}
                                    onChange={this.handleChange}
                                    disabled={!isNewhowitwork && !editables.heading}
                                />
                                {this.renderPencilIcon("heading")}
                            </div>
                            <span className="help-block">{errors.heading}</span>
                        </div>
                        <div className="form-group col">
                            <label htmlFor="title_arabic">Card Title (Arabic) *</label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="heading_ar"
                                    className={
                                        "form-control py-2" + (errors.heading_ar ? " has-error" : "")
                                    }
                                    placeholder="Title in Arabic"
                                    value={howitwork.heading_ar || ""}
                                    onChange={this.handleChange}
                                    disabled={!isNewhowitwork && !editables.heading_ar}
                                />
                                {this.renderPencilIcon("heading_ar")}
                            </div>
                            <span className="help-block">{errors.heading_ar}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col">
                            <label htmlFor="description">Card Description (English) *</label>
                            <div className="input-group">
                                <textarea
                                    placeholder="Description in English"
                                    name="description"
                                    id="desc1"
                                    className={
                                        "form-control py-2" + (errors.description ? " has-error" : "")
                                    }
                                    value={howitwork.description || ""}
                                    onChange={this.handleChange}
                                    disabled={!isNewhowitwork && !editables.description}
                                >
                                </textarea>
                                {this.renderPencilIcon("description")}
                            </div>
                            <span className="help-block">{errors.description}</span>
                        </div>
                        <div className="form-group col">
                            <label htmlFor="description_arabic">Card Description (Arabic) *</label>
                            <div className="input-group">
                                <textarea
                                    placeholder="Description in Arabic"
                                    name="description_ar"
                                    id="desc_ar"
                                    className={
                                        "form-control py-2" + (errors.description_ar ? " has-error" : "")
                                    }
                                    value={howitwork.description_ar || ""}
                                    onChange={this.handleChange}
                                    disabled={!isNewhowitwork && !editables.description_ar}
                                >
                                </textarea>
                                {this.renderPencilIcon("description_ar")}
                            </div>
                            <span className="help-block">{errors.description_ar}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col">
                            <label>Image *</label>
                            <div className="input-group">
                                <input
                                    type="file"
                                    id="imageInput"
                                    name="image"
                                    accept="image/*"
                                    className={
                                        "form-control "
                                    }
                                    onChange={this.selectPrimaryImage}
                                    disabled={!isNewhowitwork && !editables.image}
                                ></input>
                                {this.renderPencilIcon("image")}
                            </div>
                            <span className="help-block">{errors.image}</span>
                        </div>
                        <div className="form-group col">
                            {this.state.primary_imgSrc && (
                                <>
                                    <label><b>Selected Image</b></label>
                                    <div className="form-image-container form-image-view">
                                        <img
                                            src={this.state.primary_imgSrc}
                                            className="form-image"
                                            alt="selected"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        );

        return (
            <>
                <div className="manage-package">
                    <div className="page-header">
                        <div className="actions">
                            <div className="action-item">
                                <Search
                                    searchExpanded={true}
                                    searchTerm={searchTerm}
                                    handleSearch={this.handleSearch}
                                    clearSearch={this.handleSearchClear}
                                    handleSearchExit={this.handleSearchExit}
                                    ref={(input) => {
                                        this.search = input;
                                    }}
                                />
                            </div>
                            <div className="action-item">
                                <button
                                    style={{ backgroundColor: "#8FD14F" }}
                                    className="btn btn-md new-user"
                                    onClick={this.handleAddNew}
                                    disabled={!isEditable}
                                >
                                    <i className="fa fa-plus-circle"></i>Add New
                                </button>
                            </div>
                        </div>
                    </div>

                    <Card body>
                        <h3 className="p-head">How it works</h3>
                        <DataTable
                            data={finalList}
                            columns={this.columns}
                            defaultSortField="id"
                            defaultSortAsc={false}
                            sortIcon={<ArrowDownward></ArrowDownward>}
                            // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                            // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            highlightOnHover
                            // pagination
                            responsive
                            noHeader={this.state.searchApplied ? false : true}
                            title={
                                this.state.searchApplied ? (
                                    <p className="table-header">
                                        {"Found " + finalList.length + " results"}
                                    </p>
                                ) : (
                                    ""
                                )
                            }
                            noDataComponent={
                                this.state.loadError ? (
                                    <AlertBox
                                        message={this.state.loadError}
                                        error={true}
                                    ></AlertBox>
                                ) : (
                                    <AlertBox message="There's nothing here."></AlertBox>
                                )
                            }
                        />
                    </Card>

                    <Modal
                        size="lg"
                        show={this.state.showModal}
                        onHide={this.hideModal}
                        className="user-data-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                            <div className="user-title"></div>
                            <button onClick={this.handleSave} disabled={!isEditable} className="btn modal-save-btn">
                                Save
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <Tabs id="tabs_website_categories">
                                <Tab eventKey="key_details_form" title="Details">
                                    {detailsForm}
                                </Tab>
                            </Tabs>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks)