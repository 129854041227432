import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PhoenixAPI from "utils/PhoenixAPI";
import OpenGoogleMapWithPin from "../OpenMap";
import { formatDate, formatSingleTimeslot } from "utils/commons";
import "./orderCards.scss";
import API from "utils/API";
import { NavLink } from "react-router-dom";
import Notification from '../../HomecareMwebPanelNew/Notification';
import { useHistory } from "react-router-dom";
import { getUserId } from "services/UserService";
import {trackClientsLabDetailsOpenOrders, trackClientLabPastordersClicks} from "analytics"

const BASE_URL_BOOKING_DETAILS = "/home-care-order-details";

export default function OrderCards(props) {
  const {
    startDate,
    endDate,
    homecareServiceId,
    setIsShowClient,
    setIsShowOrder,
    setClientDetail,
    setSearchOrders,
    searchOrders,
    orders,
    setOrders,
    searchFlag=false,
    searchValue,
    professionals,
    user = {},
    pastOrder=false
  } = props;

  const nurseUserId = getUserId();
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [labs, setLabs] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [changeNurseOrder, setChangeNurseOrder] = useState({});
  const isMountedRef = React.useRef(true);
  const [message, setMessage] = useState("");
  const [nursePanelIsArabic,setNursePanelIsArabic] = useState("");



  useEffect(()=>{
    if(window.localStorage && localStorage.getItem("nursePanelIsArabic")){
      const isArabic = JSON.parse(localStorage.getItem("nursePanelIsArabic"));
      setNursePanelIsArabic(isArabic);
    }
  },[])

  const getFullAddress = (order) => {
    if (!order) return "";

    const { address_line1, address_line2, building_apt, city, country } = order;
    const location = [address_line1, address_line2, building_apt, city, country]
      .filter((it) => it ?? false)
      // .join("\r\n");
      .join(", ");

    return order.userAddress ??"";
  };

 
  const hideModal = () => {
    setIsShowModal(false);
  };


  



  const handleCardClick = (item) => {
    const userDetails=user.user ??{}
    const deviceId= sessionStorage.getItem("device_id") ??"";
   localStorage.setItem("isPastOrder", pastOrder)
    const [appointmentDate, appointmentTime] =
    item && item.confirmedDateTime

      ? item.confirmedDateTime
      .split(" ")
      : [null, null];

  const formattedDate = appointmentDate
    ? formatDate(appointmentDate)
    : "";
  const formattedTimeslot = appointmentTime
    ? formatSingleTimeslot(appointmentTime)
    : "";
    const  trackingData={
      "homecareId":userDetails?.homecare_service_id??"",
      "deviceId":deviceId,
      "nurseName":userDetails?.username??"",
      "nurseId":userDetails?.id??"",
       "deviceId":deviceId,
       "paymentMode":item.paymentMethod,
       "labName":item.lab1Name,
        "packageName":item.packageName,
        "slotDateTime":formattedDate +" "+formattedTimeslot
     }

   
     if(pastOrder){
      trackClientLabPastordersClicks(trackingData)
     }
     else{
      trackClientsLabDetailsOpenOrders(trackingData)
     }

    return history.push(`/dashboard/nurse/client-lab-details/${nurseUserId}/${item.orderId}`)
  };

  const renderOrderCards = (orders) => {
    
    return (
      <>
        {orders && orders.length > 0 ? (
          orders.map((item, index) => {
            const [appointmentDate, appointmentTime] =
              item && item.confirmedDateTime

                ? item.confirmedDateTime
                .split(" ")
                : [null, null];

            const formattedDate = appointmentDate
              ? formatDate(appointmentDate)
              : "";
            const formattedTimeslot = appointmentTime
              ? formatSingleTimeslot(appointmentTime)
              : "";

            return (
              <div
                className="clients-card"
                onClick={() => {
                  handleCardClick(item);
                }}
                key={index}
              >
                <div className="client-name-date">
                  <div className="name-txt" >{item.userName}</div>
                  <div className="date-time">
                    <span className="date"> {formattedDate}</span>
                    <span id="separator">|</span>
                    <span className="date">{formattedTimeslot}</span>
                  </div>
                </div>
                  <div className="payment-collection">
                    {nursePanelIsArabic? "الدفع" : "Payment"} :
                    <span className="payment-span" style={{color:"#F77077"}}>
                    {item.isRecollectionOrder?"Paid":item.paymentMethod === "COD_MAMOPAY" 
                     ? (item.isMamopayTransactionCompleted 
                     ? nursePanelIsArabic? "مدفوع" : "Paid" 
                     : (item.homecarePaymentStatus!=null? nursePanelIsArabic? "مدفوع" : "Paid": ((item.paymentToBeCollected ==null ||  item.paymentToBeCollected =="0") ? nursePanelIsArabic? "مدفوع" : "Paid" :`${item.paymentToBeCollected ?? 0} ${item.currency} ${nursePanelIsArabic? " للتحصيل " : "to be collected" }`)))
                     :item.isMamopayTransactionCompleted? nursePanelIsArabic? "مدفوع" : "Paid"
                     : (item.homecarePaymentStatus!=null? nursePanelIsArabic? "مدفوع" : "Paid":((item.paymentToBeCollected ==null ||  item.paymentToBeCollected =="0") ? nursePanelIsArabic? "مدفوع" : "Paid":`${item.paymentToBeCollected ?? 0} ${item.currency} ${nursePanelIsArabic? " للتحصيل " : "to be collected" }`))}
                    </span>
                  </div>
                

                <div className= {nursePanelIsArabic? "ar-client-location" : "client-location"} >
                  <div className="location-cont">
                    <div className="location-in-text">
                      <div className="location-header"> {nursePanelIsArabic? " العنوان " : "Address"} </div>
                      <div className="location-detail">
                        {getFullAddress(item) || "null"}
                      </div>
                    
                      <div
                        className="location-in-map"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="open-map-btn">
                          <OpenGoogleMapWithPin item={item}  user={user?.user??{}} nursePanelIsArabic={nursePanelIsArabic} />
                        </div>
                      </div>
                    </div>
                    {item.orderId ? (
                      <div className="item-status">
                        <div className="order-id-div">ID : {item.orderId}</div>
                     
                        {(item.isEnd) ? (
                          <div className="completed-order"> {nursePanelIsArabic? "تم إكمال الطلب" : "Completed"} </div>
                        ) : (item.isStart) ? (
                          <div className="upcoming-order"> {nursePanelIsArabic? "في انتظار" : "Waiting"} </div>
                        ) : (
                          <div className="upcoming-order">{nursePanelIsArabic? "في انتظار" : "Waiting"}</div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

              
              </div>
            );
          })
        ) : (
          <></>
        )}{" "}
      </>
    );
  };

  return (
    <>
      <div className="clients-wrapper-nurse-panel">
        {searchFlag  ? renderOrderCards(searchOrders) : renderOrderCards(orders)}
        
        {message && (
          <Notification
            setMessage={setMessage}
            message={message}
            isError={isError}
          />
        )}
      </div>
    </>
  );
}
