import React, { Component } from "react"
import Search from "components/Search/Search";
import API from "utils/API";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import {CharLimit} from "utils/CharachterLimit";

const BASE_URL_WEBSITE_PROMOTIONAL_BANNERS = "admin-website-promotional-banner/";
const STATUS_OPTIONS = [
    {
        key: true,
        value: true,
        text: "Active",
    },
    {
        key: false,
        value: false,
        text: "Inactive",
    },
]
class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banners: [],
            rankOptions: [],
            banner: {},
            errors: {},
            editables: {},
            showModal: false,
            loadError: null,
            searchTerm: null,
            minRank: 1,
            maxRank: 0,
            primary_imgSrc: "",
            secondary_imgSrc: "",
            primary_imgSrc_arabic: "",
            secondary_imgSrc_arabic: ""
        };

        this.columns = [
            {
                name: "ID",
                selector: "id",
                width: "100px",
                sortable: true,
            },
            {
                name: "Name",
                selector: "name",
                wrap: true,
                sortable: true,
            },
            {
                name: "Link",
                selector: "link",
                wrap: true,
                sortable: true
            },
            {
                name: "Rank",
                selector: "position_value",
                width: "100px",
                sortable: true,
                center: true
            },
            {
                name: "Status",
                selector: "is_active",
                maxWidth: "150px",
                center: true,
                cell: (p) => <Status isActive={p.is_active} />,
                sortable: true,
            },
            {
                name: "View",
                center: true,
                cell: (p) => this.renderViewButton(p.id),
            },
        ]
    }

    renderViewButton = (id) => (
        <div>
            <button
                className="btn px-5"
                style={{ backgroundColor: "#CEE741" }}
                onClick={() => {
                    this.handleView(id);
                }}
            >
                View
            </button>
        </div>
    );

    componentDidMount() {
        this.fetchbanners()
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedCountry !== nextProps.selectedCountry) {
            this.setState({
                countries: nextProps.countries,
                selectedCountry: nextProps.selectedCountry
            })
        }
    }

    fetchbanners = async () => {
        try {
            this.showLoading();
            const response = await API.get(BASE_URL_WEBSITE_PROMOTIONAL_BANNERS)
            const banners = response.data || []
            const ranks = banners.filter((it) => it.position_value).map((it) => it.position_value);
            const minRank = Math.min(...ranks) || 0;
            const maxRank = Math.max(...ranks) || 0;
            this.setState({ banners, minRank, maxRank })
            this.hideLoading()
        }
        catch (error) {
            console.error(
                "Error on fetching promotional banners from this URL: ",
                BASE_URL_WEBSITE_PROMOTIONAL_BANNERS
            );
            console.error("Error on fetching promotional banners", error);
            this.hideLoading();
            this.showErrorNotification(
                "Error on loading promotional banners: ",
                error.message
            );
            this.setState({
                loadError: "Some error has occured. Please try again",
            });
        }
    }

    handleChange = ({ currentTarget: input }) => {
        const banner = { ...this.state.banner };
        banner[input.name] = input.value;
        this.setState({ banner });
    };

    handleSearch = (queryString) => {
        this.setState({
            searchApplied: true,
            searchTerm: queryString ? queryString : "",
        });
    };

    handleSearchClear = () => {
        this.setState({
            searchTerm: "",
            searchApplied: false,
        });
        this.search.searchInput.focus();
    };

    handleSearchExit = (e) => {
        const value = e.target.value;
        if (!value || !value.length)
            this.setState({
                searchTerm: "",
                searchApplied: false,
            });
    };

    handleAddNew = () => {
        const banner = {
            is_active: false,
            position_value: this.state.maxRank + 1,
        };
        this.setState({ banner, isNewbanner: true }, () =>
            this.showModal()
        );
    };

    handleView = (id) => {
        const banners = this.state.banners || [];
        const banner = banners.find((it) => it.id === id);
        if (banner) {
            const country = this.findCountry(banner.country);
            this.setState(
                {
                    isNewbanner: false,
                    banner: {
                        ...banner,
                        country_name: country ? country.country_name : "",
                    },
                    primary_imgSrc: banner.image,
                    primary_imgSrc_arabic: banner.image_arabic,
                    secondary_imgSrc: banner.secondary_image,
                    secondary_imgSrc_arabic: banner.secondary_image_arabic
                },
                () => this.showModal()
            );
        }
    };

    showModal = () => {
        const rankOptions = this.createRankOptions();
        this.setState({ rankOptions, showModal: true });
    };

    hideModal = () => {
        this.setState({ showModal: false });
        this.resetForm();
    };

    resetForm = () => {
        this.setState({
            errors: {},
            editables: {},
            banner: {},
            isNewbanner: false,
            primary_imgSrc: "",
            primary_imgSrc_arabic: "",
            secondary_imgSrc: "",
            secondary_imgSrc_arabic: ""
        });
    };

    createRankOptions = () => {
        const { minRank, maxRank, isNewbanner } = this.state;
        if (
            minRank === null ||
            minRank === undefined ||
            minRank === "" ||
            maxRank === null ||
            maxRank === "" ||
            maxRank === undefined
        )
            return;
        const until = isNewbanner ? maxRank + 1 : maxRank;
        let rankOptions = [];
        for (let i = minRank; i <= until; i++)
            rankOptions.push({ key: i, value: i, text: i });
        if (rankOptions.length === 0)
            rankOptions.push({ key: 1, value: 1, text: 1 });
        return rankOptions;
    };

    findCountry = (id) => {
        const countries = this.state.countries || [];
        return countries.find((country) => country.id === id);
    };

    handleSave = async () => {
        if (!this.state.selectedCountry) {
            this.showErrorNotification("Please select a country!");
            return;
        }
        const errors = this.validate();
        console.log("errors:", errors)
        this.setState({ errors: errors || {} });
        if (errors) {
            this.showErrorNotification("There are some invalid form fields!");
            return;
        }
        const { banner } = this.state;
        const isValidId =
            banner.id !== null && banner.id !== undefined;
        const apiOptions = isValidId
            ? this.createApiOptions(
                "patch",
                `${BASE_URL_WEBSITE_PROMOTIONAL_BANNERS}${banner.id}/`
            )
            : this.createApiOptions("post", BASE_URL_WEBSITE_PROMOTIONAL_BANNERS);
        try {
            this.showLoading()
            await API.request(apiOptions);
            this.hideLoading();
            this.hideModal();
            this.showSuccessNotification(
                isValidId ? "Updated Successfully." : "Created Successfully."
            );
            this.fetchbanners();
        }
        catch (error) {
            this.hideLoading();
            this.showErrorNotification("Some error occurred: " + error.message);
            console.error("Some error occurred", error.message);
        }
    };

    validate = () => {
        const errors = {};
        const TWO_MEGA_BYTE = 2048000;
        const { name, title, title_arabic, link, link_arabic, image, image_arabic, secondary_image, secondary_image_arabic, description, description_arabic } = this.state.banner
        const { primary_imgSrc, primary_imgSrc_arabic, secondary_imgSrc, secondary_imgSrc_arabic } = this.state;

        if (!name || name.trim() === "")
            errors.name = "Name (English) is required field!";
        else if (name.length > CharLimit.HUNDRED_LIMIT)
            errors.name = "Name (English) can not take more than 100 characters!";

        if (!title || title.trim() === "")
            errors.title = "Title is required field!"
        else if (title.length > CharLimit.TWO_HUNDRED_LIMIT)
            errors.title = "Title (English) can not take more than 200 characters!"

        if (!title_arabic || title_arabic.trim() === "")
            errors.title_arabic = "Title is required field!"
        else if (title_arabic.length > CharLimit.TWO_HUNDRED_LIMIT)
            errors.title_arabic = "Title (English) can not take more than 200 characters!"

        if (!link || link.trim() === "")
            errors.link = "Link (English) is required field";
        else if (link.length > CharLimit.FIVE_HUNDERED_LIMIT)
            errors.link = "Link (English) can not take more than 500 characters"

        if (!link_arabic || link_arabic.trim() === "")
            errors.link_arabic = "Link (Arabic) is required field";
        else if (link_arabic.length > CharLimit.FIVE_HUNDERED_LIMIT)
            errors.link_arabic = "Link (Arabic) can not take more than 500 characters"

        // if(!description || description.trim()==="")
        // errors.description="Description (English) is required field"

         if(description && description.length>CharLimit.FIVE_HUNDERED_LIMIT)
        errors.description="Description (English) can not take more than 500 characters"

        // if(!description_arabic || description_arabic.trim()==="")
        // errors.description_arabic="Description (Arabic) is required field"

        if(description_arabic && description_arabic.length>CharLimit.FIVE_HUNDERED_LIMIT)
        errors.description_arabic="Description (Arabic) can not take more than 500 characters"

        if (!image && !primary_imgSrc)
            errors.image = "Promotional Graphic 1 (English) is required field"

        else if (image && image.size && image.size > TWO_MEGA_BYTE)
            errors.image = "Image should be less than 2MB"

        if (!image_arabic && !primary_imgSrc_arabic)
            errors.image_arabic = "Promotional Graphic 1 (Arabic) is required field"
        else if (image_arabic && image_arabic.size && image_arabic.size > TWO_MEGA_BYTE)
            errors.image_arabic = "Image should be less than 2MB"

        // if (!secondary_image && !secondary_imgSrc)
        // errors.secondary_image = "banner Graphic 2 (English) is required field"

        // else if (secondary_image && secondary_image.size && secondary_image.size > TWO_MEGA_BYTE)
        //     errors.secondary_image = "Image should be less than 2MB"

        // if (!secondary_image_arabic && !secondary_imgSrc_arabic)
        //     errors.secondary_image_arabic = "banner Graphic 2(Arabic) is required field"
        // else if (secondary_image_arabic && secondary_image_arabic.size && secondary_image_arabic.size > TWO_MEGA_BYTE)
        //     errors.secondary_image_arabic = "Image should be less than 2MB"
        //   if (rank === null || rank === undefined)
        //   errors.rank = "Rank is a required field!";

        // if (is_active === null || is_active === undefined)
        //   errors.status = "Status is a required field!";

        return Object.keys(errors).length === 0 ? null : errors;
    }

    createApiOptions = (method, url) => {
        const formData = this.createFormData();
        return {
            method,
            url,
            data: formData,
            headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            },
        };
    };

    createFormData = () => {
        const { isNewbanner, banner, selectedCountry } = this.state;
        const { image, image_arabic, secondary_image, secondary_image_arabic } = this.state.banner
        const formData = new FormData();

        if (isNewbanner)
            formData.append("country", selectedCountry ? selectedCountry.id : null);

        formData.append("name", banner.name);
        formData.append("link", banner.link);
        formData.append("link_arabic", banner.link_arabic);
        formData.append("description", banner.description);
        formData.append("title", banner.title)
        formData.append("title_arabic", banner.title_arabic)
        formData.append("description_arabic", banner.description_arabic);
        if (image && image.name) {
            formData.append("image", image, image.name);
            formData.append("image_s3", image, image.name);
        }

        if (image_arabic && image_arabic.name) {
            formData.append("image_arabic", image_arabic, image_arabic.name);
            formData.append("image_arabic_s3", image_arabic, image_arabic.name);
        }
        if (secondary_image && secondary_image.name) {
            formData.append("secondary_image", secondary_image, secondary_image.name);
            formData.append("secondary_image_s3", secondary_image, secondary_image.name);
        }

        if (secondary_image_arabic && secondary_image_arabic.name) {
            formData.append("secondary_image_arabic", secondary_image_arabic, secondary_image_arabic.name);
            formData.append("secondary_image_arabic_s3", secondary_image_arabic, secondary_image_arabic.name);
        }
        formData.append("position_value", banner.position_value);
        formData.append("is_active", banner.is_active);

        return formData;
    };

    setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
    };

    renderPencilIcon = (fieldName) => {
        const isEditable = this.props.isEditable ?? true;
        return isEditable && !this.state.isNewbanner ? (
            <div
                onClick={() => this.setEditable(fieldName)}
                style={{ cursor: "pointer" }}
                className="input-group-append"
            >
                <i className="input-group-text fa fa-pencil"></i>
            </div>
        ) : (
            ""
        );
    }

    getFilteredbanners = () => {
        const { banners, selectedCountry } = this.state;
        const bannersForCurrentCountry =
            banners && selectedCountry
                ? banners.filter((p) => p.country === selectedCountry.id)
                : [];
        return this.getSearchedbanners(bannersForCurrentCountry);
    };

    getSearchedbanners = (banners) => {
        const { searchApplied, searchTerm } = this.state;
        if (!searchApplied || !searchTerm) return banners;
        const newValue = searchTerm.slice(0).trim().toLowerCase();
        return banners.filter((banner) => {
            const { id, name, link, position_value } = banner;
            return (
                (name && name.toLowerCase().indexOf(newValue) !== -1) ||
                (link && link.toLowerCase().indexOf(newValue) !== -1) ||
                (id && id.toString() === newValue) ||
                (position_value && position_value.toString() === newValue)
            );
        });
    };

    showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);

    showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);

    showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
            notificationMessage,
            successMessage: isSuccessMessage,
            showNotification: true,
        });

    showLoading = () => this.props.toggleLoading({ isLoading: true });

    hideLoading = () => this.props.toggleLoading({ isLoading: false });

    selectbannerPrimaryImage = (e) => {
        if (e.target.files && e.target.files.length) {
            const image = e.target.files[0];
            const newbanner = { ...this.state.banner, image }
            this.setState({ banner: newbanner });
            const reader = new FileReader();
            reader.onloadend = function () {
                this.setState({
                    primary_imgSrc: [reader.result],
                });
            }.bind(this);
            reader.readAsDataURL(image);
        }
    }

    selectbannerPrimaryArabicImage = (e) => {
        if (e.target.files && e.target.files.length) {
            const image_arabic = e.target.files[0];
            const newbanner = { ...this.state.banner, image_arabic }
            this.setState({ banner: newbanner });
            const reader = new FileReader();
            reader.onloadend = function () {
                this.setState({
                    primary_imgSrc_arabic: [reader.result]
                });
            }.bind(this);
            reader.readAsDataURL(image_arabic);
        }
    }

    selectbannerSecondaryImage = (e) => {
        if (e.target.files && e.target.files.length) {
            const secondary_image = e.target.files[0];
            const newbanner = { ...this.state.banner, secondary_image }
            this.setState({ banner: newbanner });
            const reader = new FileReader();
            reader.onloadend = function () {
                this.setState({
                    secondary_imgSrc: [reader.result],
                });
            }.bind(this);
            reader.readAsDataURL(secondary_image);
        }
    }

    selectbannerSecondaryArabicImage = (e) => {
        if (e.target.files && e.target.files.length) {
            const secondary_image_arabic = e.target.files[0];
            const newbanner = { ...this.state.banner, secondary_image_arabic }
            this.setState({ banner: newbanner });
            const reader = new FileReader();
            reader.onloadend = function () {
                this.setState({
                    secondary_imgSrc_arabic: [reader.result]
                });
            }.bind(this);
            reader.readAsDataURL(secondary_image_arabic);
        }
    }

    render() {
        const { searchTerm, editables, errors, isNewbanner, banner, countries, selectedCountry } = this.state;
        const finalList = this.getFilteredbanners();
        const isEditable = this.props.isEditable ?? true;
        const detailsForm = (
            <div className="container mt-4">
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="name">Name (Admin Panel Only)*</label>
                        <div className="input-group">
                            <input
                                value={banner && banner.name || ""}
                                onChange={this.handleChange}
                                id="name"
                                name="name"
                                type="text"

                                className={
                                    "form-control py-2" + (errors.name ? " has-error" : "")
                                }
                                disabled={!isNewbanner && !editables.name}
                            />
                            {this.renderPencilIcon("name")}
                        </div>
                        <span className="help-block">{errors.name}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="country">Country</label>
                        <input
                            value={
                                isNewbanner
                                    ? selectedCountry && selectedCountry.country_name
                                    : banner.country_name || ""
                            }
                            type="text"
                            id="country"
                            name="country"
                            readOnly
                            className={
                                "form-control py-2" + (errors.country ? " has-error" : "")
                            }
                        />
                        <span className="help-block">{errors.country}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="status">Status</label>
                        <div className="input-group">
                            <Dropdown
                                value={banner.is_active}
                                onChange={(event, value) => {
                                    this.setState({
                                        banner: {
                                            ...banner,
                                            is_active: value.value,
                                        },
                                    });
                                }}
                                id="status"
                                name="status"
                                placeholder="Status"
                                search
                                selection
                                options={STATUS_OPTIONS}
                                disabled={!isNewbanner && !editables.status}
                                className={"form-control" + (errors.status ? " has-error" : "")}
                            />
                            {this.renderPencilIcon("status")}
                        </div>
                        <span className="help-block">{errors.status}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="rank">Rank</label>
                        <div className="input-group">
                            <Dropdown
                                value={banner.position_value}
                                onChange={(event, value) => {
                                    const category = { ...banner };
                                    category.position_value = value.value;
                                    this.setState({ banner: category });
                                }}
                                id="rank"
                                placeholder="Rank"
                                search
                                selection
                                options={this.state.rankOptions}
                                disabled={!isNewbanner && !editables.rank}
                                className={"form-control" + (errors.rank ? " has-error" : "")}
                            />
                            {this.renderPencilIcon("rank")}
                        </div>
                        <span className="help-block">{errors.rank}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="title">Title (English)*</label>
                        <div className="input-group">
                            <textarea
                                value={banner && banner.title || ""}
                                type="text"
                                id="title"
                                name="title"
                                onChange={this.handleChange}
                                disabled={!isNewbanner && !editables.title}
                                className={
                                    "form-control py-2" + (errors.title ? " has-error" : "")
                                }
                            ></textarea>
                            {this.renderPencilIcon("title")}
                        </div>
                        <span className="help-block">{errors.title}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="title_arabic">Title (Arabic)*</label>
                        <div className="input-group">
                            <textarea
                                value={banner && banner.title_arabic || ""}
                                type="text"
                                id="title_arabic"
                                name="title_arabic"
                                onChange={this.handleChange}
                                disabled={!isNewbanner && !editables.title_arabic}
                                className={"form-control py-2" + (errors.title_arabic ? " has-error" : "")}
                            />
                            {this.renderPencilIcon("title_arabic")}
                        </div>
                        <span className="help-block">{errors.title_arabic}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="link">Link (English)*</label>
                        <div className="input-group">
                            <textarea
                                value={banner && banner.link || ""}
                                onChange={this.handleChange}
                                type="text"
                                id="link"
                                name="link"
                                disabled={!isNewbanner && !editables.link}
                                className={
                                    "form-control py-2" + (errors.link ? " has-error" : "")
                                }
                            ></textarea>
                            {this.renderPencilIcon("link")}
                        </div>
                        <span className="help-block">{errors.link}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="link_arabic">Link (Arabic)*</label>
                        <div className="input-group">
                            <textarea
                                value={banner && banner.link_arabic || ""}
                                type="text"
                                id="link_arabic"
                                name="link_arabic"
                                onChange={this.handleChange}
                                disabled={!isNewbanner && !editables.link_arabic}
                                className={"form-control py-2" + (errors.link_arabic ? " has-error" : "")}
                            />
                            {this.renderPencilIcon("link_arabic")}
                        </div>
                        <span className="help-block">{errors.link_arabic}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="description">Description (English)</label>
                        <div className="input-group">
                            <textarea
                                value={banner &&  banner.description || ""}
                                type="text"
                                id="description"
                                name="description"
                                onChange={this.handleChange}
                                disabled={!isNewbanner && !editables.description}
                                className={
                                    "form-control py-2" + (errors.description ? " has-error" : "")
                                }
                            ></textarea>
                            {this.renderPencilIcon("description")}
                        </div>
                        <span className="help-block">{errors.description}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="description_arabic">Description (Arabic)</label>
                        <div className="input-group">
                            <textarea
                                value={banner && banner.description_arabic || ""}
                                type="text"
                                id="description_arabic"
                                name="description_arabic"
                                onChange={this.handleChange}
                                disabled={!isNewbanner && !editables.description_arabic}
                                className={"form-control py-2" + (errors.description_arabic ? " has-error" : "")}
                            />
                            {this.renderPencilIcon("description_arabic")}
                        </div>
                        <span className="help-block">{errors.description_arabic}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="imageInput">Promotional Graphic 1 (English)*</label>
                        <div className="input-group">
                            <input
                                onChange={this.selectbannerPrimaryImage}
                                id="imageInput"
                                name="image"
                                type="file"
                                accept="image/*"
                                disabled={!isNewbanner && !editables.image}
                                className={
                                    "form-control" +
                                    (errors.image ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("image")}
                        </div>
                        <span className="help-block">{errors.image}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="imageInput">Promotional Graphic 1 (Arabic)*</label>
                        <div className="input-group">
                            <input
                                onChange={this.selectbannerPrimaryArabicImage}
                                id="imageInput"
                                name="image"
                                type="file"
                                accept="image/*"
                                disabled={!isNewbanner && !editables.image_arabic}
                                className={
                                    "form-control" +
                                    (errors.image_arabic ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("image_arabic")}
                        </div>
                        <span className="help-block">{errors.image_arabic}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        {this.state.primary_imgSrc && (
                            <>
                                <div className="form-image-container form-image-view">
                                    <img
                                        src={this.state.primary_imgSrc}
                                        className="form-image"
                                        alt="selected"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="form-group col">
                        {this.state.primary_imgSrc_arabic && (
                            <>
                                <div className="form-image-container form-image-view">
                                    <img
                                        src={this.state.primary_imgSrc_arabic}
                                        className="form-image"
                                        alt="selected"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="imageInput">Promotional Graphic 2 (English)</label>
                        <div className="input-group">
                            <input
                                onChange={this.selectbannerSecondaryImage}
                                id="imageInput"
                                name="image"
                                type="file"
                                accept="image/*"
                                disabled={!isNewbanner && !editables.secondary_image}
                                className={
                                    "form-control" +
                                    (errors.secondary_image ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("secondary_image")}
                        </div>
                        <span className="help-block">{errors.secondary_image}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="imageInput">Promotional Graphic 2 (Arabic)</label>
                        <div className="input-group">
                            <input
                                onChange={this.selectbannerSecondaryArabicImage}
                                id="imageInput"
                                name="image"
                                type="file"
                                accept="image/*"
                                disabled={!isNewbanner && !editables.secondary_image_arabic}
                                className={
                                    "form-control" +
                                    (errors.secondary_image_arabic ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("secondary_image_arabic")}
                        </div>
                        <span className="help-block">{errors.secondary_image_arabic}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        {this.state.secondary_imgSrc && (
                            <>
                                <div className="form-image-container form-image-view">
                                    <img
                                        src={this.state.secondary_imgSrc}
                                        className="form-image"
                                        alt="selected"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="form-group col">
                        {this.state.secondary_imgSrc_arabic && (
                            <>
                                <div className="form-image-container form-image-view">
                                    <img
                                        src={this.state.secondary_imgSrc_arabic}
                                        className="form-image"
                                        alt="selected"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );

        return (
            <>
                <div className="manage-package">
                    <div className="page-header">
                        <div className="actions">
                            <div className="action-item">
                                <Search
                                    searchExpanded={true}
                                    searchTerm={searchTerm}
                                    handleSearch={this.handleSearch}
                                    clearSearch={this.handleSearchClear}
                                    handleSearchExit={this.handleSearchExit}
                                    ref={(input) => {
                                        this.search = input;
                                    }}
                                />
                            </div>
                            <div className="action-item">
                                <button
                                    style={{ backgroundColor: "#8FD14F" }}
                                    className="btn btn-md new-user"
                                    onClick={this.handleAddNew}
                                    disabled={!isEditable}
                                >
                                    <i className="fa fa-plus-circle"></i>Add New
                                </button>
                            </div>
                        </div>
                    </div>

                    <Card body>
                        <h3 className="p-head">Banner</h3>
                        <DataTable
                            data={finalList}
                            columns={this.columns}
                            defaultSortField="id"
                            defaultSortAsc={false}
                            sortIcon={<ArrowDownward></ArrowDownward>}
                            // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                            // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            highlightOnHover
                            // pagination
                            responsive
                            noHeader={this.state.searchApplied ? false : true}
                            title={
                                this.state.searchApplied ? (
                                    <p className="table-header">
                                        {"Found " + finalList.length + " results"}
                                    </p>
                                ) : (
                                    ""
                                )
                            }
                            noDataComponent={
                                this.state.loadError ? (
                                    <AlertBox
                                        message={this.state.loadError}
                                        error={true}
                                    ></AlertBox>
                                ) : (
                                    <AlertBox message="There's nothing here."></AlertBox>
                                )
                            }
                        />
                    </Card>

                    <Modal
                        size="lg"
                        show={this.state.showModal}
                        onHide={this.hideModal}
                        className="user-data-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                            <div className="user-title"></div>
                            <button onClick={this.handleSave} disabled={!isEditable} className="btn modal-save-btn">
                                Save
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <Tabs id="tabs_website_categories">
                                <Tab eventKey="key_details_form" title="Details">
                                    {detailsForm}
                                </Tab>
                            </Tabs>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner)