import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import Status from "components/Common/Status";
import { fetchCountries } from "services/RegionService";
import { fetchArticleCategories } from "services/HealthHubService";
import { saveArticleCategory } from "services/HealthHubService";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import {CharLimit} from "utils/CharachterLimit";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];
const TWO_MEGA_BYTE = 2_097_152;

class ArticleCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      articleCategories: [],
      articleCategory: {},
      isNewArticleCategory: false,
      countries: [],
      rankOptions: [],
      errors: {},
      editables: {},
      loadError: null,
      searchTerm: null,
      selectedCountry: null,
      showModal: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "categoryNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (Arabic)",
        selector: "categoryNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (category) => (
          <p style={{ textAlign: "right" }}>{category.categoryNameArabic}</p>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (category) => <Status isActive={category.status === "Active"} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (category) => this.renderViewButton(category.id),
      },
    ];
  }

  async componentDidMount() {
    this.fetchAclUser();
    await this.fetchCountries();
    this.fetchArticleCategories();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.ARTICLE_CATEGORIES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) ?? [];
      const selectedCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry });
    } catch (error) {
      this.showErrorNotification("Error on fetching countries!", error.message);
    }
  };

  fetchArticleCategories = async () => {
    try {
      this.showLoading();
      const articleCategories = (await fetchArticleCategories()) ?? [];
      articleCategories.forEach((it) => {
        delete it.healthHub;
        return it;
      });
      this.setState({ articleCategories });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching article categories: ",
        error.message
      );
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    } finally {
      this.hideLoading();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const articleCategory = { ...this.state.articleCategory };
    articleCategory[input.name] = input.value;
    this.setState({ articleCategory });
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const articleCategory = { ...this.state.articleCategory, image };
      this.setState({ articleCategory });
      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const articleCategory = {
        ...this.state.articleCategory,
        imageSrc: [reader.result],
      };
      this.setState({ articleCategory });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  handleAddNew = () => {
    this.setState({ isNewArticleCategory: true }, () => this.showModal());
  };

  handleView = (id) => {
    const articleCategories = this.state.articleCategories || [];
    const articleCategory = articleCategories.find((it) => it.id === id);
    if (articleCategory) {
      this.setState(
        {
          isNewArticleCategory: false,
          articleCategory: {
            ...articleCategory,
            imageSrc: articleCategory.image,
          },
        },
        () => this.showModal()
      );
    }
  };

  handleSave = async () => {
    if (!this.state.selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { articleCategory } = this.state;
    articleCategory.meta_title_arabic=articleCategory.meta_title_arabic||articleCategory.metaTitle
    articleCategory.meta_description_arabic=articleCategory.meta_description_arabic||articleCategory.metaDescription
    articleCategory.keywords_arabic=articleCategory.keywords_arabic||articleCategory.keywords
    try {
      await saveArticleCategory(articleCategory);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchArticleCategories();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const errors = {};
    const {
      categoryNameEnglish,
      categoryNameArabic,
      rank,
      status,
      image,
      seoUrl,
      metaTitle,
      metaDescription,
      keywords,
      meta_title_arabic,
      meta_description_arabic,
      keywords_arabic,
    } = this.state.articleCategory;
 console.log("Article category: " ,this.state.articleCategory);
    if (!categoryNameEnglish || categoryNameEnglish.trim() === "")
      errors.categoryNameEnglish = "Name (English) is a required field!";
    else if (categoryNameEnglish.length > CharLimit.HUNDRED_LIMIT)
      errors.categoryNameEnglish =
        "Name (English) can not take more than 100 characters!";

    if (!categoryNameArabic)
      errors.categoryNameArabic = "Name (Arabic) is a required field!";
    else if (categoryNameArabic.length > CharLimit.HUNDRED_LIMIT)
      errors.categoryNameArabic =
        "Name (Arabic) can not take more than 100 characters!";

    if (rank === null || rank === undefined)
      errors.rank = "Rank is a required field!";

    if (status === null || status === undefined)
      errors.status = "Status is a required field!";

    if (!image) errors.image = "Image cannot be blank";
    else if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    if (!seoUrl || seoUrl.trim() === "")
      errors.seoUrl = "Seo URL is a required field!";
    else if (seoUrl.length > CharLimit.HUNDRED_LIMIT)
      errors.seoUrl = "Seo URL can not contain more than 100 characters!";

    if (metaTitle && metaTitle.length > CharLimit.HUNDRED_LIMIT)
      errors.metaTitle = "Title can not contain more than 100 characters!";
   if(meta_title_arabic && meta_title_arabic.length > CharLimit.HUNDRED_LIMIT)
    errors.meta_title_arabic = "Title (Arabic) can not contain more than 100 characters!";
    if (metaDescription && metaDescription.length > CharLimit.TWO_HUNDRED_LIMIT)
      errors.metaDescription =
        "Description can not contain more than 200 characters!";
    if(meta_description_arabic && meta_description_arabic.length > CharLimit.TWO_HUNDRED_LIMIT)
      errors.meta_description_arabic =
        "Description (Arabic) can not contain more than 200 characters!";
    if (keywords && keywords.length > CharLimit.FIVE_HUNDERED_LIMIT)
      errors.keywords = "keywords can not contain more than 500 characters!";
  if(keywords_arabic && keywords_arabic.length > CharLimit.FIVE_HUNDERED_LIMIT)
    errors.keywords_arabic = "Keywords (Arabic) can not contain more than 500 characters!";
    return Object.keys(errors).length === 0 ? null : errors;
  };

  showModal = () => {
    const { isNewArticleCategory, selectedCountry } = this.state;
    const rankOptions = this.createRankOptions();
    if (isNewArticleCategory) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const articleCategory = {
        status: "Inactive",
        rank,
        country: selectedCountry.id,
      };
      this.setState({
        articleCategory,
        rankOptions,
        showModal: true,
      });
    } else {
      this.setState({ rankOptions, showModal: true });
    }
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      articleCategory: {},
      isNewArticleCategory: false,
    });
  };

  createRankOptions = () => {
    const { isNewArticleCategory } = this.state;
    const articleCategories =
      this.getArticleCategoriesForCurrentCountry() ?? [];
    const ranks = articleCategories
      .filter((it) => it.rank)
      .map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewArticleCategory ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleView(id);
        }}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (this.state.isNewArticleCategory || !this.state.isEditable) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  getFilteredArticleCategories = () => {
    const articleCategoriesForCurrentCountry =
      this.getArticleCategoriesForCurrentCountry();
    return this.getSearchedArticleCategories(
      articleCategoriesForCurrentCountry
    );
  };

  getArticleCategoriesForCurrentCountry = () => {
    const { articleCategories, selectedCountry } = this.state;
    return articleCategories && selectedCountry
      ? articleCategories.filter((it) => it.country === selectedCountry.id)
      : [];
  };

  getSearchedArticleCategories = (articleCategories) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return articleCategories;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return articleCategories.filter((articleCategory) => {
      const { id, categoryNameEnglish, categoryNameArabic, rank } =
        articleCategory;
      return (
        (categoryNameEnglish &&
          categoryNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (categoryNameArabic &&
          categoryNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      isNewArticleCategory,
      articleCategory,
      countries,
      selectedCountry,
      editables,
      errors,
      searchTerm,
      isEditable,
    } = this.state;

    const articleCategories = this.getFilteredArticleCategories();
    console.log("render:: Article Categories: ", articleCategories);

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="categoryNameEnglish">Name (English)*</label>
            <div className="input-group">
              <input
                value={articleCategory.categoryNameEnglish}
                onChange={this.handleChange}
                id="categoryNameEnglish"
                name="categoryNameEnglish"
                type="text"
                placeholder="Name in English"
                className={
                  "form-control py-2" +
                  (errors.categoryNameEnglish ? " has-error" : "")
                }
                disabled={
                  !isNewArticleCategory && !editables.categoryNameEnglish
                }
              />
              {this.renderPencilIcon("categoryNameEnglish")}
            </div>
            <span className="help-block">{errors.categoryNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="categoryNameArabic">Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={articleCategory.categoryNameArabic}
                onChange={this.handleChange}
                id="categoryNameArabic"
                name="categoryNameArabic"
                type="text"
                placeholder="Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.categoryNameArabic ? " has-error" : "")
                }
                disabled={
                  !isNewArticleCategory && !editables.categoryNameArabic
                }
              />
              {this.renderPencilIcon("categoryNameArabic")}
            </div>
            <span className="help-block">{errors.categoryNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={articleCategory.rank}
                onChange={(event, value) => {
                  const category = { ...articleCategory };
                  category.rank = value.value;
                  this.setState({ articleCategory: category });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewArticleCategory && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={articleCategory.status}
                onChange={(event, value) => {
                  this.setState({
                    articleCategory: {
                      ...articleCategory,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewArticleCategory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewArticleCategory && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {articleCategory?.imageSrc && (
              <>
                <label htmlFor="selected_package_image">
                  Selected Thumbnail Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={articleCategory.imageSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={
                this.findCountry(articleCategory.country)?.country_name ?? ""
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
      </div>
    );

    const seoMarketingForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seoUrl">Seo URL*</label>
            <div className="input-group">
              <input
                value={articleCategory.seoUrl ?? ""}
                onChange={this.handleChange}
                id="seoUrl"
                name="seoUrl"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.seoUrl ? " has-error" : "")
                }
                disabled={!isNewArticleCategory && !editables.seoUrl}
              />
              {this.renderPencilIcon("seoUrl")}
            </div>
            <span className="help-block">{errors.seoUrl}</span>
          </div>
          {/* <div className="form-group col">
            <label htmlFor="url_name_arabic">Seo URL Arabic</label>
            <div className="input-group">
              <input
                value={articleCategory.url_name_arabic ?? ""}
                onChange={this.handleChange}
                id="url_name_arabic"
                name="url_name_arabic"
                type="text"
                placeholder="Seo URL Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.url_name_arabic ? " has-error" : "")
                }
                disabled={!isNewArticleCategory && !editables.url_name_arabic}
              />
              {this.renderPencilIcon("url_name_arabic")}
            </div>
            <span className="help-block">{errors.url_name_arabic}</span>
          </div> */}
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="metaTitle">Meta Title</label>
            <div className="input-group">
              <input
                value={articleCategory.metaTitle ?? ""}
                onChange={this.handleChange}
                id="metaTitle"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewArticleCategory && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="meta_title_arabic">Meta Title Arabic</label>
            <div className="input-group">
              <input
                value={articleCategory.meta_title_arabic ?? ""}
                onChange={this.handleChange}
                id="meta_title_arabic"
                name="meta_title_arabic"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.meta_title_arabic ? " has-error" : "")
                }
                disabled={!isNewArticleCategory && !editables.meta_title_arabic}
              />
              {this.renderPencilIcon("meta_title_arabic")}
            </div>
            <span className="help-block">{errors.meta_title_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="metaDescription">Meta Description</label>
            <div className="input-group">
              <input
                value={articleCategory.metaDescription ?? ""}
                onChange={this.handleChange}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewArticleCategory && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="meta_description_arabic">Meta Description Arabic</label>
            <div className="input-group">
              <input
                value={articleCategory.meta_description_arabic ?? ""}
                onChange={this.handleChange}
                id="meta_description_arabic"
                name="meta_description_arabic"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.meta_description_arabic ? " has-error" : "")
                }
                disabled={!isNewArticleCategory && !editables.meta_description_arabic}
              />
              {this.renderPencilIcon("meta_description_arabic")}
            </div>
            <span className="help-block">{errors.meta_description_arabic}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={articleCategory.keywords ?? ""}
                onChange={this.handleChange}
                id="keywords"
                name="keywords"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                readOnly={!isNewArticleCategory && !editables.keywords}
              ></textarea>
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keywords_arabic">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={articleCategory.keywords_arabic ?? ""}
                onChange={this.handleChange}
                id="keywords_arabic"
                name="keywords_arabic"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.keywords_arabic ? " has-error" : "")
                }
                readOnly={!isNewArticleCategory && !editables.keywords_arabic}
              ></textarea>
              {this.renderPencilIcon("keywords_arabic")}
            </div>
            <span className="help-block">{errors.keywords_arabic}</span>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <DataTable
            data={articleCategories}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + articleCategories.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.handleSave} disabled={!isEditable} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_article_categories" justify>
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
              <Tab eventKey="key_seo_marketing_form" title="SEO Marketing">
                {seoMarketingForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCategories);
