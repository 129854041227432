import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Notification from './Notification';
import "./ChangeMemberDetails.scss";
import PhoenixAPI from "../../utils/PhoenixAPI";
import { getUserId, getUserName, getUserEmail } from 'services/UserService';
const genders=[{key:"Select",text:"Select",value:""},{key:"Male",value:"M",text:"Male"},{key:"Female",value:"F",text:"Female"}];
const BASE_URL_FAMILY_MEMBERS = "/multiple-profile/admin";
const relations = [
  { type: "Spouse", text: "spouse" },
  { type: "Father", text: "father" },
  { type: "Mother", text: "mother" },
  { type: "Other", text: "other" }
];
function ChangeMemberDetails (props){
    const [memberDetails, setMemberDetails] = useState([]);
    const [checkedState, setCheckedState] = useState([]);
    const [error, setError] = useState({});
    const[isError,setIsError]=useState(false);
    const[message,setMessage]=useState(false);
    const [inputs, setInputs] = useState({});
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState('');
    const[orderId,setOrderId] = useState(props.match.params.orderId);
    const[userId,setUserId] = useState();
    const[isLoading,setIsLoading] = useState(false);
    const[selectedMember,setSelectedMember] = useState({});
    const[isButtonDisabled,setIsButtonDisabled] = useState(false);
    
    const[order,setOrder]=useState({});
    const nurseUserId= getUserId();

    useEffect(()=>{
      const {userId,orderId}=props.match.params;
      
      if(userId){
        getMemberDetails(userId);
        
        setUserId(userId);
        
        
        
      }
      if(orderId){
        setOrderId(orderId);
      }

      
   },[orderId])

    const CheckBox = ({ isChecked ,checkboxId}) => {
        return (
          <div className="checkbox-container" id={"member-select-checkbox-cont"+checkboxId}>
            <input type="checkbox" checked={isChecked} id={checkboxId} />
            <span className="checkmark"></span>
          </div>
        );
      };
     
   

      function handleBackButton(data) {
       return history.goBack();
    }

    
      const handleTimeDate = (e,inputName) => {
    
        const { value } = e.target;

        if (inputName === "date") {
          setSelectedDate(value);
          setInputs((prev) => ({
            ...prev,
            dob: value, // Update dob in inputs state
          }));
        }
      }
    

    function handleChange(e) {
      const { name, value } = e.target;
      setInputs((prev) => ({ ...prev, [name]: value }))
  }

  function handleRelation(text){
    setInputs((prev) => ({
      ...prev,
      relation: text, // Update the selected relation
    }));
  }

    const handleCheckboxChange = (index) => {
      const updatedCheckedState = checkedState.map((_, i) => i === index);
  setCheckedState(updatedCheckedState);

  // Update the selected member based on the checkbox selection
  setSelectedMember({
     id:memberDetails[index]?.id,
    selectedMemberName: memberDetails[index]?.firstName+' '+memberDetails[index]?.lastName,
    relation: memberDetails[index]?.relation,
  });
    };
   const handleSubmit=async() => {
       
    if(selectedMember.selectedMemberName===undefined){
      setIsError(true);
      setMessage("Please select a member");
      return;
    }
    else if(selectedMember.id==userId || selectedMember.id==order?.dependentId){
      setIsError(true);
      setMessage("please  select another member ");
      return;
    }
    try{
    const { selectedMemberName, relation,id } = selectedMember;
    const url =`assign-dependent/child-order-id/${orderId}`;
    
    const payload= id==userId?{
      dependentId:null,
      modifiedBy: nurseUserId
    }
      :{
      dependentId:id,
      modifiedBy: nurseUserId
    };
   const response=await  PhoenixAPI.patch(url,payload);
   console.log("response",response);
   if(response.status === 200){
    setIsError(false);
    setMessage("Member assigned successfully");
    handleBackButton();
    }else{
      setIsError(true);
      setMessage("Failed to assign member");
 
   }

  } catch (error) {
     setIsError(true);
      setMessage(error.message);
  }

   };

   const getMemberDetails=async(userId)=>{
      const url= 'multiple-profile/?id='+userId;
      try{
        setIsLoading(true);

      const { data: members =[] } =await PhoenixAPI.get(url);
       console.log("Members:",members);

       setMemberDetails(members);

       setCheckedState(new Array(members.length).fill(false));
        
      await getUserDetails(nurseUserId,orderId,members);
      }catch(error){
        console.log("Error occurred in fetcching orders", error.message)
      }
      finally{
       setIsLoading(false)
      }
   }

   const getUserDetails=async(nurseUserId,orderId,members)=>{
    let  url=`nurse/new-orders/${nurseUserId}/order-id/${orderId}`
  try{
  setIsLoading(true)

  const { data: order ={} } =await PhoenixAPI.get(url);
  console.log("OrderId:",order);
  setOrder(order);

  const updatedMembers = [
    ...members, 
    { id: userId, relation: "Self", firstName: "Self",lastName:"" }
  ];
  setMemberDetails(updatedMembers);
  
  if(order.dependentId===null){
    
    setSelectedMember({
      id:order.userId,
      selectedMemberName:order.userName,
      relation:"self"
    });
    const updatedMembers = [
      ...members, 
      { id: userId, relation: "Self", firstName: order.primaryUserName,lastName:"" }
    ];
   
    setMemberDetails(updatedMembers);
  }
  else{
    console.log("member details",members);
    const filteredMember=members.filter((member)=>member.id===order.dependentId)
    console.log("filteredMember",filteredMember);
    setSelectedMember({
      id:filteredMember[0].id,
      selectedMemberName:filteredMember[0].firstName+" "+filteredMember[0].lastName,
      relation:filteredMember[0].relation
    })
    const updatedMembers = [
      ...members, 
      { id: userId, relation:"Self" , firstName:order.primaryUserName ,lastName:"" }
    ];
    setMemberDetails(updatedMembers);
  }
  setCheckedState(new Array(updatedMembers.length).fill(false));
  }
  catch(error){
    console.log("Error occurred in fetcching orders", error.message)
  }
  finally{
   setIsLoading(false)
  }

   }

    const validate=()=>{
      let isError = false;
      const errors = {};
      if (!inputs.firstName) {
        errors.firstName = "First Name is required";
        isError = true;
      }
      if (!inputs.lastName) {
        errors.lastName = "Last Name is required";
        isError = true;
      }
      if (!inputs.dob) {
        errors.dob = "Date of Birth is required";
        isError = true;
      }
      if (!inputs.gender) {
        errors.gender = "Gender is required";
        isError = true;
      }
      if(!inputs.relation){
        errors.relation = "Relation is required";
        isError = true;
      }
      
      setError({...errors })
      return isError;
    }
   const handleAddMember=async()=>{
    const errors = validate();
    
   if (errors) {
      setIsError(true);
      setMessage("There are some invalid form fields!");
      return;
    }
    

    
    console.log("inputs",inputs);
    const payload = { ...inputs };

    try {
      setIsLoading(true);
      setIsButtonDisabled(true);
      const formData = new FormData();
      payload.userId=userId;
      payload.isActive = true;
      formData.append("member", JSON.stringify(payload));
      formData.append("updated_by","nurse");
      const config = {
        method: "POST",
        url: BASE_URL_FAMILY_MEMBERS,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      await PhoenixAPI.request(config);
      setInputs({});
      setSelectedDate("");
      setIsButtonDisabled(false);
      console.log("inputs",inputs)
      getMemberDetails(userId);
      
    }catch (error) {
      setIsError(true);
        setMessage("error on add member");
    } finally {
      setIsLoading(false);
    }

   }

   

   

    return (
        <>
        <div className="change-meber-form-container">
        <div className='top-header'>
        <div className='header-content'>
            <div className='content' onClick={() => handleBackButton()}>
            <div className='lt' >&lt;</div>
            <div className='header-title' >Change Member</div>
          </div>
        </div>
        </div>
        <div className='change-member-div'>
         <div className="selected-member"> <div className="selected-header">Selected Member</div>
         <div className="selected-member-details">
            <div className="selected-name-relation">
                <div className="selected-member-name"> {selectedMember?.selectedMemberName}</div>
                
            </div>
           <div className= "member-relation-text-container">
           <div className="relation-text">{selectedMember?.relation}</div>
           </div>
            
         </div>
         </div> 
        </div>

        <div className='change-member-div'>
         <div className="selected-member"> <div className="selected-header">List of Members</div>
         {memberDetails?.map(( member,index) => (<div className="selected-member-details">
         
   
    
    <div className="selected-name-relation">
                <div className="selected-member-name">{member.firstName +" " + member.lastName}</div>
                <div className="selected-member-relation">{member.relation}</div>
    </div>
    
    <div className="checkbox-text-container"> <div className="selected-text">Select</div>
    <div  key={index} onClick={() => handleCheckboxChange(index)}>
      <CheckBox isChecked={checkedState[index]} index={index} />
    </div>
    </div>
  </div>
    ))}

           
            
         
         </div> 
        </div>

        <div className="add-member">
         <div className="add-selected-header">
          Add New Member
         </div>
        <div className= "add-member-allsection">
           <div className="input-firstname">
           <label htmlFor="" className='label'>First Name  <span className='star'>*</span></label>
                            <input className={`${error.name ? "error-border" : ""}`} type="text" name="firstName" placeholder='Enter name' value={inputs?.firstName|| ""} onChange={(e) => handleChange(e)} />
                            {error.firstName && <div className='error'>{error.firstName}</div>}
            </div>
            <div className="input-firstname">
           <label htmlFor="" className='label'>Last Name  <span className='star'>*</span></label>
                            <input className={`${error.name ? "error-border" : ""}`} type="text" value={inputs?.lastName ||""} name="lastName" placeholder='Enter name' onChange={(e) => handleChange(e)} />
                            {error.lastName && <div className='error'>{error.lastName}</div>}
            </div>
        </div>

        <div className="add-member-allsection">
          <div className="date-birth">
          <label htmlFor="date" className='label'> Date of Birth <span className='star'>*</span></label>
          <input className={`${error.date ? "error-border" : ""}`} type="date" name="date" value={selectedDate} onChange={(e) => handleTimeDate(e,"date")} />
          {error.dob && <div className='error'>{error.dob}</div>}
            </div>
            <div className="gender">
              <label htmlFor="" className='label'>Select Gender <span className='star'>*</span></label>
              <select className={`${error.gender?"error-border":""}`} name="gender" options={genders} value={inputs?.gender||""} onChange={(e)=>handleChange(e)}>
              {genders.map((gender) => (
    <option key={gender.key} value={gender.value}>
      {gender.text}
    </option>
  ))}
   </select>
              
    {error.gender && <div className='error'>{error.gender}</div>}

            </div>

          </div>
          <div className="add-member-allsection">
            <div className="relation">
            <label htmlFor="" className='label'>Relation <span className='star'>*</span></label>
            <div className="relation-input">
            {relations.map((relationItem) => (
      <div
        key={relationItem.type}
        id={`member-relation-txt-${relationItem.type.toLowerCase()}`}
        className={`relation-form ${
          inputs?.relation === relationItem.type ? "relation-selected" : ""
        } ${error.relation ? "error-border" : ""}`}
        onClick={() => handleRelation(relationItem.type)}
      >
        {relationItem.text}
      </div>
    ))}
    </div>
    {error.relation && <div className='error'>{error.relation}</div>}
</div>
</div>
          

          <div className={`save-button ${isButtonDisabled ? "disabled" : ""}`}  onClick={!isButtonDisabled ? handleAddMember : null}>
                        Add Member
                    </div>

        </div>

           
          </div>
          {message && <Notification setMessage={setMessage} message={message} isError={isError}  />}
        <div className="save-and-proceed" onClick={(e) => handleSubmit(e)}>
          <div className="proceed-btn" >Save and Proceed</div>
        </div>


        
        
        
        </>)

}

export default ChangeMemberDetails;