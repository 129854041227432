import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import API from "utils/API";
import { compareStrings } from "utils/commons";

SelectProductCategory.propTypes = {
  countryId: PropTypes.number.isRequired,

  selectedProductCategoryId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectProductCategory.defaultTypes = {
  selectedProductCategoryId: [],
  onSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectProductCategory(props) {
  const {
    countryId,
    menuPlacement,
    selectedProductCategoryId,
    onSelect,
    showOnlyActive = false,
    isDisabled = false,
  } = props;
  
  const [productCategories, setProductCategories] = useState([]);
  useEffect(() => {
    if (!countryId) {
      console.log("Invalid country id!", { countryId });
      return;
    }
    const fetchProductCategories = async () => {
      try {
        const { data: productCategories = [] } =
          (await API.get("products/")) || [];
      
        const filteredPackagesForCountry =
          productCategories &&
          productCategories.filter((item) => item.country === countryId);
        setProductCategories(filteredPackagesForCountry);
      } catch (error) {
        console.log("Error on fetching Coach Consultations", error);
      }
    };
    fetchProductCategories();
  }, [countryId]);
  const findProductCategory = (id) =>
    productCategories.find((it) => it.id === id);

  const [activeProductCategories, inactiveProductCategories] = _.partition(
    productCategories,
    (it) => it.status == "Active"
  );

  activeProductCategories.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  inactiveProductCategories.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const options = showOnlyActive
    ? activeProductCategories.map(createOption)
    : [
        {
          label: "Active",
          options: activeProductCategories.map(createOption),
        },
        {
          label: "Inactive",
          options: inactiveProductCategories.map(createOption),
        },
      ];
  
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selectedProductCategories = selectedProductCategoryId
    .map(findProductCategory)
    .map(createOption);

  return (
    <Select
      key={`my_unique_select_key__${
        selectedProductCategoryId && selectedProductCategoryId.id
      }`}
      value={selectedProductCategories}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder="Select Category"
      menuPlacement={"top"}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

const createOption = (productCategory) => {
  return productCategory
    ? {
        key: productCategory.id,
        value: productCategory.id,
        label: productCategory.name,
      }
    : null;
};

export default SelectProductCategory;
