import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { Radio } from "semantic-ui-react";
import moment from "moment";
import SelectAgentBetter from "./SelectAgentBetter";
import PaymentInformation from "./PaymentInformation";
import TransactionInformation from "./TransactionInformation";
import EditOrderNotes from "./EditOrderNotes";
import EditCustomerNotes from "./EditCustomerNotes";
import SelectOrderStatus from "./SelectOrderStatus";
import SelectCoach from "./SelectCoach";
import SelectLab from "./SelectLab";
import SelectTimeslot from "./SelectTimeslot";
import LabInformationCard from "./LabInformationCard";
import HomecareInformationCard from "./HomecareInformationCard";
import OrderNotesCard from "./OrderNotesCard";
import EditPaymentInfoModal from "./EditPaymentInfoModal";
import EditCartOrderViewModal from "./EditCartOrderViewModal";
import EditCartOrderBasicViewModal from "./EditCartOrderBasicViewModal";
import ConfirmOrderCancelViewModal from "./ConfirmOrderCancelViewModal";
import ConfirmCancelRefundNotes from "./ConfirmCancelRefundNotes";
import { fetchCountry } from "services/RegionService";
import OrdersListCancel from "./OrdersListCancel";
import {
  fetchOrder,
  fetchSupplementOrder,
  saveOrder,
  updateChildOrder,
  updateParentOrderStatus,
  cancelParentOrder,
} from "services/OrderService";
import {
  fetchClientUser,
  fetchAdminUsers,
  fetchCoachUsers,
  fetchOperationsUsers,
} from "services/UserService";
import { getOrderStatusWithColor } from "./OrderUtils";
import API from "utils/API";
import EditCustomerIDInfo from "./EditCustomerIDInfo";
import { updateClientUserIDInfo } from "services/UserService";
import { getUserEmail } from "services/UserService";
import { saveOrderStatusLogs } from "services/OrderService";
import SelectHomeCareService from "./SelectHomeCareService";
import OperationsInternalCard from "./OperationsInternalCard";
import EditCartOrderNoHomeAppointmentViewModal from "./EditCartOrderNoHomeAppointmentViewModal";
import { compareStrings } from "utils/commons";
import OperationsInternalBasicCard from "./OperationsInternalBasicCard";
import { checkAndUpdateParentOrderStatus } from "services/OrderService";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import SelectMiniPackages from "components/Common/SelectMiniPackages";
import PdfPreview from "components/Common/PdfPreview";
import EditCustomerShippingAddress from "./EditCustomerShippingAddress";
import { fetchFamilyMembers } from "services/FamilyMembersService";
import { updateChildPastOrder } from "services/OrderService";
import axios from "axios";
import { green } from "@material-ui/core/colors";
import { Dropdown } from "semantic-ui-react";
import cancelReasonOptions from "../../utils/CancelReason";

import EditDependentIDInfo from "./EditDependentIDInfo";

import SelectCustomTestAddons from "components/Common/SelectCustomTestAddons";
import { formatDate } from "utils/commons";
import HomecareInfoResample from "./HomecareInfoResample";


const EVENT_ORDER_STATUS_CHANGE = "ORDER_STATUS_CHANGE";
// const IMAGE_BASE_URL =
//   process.env.NODE_ENV === "development"
//     ? process.env.REACT_APP_BASE_IMAGE_URL_QA
//     : process.env.REACT_APP_BASE_IMAGE_URL_PROD;
const IMAGE_BASE_URL = process.env.REACT_APP_BASE_IMAGE_URL;
const BASE_URL_MINI_PACKAGES = "/api/v1/mini-packages";
const BASE_URL_UPDATE_CART_ORDER_ADDRESS="/admin/update-cart-order-address";
const BASE_URL_UPDATE_REFUND_NOTES="/admin/update-cancel-and-refund-notes";
const BASE_URL_CUSTOMER_NOTES="/order-client-notes";
const API_DEPENEDENT_INFO_UPDATE="/nurse/fullNameId/";


class OrdersDashboardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      agents: [],
      wellbeingCoaches: [],
      labs: [],
      homeCareServices: [],
      order: null,
      country: null,
      customerIDInfo: {},
      collapsibles: {},
      customerIDInfoForModal: null,
      customerNotesForModal: null,
      clientNotesForModal:null,
      orderRefundNotesModal:null,
      orderNotesForModal: null,
      editRefundNotes:null,
      editRefundFlag:false,
      isConfirmOrderCancelViewModalVisible: false,
      isConfirmOrderCancelRefundViewModalVisible:false,
      isEditOrderNotesViewModalVisible: false,
      isEditCustomerNotesViewModalVisible: false,
      isEditCustomerIDInfoViewModalVisible: false,
      isEditPaymentInfoViewModalVisible: false,
      isEditCartOrderBasicViewModalVisible: false,
      isEditCartOrderViewModalVisible: false,
      isEditCartOrderNoAppointmentViewModalVisible: false,
      isEditCustomerShippingAddressViewModalVisible:false,
      isEditClientNotesViewModalVisible: false,
      isCodMamopayPaymentStatus:false,
      isNotifyClientRefundCancel:false,
      shippingAddressModal:null,
      refundFinalNotes:null,
      operationsUsers: [],
      miniPackages: [],
      isNotifyClientCancel:false,
      cancelNotes:null,
      cancelNoteFinal:null,
      orderType:"",
      familyMembers:[],
      externalCoaches:[],
      secondaryCoachId:null,
      isCancelCompletedModal:false,
      shouldShowSendDetailsToLabBtn : true,

      nurses:[],
      cancelReason:"",
      dependentIDInfoForModal:{},
      isEditDependentIDInfoViewModalVisible:false,
      

      cancelReason:null,
      collapsiblesOperation:{}
    };
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchAgents();
    this.fetchWellbeingCoaches();
    this.fetchLabs();
    this.fetchHomeCareServices();
    this.fetchOrder();
    this.fetchPaymentGateways();
    this.fetchOperationsUsers();
    // this.fetchMiniPackages();
    this.fetchExternalCoaches();
    // this.shouldShowSendDetailsToLabBtnFunc();
    this.fetchNurses();
  }

  shouldShowSendDetailsToLabBtnFunc = async(id,cartItemDetails)=>{

    if(cartItemDetails.orderStatus==="SAMPLE COLLECTED BY HOMECARE"){
      console.log("this is the response",cartItemDetails.orderStatus);
      this.setState({shouldShowSendDetailsToLabBtn : true})
    }
    else{
      console.log("this is the response",cartItemDetails.orderStatus);
      this.setState({shouldShowSendDetailsToLabBtn : false})
      return false;
    }
  }
  
  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        // console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.CART_ORDERS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchAgents = async () => {
    try {
      const agents = (await fetchAdminUsers()) || [];
      this.setState({ agents });
    } catch (error) {
      this.showErrorNotification("Error on fetching agents: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  };

  fetchWellbeingCoaches = async () => {
    try {
      const wellbeingCoaches = (await fetchCoachUsers()) || [];
      this.setState({ wellbeingCoaches });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching wellbeing coaches: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  };

  fetchExternalCoaches=async()=>{
    try {
      const {data:externalCoaches=[]} = await PhoenixAPI.get("external/coach/users/") || [];
      this.setState({ externalCoaches });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching external coaches: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  }

  fetchLabs = async () => {
    try {
      const { data: labs } = await API.get("create-laboratory/");
      const activeLabs= labs && labs.filter((item)=>item.is_active)
      console.log("Active Labs", activeLabs)
      this.setState({ labs:activeLabs });
    } catch (error) {
      this.showErrorNotification("Error on fetching labs: ", error.message);
      console.log("Error on fetching labs", error);
    }
  };
  
  updateCODMamoPayementStatus=async()=>{
    const{order}=this.state;
    const orderId=order?.id
    const orderUrl=`admin/cart-orders/${orderId}`
    // console.log("checking payement status",this.state.isCodMamopayPaymentStatus);
   
   try{
     const {data:updatedOrder}= await PhoenixAPI.get(orderUrl);
    this.setState({order:updatedOrder});
    console.log("this is the required data for now",updatedOrder);
  }
  
    catch (error) {
      console.log("error on saving", error);
      this.showErrorNotification("error of gettingupdate order",error.message);
    }

   // this.setState({"isCodMamopayPaymentStatus":!this.state.isCodMamopayPaymentStatus});
     
   // this.getPaymentDetails();
  }
  updatePaymentMethod=async()=>{
    const{order}=this.state;
    const orderId=order?.id;
    const orderUrl=`admin/cart-orders/${orderId}`
   
   
   try{
     const {data:updatedOrder}= await PhoenixAPI.get(orderUrl);
    this.setState({order:updatedOrder});
  }
  
    catch (error) {
      console.log("error on saving", error);
      this.showErrorNotification("error of gettingupdate order",error.message);
    }
  }

  fetchHomeCareServices = async () => {
    try {
      const { data: homeCareServices = [] } = await API.get(
        "home-care-service/"
      );
      // const activeHomeCareServices = homeCareServices.filter(
      //   (it) => it.is_active
      // );
      this.setState({ homeCareServices: homeCareServices });
    } catch (error) {
      console.log("Error on fetching homecare services", error);
      this.showErrorNotification(
        "Error on fetching homecare services: ",
        error.message
      );
    }
  };

  fetchNurses = async () => {
    try {
      const { data: nurses = [] } = await PhoenixAPI.get("nurse/all");
      
      

      this.setState({ nurses});
      // console.log("nurses ARE" ,nurses);

    } catch (error) {
      this.showErrorNotification("Error on fetching nurses: ", error.message);
    }
  };


  fetchPaymentGateways = async () => {
    try {
      const { data: paymentGateways = [] } = await API.get("payment-gateway/");
      this.setState({ paymentGateways });
    } catch (error) {
      console.log("Error on fetching payment gateways", error);
    }
  };

  fetchOperationsUsers = async () => {
    try {
      const data = (await fetchOperationsUsers()) || [];
      let operationsUsers = data.sort((a, b) => {
        return a.userName.localeCompare(b.userName);
      });
      this.setState({ operationsUsers });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Operations Users: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  };

  fetchOrder = async () => {
    const { orderId } = this.props.match.params;
    // console.log("fetchOrder:: orderId:", orderId);
    this.showLoading();
    try {
      const currentPath = window.location.href.split("/admin")[1];
      let order={};
      if(currentPath.includes("/supplement")){
        order= await fetchSupplementOrder(orderId);
      }
      else{ order = await fetchOrder(orderId);
      }
      const country = await this.fetchCountry(
        order?.transactionDetails?.orderRequestPayload?.countryId
      );
      const customerIDInfo = await this.fetchCustomerIDInfo(order?.userId);
      
      // const familyMembers= await fetchFamilyMembers(order?.userId)
     
      // const selfMember={
      //   "id":"self",
      //   "fullName":`Self`,
      //   "relation":"self"
      // }
      // familyMembers.push(selfMember)
      this.setState({ order, country, customerIDInfo });
    } catch (error) {
      this.showErrorNotification("Error on fetching order: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountry = async (countryId) => {
    // console.log("fetchCountry:: countryId:", countryId);
    if (countryId === null || countryId === undefined) return null;
    return await fetchCountry(countryId);
  };

  fetchCustomerIDInfo = async (customerId) => {
    // console.log("fetchCustomerIDInfo:: customerId:", customerId);
    if (customerId === null || customerId === undefined) return null;
    try {
      return await fetchClientUser(customerId);
    } catch (error) {
      return null;
    }
  };

  fetchMiniPackages = async () => {
    try {
      this.showLoading();
      const { data: miniPackages = [] } = await PhoenixAPI.get(
        BASE_URL_MINI_PACKAGES
      );
      this.setState({ miniPackages });
    } catch (error) {
      console.log("fetchMiniPackages:: Error on fetching mini packages!", error);
    } finally {
      this.hideLoading();
    }
  };

  handleCollapsibleChange = (key,cartItemDetails) => {
    console.log("this is the key",key)
    this.shouldShowSendDetailsToLabBtnFunc(key,cartItemDetails)
    const collapsibles = {};
    if (this.state.collapsibles[key] === undefined) collapsibles[key] = true;
    this.setState({ collapsibles });
  };

  handleOperationCollapsibleChange=(key,cartItemDetails)=>{
    const collapsiblesOperation={};
    if(this.state.collapsiblesOperation[key]===undefined)collapsiblesOperation[key] = true;
    this.setState({collapsiblesOperation});

  }
  handleSecondaryCoach=(cartItemDetails)=>{
    const {secondaryCoachId=null}=cartItemDetails || {}
     this.setState({secondaryCoachId})
  }

  handleHideEditOrderNotesViewModal = () =>
    this.setState({
      isEditOrderNotesViewModalVisible: false,
      orderNotesForModal: null,
    });

    handleHideRefundOrderNotesModal=()=>
    this.setState({
      isConfirmOrderCancelRefundViewModalVisible:false,
      orderRefundNotesModal:null,
    })


  handleHideEditCustomerNotesViewModal = () =>
    this.setState({
      isEditCustomerNotesViewModalVisible: false,
      customerNotesForModal: null,
    });

    handleHideEditClientNotesViewModal = () =>
    this.setState({
      isEditClientNotesViewModalVisible: false,
      
    });

    handleHideEditCustomerShippingAddressViewModal=()=>{
      this.setState({
        isEditCustomerShippingAddressViewModalVisible:false,
        shippingAddressModal:null,
      })
    }

  handleHideEditCustomerIDInfoViewModal = () =>
    this.setState({
      isEditCustomerIDInfoViewModalVisible: false,
      customerIDInfoForModal: null,
    });

    handleHideDependentIDInfoViewModal=()=>
      this.setState({
        isEditDependentIDInfoViewModalVisible:false,
        dependnetIDInfoForModal:null,
      })
  handleHideEditPaymentInfoViewModal = () =>
    this.setState({ isEditPaymentInfoViewModalVisible: false });

  handleHideEditCartOrderViewModal = () =>
    this.setState({
      cartItem: null,
      cartItemDetails: null,
      isEditCartOrderViewModalVisible: false,
    });

  handleHideEditCartOrderBasicViewModal = () =>
    this.setState({
      cartItemDetails: null,
      isEditCartOrderBasicViewModalVisible: false,
    });

  handleHideEditCartOrderNoAppointmentViewModal = () =>
    this.setState({
      cartItem: null,
      cartItemDetails: null,
      isEditCartOrderNoAppointmentViewModalVisible: false,
    });
  showCompleteCancelButton=()=>{
    const{order}=this.state;
    this.setState({
      isCancelCompletedModal:true,
      orderId:order?.id
    })
  }
  handleHideCompleteCancelButton=()=>{
    this.setState({
      isCancelCompletedModal:false,
      orderId:null
    })
  }
  

  showEditOrderNotesViewModal = () => {
    const { order } = this.state;
    this.setState({
      isEditOrderNotesViewModalVisible: true,
      orderNotesForModal: order?.orderNotes ?? null,
    });
  };
  showEditRefundNotesViewModal = () => {
    const { order } = this.state;
    this.setState({
      isEditOrderRefundNotesViewModalVisible: true,
      orderRefundNotesModal: order?.refundNotes ?? null,
    });
  };
  hideCancelNotesViewModal=()=>{
    this.setState({ isConfirmOrderCancelViewModalVisible: false,cancelNotes:null,isNotifyClientCancel:false,cancelReason:null });
  }

  showEditPaymentInfoViewModal = () =>
    this.setState({ isEditPaymentInfoViewModalVisible: true });

  showEditCustomerNotesViewModal = () => {
    const { order } = this.state;
    this.setState({
      isEditCustomerNotesViewModalVisible: true,
      customerNotesForModal: order?.userDetails?.clientNote ?? null,
    });
  };

  showEditClientNotesViewModal=()=>{
    const { order } = this.state;
      // console.log("check client note",order?.clientNotes);
    this.setState({
      isEditClientNotesViewModalVisible: true,
      clientNotesForModal: order?.clientNotes ?? null,
    });
  }

  showEditCustomerShippingAddressViewModal=async()=>{
     const {order}=this.state;
    
     
     const {data:userCityAddress=[]}= await PhoenixAPI.get(`user-address/${order.userId}/${order.cityId}`)
     this.setState({isEditCustomerShippingAddressViewModalVisible:true,
      shippingAddressModal:order?.addressDetails ?? null,userCityAddress:userCityAddress

    })
  }
  showEditCustomerIDInfoViewModal = () =>
    this.setState({
      isEditCustomerIDInfoViewModalVisible: true,
      customerIDInfoForModal: this.state.customerIDInfo ?? null,
    });

    showDependentInfoViewModal =(dependentInfo)=>
      this.setState({
        isEditDependentIDInfoViewModalVisible: true,
        dependentIDInfoForModal: dependentInfo?? null,
        
      });

  showEditCartOrderViewModal = (cartItem, cartItemDetails) =>
    this.setState({
      cartItem,
      cartItemDetails,
      isEditCartOrderViewModalVisible: true,
    });
   
    completeOrderWithoutReviewButton=async(orderId)=>{
      this.showLoading();
      try{
        const statusvalue="COMPLETED";
        const userId=getUserId();
      await  API.patch("order-list/" + orderId + "/", { order_status: statusvalue ,modified_by:userId
        });
        this.showSuccessNotification("Order Completed Successfully");
        this.fetchOrder();
      }
      catch(error){
        this.showErrorNotification("Error on updating order status: " + error.message);
      }
      finally {
        this.hideLoading();
      }
    }
    renderCompleteWithoutReview=(orderId)=>{
      return (
        <button
          onClick={(e) => this.completeOrderWithoutReviewButton(orderId)}
          className="btn btn-md new-user"
          style={{  backgroundColor: "#0CA789",color: "white", border:"1px soid green", margin:"8px 0px 0px 18px",}}
        >
          Complete Order without Review
        </button>
      );
    }




  renderChildCancelOrderButton = (cartItem, cartItemDetails) => {

    return (<button
      onClick={(e) =>
        this.setState({ isConfirmOrderCancelViewModalVisible: true, orderType: "child", cartItem, cartItemDetails })
      }
      disabled={!this.state.isEditable}
      className="btn button btn-danger"
    >
      Cancel Order
    </button>);
  }
  renderChildCancelRefundOrderButton = (cartItem, cartItemDetails) => {
    return (<button
      onClick={(e) =>
        this.setState({ isConfirmOrderCancelRefundViewModalVisible: true, orderType: "child", cartItem, cartItemDetails })
      }
      disabled={!this.state.isEditable}
      className="btn button btn-danger"
    >
      Refund & Cancel
    </button>);
  }

  renderChildSendDetailsToLab = (cartItem, cartItemDetails) => {
    return ( this.state.shouldShowSendDetailsToLabBtn? <button
      onClick={(e) =>
        this.sendDetailsToLabFunc(cartItem.orderId)
      }
      // disabled={!this.state.shouldShowSendDetailsToLabBtn}
      className="btn btn-md new-user"
      style={{  backgroundColor: "#0CA789",color: "white", border:"1px soid green", margin:"8px 0px 0px 18px",}}
    >
      Send Details to Lab
    </button> : <div></div> );
  }

  sendDetailsToLabFunc = async(id)=>{
    const url = "send-details-to-lab"
    const payload = JSON.stringify(id);
    const response  = await PhoenixAPI.post(url,payload,{
      headers:{
        'Content-Type': 'application/json'
      }
    })
    console.log("this is send details result",response)
    if(response.data.message=="Email sent to lab"){
      this.showSuccessNotification("Details sent to lab")
    }
    else if(response.data.message=="two labs are involved"){
      this.showErrorNotification("Two labs are involved")
    }
    else if(response.data.message=="Email won't be sent to lab"){
      this.showErrorNotification("Lab is associated with homecare")
    }
    else if(response.data.message=="Home care is not assigned"){
      this.showErrorNotification("Home care is not assigned")
    }
    else{
      this.showErrorNotification("Something went wrong")
    }

  }

  showEditCartOrderBasicViewModal = (cartItem, cartItemDetails) =>
    this.setState({
      cartItem,
      cartItemDetails,
      isEditCartOrderBasicViewModalVisible: true,
    });

  showEditCartOrderNoAppointmentViewModal = (cartItem, cartItemDetails) =>
    this.setState({
      cartItem,
      cartItemDetails,
      isEditCartOrderNoAppointmentViewModalVisible: true,
    });

  handleAgentSelect = async (order, agentId) => {
    // console.log("handleAgentSelect::", { agentId, order });
    try {
      order.agentId = agentId;
      await saveOrder(order);
      this.showSuccessNotification("Agent assigned successfully.");
      this.fetchOrder();
    } catch (error) {
      this.showErrorNotification("Error on assigning agent: ", error.message);
    }
  };

  handleSaveProfessional=(professionalId,orderProfessionalId,homeCareId,orderId)=>{
    const selectedNurse = this.state.nurses.filter(
      (item) => item.professionalId == professionalId
    );
    const modifiedBy=getUserId();
    console.log("nurseobj", selectedNurse);
    const nurseObject = selectedNurse[0] || {};
    const { professionalId: professionalIdFinal = null } = nurseObject;
    if( professionalIdFinal && orderProfessionalId !== professionalIdFinal ){
      const nursePayload = {
        homecareId:homeCareId ,
        orderId: orderId,
        professionalId: professionalIdFinal,
        userId: parseInt(modifiedBy) ?? null,
      };
      try {
  
        const response = PhoenixAPI.post("nurse/assign-nurse", nursePayload);
  
        console.log("response: " + response);
      }
      catch (error) {
        console.log("error: " + error.message)
      }
    }
  }

  handleSaveCartOrderBasic = (orderDetails, updatedDetails) => {
    // console.log("OrderDashboardDetails:: handleSaveCartOrderBasic", {
    //   orderDetails,
    //   updatedDetails,
    // });
    
    const {
      orderStatus,
      homeCareCost,
      homeCareId,
      handledById,
      customTestPriceAdditional,
      lab1Id,
      lab1CustomCost,
      paymentGateway1Id,
      referenceNumber1,
      lab2Id,
      lab2CustomCost,
      paymentGateway2Id,
      referenceNumber2,
      revisedPrice,
      couponAmount,
      revisedPriceAfterCoupon,
      refundAmount,
      customTestBiomarkers,
      notes,
      couponCodeValue,
      sampleDetailsLab1,
      sampleDetailsLab2,
      specialInstructions,
      paymentToBeCollected,
      professionalId
    } = updatedDetails;

    const payload = {};

    console.log("check check orderstatus",orderStatus)

    if (orderStatus && orderDetails.orderStatus !== orderStatus)
      payload.order_status = orderStatus;
    if(orderDetails.homeCareId !==homeCareId)
    payload.home_care_id = homeCareId ?? null;
    
    


   


    if (orderDetails.homeCareCost !== homeCareCost){

      payload.home_care_cost = homeCareCost;
      payload.is_homecare_cost_manually_updated=true;
    }
    

    if (handledById && orderDetails.handledById !== handledById)
      payload.handled_by = Number(handledById);
    if (orderDetails.customTestPriceAdditional !== customTestPriceAdditional)
      payload.custom_test_price_additional = Number(customTestPriceAdditional);

    if (lab1Id && lab1Id) {
      payload.lab_1 = lab1Id ? Number(lab1Id) : null;
    }

    if (lab2Id && lab2Id) {
      payload.lab_2 = lab2Id ? Number(lab2Id) : null;
    }
   

    if (orderDetails.lab1CustomCost !== lab1CustomCost)
      payload.lab_1_custom_cost = Number(lab1CustomCost);

    if (orderDetails.lab2CustomCost !== lab2CustomCost)
      payload.lab_2_custom_cost = Number(lab2CustomCost);

    if (
      paymentGateway1Id &&
      orderDetails.paymentGateway1Id !== paymentGateway1Id
    )
      payload.payment_gateway_1 = Number(paymentGateway1Id);

    if (
      paymentGateway2Id &&
      orderDetails.paymentGateway2Id !== paymentGateway2Id
    )
      payload.payment_gateway_2 = Number(paymentGateway2Id);

    if (orderDetails.referenceNumber1 !== referenceNumber1)
      payload.reference_number_1 = referenceNumber1;

    if (orderDetails.referenceNumber2 !== referenceNumber2)
      payload.reference_number_2 = referenceNumber2;

    if (orderDetails.revisedPrice !== revisedPrice)
      payload.revised_price = Number(revisedPrice);

    if (orderDetails.couponAmount !== couponAmount)
      payload.coupon_amount = Number(couponAmount);

    if (orderDetails.revisedPriceAfterCoupon !== revisedPriceAfterCoupon)
      payload.revised_price_after_coupon = Number(revisedPriceAfterCoupon);

    if (orderDetails.refundAmount !== refundAmount)
      payload.refund_amount = Number(refundAmount);

    if (orderDetails.customTestBiomarkers !== customTestBiomarkers)
      payload.custom_test_biomarkers = customTestBiomarkers;
    if (orderDetails.notes !== notes) payload.notes = notes;

    if (orderDetails.couponCodeValue !== couponCodeValue)
      payload.coupon_code_value = couponCodeValue;

    if (orderDetails.sampleDetailsLab1 !== sampleDetailsLab1)
      payload.sample_details_lab1 = sampleDetailsLab1;

    if (orderDetails.sampleDetailsLab2 !== sampleDetailsLab2)
      payload.sample_details_lab2 = sampleDetailsLab2;

    if (orderDetails.paymentToBeCollected !== paymentToBeCollected)
      payload.payment_to_be_collected = paymentToBeCollected;

    if (orderDetails.specialInstructions !== specialInstructions)
      payload.special_instructions = specialInstructions;

    if (Object.keys(payload).length > 0) {
      // const payload = { order_status: orderStatus };
      this.updateChildOrder(orderDetails.itemOrderId, payload, () => {
        this.setState({ isSaving: false });
        this.handleHideEditCartOrderBasicViewModal();
        if (orderDetails.orderStatus !== orderStatus){
          this.saveOrderStatusLogs(
            orderDetails.itemOrderId,
            orderDetails.orderStatus,
            orderStatus
          );
        }
      });
    } else {
      this.handleHideEditCartOrderBasicViewModal();
    }
  };
 
  

  handleSaveCartOrder = (orderDetails, updatedDetails) => {
    // console.log("OrderDashboardDetails:: handleSaveCartOrder", {
    //   orderDetails,
    //   updatedDetails,
    // });

    const [confirmedDatee, confirmedTimee] = orderDetails?.confirmedDateTime
      ? orderDetails.confirmedDateTime?.split(" ")
      : [null, null];
    const confirmedDateeTimee = `${confirmedDatee} ${confirmedTimee}`;

    const {
      orderStatus,
      bookedSlotDate,
      bookedSlotTime,
      confirmedDate,
      confirmedTime,
      laboratoryId,
      nutritionistId,
      labCost,
      homeCareCost,
      homeCareId,
      handledById,
      customTestPriceAdditional,
      lab1Id,
      lab1CustomCost,
      paymentGateway1Id,
      referenceNumber1,
      lab2Id,
      lab2CustomCost,
      paymentGateway2Id,
      referenceNumber2,
      revisedPrice,
      couponAmount,
      revisedPriceAfterCoupon,
      refundAmount,
      customTestBiomarkers,
      notes,
      couponCodeValue,
      sampleDetailsLab1,
      sampleDetailsLab2,
      specialInstructions,
      paymentToBeCollected,
      secondaryCoachId,
      labCostNotes,
      homecareStatus,
      professionalId,
      isSampleValidated,
    } = updatedDetails;

    const payload = {};
    if (orderStatus && orderDetails.orderStatus !== orderStatus)
      payload.order_status = orderStatus;
    if (bookedSlotDate && orderDetails.bookedSlotDate !== bookedSlotDate) {
      payload.pickupDate = bookedSlotDate;
      payload.booked_slot_date = bookedSlotDate;
    }
    if (bookedSlotTime && orderDetails.bookedSlotTime !== bookedSlotTime) {
      payload.pickupTime = bookedSlotTime;
      payload.booked_slot_time = bookedSlotTime;
    }
    if (confirmedDate && confirmedDatee !== confirmedDate)
      payload.confirmDate = confirmedDate;
    if (confirmedTime && confirmedTimee !== confirmedTime)
      payload.confirmTime = confirmedTime;
    if (confirmedDate && confirmedTime) {
      const confirmedDateTime = `${confirmedDate} ${confirmedTime}`;
      if (confirmedDateeTimee !== confirmedDateTime)
        payload.confirmed_date_time = confirmedDateTime;
    }
    if (laboratoryId && orderDetails.laboratoryId !== laboratoryId)
      payload.laboratory = laboratoryId;
    if (nutritionistId && orderDetails.nutritionistId !== nutritionistId)
      payload.nutritionist = nutritionistId;

    if(orderDetails.homeCareId !==homeCareId){
      if(homeCareId==""){
        payload.home_care_id=null
      }
      else{
      payload.home_care_id = homeCareId ?? null;
      }
    }
      if(orderDetails.homecareStatus!==homecareStatus)
        payload.homecare_status=homecareStatus;
   
    
    if (orderDetails.labCost !== labCost) payload.lab_cost = labCost;
    if (orderDetails.homeCareCost !== homeCareCost){
      payload.home_care_cost = homeCareCost;
      payload.is_homecare_cost_manually_updated=true;
    }
      

    if (handledById && orderDetails.handledById !== handledById)
      payload.handled_by = Number(handledById);
    if (orderDetails.customTestPriceAdditional !== customTestPriceAdditional)
      payload.custom_test_price_additional = Number(customTestPriceAdditional);

    if (lab1Id && lab1Id) {
      payload.lab_1 = lab1Id ? Number(lab1Id) : null;
    }
    if (lab2Id && lab2Id) {
      payload.lab_2 = lab2Id ? Number(lab2Id) : null;
    }
   

    if (orderDetails.lab1CustomCost !== lab1CustomCost)
      payload.lab_1_custom_cost = Number(lab1CustomCost);

    if (orderDetails.lab2CustomCost !== lab2CustomCost)
      payload.lab_2_custom_cost = Number(lab2CustomCost);

    if (
      paymentGateway1Id &&
      orderDetails.paymentGateway1Id !== paymentGateway1Id
    )
      payload.payment_gateway_1 = Number(paymentGateway1Id);

    if (
      paymentGateway2Id &&
      orderDetails.paymentGateway2Id !== paymentGateway2Id
    )
      payload.payment_gateway_2 = Number(paymentGateway2Id);

    if (orderDetails.referenceNumber1 !== referenceNumber1)
      payload.reference_number_1 = referenceNumber1;

    if (orderDetails.referenceNumber2 !== referenceNumber2)
      payload.reference_number_2 = referenceNumber2;

    if (orderDetails.revisedPrice !== revisedPrice)
      payload.revised_price = Number(revisedPrice);

    if (orderDetails.couponAmount !== couponAmount)
      payload.coupon_amount = Number(couponAmount);

    if (orderDetails.revisedPriceAfterCoupon !== revisedPriceAfterCoupon)
      payload.revised_price_after_coupon = Number(revisedPriceAfterCoupon);

    if (orderDetails.refundAmount !== refundAmount)
      payload.refund_amount = Number(refundAmount);

    if (orderDetails.customTestBiomarkers !== customTestBiomarkers)
      payload.custom_test_biomarkers = customTestBiomarkers;
    if (orderDetails.notes !== notes) payload.notes = notes;

    if (orderDetails.couponCodeValue !== couponCodeValue)
      payload.coupon_code_value = couponCodeValue;

    if (orderDetails.sampleDetailsLab1 !== sampleDetailsLab1)
      payload.sample_details_lab1 = sampleDetailsLab1;

    if (orderDetails.sampleDetailsLab2 !== sampleDetailsLab2)
      payload.sample_details_lab2 = sampleDetailsLab2;

    if (orderDetails.paymentToBeCollected !== paymentToBeCollected)
      payload.payment_to_be_collected = paymentToBeCollected;

    if (orderDetails.specialInstructions !== specialInstructions)
      payload.special_instructions = specialInstructions;

      if(orderDetails.secondaryCoachId&& orderDetails.secondaryCoachId){
        payload.secondary_coach_id = Number(secondaryCoachId);
      }

      if(orderDetails.isSampleValidated !==isSampleValidated){
        payload.is_sample_validated = isSampleValidated;
      }

      if (orderDetails.labCostNotes !== labCostNotes) payload.lab_cost_notes = labCostNotes;
    if (Object.keys(payload).length > 0) {
      this.updateChildOrder(orderDetails.itemOrderId, payload, () => {
        this.setState({ isSaving: false });
        if(orderDetails.homeCareId!==homeCareId){
          this.showSuccessNotification("Order BroadCast successfully");
        }
        this.handleSaveProfessional(professionalId,orderDetails.professionalId,homeCareId,orderDetails.itemOrderId);
        
        this.handleHideEditCartOrderViewModal();
        this.handleHideEditCartOrderNoAppointmentViewModal();
        if (orderStatus && orderDetails.orderStatus !== orderStatus) {
          this.saveOrderStatusLogs(
            orderDetails.itemOrderId,
            orderDetails.orderStatus,
            orderStatus
          );
        }
      });
      
    } 
    else {
      if(professionalId !== orderDetails.professionalId){
        this.handleSaveProfessional(professionalId,orderDetails.professionalId,homeCareId,orderDetails.itemOrderId);
      }
      this.handleHideEditCartOrderViewModal();
      this.handleHideEditCartOrderNoAppointmentViewModal();
    }

    
    if(orderStatus==="SAMPLE COLLECTED BY HOMECARE"){
      this.setState({
        shouldShowSendDetailsToLabBtn : true
      })
    }
    else{
      this.setState({
        shouldShowSendDetailsToLabBtn : false
      })
    }
    // window.location.reload()
  };

  checkAndUpdateParentOrderStatus = async (childOrderId) => {
    if (childOrderId === null || childOrderId === undefined) {
      return;
    }
    const { order } = this.state;
    if (order?.orderStatus === "CANCELLED") return;

    try {
      await checkAndUpdateParentOrderStatus(childOrderId);
      this.showSuccessNotification("Order status updated successfully.");
    } catch (error) {
      console.log("Error on updating parent order status", error);
      this.showErrorNotification(
        "Error on updating parent order status: " + error.message
      );
    }
  }

  /*
  Now we are using the backend API for checking updating parent order status, when any child order gets updated.
  checkAndUpdateParentOrderStatus = async () => {
    const { order } = this.state;
    const { items } = order ?? {};
    if (order?.orderStatus === "CANCELLED") return;

    const isAllChildOrdersStatusIs = (...status) =>
      items &&
      items.length &&
      items.every((item) => status.includes(item.orderStatus));

    let orderStatus = null;
    if (isAllChildOrdersStatusIs("CANCELLED")) {
      orderStatus = "CANCELLED";
    } else if (isAllChildOrdersStatusIs("COMPLETED")) {
      orderStatus = "COMPLETED";
    } else if (isAllChildOrdersStatusIs("CREATED")) {
      orderStatus = "CREATED";
    } else if (isAllChildOrdersStatusIs("COMPLETED", "CANCELLED")) {
      orderStatus = "COMPLETED";
    } else {
      orderStatus = "PROCESSING";
    }

    if (order?.orderStatus && order.orderStatus === orderStatus) return;

    try {
      await updateParentOrderStatus(Number(order.id), orderStatus);
      await this.saveOrderStatusLogs(
        Number(order.id),
        order.orderStatus,
        orderStatus
      );
      this.showSuccessNotification("Order status updated successfully.");
      this.fetchOrder();
    } catch (error) {
      console.log("Error on updating parent order status", error);
      this.showErrorNotification(
        "Error on updating parent order status: " + error.message
      );
    }
  };
  */
  cancelChildOrder = async(childOrderId,childOrderStatus)=>{
   const {isNotifyClientRefundCancel ,isNotifyClientCancel,cancelNoteFinal,cancelReason} = this.state;
   const payload = this.state.refundFinalNotes!== null && this.state.refundFinalNotes !== ""?{refund_and_cancel_order_notes:this.state.refundFinalNotes,
    is_refund_and_cancel_order :true,
    is_refund_and_cancel_notify:isNotifyClientRefundCancel,
    cancellation_reason:cancelReason,
     order_status: "CANCELLED" }:{order_status: "CANCELLED",is_send_notification:isNotifyClientCancel,cancellation_notes:cancelNoteFinal,cancellation_reason:cancelReason };

     const userId=getUserId();
       
      if(userId){
        payload.modified_by=userId;
      }
     if(!childOrderId) return;
    try{
      await updateChildOrder(childOrderId, payload);
    await this.saveOrderStatusLogs(childOrderId, childOrderStatus, "CANCELLED");
    this.showSuccessNotification("Order cancelled successfully.");
    this.fetchOrder();
    }
    catch(error){
      this.showErrorNotification(
        "Error on cancelling  order: " + error.message
      );
    }
}


  cancelOrder = async () => {
    const { order,isNotifyClientRefundCancel ,isNotifyClientCancel,cancelNoteFinal,cancelReason} = this.state;
    console.log("cancel in order",cancelNoteFinal);
    const { items } = order ?? {};
    // console.log("OrdersDashboardDetails:: cancelOrder::", order);
    if (!order) return;
    try {
      if (items && items.length) {
        // console.log("OrdersDashboardDetails:: canceling child orders", items);
        await Promise.all(
          items.flatMap((item) => {
            const { itemOrderId, orderStatus } = item;
            const userId=getUserId();
           
            const payload = this.state.refundFinalNotes!== null && this.state.refundFinalNotes !== ""?{refund_and_cancel_order_notes:this.state.refundFinalNotes,
              is_refund_and_cancel_order :true,
              is_refund_and_cancel_notify:isNotifyClientRefundCancel,
              cancellation_reason:cancelReason,
               order_status: "CANCELLED" }:{order_status: "CANCELLED",is_send_notification:isNotifyClientCancel,cancellation_notes:cancelNoteFinal,cancellation_reason:cancelReason };

               if(orderStatus !=="CANCELLED" && orderStatus !=="COMPLETED"){
                if(userId){
                  payload.modified_by=userId;
                }
            return [ 
              updateChildOrder(itemOrderId, payload),
              this.saveOrderStatusLogs(itemOrderId, orderStatus, "CANCELLED"),
            ];
          }
          })
        );
      }
      await cancelParentOrder(Number(order.id));
      await this.saveOrderStatusLogs(
        Number(order.id),
        order.orderStatus,
        "CANCELLED"
      );
     // this.handleSaveRefundNotes(this.state.refundFinalNotes);
      this.showSuccessNotification("Order cancelled successfully.");
      window.location.reload();
    } catch (error) {
      console.log("Error on cancelling parent order", error);
      this.showErrorNotification(
        "Error on cancelling parent order: " + error.message
      );
    }
  };

  updateChildOrder = async (orderId, payload, onSuccess = (f) => f) => {
    // Delete appoitment date and time values from payload, during update.
    const userId=getUserId();
    if(userId){
      payload.modified_by=userId;
    }
    if (payload.client_note === null || payload.client_note === undefined) {
      delete payload.client_note;
    }
    delete payload.pickupDate;
    delete payload.pickupTime;
    delete payload.booked_slot_date;
    delete payload.booked_slot_time;
    try {
      await updateChildOrder(orderId, payload);
      this.showSuccessNotification("Order updated successfully.");
      await onSuccess();
      await this.checkAndUpdateParentOrderStatus(orderId);
      this.fetchOrder();
    } catch (error) {
      this.showErrorNotification("Error on updating order status: " + error.message);
    }
  };

  saveOrderStatusLogs = async (orderId, previousValue, newValue) => {
    const userEmail = getUserEmail();

    let payload = {
      modifiedBy: userEmail,
      previousValue,
      newValue,
      orderId,
      event: EVENT_ORDER_STATUS_CHANGE,
    };

    try {
      await saveOrderStatusLogs(payload);
    } catch (error) {
      console.log("Error on creating order status logs", error);
      this.showErrorNotification("Error on saving order status logs!", error.message);
    }
  };

  handleSaveOrderNotes = (orderNotes) => this.saveOrderNotes(orderNotes);
  handleSaveRefundNotes=(refundNotes,notificationClient,cancelReasonFinal)=>this.saveRefundNotes(refundNotes,notificationClient,cancelReasonFinal);
  handleSaveCancelNotes=(cancelNotes,notifyClient,cancelReasonFinal)=>this.saveCancelNotes(cancelNotes,notifyClient,cancelReasonFinal);
 
  saveCancelNotes=async(cancelNotes,notifyClient,cancelReasonFinal)=>{
    const{order,cartItem,cartItemDetails,orderType}=this.state;
    
    if (!order) {
      console.log("saveOrderRefundNotes:: Invalid order:", order,cancelNotes);
      return;
    }
    try{
      console.log("cancel notes",cancelNotes,notifyClient);
   this.setState({ cancelNoteFinal:cancelNotes,isNotifyClientCancel:notifyClient,cancelReason:cancelReasonFinal},()=>{if(orderType=="parent")this.cancelOrder();else{this.cancelChildOrder(cartItem.orderId,cartItemDetails.orderStatus)};this.hideCancelNotesViewModal();});
   
    }
    catch (error) {
      this.showErrorNotification(
        "Error on saving Order Notes: ",
        error.message
      );
    }

  }

  saveRefundNotes=async(refundNotes,clientNotified,cancelReasonFinal)=>{
    const { order ,orderType,cartItem,cartItemDetails} = this.state;
      //  console.log("order is",order);
    if (!order) {
      console.log("saveOrderRefundNotes:: Invalid order:", order,refundNotes);
      return;
    }
    console.log("saveOrderRefundNotes::", { order, refundNotes });
    try {
     // order.refund_and_cancel_order_notes =refundNotes;
     // order.is_refund_and_cancel_order =true;
     // await saveOrder(order);
     this.setState({refundFinalNotes:refundNotes,isNotifyClientRefundCancel:clientNotified,cancelReason:cancelReasonFinal},()=>{if(orderType=="parent")this.cancelOrder();else{this.cancelChildOrder(cartItem.orderId,cartItemDetails.orderStatus)}});
      this.handleHideRefundOrderNotesModal();
      this.showSuccessNotification("Refund Order Reason,Notes saved successfully.");
      console.log("refund notes",this.state.refundFinalNotes,this.state.isNotifyClientRefundCancel);
      
     // this.fetchOrder();
    } catch (error) {
      this.showErrorNotification(
        "Error on saving Order Notes: ",
        error.message
      );
    }
  }
  
  saveOrderNotes = async (orderNotes) => {
    const { order } = this.state;
    if (!order) {
      console.log("saveOrderNotes:: Invalid order:", order);
      return;
    }
    const modifiedBy = getUserId();
    const orderUUID= order.orderUUID;
    const payload = {
      modified_by: modifiedBy,
      orderUUID: orderUUID,
      orderNote: orderNotes,
    };
    console.log("saveOrderNotes::", { order, orderNotes });
    // try {
    //   order.orderNotes = orderNotes;
    //   await saveOrder(order);
    //   this.handleHideEditOrderNotesViewModal();
    //   this.showSuccessNotification("Order Notes saved successfully.");
    //   this.fetchOrder();
    // } catch (error) {
    //   this.showErrorNotification(
    //     "Error on saving Order Notes: ",
    //     error.message
    //   );
    // }
    try{ 
      const response= await PhoenixAPI.patch("new-cart/update", payload);
      console.log("response", response)
      this.handleHideEditOrderNotesViewModal();
      this.showSuccessNotification("Order Notes saved successfully.");
      this.fetchOrder();
    }
    catch(error){
    this.showErrorNotification(
        "Error on saving Order Notes: ",
        error.message
      );
    }
  };

  handleSaveCustomerNote = (customerNote) =>
    this.saveCustomerNote(customerNote);

  handleSaveClientNote=(clientNote)=>
  this.saveClientNote(clientNote);
  
  handleListCancelItems=(cancelIds,cancelNotes)=>
  this.listCancelItems(cancelIds,cancelNotes);

  listCancelItems=async(cancelIds,cancelNotes)=>{
  const userId=getUserId();
  const { orderId } = this.props.match.params;
    if(cancelIds.length>0){
    const url="cancel-the-order";
    const payload={
      ids:cancelIds,
    cancellationNotes:cancelNotes,
    modifiedBy:userId,
    orderStatus:"CANCELLED",
    cartOrderId:orderId
    };
      

      try {
        const response=await PhoenixAPI.patch(url, payload)
        // console.log("response:",response)
        this.handleHideCompleteCancelButton();
        this.showSuccessNotification("Order Cancelled Successfully.");
        this.fetchOrder();
      } catch (error) {
        this.showErrorNotification(
          "Error on cancelling order: ",
          error.message
        );
      }
    }
  }
  
saveClientNote=async(clientNote)=>{
  const { order } = this.state;
  const { userId } = order ?? {};
  if (!order) {
    console.log("saveCustomerNote:: Invalid order:", order);
    return;
  }
  console.log("saveCustomerNote::", { userId, clientNote });
  const payload = {
    clientNotes: clientNote,
  };
  const url = `${BASE_URL_CUSTOMER_NOTES}/${userId}`;
  try {
    const response = await PhoenixAPI.patch(url, payload);

    this.handleHideEditClientNotesViewModal();
    this.showSuccessNotification("Client Note saved successfully.");
    this.fetchOrder();
  } catch (error) {
    this.showErrorNotification(
      "Error on saving Customer Note: ",

      error.message
    );
  }

}
  

  handleSaveCustomerIDInfo = (customerIDInfo) => {
    const { order } = this.state;
    // console.log("handleSaveCustomerIDInfo:: customerIDInfo", customerIDInfo);
    this.saveCustomerIDInfo(order.userId, customerIDInfo);
  };

  handleSaveDependentIDInfo=(customerIDInfo) => {
      const { order } = this.state;
    console.log("handleSaveDependentIDInfo:: customerIDInfo", customerIDInfo);
    this.saveDependentIDInfo( customerIDInfo);

  }

  saveDependentIDInfo = async (customerIDInfo) => {
     
     const{frontId,backId,fullNameId,childOrderId}=customerIDInfo;
     let formData = new FormData();
     formData.append("fullNameId",fullNameId);
     formData.append("frontId", frontId);
     formData.append("backId", backId);
     

     try {
      const response = await PhoenixAPI.patch(`${API_DEPENEDENT_INFO_UPDATE}${childOrderId}`, formData);
      
      this.handleHideDependentIDInfoViewModal();
      this.showSuccessNotification("Dependent ID Info saved successfully.");
      this.fetchOrder();
    } catch (error) {

      this.showErrorNotification(
        "Error on saving Dependent ID Info: ",
        error.message
      );

    }
  }

  saveCustomerIDInfo = async (customerId, customerIDInfo) => {
    try {
      await updateClientUserIDInfo(customerId, customerIDInfo);
      this.handleHideEditCustomerIDInfoViewModal();
      this.showSuccessNotification("Customer ID Info saved successfully.");
      this.fetchOrder();
    } catch (error) {
      this.showErrorNotification(
        "Error on saving Customer ID Info: ",
        error.message
      );
    }
  };

  handleSavePaymentInfo = (paymentInfo) => {
    // console.log("handleSavePaymentInfo:: paymentInfo:", paymentInfo);
  };

  saveCustomerNote = async (customerNote) => {
    const { order } = this.state;
    const { userId } = order ?? {};
    if (!order) {
      console.log("saveCustomerNote:: Invalid order:", order);
      return;
    }
    console.log("saveCustomerNote::", { order, customerNote });
    const payload = {
      clientNotes: customerNote,
    };
    const url = `${BASE_URL_CUSTOMER_NOTES}/${userId}`;
    try {
      const response = await PhoenixAPI.patch(url, payload);
      this.handleHideEditCustomerNotesViewModal();
      this.showSuccessNotification("Customer Note saved successfully.");
      this.fetchOrder();
    } catch (error) {
      this.showErrorNotification(
        "Error on saving Customer Note: ",
        error.message
      );
    }
  };



  handleSaveShippingAddress=async(shippingAddress)=>{
    const { order } = this.state;
    if (!order) {
      console.log("saveCustomerShipping Address:: Invalid order:", order);
      return;
    }
    const {id, userId}=order ?? {}

    console.log("saveShipping Address::", { order, shippingAddress })
    const {addressLine1,addressLine2,buildingApt,cityId,countryId, mapUrl,addressId}=shippingAddress ?? {};
    const payload={
      userId:userId,
      addressLine1:addressLine1,
      addressLine2:addressLine2,
      buildingApt:buildingApt,
      cityId:cityId,
      countryId:countryId,
      defaultAddress:false,
      mapUrl:mapUrl,
      id:addressId
    }
    
    try {
      const response=await PhoenixAPI.patch(`${BASE_URL_UPDATE_CART_ORDER_ADDRESS}/${id}`, payload)
      // console.log("response:",response)
      this.handleHideEditCustomerShippingAddressViewModal();
      this.showSuccessNotification("Shipping Address saved successfully.");
      this.fetchOrder();
    } catch (error) {
      this.showErrorNotification(
        "Error on saving Shipping Address: ",
        error.message
      );
    }
  }

  getCountryId = () => {
    const { order } = this.state;
    const { addressDetails } = order ?? {};
    return addressDetails?.countryId
      ? Number(addressDetails?.countryId)
      : order?.countryId;
  };

  getCityId = () => {
    const { order } = this.state;
    const { addressDetails } = order ?? {};
    return addressDetails?.cityId ?? order?.cityId;
  };

  getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };
  
  handleDownloadInVoice = async () => {
    const { order = {} } = this.state;
    const orderId = order.id ?? null;
     if(!orderId){
      // console.log("Invalid OrderId");
      return;
     }
   
      try {
        const response = await PhoenixAPI.get(`payment/invoice-pdf-url/cartOrderId/${orderId}`)
        const data = response && response.data || {};
        const { message = "", statusCode = "" } = data;
        if(typeof message==="string" && message.toLowerCase().endsWith(".pdf")){
          const url = message;
          const lastIndex = url.lastIndexOf("/");
          if(lastIndex < url.length){
            const filename = url.substring(lastIndex + 1);
            const link = document.createElement('a');
            link.href = url;
            link.target = "_blank";
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
        else{
          this.showErrorNotification(message)
        }
      }
      catch (error) {
        console.log("error:", error.response)
        const data = error.response && error.response.data || {}
        const errorMessage = data.message ?? "";
        this.showErrorNotification("Error in Downloading Invoice: " + errorMessage)
      }
    
    
  }

  handleSupplementInvoice=async(cartItem)=>{
    const { order = {} } = this.state;
    const orderId = order.id ?? null;
    const userId=order.userId??null;
     if(!orderId){
      console.log("Invalid OrderId");
      return;
     }
    const payload = {
      "cart_order_id": orderId ?? null,
      "user_id": userId ?? null,
    }
    try {
      const response = await API.post("supplement-cart-invoice/", payload)
      const data = response && response.data || {};
      const { message = "", status_code = "" } = data;
      if(typeof message==="string" && message.toLowerCase().endsWith(".pdf")){
      const url = message;
      const lastIndex = url.lastIndexOf("/");
      if(lastIndex < url.length){
        const filename = url.substring(lastIndex + 1);
        const link = document.createElement('a');
        link.href = url;
        link.target="_blank";
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      }
      else{
        this.showErrorNotification(message)
      }
    }
    catch (error) {
      console.log("error:", error.response)
      const data = error.response && error.response.data || {}
      const errorMessage = data.message ?? "";
      this.showErrorNotification("Error in Generating Breakup Invoice: " + errorMessage)

    }
  }

  handleGenerateInVoice = async (cartItem) => {
    const { country = {} } = this.state;
    if (!country || !cartItem) {
      console.log("Invalid Country or Cart Item !", {
        country,
        cartItem,
      });
      return;
    }
    const payload = {
      "order": cartItem.orderId ?? null,
      "country": country.id ?? null,
    }
    try {
      const response = await API.post("user-invoice/", payload)
      const data = response && response.data || {};
      const { message = "", status_code = "" } = data;
      if(typeof message==="string" && message.toLowerCase().endsWith(".pdf")){
      const url = message;
      const lastIndex = url.lastIndexOf("/");
      if(lastIndex < url.length){
        const filename = url.substring(lastIndex + 1);
        const link = document.createElement('a');
        link.href = url;
        link.target="_blank";
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      }
      else{
        this.showErrorNotification(message)
      }
    }
    catch (error) {
      console.log("error:", error.response)
      const data = error.response && error.response.data || {}
      const errorMessage = data.message ?? "";
      this.showErrorNotification("Error in Generating Breakup Invoice: " + errorMessage)

    }

  }

  getPaymentDetails = () => {
    const { order, country } = this.state;
    // console.log("getPaymentInformation:: order:", order);
    if (!order || !order.transactionDetails) return null;
    const { couponDetails, transactionDetails } = order;
    const { orderRequestPayload } = transactionDetails;
    // console.log("getPaymentInformation::", {
    //   transactionDetails,
    //   orderRequestPayload,
    // });
    const { cartItems = [] } = orderRequestPayload ?? {};
    let  numberOfItems =0;
    let orderType="";
    const currentPath = window.location.href.split("/admin")[1];
    if(currentPath.includes("/supplement/")){
      numberOfItems = cartItems.filter((cartItem)=>cartItem.itemType=="Supplement" || cartItem.itemType== "Medicine" || cartItem.itemType== "Bundle")
      .map((cartItem) => cartItem.quantity)
      .reduce((total, current) => total + current, 0);
      orderType="Supplement";

    }
     else{
     numberOfItems = cartItems
      .map((cartItem) => cartItem.quantity)
      .reduce((total, current) => total + current, 0);
      orderType="Cart Orders";
     }
     console.log("checking value",orderRequestPayload.additionalDicountNote,orderRequestPayload);
    return {
      
      transactionId: transactionDetails.transactionId,
      paymentMethod: transactionDetails?.paymentMethod,
      paymentGateway: transactionDetails?.paymentGateway,
      paymentStatus: transactionDetails?.paymentStatus,
      couponApplied: transactionDetails.couponApplied,
      transId:transactionDetails?.id,
      couponCode: orderRequestPayload.couponCode,
      couponDetails,
      discountValue: transactionDetails.discountValue,
      currency: country ? country.currency : transactionDetails.currency,
      numberOfItems,
      totalAmount: transactionDetails.totalAmount,
      taxPercentage: country ? parseFloat(country?.vat_percentage) : null,
      taxAmount: transactionDetails.taxAmount,
      finalAmount: transactionDetails.finalAmount,
      additionalDiscount:transactionDetails?.additionalDiscount,
      additionalDiscountNote:transactionDetails?.additionalDicountNote,
      isWalletBalanceUsed: transactionDetails.isWalletBalanceUsed,
      walletAmount: transactionDetails?.walletAmount,
      deliveryCharges: transactionDetails?.deliveryCharges,
      subscriptionDiscount:transactionDetails?.subscriptionDiscount,
      nurseVisitFee:transactionDetails?.nurseVisitFee,

      codMamopayNotes :transactionDetails?.paymentMethod=="COD_MAMOPAY"?transactionDetails?.codMamopayNotes:"",
      orderId:order?.id,
      cashbackAmount:transactionDetails?.cashbackAmount,
      serviceReference: transactionDetails?.transactionReference,
      orderType,

   
      draftOrder:orderRequestPayload?.isDraftOrder,
      orderCreatedByName:order.orderCreatedByName,
      mokafaRewards:transactionDetails?.mokafaRewards,
      mamopayTransactionComplete: transactionDetails?.paymentMethod=="COD_MAMOPAY"?transactionDetails?.isMamopayTransactionCompleted:false,
    };

  }

  getTransactionDetails = () => {
    const { order } = this.state;
    // console.log("getTransactionDetails:: order:", order);
    if (!order || !order.transactionDetails) return null;

    const { transactionDetails } = order;
    const { orderRequestPayload } = transactionDetails;
    // console.log("getTransactionDetails::", {
    //   transactionDetails,
    //   orderRequestPayload,
    // });
    return {
     
      paymentMethod: transactionDetails?.paymentMethod,
      transactionDate: transactionDetails.createdAt,
      serviceReference: transactionDetails.transactionReference,
      valeoRequestId: transactionDetails.valeoRequestId,
      merchantRequestId: transactionDetails.merchantRequestId,
      paymentDescription: transactionDetails?.paymentDescription,
      paymentStatusDescription: transactionDetails.paymentStatusDesc,
      paymentIP: transactionDetails.paymentIp,
      transactionId:transactionDetails?.id,
      orderId:order?.id,
      orderStatus:order?.orderStatus,
      isDraftOrder:order?.isDraftOrder,
      mamopayLink :transactionDetails?.mamopayPaymentUrl

    };
  };

 

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderPurchaseDate = () => {
    const { order } = this.state;
    const { createdAt, orderSource } = order ?? {};
    return order ? (
      <>
        <span className="font-weight-bold"># {order.orderUUID}</span>
        <br />
        <span>
          {this.getFormattedPurchasedDate(createdAt)} from {orderSource}
        </span>
      </>
    ) : null;
  };

  getCurrency = () => {
    const { order, country } = this.state;
    // console.log("getCurrency::", { order, country });
    return order?.transactionDetails?.currency ?? country?.currency;
  };

  getOperationalStatus = (operationalStatus) => {
    return <span className="ui label">{operationalStatus}</span>;
  };

  renderCartItemsCard = () => {
    const { order } = this.state;
    // console.log("renderCartItemsCard:: order: ", order);
    if (!order || !order.items || !order.items.length) return null;

    const { items = [], orderItems = [] } = order;
    const cartItemsCard = items.map((childOrder, index) => {
      const cartItem = orderItems.find(
        (orderItem) => orderItem.orderId === childOrder.itemOrderId
      );
      return this.renderCartItemCard(index, cartItem, childOrder);
    });

    const orderStatusWithColor = getOrderStatusWithColor(order.orderStatus);
    const { status, color } = orderStatusWithColor ?? {};
    const operationStatus = (
      <span className={`ui small ${color} tag label mb-4`}>{status}</span>
    );
    return (
      <Card>
        <Card.Body>
          {operationStatus}
          {cartItemsCard}
        </Card.Body>
      </Card>
    );
  };

  renderCartItemCard = (index, cartItem, cartItemDetails) => {
    const { collapsibles = {} } = this.state;
    // console.log("renderCartItemCard::", { index, cartItem, cartItemDetails });
    const { quantity, finalPrice } = cartItem ?? {};
    const { imageUrl, itemNameEnglish,packageInternalName,displayName,displayNameAr,itemType,isRecollectionOrder, transferedEmail=null} = cartItemDetails ?? {};
    console.log("cartItemDetails transferedEmail", transferedEmail)
    const isDisplayNameIV=itemNameEnglish && itemNameEnglish.trim().indexOf("IV ")==0?true:false || false;
    const correctImageUrl =
      imageUrl && !imageUrl.startsWith("http")
        ? IMAGE_BASE_URL + imageUrl
        : imageUrl;
    // console.log("renderCartItemCard:: imageURLS:", {
    //   imageUrl,
    //   correctImageUrl,
    // });
    const currency = this.getCurrency() ?? "";
    const transeferredText= transferedEmail?`This order is transferred to ${transferedEmail}`:""
    return (
      <Card key={cartItem?.id ?? index}>
        <Card.Body>
          {transferedEmail ? 
          <div className="row" style={{marginTop:"8px",marginBottom:"25px", textAlign:"center", color:"red"}}>
            {transeferredText}
            </div>:""}
          <div className="row">
            <div className="col-2">
              <div>
                <img
                  src={correctImageUrl ?? ""}
                  alt="Cart Item Pic"
                  className="item img-thumbnail mx-auto"
                  style={{
                    width: "100%",
                    maxHeight: "50px",
                    objectFit: "cover",
                  }}
                />
                <div className="floating ui label">{quantity ?? ""}</div>
              </div>
            </div>
            <div className="col ml-2">
              {itemType=="Mini_Package"?itemNameEnglish:packageInternalName?packageInternalName:itemNameEnglish}
              <p>{isDisplayNameIV?displayName:""}</p>
             { isRecollectionOrder?"(recollection)":""}
              </div>
            <div className="col-3">{`${currency} ${finalPrice} x ${quantity}`}</div>
            <div className="col-2">{`${currency} ${
             (finalPrice * quantity).toFixed(2)
            }`}</div>
            <div className="col text-right mr-1 pr-0">
              <i
                onClick={() =>
                 { this.handleCollapsibleChange(cartItemDetails.itemOrderId,cartItemDetails);
                  this.handleSecondaryCoach(cartItemDetails)}
    
                }
                className={`caret square ${
                  collapsibles[cartItemDetails.itemOrderId] ? "up" : "down"
                } icon big`}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          </div>
          {collapsibles[cartItemDetails.itemOrderId]
            ? this.renderCartItemDetails(cartItem, cartItemDetails)
            : null}
            <div className="row">
            {this.renderBundleItemsCard(index, cartItem, cartItemDetails)}
            </div>
        </Card.Body> 
      </Card>
    );
  };
  renderPencilIcon = () => {
    return  (
      <div
        onClick={() => this.setState({"editRefundFlag":true})}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>) ;
  }

  handleSupplementReturn=async(orderId)=>{
    try{
      if (orderId === null || orderId === undefined) {
        return;
      }
     const response = await PhoenixAPI.post(`unicommerce/create-reverse-pickup/${orderId}`);
    //  console.log("response is",response);
     this.showSuccessNotification("supplement return successfull");
     window.location.reload();
    }
    catch(error){
      console.log("error supplemet",error);
     this.showErrorNotification("error on return supplement",error.message);
     }
  }
  handleEditRefundNotes=async(editRefundNotes,orderId,cartItemDetails)=>{
    // console.log("orderId notes",orderId,editRefundNotes);
    const payload={"refundAndCancelOrderNotes":editRefundNotes };
    const config = {
      method: "PATCH",
      url: `${BASE_URL_UPDATE_REFUND_NOTES}/${orderId}`,
      data: payload,
    };
    
     try{

     const response=await PhoenixAPI.request(config);
     this.setState({"editRefundFlag":false});
    //  console.log("response",response);
     // cartItemDetails.refundAndCancelOrderNotes=editRefundNotes;
      if(response.status==200){
        console.log("response",response.statusCode);
    this.showSuccessNotification("Order updated successfully.");
    window.location.reload();
      }
     }
    catch (error) {
      this.showErrorNotification("Error on updating refund notes: " + error.message);
    }

  }
  handleClearRefundNotes=()=>{
    this.setState({"editRefundNotes":""})
  }
  handleSelectExternalCoach=async(orderItem)=>{
    const {secondaryCoachId=null}= this.state;
    const modifiedBy = getUserId();
    const {orderStatus="", itemOrderId:chilOrderId=null}= orderItem || {};
    const isPastOrder = ["COMPLETED", "CANCELLED"].includes(orderStatus);
    if(orderStatus=="" && !secondaryCoachId){
      this.setState({"secondaryCoachId":null});
      return;
    }
    const payload={
      "secondary_coach_id":secondaryCoachId,
      modified_by: modifiedBy,
    }
    
    try{
      const response = isPastOrder
      ? await updateChildPastOrder(chilOrderId, payload)
      : await updateChildOrder(chilOrderId, payload);
      console.log("response:", response);
      this.showSuccessNotification("Secondary Coach Assigned Successfully");
      window.location.reload();
    }
    catch(error){
      this.showErrorNotification(
        "Some Error occured in updating  orders:",
        error.message
      );
    }
    finally {
      this.hideLoading();
    }
    
  } 

  fetchingFamilyMember=async(cartItemDetails)=>{
    console.log("CartItemItemDetails", cartItemDetails)
    const {userId=null}= cartItemDetails ??{};
    if(userId){
      const familyMembers= await fetchFamilyMembers(userId)
     
      const selfMember={
        "id":"self",
        "fullName":`Self`,
        "relation":"self"
      }
      familyMembers.push(selfMember)
      this.setState({ familyMembers });
    }
  }

  renderCartItemDetails = (cartItem, cartItemDetails) => {
    const { orderId, itemType, itemId } = cartItem ?? {};

    const { operationType, orderStatus, orderStatusList, orderType, dependent, biomarkerMiniPackages ,isReversePickUp,subscriptionDiscount,isRefundAndCancelOrder,refundAndCancelOrderNotes,cancellationReason, addonMiniPackagesPrice,cancellationNotes, secondaryCoachId, customAddonPackages} = cartItemDetails;
     console.log("checking",isRefundAndCancelOrder,refundAndCancelOrderNotes,cancellationNotes,secondaryCoachId);
//     this.setState({childOrderId:orderId,childOrderStatus:orderStatus});

    const isBloodPackage = itemType === "Blood_Package";
    const isMiniPackage = itemType === "Mini_Package";
    const isCustomPackage = itemType === "Custom_Package";
    const isHomeAppointmentRequired =
      isCustomPackage &&
      operationType &&
      operationType.includes("Home Appointment");
    const isCoachNoteRequired =
      isCustomPackage &&
      operationType &&
      operationType.includes("Requires Coach Note");
    const isPDFDownloadRequired =
      isCustomPackage &&
      operationType &&
      operationType.includes("PDF Download");
    //const condition = itemType === "Blood_Package" || (itemType === "Custom_Package" && operationType && operationType.includes("Home Appointment"));
    const condition =
      isBloodPackage ||
      isMiniPackage ||
      isHomeAppointmentRequired ||
      isCoachNoteRequired ||
      isPDFDownloadRequired;
    const isBundlePackage = itemType === "Bundle";

    const conditionForBreakInvoice= isBloodPackage || isMiniPackage;
    const formatDateTime = (dateTimeString) => {
      const [confirmDate, confirmTime] = dateTimeString.split(' ');
      return { confirmDate, confirmTime };
    };
    
    const refundNotesDetails=(<div className="form-group col">
    <label htmlFor="cancelReason">Reason for Cancel Order</label>
      <div className="input-group">
      <Dropdown
                value={cancellationReason}
                
                id="cancel_reason"
                placeholder="Cancel Reason"
                search
                selection
                options={cancelReasonOptions}
                disabled={true}

                style={{ width: '100%',height: '100%' }}
                className={"form-control"}
              />
      </div>

    <label htmlFor="RefundNotes">Notes for Refund & Cancelled</label>
    <div className="input-group">
    <textarea
      value={this.state.editRefundNotes?this.state.editRefundNotes:refundAndCancelOrderNotes}
      onChange={(event)=>{this.setState({"editRefundNotes":event.target.value})}}
      id="refundNotes"
      name="refundNotes"
      readOnly={!this.state.editRefundFlag}
      className="form-control py-2" >
      </textarea>
      {this.renderPencilIcon()}
      </div>
      <br/>
    { this.state.editRefundFlag? <button
              className="btn button btn-success "
              onClick={()=>{this.handleEditRefundNotes(this.state.editRefundNotes,orderId,cartItemDetails);this.handleClearRefundNotes()}}
              
            >
              Save
            </button>:<></>}
  </div>


    );
    const cancelNotesDetails = ( <>
    <div className="form-group col">
      <label htmlFor="cancelReason">Reason for Cancel Order</label>
      <div className="input-group">
      <Dropdown
                value={cancellationReason}
                
                id="cancel_reason"
                placeholder="Cancel Reason"
                search
                selection
                options={cancelReasonOptions}
                disabled={true}

                style={{ width: '100%',height: '100%' }}
                className={"form-control"}
              />
      </div>
    
    
    
      <label htmlFor="RefundNotes">Notes for Cancel Order</label>
      <div className="input-group">
        <textarea
          value={cancellationNotes}

          id="cancelNotes"

          name="cancelNotes"
          readOnly={true}
          className="form-control py-2" >
        </textarea>
        </div>
      </div>
    

  </>  );



    const commonOrderDetails = <div className="row">
      <div className="form-group col-6">
        <label htmlFor="orderId">Order ID</label>
        <input
          value={orderId ?? ""}
          type="text"
          id="orderId"
          name="orderId"
          readOnly={true}
          className="form-control py-2" />
      </div>
      <div className="form-group col-6">
        <label htmlFor="orderType">Order Type</label>
        <input
          value={orderType ?? ""}
          type="text"
          id="orderType"
          name="orderType"
          readOnly={true}
          className="form-control py-2" />
      </div>
    </div>;
      const subsDiscountAndMiniPackages = <div className="row">
      <div className="form-group col-6">
        <label htmlFor="subscriptionDiscount">Subscription Discount</label>
        <input
          value={subscriptionDiscount==null||subscriptionDiscount==undefined?0:subscriptionDiscount}
          type="text"
          id="subscriptionDiscount"
          name="subscriptionDiscount"
          readOnly={true}
          className="form-control py-2" />
      </div>
      <div className="form-group col-6">
        <label htmlFor="addonMiniPackagesPrice">Addon Mini Packages Price</label>
        <input
          value={addonMiniPackagesPrice==null||addonMiniPackagesPrice==undefined?0:addonMiniPackagesPrice}
          type="text"
          id="addonMiniPackagesPrice"
          name="addonMiniPackagesPrice"
          readOnly={true}
          className="form-control py-2" />
      </div>

    </div>
    const dependentDetails = (
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="dependentName">Customer Name</label>
          <input
            value={dependent ? `${dependent.firstName ?? ""} ${dependent.lastName}`.trim() : ""}
            type="text"
            id="dependentName"
            name="dependentName"
            readOnly={true}
            className="form-control py-2" />
        </div>
        <div className="form-group col-6">
          <label htmlFor="relation">Relation</label>
          <input
            value={dependent?.relation ?? ""}
            type="text"
            id="relation"
            name="relation"
            readOnly={true}
            className="form-control py-2" />
        </div>
      </div>
    );

    if (!condition) {
      const {
        labs = [],
        homeCareServices = [],
        operationsUsers = [],
        paymentGateways = [],
      } = this.state;
      const currentPath = window.location.href.split("/admin")[1];
      const countryId=this.getCountryId()
      return (
        <>
          <div className="row mt-2">
            <div className="form-group col-6">
              <label>Order Status</label>
              <SelectOrderStatus
                options={orderStatusList}
                value={orderStatus}
                onSelect={(selectedOrderStatus) =>
                  this.handleCartItemOrderStatusChange(
                    cartItem,
                    selectedOrderStatus
                  )
                }
                isDisabled={true}
              />
            </div>
            {this.state.isEditable && !["COMPLETED", "CANCELLED"].includes(orderStatus) && (
              <div className="form-group col text-right">
                <span
                  onClick={(event) =>
                    this.showEditCartOrderBasicViewModal(
                      cartItem,
                      cartItemDetails
                    )
                  }
                  style={{ cursor: "pointer", color: "#2D9BF0" }}
                >
                  Edit
                </span>
              </div>
            )}
            {isRefundAndCancelOrder?refundNotesDetails:orderStatus=="CANCELLED" && cancellationNotes?cancelNotesDetails:<></>}
          </div>
          {subsDiscountAndMiniPackages}
          {dependent && dependentDetails}
          {commonOrderDetails}

          {["COMPLETED", "CANCELLED",].includes(orderStatus) ||
            <div className="row ml-1">
              <div className="mt-2 mb-3">
                {this.renderChildCancelRefundOrderButton(cartItem, cartItemDetails)}
              </div>
              <div className="mt-2 pl-3 mb-3">
                {this.renderChildCancelOrderButton(cartItem, cartItemDetails)}
              </div>
            </div>}

            {customAddonPackages && customAddonPackages.length>0&&(
              <div className="row">
               <div className="form-group col-6">
            <label>Custom Add-On Packages</label>
            <SelectCustomTestAddons
                countryId={countryId}
              selectedCustomPackageIds={customAddonPackages.map((it) => it.id)
              }
              isDisabled={true}
            />
           </div> 
              </div>
          )}

         {currentPath.includes("/supplement/") && itemType=="Supplement" && orderStatus=="COMPLETED" && !isReversePickUp? <button
              className="btn button-lightyellow"
              onClick={()=>{this.handleSupplementReturn(orderId);}}
              
            >
              Reverse Pickup
            </button>:<></>}

          {/* {itemType === "Bundle" && (
            <div className="row">
              <OperationsInternalCard
                cartItemDetails={cartItemDetails}
                operationsUsers={operationsUsers}
                labs={labs}
                paymentGateways={paymentGateways}
                homeCareServices={homeCareServices}
              />
            </div>
          )} */}
          {isBundlePackage && (
            <div className="row">
           { /*  <OperationsInternalCard
                cartItemDetails={cartItemDetails}
                operationsUsers={operationsUsers}
                labs={labs}
                paymentGateways={paymentGateways}
                homeCareServices={homeCareServices}
              />*/}
              <OperationsInternalBasicCard cartItemDetails={cartItemDetails} />
            </div>
          )}
          {/* <div className="row">
            <OperationsInternalBasicCard cartItemDetails={cartItemDetails} />
          </div> */}
          {!isBundlePackage && (
            <div className="row">
              <OperationsInternalBasicCard cartItemDetails={cartItemDetails} />
            </div>

          )}
        </>
      );
    } else {
      const {
        labs = [],
        homeCareServices = [],
        operationsUsers = [],
        paymentGateways = [],
        collapsiblesOperation=[],
      } = this.state;
      const {
        bookedSlotDate,
        bookedSlotTime,
        specimenCollectionDate,
        specimenCollectionTime,
        isAttachmentUploaded,
        isResultUploaded,
        confirmedDateTime,
        nutritionistId,
        recollectedOrders
      } = cartItemDetails ?? {};

      const { quantity, finalPrice } = cartItem ?? {};
    const { imageUrl, itemNameEnglish,packageInternalName,displayName,displayNameAr,itemType,isRecollectionOrder, transferedEmail=null} = cartItemDetails ?? {};
    console.log("cartItemDetails transferedEmail", transferedEmail)
    const isDisplayNameIV=itemNameEnglish && itemNameEnglish.trim().indexOf("IV ")==0?true:false || false;
    const correctImageUrl =
      imageUrl && !imageUrl.startsWith("http")
        ? IMAGE_BASE_URL + imageUrl
        : imageUrl;
    console.log("renderCartItemCard:: imageURLS:", {
      imageUrl,
      correctImageUrl,
    });
    const currency = this.getCurrency() ?? "";

      const [confirmedDate, confirmedTime] = confirmedDateTime
        ? confirmedDateTime.split(" ")
        : [null, null];
      const countryId = this.getCountryId();
      const cityId = this.getCityId();
      // console.log("renderCartItemDetails:: region:", { countryId, cityId });
      const shouldShowAppointmentDateTimeForm =
        isBloodPackage ||
        isMiniPackage ||
        (isCustomPackage &&
          operationType &&
          operationType.includes("Home Appointment"));
      const shouldShowSelectCoachForm =
        isBloodPackage ||
        isMiniPackage ||
        (isCustomPackage &&
          operationType &&
          ["Requires Coach Note"].some((option) =>
            operationType.includes(option)
          ));

      const showWithoutReviewButton= (isHomeAppointmentRequired && isPDFDownloadRequired && isCoachNoteRequired) || isBloodPackage || isMiniPackage ;    
      const appointmentDateTimeForm = (
        <div className="row">
          <div className="form-group col">
            <label htmlFor="bookedSlotDate">Appointment Date</label>
            <input
              value={bookedSlotDate ?? specimenCollectionDate ?? ""}
              type="date"
              id="bookedSlotDate"
              name="bookedSlotDate"
              readOnly={true}
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="bookedSlotTime">Appointment Time</label>
            <SelectTimeslot
              cityId={cityId}
              packageType={itemType ? itemType.toLowerCase() : ""}
              packageId={itemId}
              date={bookedSlotDate ?? null}
              selectedTimeslot={
                bookedSlotTime ?? specimenCollectionTime ?? null
              }
              isDisabled={true}
            />
          </div>
        </div>
      );
      const confirmedDateTimeForm = (
        <div className="row">
          <div className="form-group col">
            <label>Confirmed Date</label>
            <input
              value={confirmedDate ?? ""}
              type="date"
              id="confirmedDate"
              name="confirmedDate"
              readOnly={true}
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label>Confirmed Time</label>
            <input
              value={confirmedTime ?? ""}
              type="time"
              id="confirmedTime"
              name="confirmedTime"
              readOnly={true}
              className="form-control"
            />
          </div>
        </div>
      );
      const selectCoachForm = (
        <div className="row">
          <div className="form-group col-6">
            <label>Coach</label>
            <SelectCoach
              coaches={this.state.wellbeingCoaches ?? []}
              selectedCoachId={nutritionistId}
              isDisabled={true}
            />
          </div>
          {(isMiniPackage || (biomarkerMiniPackages && biomarkerMiniPackages.length>0)) && (
            <div className="form-group col-6">
              <label>Mini Packages</label>
              <SelectMiniPackages
                miniPackages={isMiniPackage?cartItemDetails.miniPackageAddonList??[]:biomarkerMiniPackages??[]}
                selectedMiniPackageIds={
                  isMiniPackage 
                    ? cartItemDetails?.miniPackages ?? []
                    : biomarkerMiniPackages.map((it) => it.id)
                }
                isDisabled={true}
              />
            </div>
          )}
          {customAddonPackages && customAddonPackages.length>0&&(
           <div className="form-group col-6">
            <label>Custom Add-On Packages</label>
            <SelectCustomTestAddons
                countryId={countryId}
              selectedCustomPackageIds={customAddonPackages.map((it) => it.id)
              }
              isDisabled={true}
            />
           </div> 
          )}
        </div>
      );
      const externalCoachForm=(
        <div className="row">
           <div className="form-group col-6">
            <label>Secondary Coach</label>
            <SelectCoach
              coaches={this.state.externalCoaches ?? []}
              selectedCoachId={["COMPLETED", "CANCELLED"].includes(orderStatus) ?this.state?.secondaryCoachId:(this.state.secondaryCoachId ?? null)}
              onCoachSelect={(coachId) =>
                this.setState({secondaryCoachId: coachId})
              }
              isDisabled={false}
            />
          </div>
          <div className="form-group col-6" style={{marginTop:"30px"}}>
          <button
              onClick={()=>this.handleSelectExternalCoach(cartItemDetails)}
              className="btn px-5"
              style={{ backgroundColor: "#CEE741" }}
              >
              Save
              </button>
          </div>
          
        </div>
      )
      return (
        <>
          <div className="row mt-2">
            <div className="form-group col-6">
              <label>Order Status</label>
              <SelectOrderStatus
                options={orderStatusList}
                value={orderStatus}
                onSelect={(selectedOrderStatus) =>
                  this.handleCartItemOrderStatusChange(
                    cartItem,
                    selectedOrderStatus
                  )
                }
                isDisabled={true}
              />
            </div>
            
           {conditionForBreakInvoice && orderStatus==="COMPLETED" && <div className="form-group col-6">
              <button
              onClick={() => this.handleGenerateInVoice(cartItem)}
              className="btn px-5"
              style={{ backgroundColor: "#CEE741" }}
              >
                Generate Breakup Invoice
              </button>
            </div>}
            {this.state.isEditable && !["COMPLETED", "CANCELLED"].includes(orderStatus) && (
              <div className="form-group col text-right">
                <span
                  onClick={() => {
                    if (isBloodPackage || isMiniPackage || isHomeAppointmentRequired) {
                    this.showEditCartOrderViewModal(
                        cartItem,
                        cartItemDetails
                      );
                    } else {
                      this.showEditCartOrderNoAppointmentViewModal(
                        cartItem,
                        cartItemDetails
                      );
                    }
                    this.fetchingFamilyMember(cartItemDetails)
                  }}
                  style={{ cursor: "pointer", color: "#2D9BF0" }}
                >
                  Edit
                </span>
                
              </div>
            )}
            {isRefundAndCancelOrder?refundNotesDetails:orderStatus=="CANCELLED" && cancellationNotes?cancelNotesDetails:<></>}
          </div>
          {subsDiscountAndMiniPackages}
          {dependent && dependentDetails}
          {commonOrderDetails}
          {shouldShowAppointmentDateTimeForm && appointmentDateTimeForm}
          {shouldShowAppointmentDateTimeForm && confirmedDateTimeForm}
          {shouldShowSelectCoachForm && selectCoachForm}
          {shouldShowSelectCoachForm && externalCoachForm}
          {["COMPLETED", "CANCELLED",].includes(orderStatus) ||
            <div className="row ml-1">
              <div className="mt-2 mb-3">
                {this.renderChildCancelRefundOrderButton(cartItem, cartItemDetails)}
              </div>
              <div className="mt-2 pl-3 mb-3">
                {this.renderChildCancelOrderButton(cartItem, cartItemDetails)}
              </div>
              <div style={{position:"absolute", right:"24px"}} >
                {this.renderChildSendDetailsToLab(cartItem,cartItemDetails)}
              </div>
            </div>}
          {showWithoutReviewButton && orderStatus =="RESULTS UPLOADED TO APP" &&
           <div className="row">
           <div className="form-group col text-right">
            {this.renderCompleteWithoutReview(orderId)}
           </div>
           </div>}
            {customAddonPackages && customAddonPackages.length>0&&(
              <div className="row">
               <div className="form-group col-6">
            <label>Custom Add-On Packages</label>
            <SelectCustomTestAddons
                countryId={countryId}
              selectedCustomPackageIds={customAddonPackages.map((it) => it.id)
              }
              isDisabled={true}
            />
           </div> 
              </div>
          )}

          <div className="row">
            {/* <div className="form-group col">
              <label>Lab</label>
              <SelectLab
                labs={labs ?? []}
                selectedLabId={laboratoryId}
                isDisabled={true}
              />
            </div> */}
            {/* <div className="form-group col">
              <label>Homecare Service</label>
              <SelectHomeCareService
                homeCareServices={homeCareServicesForCountry ?? []}
                selectedHomeCareServiceId={homeCareId}
                isDisabled={true}
              />
            </div> */}
          </div>
          <div className="row">
            {/* <div className="form-group col">
              <label htmlFor="labCost">Lab Costs</label>
              <input
                value={labCost ?? ""}
                type="text"
                name="labCost"
                id="labCost"
                className="form-control py-2"
                readOnly={true}
              />
            </div> */}
            {/* <div className="form-group col">
              <label htmlFor="homeCareCost">Homecare Costs</label>
              <input
                value={homeCareCost ?? ""}
                type="text"
                name="homeCareCost"
                id="homeCareCost"
                className="form-control py-2"
                readOnly={true}
              />
            </div> */}
          </div>
          <div className="row">
            <div className="form-group col">
              <p className="">
                Note: There is no calculation done by backend with respect to
                quantity. Lab Costs and Homecare Costs are for Admin user to
                input.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <Radio
                label="PDF Attached"
                checked={isAttachmentUploaded ? true : false}
                disabled={true}
              />
            </div>
            <div className="form-group col">
              <Radio
                label="Coach Reviewed"
                checked={isResultUploaded}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col pb-0 mb-0">
              {this.renderLabInformationCard(cartItemDetails)}
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col pb-0 mb-0">
              {this.renderHomecareInformationCard(cartItemDetails)}
            </div>
          </div>
          <div className="row">
            <OperationsInternalCard
              cartItemDetails={cartItemDetails}
              operationsUsers={operationsUsers}
              labs={labs}
              paymentGateways={paymentGateways}
              homeCareServices={homeCareServices}
            />
          </div>

          <div className="form-content">
          <span>History</span>
          </div>

          {recollectedOrders.map((order,index) => {
             const { confirmDate, confirmTime } = formatDateTime(order.confirmedDateTime);
             console.log("confirm date time",order.confirmedDateTime,confirmDate,confirmTime)
             const{orderStatus}=order;
            return(<Card key={"sample"+order?.id }>
            <Card.Body>
          <div key={order.id} className="row">
          <div className="col-2">
              <div>
                <img
                  src={correctImageUrl ?? ""}
                  alt="Cart Item Pic"
                  className="item img-thumbnail mx-auto"
                  style={{
                    width: "100%",
                    maxHeight: "50px",
                    objectFit: "cover",
                  }}
                />
                <div className="floating ui label">{quantity ?? ""}</div>
              </div>
            </div>
            <div className="col ml-2">
              {itemType=="Mini_Package"?itemNameEnglish:packageInternalName?packageInternalName:itemNameEnglish}
              <p>{isDisplayNameIV?displayName:""}</p>
             { isRecollectionOrder?`(sampleCollection)-${index+1}`:""}
              </div>
            <div className="col-3">{`${currency} ${finalPrice} x ${quantity}`}</div>
            <div className="col-2">{`${currency} ${
             (finalPrice * quantity).toFixed(2)
            }`}</div>
            <div className="col text-right mr-1 pr-0">
              <i
                onClick={() => this.handleOperationCollapsibleChange(order.id)}
                className={`caret square ${this.state.collapsiblesOperation[order.id] ? 'up' : 'down'} icon big`}
                style={{ cursor: 'pointer' }}
              ></i>
            </div>
            </div>
            {this.state.collapsiblesOperation[order.id] &&
            <>
              <div className="row">
              <div className="form-group col-6">
              <label>Order Status</label>
              <SelectOrderStatus
                options={orderStatusList}
                value={orderStatus}
               
                isDisabled={true}
              />
            </div>
              </div>

              <div className="row">
          <div className="form-group col">
            <label>Confirmed Date</label>
            <input
              value={confirmDate ?? ""}
              type="date"
              id="confirmedDate"
              name="confirmedDate"
              readOnly={true}
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label>Confirmed Time</label>
            <input
              value={confirmTime ?? ""}
              type="time"
              id="confirmedTime"
              name="confirmedTime"
              readOnly={true}
              className="form-control"
            />
          </div>
        </div>
              <div className="row">
                <HomecareInfoResample  ResampleOrder={order}/>

              </div>

              <div className="row">
              <OperationsInternalCard
              cartItemDetails={order}
              operationsUsers={operationsUsers}
              labs={labs}
              paymentGateways={paymentGateways}
              homeCareServices={homeCareServices}
              recollectionOrder={true}
            />
              </div>
            </>
            }
            </Card.Body>
            </Card>
    );})}
            
          </>
      );
    }
  };

  renderLabInformationCard = (cartItemDetails) => {
    const { order , country} = this.state;
  
    const { userDetails = {}, userProfile, addressDetails, paymentMethod, transactionDetails} = order ?? {};
  
   const  currency=country ? country.currency : transactionDetails.currency;
    const isCodOrder=paymentMethod==="COD";
    
    console.log("isCodOrder:::",paymentMethod,isCodOrder);
    return (
      <LabInformationCard
        itemDetails={cartItemDetails}
        userDetails={{
          ...userDetails,
          gender: userProfile?.gender ?? null,
          dob: userProfile?.dob ?? null,
        }}
        addressDetails={addressDetails}
        isCodOrder={isCodOrder}
        
        currency={currency}
      />
    );
  };

  renderHomecareInformationCard = (cartItemDetails) => {
    if (!cartItemDetails || !cartItemDetails.itemOrderId) {
      return null;
    }
    const { itemOrderId, itemType, operationType } = cartItemDetails;
    const isBloodPackage = itemType === "Blood_Package";
    const isMiniPackage = itemType === "Mini_Package";
    const isCustomPackage = itemType === "Custom_Package";
    const isHomeAppointmentRequired =
      isCustomPackage &&
      operationType &&
      operationType.includes("Home Appointment");
    const isHomeCareInfoVisible = isBloodPackage || isMiniPackage || isHomeAppointmentRequired;
    return isHomeCareInfoVisible ? <HomecareInformationCard key={itemOrderId} orderId={itemOrderId} /> : null;
  };

  renderAgentDropdown = () => {
    const { order } = this.state;
    return (
      <SelectAgentBetter
        agents={this.state.agents ?? []}
        selectedAgentId={order.agentId}
        onAgentSelect={(agentId) => this.handleAgentSelect(order, agentId)}
        isDisabled={order?.orderStatus === "COMPLETED" || !this.state.isEditable}
      />
    );
  };

  renderDownloadInvoice = () => {
    const currentPath = window.location.href.split("/admin")[1];
    return (
      <button
        onClick={() => currentPath.includes("/supplement/")?this.handleSupplementInvoice():this.handleDownloadInVoice()}
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
      >
        Download Invoice
      </button>
    )
  }

  renderEditButton = (onClick) => {
    return this.state.isEditable ? (
      <span onClick={onClick} style={{ cursor: "pointer", color: "#2D9BF0" }}>
        Edit
      </span>
    ) : null;
  };

  renderAddButton = (onClick) => {
 
    return this.state.isEditable ? (
      <span onClick={onClick} style={{ cursor: "pointer", color: "#2D9BF0" }}>
        Add
      </span>
    ) : null;
  };

  renderClientNotes=(onClick)=>{
    return this.state.isEditable?(
      <span onClick={onClick} style={{ cursor: "pointer", color: "#2D9BF0" }}>
      Edit
    </span>
    ):null
  };

  renderCustomerIDInfo = () => {
    const { customerIDInfo } = this.state;
    const { full_name_id, front_id, front_id_s3_url, back_id, back_id_s3_url,first_name,last_name,eid_updated_by,eid_updated_at } = customerIDInfo ?? {};
    const frontIdImageUrl = front_id_s3_url || front_id || null;
    const backIdImageUrl = back_id_s3_url || back_id || null;
     console.log("full name id",full_name_id);
    const{order}=this.state;
    const{items}=order;
    const seenDependentIds = new Set();

    const dependents = items.reduce((acc, item) => {
      if (item.dependent !== null && !seenDependentIds.has(item.dependent.id)) {
        seenDependentIds.add(item.dependent.id);
        acc.push({
          itemOrderId: item.itemOrderId,
          dependent: item.dependent
        });
      }
      return acc;
    }, []);
   console.log("dependent length",dependents.length,dependents);

    const frontIdPreview = frontIdImageUrl
      ? frontIdImageUrl.toLowerCase().endsWith(".pdf")
        ? <PdfPreview id="user_front_id" pdfFileUrl={frontIdImageUrl} maxHeight="100px"  />
        : <img
          id="user_front_id"
          name="user_front_id"
          src={frontIdImageUrl}
          alt="Selected"
          className="img-thumbnail mx-auto"
          style={{
            width: "100%",
            height: "70%",
            objectFit: "cover",
          }}
        />
      : null;
    
    const backIdPreview = backIdImageUrl
      ? backIdImageUrl.toLowerCase().endsWith(".pdf")
        ? <PdfPreview id="user_back_id" pdfFileUrl={backIdImageUrl} maxHeight="100px" />
        : <img
          id="user_back_id"
          name="user_back_id"
          src={backIdImageUrl}
          alt="Selected"
          className="img-thumbnail mx-auto"
          style={{
            width: "100%",
            height: "70%",
            objectFit: "cover",
          }}
        />
      : null;



    const customerIDInfoView = customerIDInfo ? (
      <>
        <div className="row">
          <div className="col">
            <span><strong>Primary User : </strong>{full_name_id!==null &&full_name_id!="" ?full_name_id:`${first_name} ${last_name}` }</span>
            <br/>
            <span> <strong>Updated By :</strong>{eid_updated_by} <strong>Updated At :</strong>{eid_updated_at? formatDate(eid_updated_at):""} </span>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-6">{frontIdPreview}</div>
          <div className="col-6">{backIdPreview}</div>
        </div>
      </>
    ) : (
      <p className="placeholder mb-2">
        Customer ID info not found for the admin user!
      </p>
    );
    
    const dependentIDInfoView= dependents.length>0?(dependents.map((dependentDet, index) => { 
      // Preview for dependent's front ID
      const frontIdPreview = dependentDet.dependent.frontId
        ? dependentDet.dependent.frontId.toLowerCase().endsWith(".pdf")
          ? <PdfPreview id={`dependent_front_id_${index}`} pdfFileUrl={dependentDet.dependent.frontId} maxHeight="100px" />
          : <img
            id={`dependent_front_id_${index}`}
            name="dependent_front_id"
            src={dependentDet.dependent.frontId}
            alt="Selected"
            className="img-thumbnail mx-auto"
            style={{
              width: "100%",
              height: "70%",
              objectFit: "cover",
            }}
          />
        : null;
  
      // Preview for dependent's back ID
      const backIdPreview = dependentDet.dependent.backId
        ? dependentDet.dependent.backId.toLowerCase().endsWith(".pdf")
          ? <PdfPreview id={`dependent_back_id_${index}`} pdfFileUrl={dependentDet.dependent.backId} maxHeight="100px" />
          : <img
            id={`dependent_back_id_${index}`}
            name="dependent_back_id"
            src={dependentDet.dependent.backId}
            alt="Selected"
            className="img-thumbnail mx-auto"
            style={{
              width: "100%",
              height: "70%",
              objectFit: "cover",
            }}
          />
        : null;
  
      return (
        <>


          <div className="row">
         
            <div className="col">
              <span><strong>Dependent Name:</strong> {dependentDet.dependent.fullNameId && dependentDet.dependent.fullNameId.trim()!=="" && dependentDet.dependent.fullNameId !=="null"?dependentDet.dependent.fullNameId:`${dependentDet.dependent.firstName} ${dependentDet.dependent.lastName}`}</span>
              <br/>
              <span><strong>updated by :</strong> {dependentDet.dependent.eidUpdatedBy}<strong> updated at :</strong> {dependentDet.dependent.eidUpdatedAt?formatDate(dependentDet.dependent.eidUpdatedAt):""}</span>
            </div>
            <div className="col text-right">
          <span style={{ cursor: "pointer", color: "#2D9BF0" }}
                  onClick={() => {this.showDependentInfoViewModal(dependentDet)}} >Edit </span>
            </div>
            </div>
            <div className="row mt-1">
          <div className="col-6">{frontIdPreview}</div>
          <div className="col-6">{backIdPreview}</div>
        </div>
            
          
          </>);})):(
  <></>
) ;
    

    return (
      <>
        <div className="row mt-2">
          <div className="col">
            <p className="h6">ID-Information</p>
          </div>
          {customerIDInfo && (
            <div className="col text-right">
              {this.renderEditButton(this.showEditCustomerIDInfoViewModal)}
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="col">{customerIDInfoView}</div>
        </div>


         <div className="row mt-2">
          <div className="col">{dependentIDInfoView}</div>
         </div>

      </>
    );
  };

  renderCustomerNotesCard = () => {
    const { order } = this.state;
    const { clientNote: customerNote } = order?.userDetails ?? {};
    const customerNoteView = customerNote ? (
      <p>{customerNote}</p>
    ) : (
      <span className="placeholder">No notes for the customer!</span>
    );
    return (
      <>
        <div className="row mt-2">
          <div className="col">
            <p className="h6">Customer Notes</p>
          </div>
          <div className="col text-right">
            {this.renderEditButton(this.showEditCustomerNotesViewModal)}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col" style={{fontWeight: "bold" }}>{customerNoteView}</div>
        </div>
      </>
    );
  };

  renderCustomerInfoCard = () => {
    const { order } = this.state;
    const {isDraftOrder,clientNotes}= order ?? {};
    const { userName, firstName, lastName, email, phoneNumber } =
      order?.userDetails ?? {};
    const { id=null,addressLine1, addressLine2, buildingApt, city, country, phoneNo, mapUrl, lat, lng } =
      order?.addressDetails ?? {};
      const checkForAddress=  id?true:false;
    const fullName =
      firstName && lastName ? `${firstName} ${lastName}` : userName;
    const customerInfo = (
      <div>
        <div className="row mt-2">
        <div className="ml-2">
        <p className="h6">Customer Information</p>
        </div>
       

        </div>
        <p className="ml-2">
          {fullName}
          <br />
          {email}
          <br />
          {phoneNumber}
          <br />
          <div >
          Client Notes :<strong> {clientNotes}</strong> &nbsp;
          
            {this.renderClientNotes(this.showEditClientNotesViewModal)}
            </div> 
          
          <br/>
        </p>
      </div>
    );
    const mapUrlString = mapUrl ? `Map URL: ${mapUrl}` : null;
    const locationString = lat || lng ? `Location: Lat: ${lat}, Lng: ${lng}` : "";
    const shippingAddressContent = [
      fullName,
      addressLine1,
      addressLine2,
      buildingApt,
      city,
      country,
      phoneNo,
    ]
      .filter((it) => it ?? false)
      .join("\r\n");

    const shippingAddress = (
      
      <div className="mt-2">
        <div className="row mt-2">
         <div className="ml-2">
          <p className="h6">Shipping Address</p>
        </div>
        
        {checkForAddress ? <div className="col text-right">
        {this.renderEditButton(this.showEditCustomerShippingAddressViewModal)}
           </div>:<div className="col text-right">
            {this.renderAddButton(this.showEditCustomerShippingAddressViewModal)}
          </div>}
        </div>
       
          <div className="row mt-2">
          <p className="ml-2" style={{ whiteSpace: "pre-wrap" }}>
          {shippingAddressContent}
           </p>
          </div>
        
        <p className="ml-2 text-break">{mapUrlString}</p>
        <p className="ml-2 text-break">{locationString}</p>
      </div>
    );
    const customerNotes = this.renderCustomerNotesCard();
    const currentPath = window.location.href.split("/admin")[1];
    return (
      <Card className="mb-0 pb-0">
        <Card.Body className="mb-0 pb-0">
          {customerInfo}
          <div className="ui divider"></div>
          {shippingAddress}
          <div className="ui divider"></div>
          {customerNotes}
          <div className="ui divider"></div>
        { currentPath.includes("/supplement/")?<></>: this.renderCustomerIDInfo()}
        </Card.Body>
      </Card>
    );
  };

  renderPaymentInfoCard = () => (
    <Card>
      <Card.Body>
        <PaymentInformation
          paymentDetails={this.getPaymentDetails()}
          onEdit={this.showEditPaymentInfoViewModal}
          updatePaymentMethod={this.updatePaymentMethod}
       //   OnChangeStatus={this.state.isCodMamopayPaymentStatus}
        />
        <div className="ui divider"></div>
        <TransactionInformation
          transactionDetails={this.getTransactionDetails()}
          
          updateCODMamoPayementStatus={this.updateCODMamoPayementStatus}
          
        />
      </Card.Body>
    </Card>
  );
  renderCancelCompletedOrderButton=()=>(
    <button
     onClick={this.showCompleteCancelButton}
     className="btn button btn-danger">Cancel Order
    </button>
  )


  renderCancelOrderButton = () => (
    <button
      onClick={(e) =>
        this.setState({ isConfirmOrderCancelViewModalVisible: true ,orderType:"parent"})
      }
      disabled={!this.state.isEditable}
      className="btn button btn-danger"
    >
      Cancel Order
    </button>
  );
   
  renderCancelRefundOrderButton=()=>(
    <button
    onClick={(e) =>
      this.setState({ isConfirmOrderCancelRefundViewModalVisible: true,orderType:"parent" })
    }
    disabled={!this.state.isEditable}
    className="btn button btn-danger"
  >
    Refund & Cancel
  </button>
  )

  

  renderBundleItemsCard=(index, cartItem, cartItemDetails)=>{
  
   const {itemType="", bundleItemDetails=[]  } = cartItemDetails || {};
   const isShowBundleSupplements=itemType === "Bundle" && (bundleItemDetails && bundleItemDetails.length>0)
   if(isShowBundleSupplements){
   return bundleItemDetails.map((item)=>{
      return (
         <div style={{width:"50%", display:"flex",marginTop:"10px", gap:"10px"}}>
         <div className="">
         <img
            src={item.bundleItemImage}
            alt="BundleItem image"
            style={{
              maxWidth: "50px",
              height:"55px",
              objectFit: "contain",
            }}
          />
         </div>
         <div className="" style={{textAlign:"center",padding:"10px"}}>{item.bundleItemName}</div>
         </div>
      )
    })

   
   }
  else return(<></>)
    
  }

  
  handleSavingMember= async()=>{
   
    this.showLoading()
    try{
      
      await this.fetchOrder()
      
        this.handleHideEditCartOrderViewModal()
         this.handleHideEditCartOrderNoAppointmentViewModal()
      this.showSuccessNotification("Family Member updated successfully for this order.");
    }
    catch(error){
     console.log("Error in saving selecting member", error.message)
     this.showErrorNotification(
      "Error on updating parent order status: " + error.message
    );
    }
   
   
  }

  handleCancelMember = () => {
    this.handleHideEditCartOrderViewModal()
    this.handleHideEditCartOrderNoAppointmentViewModal()
  }
  handleSaveReschedule=async()=>{
    this.showLoading()
    try{
      
      await this.fetchOrder()
      
        this.handleHideEditCartOrderViewModal()
         this.handleHideEditCartOrderNoAppointmentViewModal()
      this.showSuccessNotification("Order is Rescheduled successfully");
    }
    catch(error){
     console.log("Error in saving order", error.message)
     this.showErrorNotification(
      "Error on updating parent order status: " + error.message
    );
    }
  }
  handleCancelReschedule=()=>{
    this.handleHideEditCartOrderViewModal()
    this.handleHideEditCartOrderNoAppointmentViewModal()
  }
  render() {
    const {
      order,
      cartItem,
      cartItemDetails,
      labs,
      homeCareServices,
      wellbeingCoaches,
      isNotifyClientCancel,
      isConfirmOrderCancelViewModalVisible,
      isConfirmOrderCancelRefundViewModalVisible,
      isEditCustomerNotesViewModalVisible,
      isEditCustomerIDInfoViewModalVisible,
      isEditOrderNotesViewModalVisible,
      isEditPaymentInfoViewModalVisible,
      isEditCartOrderBasicViewModalVisible,
      isEditCartOrderViewModalVisible,
      isEditCartOrderNoAppointmentViewModalVisible,
      isEditCustomerShippingAddressViewModalVisible,
      isEditClientNotesViewModalVisible,
      paymentGateways,
      agents,
      operationsUsers,
      isEditable,
      familyMembers,
      externalCoaches,
      isCancelCompletedModal,
      userCityAddress,
      nurses,
      isEditDependentIDInfoViewModalVisible
    } = this.state;
  
   const {orderStatus, isDraftOrder=false}= order ?? {};
   const currentPath = window.location.href.split("/admin")[1];

 
    if (order === null || order === undefined) {
      // console.log("render:: Invalid order:", order);
      return null;
    }
    
    const { userDetails } = order ?? {};
    const { transactionDetails } = order ?? {};
    const paymentInfo = {
      subtotal: transactionDetails.totalAmount,
      total: transactionDetails.finalAmount,
    };

    const cartItemsCard = this.renderCartItemsCard();

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-1">
            <i
              onClick={() => this.props.history.goBack()}
              className="caret square left icon big"
              style={{ fontSize: "40px", cursor: "pointer" }}
            ></i>
          </div>
          <div className="col-4">{this.renderPurchaseDate()}</div>
          <div className="col-3">{this.renderAgentDropdown()}</div>
          {orderStatus==="COMPLETED" && <div className="col">{this.renderDownloadInvoice()}</div>}
          {orderStatus==="COMPLETED" && <div className="col">{this.renderCancelCompletedOrderButton()}</div>}
        </div>
        <div className="row mt-4">
          <div className="col-8">
            {cartItemsCard}
            {this.renderPaymentInfoCard()}
          </div>
          <div className="col-4">

            <OrderNotesCard
              orderNotes={order.orderNotes}
              onEdit={this.showEditOrderNotesViewModal}
              isEditable={isEditable}
            />
            {this.renderCustomerInfoCard()}
            {currentPath.includes("/newOrder/") ?<></>: ["COMPLETED", "CANCELLED",].includes(order.orderStatus)  || (<div className="row ml-1">
             
          <div className="col mt-4 pl-0">
                {this.renderCancelRefundOrderButton()}
              </div>
              <div className="col mt-4 pl-0">
                {this.renderCancelOrderButton()}
              </div>
              
              
              </div> )}
          </div>
        </div>
        <ConfirmOrderCancelViewModal
          cancelNotesFinal={this.state.cancelNotes}
          isVisible={isConfirmOrderCancelViewModalVisible}
          onHide={this.hideCancelNotesViewModal}
          notifyClient={isNotifyClientCancel}
          onCancel={this.handleSaveCancelNotes}
          reason={this.state.cancelReason}
          
        />
        <ConfirmCancelRefundNotes
          title={order.refundAndCancelOrderNotes ? "Edit Refund Note" : " Refund & Cancel Order"}
          refundNotes={this.state.orderRefundNotesModal}
          notifyClient={this.state.isNotifyClientRefundCancel}
          onHide={this. handleHideRefundOrderNotesModal}
          onSave={this.handleSaveRefundNotes}
          isVisible={isConfirmOrderCancelRefundViewModalVisible}
          onCancel={this.cancelOrder}
          reason={this.state.cancelReason}
        />

        <EditOrderNotes
          title={order.orderNotes ? "Edit Order Note" : "Add Order Note"}
          orderNotes={this.state.orderNotesForModal}
          onHide={this.handleHideEditOrderNotesViewModal}
          onSave={this.handleSaveOrderNotes}
          isVisible={isEditOrderNotesViewModalVisible}
        />
        <EditCustomerShippingAddress
        title={
          "Add Shipping Address"
        }
         shippingAddress={this.state.shippingAddressModal ?? null}
         onHide={this.handleHideEditCustomerShippingAddressViewModal}
         onSave={this.handleSaveShippingAddress}
         isVisible={isEditCustomerShippingAddressViewModalVisible}
        order={order}
        userCityAddress={userCityAddress??[]}
        />
        <EditCustomerNotes
          title={
            userDetails.clientNote ? "Edit Customer Note" : "Add Customer Note"
          }
          customerNotes={this.state.customerNotesForModal}
          onHide={this.handleHideEditCustomerNotesViewModal}
          onSave={this.handleSaveCustomerNote}
          isVisible={isEditCustomerNotesViewModalVisible}
        />
       <EditCustomerNotes
       title={
        order.clientNotes ? "Edit Client Note" : "Add Client Note"
      }
      customerNotes={this.state.clientNotesForModal}
      onHide={this.handleHideEditClientNotesViewModal}
      onSave={this.handleSaveClientNote}
      isVisible={isEditClientNotesViewModalVisible}
      />

        <EditCustomerIDInfo
          customerIDInfo={this.state.customerIDInfoForModal ?? null}
          onHide={this.handleHideEditCustomerIDInfoViewModal}
          onSave={this.handleSaveCustomerIDInfo}
          isVisible={isEditCustomerIDInfoViewModalVisible}

        />

        <EditDependentIDInfo
              customerIDInfo={this.state.dependentIDInfoForModal?? null}
              onHide={this.handleHideDependentIDInfoViewModal}
              onSave={this.handleSaveDependentIDInfo}
              isVisible={isEditDependentIDInfoViewModalVisible}
        />

        <EditPaymentInfoModal
          paymentInfo={paymentInfo}
          onSave={this.handleSavePaymentInfo}
          onHide={this.handleHideEditPaymentInfoViewModal}
          isVisible={isEditPaymentInfoViewModalVisible}
        />
        <EditCartOrderBasicViewModal
          order={cartItem}
          orderDetails={cartItemDetails}
          onSave={ (orderDetails, updatedDetails) => {
            this.setState(
              { isSaving: true }, 
              () => this.handleSaveCartOrderBasic(orderDetails, updatedDetails)
            )
          }}
          // onSave={this.handleSaveCartOrderBasic}
          onHide={this.handleHideEditCartOrderBasicViewModal}
          isVisible={isEditCartOrderBasicViewModalVisible}
          countryId={this.getCountryId()}
          cityId={this.getCityId()}
          labs={labs}
          homeCareServices={homeCareServices}
          wellbeingCoaches={wellbeingCoaches}
          paymentGateways={paymentGateways}
          operationsUsers={operationsUsers}
        />
        <EditCartOrderViewModal
          order={cartItem}
          orderDetails={cartItemDetails}
          countryId={this.getCountryId()}
          cityId={this.getCityId()}
          labs={labs}
          homeCareServices={homeCareServices}
          nurses={nurses}
          wellbeingCoaches={wellbeingCoaches}
          onSave={ (orderDetails, updatedDetails) => {
            this.setState(
              { isSaving: true }, 
              () => this.handleSaveCartOrder(orderDetails, updatedDetails)
            )
          }}
          // onSave={this.handleSaveCartOrder}
          onHide={this.handleHideEditCartOrderViewModal}
          isVisible={isEditCartOrderViewModalVisible}
          paymentGateways={paymentGateways}
          operationsUsers={operationsUsers}
          isSaving={this.state.isSaving ?? false}
          isPaymentPendingOrder={false}
          familyMembers={familyMembers}
          userDetails={userDetails}
          onSavingFamilyMember={()=>this.handleSavingMember()}
          onCancelFamilyMember={()=>this.handleCancelMember()}
          transactionDetails={transactionDetails}
          externalCoaches={externalCoaches}
          onSaveReschedule={()=>this.handleSaveReschedule()}
          onCancelReschedule={()=>this.handleCancelReschedule()}
          isDraftOrder={order?.isDraftOrder??false}
        />
        <EditCartOrderNoHomeAppointmentViewModal
          order={cartItem}
          orderDetails={cartItemDetails}
          countryId={this.getCountryId()}
          cityId={this.getCityId()}
          labs={labs}
          homeCareServices={homeCareServices}
          nurses={nurses}
          wellbeingCoaches={wellbeingCoaches}
          onSave={(orderDetails, updatedDetails) => {
            this.setState(
              { isSaving: true }, 
              () => this.handleSaveCartOrder(orderDetails, updatedDetails)
            )
          }}
          // onSave={this.handleSaveCartOrder}
          onHide={this.handleHideEditCartOrderNoAppointmentViewModal}
          isVisible={isEditCartOrderNoAppointmentViewModalVisible}
          paymentGateways={paymentGateways}
          agents={agents}
          operationsUsers={operationsUsers}
          familyMembers={familyMembers}
          userDetails={userDetails}
          onSavingFamilyMember={()=>this.handleSavingMember()}
          onCancelFamilyMember={()=>this.handleCancelMember()}
          externalCoaches={externalCoaches}
        />
        <OrdersListCancel
        order={this.state.order}
        isVisible={isCancelCompletedModal}
        onHide={this.handleHideCompleteCancelButton}
        onSave={this.handleListCancelItems}

        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersDashboardDetails);
