import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import { compareStrings } from "utils/commons";

SelectSupplementCategories.propTypes = {
  countryId: PropTypes.number.isRequired,

  selectedSupplementCategoryIds: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectSupplementCategories.defaultTypes = {
  selectedSupplementCategoryIds: [],
  onSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectSupplementCategories(props) {
  const {
    countryId,
    menuPlacement,
    selectedSupplementCategoryIds,
    onSelect,
    showOnlyActive = false,
    isDisabled = false,
  } = props;
  
  const [supplementCategories, setSupplementCategories] = useState([]);
  useEffect(() => {
    if (!countryId) {
      console.log("Invalid country id!", { countryId });
      return;
    }
    const fetchSupplementCategories = async () => {
      try {
        const { data: supplementCategories = [] } =
          (await PhoenixAPI.get("/supplement-category/v2/admin")) || [];
       
        const filteredPackagesForCountry =
          supplementCategories &&
          supplementCategories.filter((item) => item.country === countryId);
        setSupplementCategories(filteredPackagesForCountry);
      } catch (error) {
        console.log("Error on fetching Coach Consultations", error);
      }
    };
    fetchSupplementCategories();
  }, [countryId]);
  const findSupplementCategory = (id) =>
    supplementCategories.find((it) => it.id === id);

  const [activeSupplementCategories, inactiveSupplementCategories] =
    _.partition(supplementCategories, (it) => it.status == "Active");

  activeSupplementCategories.sort((first, second) =>
    compareStrings(first.nameEnglish, second.nameEnglish, true)
  );

  inactiveSupplementCategories.sort((first, second) =>
    compareStrings(first.nameEnglish, second.nameEnglish, true)
  );

  const options = showOnlyActive
    ? activeSupplementCategories.map(createOption)
    : [
        {
          label: "Active",
          options: activeSupplementCategories.map(createOption),
        },
        {
          label: "Inactive",
          options: inactiveSupplementCategories.map(createOption),
        },
      ];
  
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selectedSupplementCategories = selectedSupplementCategoryIds
    .map(findSupplementCategory)
    .map(createOption);

  return (
    <Select
      key={`my_unique_select_key__${
        selectedSupplementCategoryIds && selectedSupplementCategoryIds.id
      }`}
      value={selectedSupplementCategories}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder="Select Category"
      menuPlacement={"top"}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

const createOption = (supplementCategory) => {
  return supplementCategory
    ? {
        key: supplementCategory.id,
        value: supplementCategory.id,
        label: supplementCategory.nameEnglish,
      }
    : null;
};

export default SelectSupplementCategories;
