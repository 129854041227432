import React, { useState } from "react";
import { connect } from "react-redux";
import {messaging} from "../../config/firebase"
import PhoenixAPI from "../../utils/PhoenixAPI"
import { getUserId } from "services/UserService";
import "./profileform.scss";

export default function ProfileDetails(props) {
  const { user } = props;

  const nursePanelIsArabic = JSON.parse(localStorage.getItem("nursePanelIsArabic"))
  const [arabic,setArabic] = useState(nursePanelIsArabic);
  const language = arabic? "English" : "العربية"

  const handleBackButton = () => {
    props.history.goBack();
  };
  const handleLogout = async() => {
    localStorage.removeItem("8js2jbhca");
    localStorage.removeItem("selectedTab");
    window && window.clevertap && window.clevertap.logout && window.clevertap.logout();
    window && window.clevertap && window.clevertap.clear && window.clevertap.clear();
    const token = await messaging.getToken();
    if(token){
      const userId=getUserId()
      try{
        const res=await PhoenixAPI.patch(`nurse/nurse-device/logout?fcmToken=${token}&userId=${userId}`)
        console.log("res", res)
      }
      catch(error){
       console.log("Error in daving fcm token", error.message)
      }
    }
   
    window.location.href = "/admin/login";
  };

  const getUserName=()=>{
    const {user_profile, first_name="", last_name=""}=user.user ??{};
    if(user_profile){
      return user_profile.full_name ?? ""
    }
    else{
      return  first_name+""+last_name
    }
  }

  const nursePanelLanguageChange = ()=>{
    if(window.localStorage && localStorage.getItem("nursePanelIsArabic")){
      const isArabic = JSON.parse(localStorage.getItem("nursePanelIsArabic"));
      localStorage.setItem("nursePanelIsArabic",JSON.stringify(!isArabic));
      setArabic(!arabic)
      window.location.reload();
    }
    else{
      localStorage.setItem("nursePanelIsArabic",JSON.stringify(true));
      setArabic(!arabic)
      window.location.reload();
    }
  }

  return (
    <div className="profileform">
      <div className={arabic? "ar-profile-card" : "profile-card"}>
        <div className="user-img">
          <img src="https://d25uasl7utydze.cloudfront.net/assets/Group.svg" />
        </div>
        <div className="profile-details">
          {" "}
          {getUserName()}
          <div className="user-id-det"> ID: {user?.user?.id}</div>
        </div>
      </div>

      <div className={arabic? "ar-language-card" : "language-card"} style={{cursor:'pointer'}} onClick={()=>{nursePanelLanguageChange()}} >
      <div className="language-card-flag" >
          <img src="https://d25uasl7utydze.cloudfront.net/assets/flag_2.png" alt="" />
          <p>Language</p>
        </div>
        <div className="language-card-div">
          <img src="https://d25uasl7utydze.cloudfront.net/assets/left_arrow_2.png" alt="" className="language-card-left-arrow" />
          <div>{language}</div>
          <img src="https://d25uasl7utydze.cloudfront.net/assets/left_arrow_2.png" alt="" className="language-card-right-arrow" />
        </div>
      </div>

      <div className="logout-btn" onClick={handleLogout}>
        {arabic? "تسجيل الخروج" : "Logout"}
      </div>
    </div>
  );
}
