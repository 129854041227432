import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

function SelectCoach(props) {
  const { coaches, selectedCoachId, isDisabled, onCoachSelect } = props;
  const findCoach = (coachId) => coaches.find((coach) => coach.id === coachId);

  const isValidName = (name) => name && name.trim() !== "";

  const createOption = (coach) => {
    if (!coach) return;
    return {
      key: coach.id,
      value: coach.id,
      label: `${coach.name}`,
    };
  };

  coaches.forEach((coach) => {
    const { firstName, lastName, userName } = coach;
    coach.name =
      isValidName(firstName) && isValidName(lastName)
        ? `${firstName} ${lastName}`
        : userName;
  });

  const coachWithValidName = coaches
    .filter((coach) => isValidName(coach.name))
    .sort((firstCoach, secondCoach) => {
      const [firstCoachFirstName = "", firstCoachLastName = ""] =
        firstCoach.name.split(" ");
      const [secondCoachFirstName, secondCoachLastName] =
        secondCoach.name.split(" ");
      return (
        compareStrings(firstCoachFirstName, secondCoachFirstName, true) ||
        compareStrings(firstCoachLastName, secondCoachLastName, true)
      );
    });

  const coachOptions = coachWithValidName.map(createOption);

  const selectedCoach = findCoach(selectedCoachId);
  if (selectedCoach)
    console.log(
      `SelectCoach:: coach found with coachId: ${selectedCoachId} coach: ${selectedCoach.name}`
    );
  else
    console.log(
      `SelectCoach:: coach not found with coachId: ${selectedCoachId}`
    );
  const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

  return (
    <Select
      key={`my_unique_select_key__${selectedCoachId}`}
      value={createOption(selectedCoach)}
      onChange={(option) => onCoachSelect(option.value)}
      options={coachOptions}
      styles={selectStyles}
      isDisabled={isDisabled}
      placeholder="Select Wellbeing Coach"
    />
  );
}

SelectCoach.propTypes = {
  coaches: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCoachId: PropTypes.number,
  isDisabled: PropTypes.bool,
  onCoachSelect: PropTypes.func.isRequired,
};

SelectCoach.defaultProps = {
  coaches: [],
  selectedCoachId: "",
  isDisabled: false,
  onCoachSelect: (f) => f,
};

export default SelectCoach;
